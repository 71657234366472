import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ClientRootState } from "../reducers";
import { AdvancedPerformanceChartMode, AdvancedPerformanceTableMode, APIAssetSearchItem, CorporateActionDashboardItem, CorporateActionsDashboard, ExpTableConfig, getCorpActionTypeStr, IndexCardItem, IndexMaster, PriceValuesByAMID, SortConfig, TableType, UserProfile, UserProfileKeys, Watchlist, WatchlistCardItem, WatchlistEditItem, WatchlistItem, WatchlistSearch, WatchlistTypeEnum, WatchlistWithLoading, AssetTypeNameMapping, OnFormSubmissionResponse, AssetTypeSeqNoMapping } from "../../constants";
import { createDateObjectIgnoreTimeZone, getDefaultSubCategoryForAsset, getEffectiveCategIDAndSubCategID, getWatchlistPriceURLs } from "../../utilities";
import dateFormat from "dateformat";
import { SetActivePortfolioPayload } from "./portfoliosReducer";
import { Big } from 'big.js';
import { BarChartData } from "../../constants/barCharts";
import { formatPercent } from "../../utilities/numberFormat";
import { Actions as LoginActions } from './loginReducer';
import { Actions as PerformanceActions } from './performanceReducer';
import { CustomAssetAllocationCategory, CustomAssetAllocationMapping, CustomAssetAllocationDefault, AssetCategorizationData, CustomAssetAllocationCategoryUpdate, CustomAssetAllocationMappingUpdate, CustomAssetAllocationMappingsDict } from "../../constants/assetMasters";

export interface BlendItem {
    MProfitCode: number;
    Percentage: number;
}

export interface BlendedBenchmark {
    ID: string;
    CustomerId: number;
    Blend: BlendItem[];
    SortOrder: number;
}

export interface SaveBlendPayload {
    CCID: number;
    FamilyId: number;
    Blend: BlendItem[];
    SortOrder: number;
}

interface InsertCustomAssetAllocationCategoryPayload {
    customAssetAllocationCategName: string;
    callback?: (newCategoryId: number) => void;
}

interface InsertCustomAssetAllocationSubCategoryPayload {
    customAssetAllocationCategID: number;
    customAssetAllocationSubCategName: string;
    callback?: (newSubCategoryId: number) => void;
}

export interface SharedDashboardState {
    CorporateActions: CorporateActionsDashboard;
    IndicesWatchlist: WatchlistWithLoading;
    PerformanceIndicesWatchlist: WatchlistWithLoading;
    PerformanceSingleIndex?: WatchlistItem;
    XIRRBenchmarkSingleIndex?: WatchlistItem;
    WatchList: WatchlistWithLoading;
    WatchListSearch: WatchlistSearch;
    IndicesMaster: IndexMaster[];
    IndicesForPerformanceMaster?: IndexMaster[];
    UserProfile: UserProfile;
    IsUserProfileFetchedFromAPI: boolean;
    TableConfigs: {
        [key in TableType]?: {
            sortConfig: SortConfig;
            tableMode?: AdvancedPerformanceTableMode;
            expTableConfig?: ExpTableConfig;
            scrollPosition?: number;
            searchTerm?: string;
        };
    };
    customAssetAllocationCategories: CustomAssetAllocationCategory[];
    customAssetAllocationMappings: CustomAssetAllocationMapping[];
    customAssetAllocationDefaults: CustomAssetAllocationDefault[];
    BlendedBenchmarks: BlendedBenchmark[];
    XIRRBlendedBenchmarkID?: string;
    XIRRBlendedBenchmarkStatus?: {
        IsSuccess: boolean;
        Message: string;
    };
    customAssetAllocationAssets: {
        [assetTypeId: number]: Array<{AMID: number, AssetName: string, AssetAllocationCategID: number}>;
    };
}

const initialState: SharedDashboardState = {
    CorporateActions: {
        IsLoading: true
    },
    IndicesWatchlist: {
        IsLoading: true
    },
    PerformanceIndicesWatchlist: {
        IsLoading: true
    },
    WatchList: {
        IsLoading: true
    },
    WatchListSearch: {
        IsLoading: true
    },
    IndicesMaster: [],
    IndicesForPerformanceMaster: [],
    PerformanceSingleIndex: {
        AssetName: 'TRI Nifty 50',
        MProfitCode: 99906
    },
    XIRRBenchmarkSingleIndex: undefined,
    UserProfile: {
        ShowZeroValues: true,
        ShowInLakhsMillions: true,
        ShowDecimals: false,
        Separator: 'IN',
        IsUseCustomCategorisation: false,
    },
    IsUserProfileFetchedFromAPI: false,
    TableConfigs: {},
    customAssetAllocationCategories: [],
    customAssetAllocationMappings: [],
    customAssetAllocationDefaults: [],
    BlendedBenchmarks: [],
    XIRRBlendedBenchmarkID: undefined,
    XIRRBlendedBenchmarkStatus: {
        IsSuccess: false,
        Message: ''
    },
    customAssetAllocationAssets: {},
}

const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        refreshIndicesMaster: (state) => {

        },
        setIndicesMaster: (state, action: PayloadAction<IndexMaster[]>) => {
            state.IndicesMaster = action.payload;
        },
        refreshIndicesForPerformanceMaster: (state) => {

        },
        setIndicesForPerformanceMaster: (state, action: PayloadAction<IndexMaster[]>) => {
            state.IndicesForPerformanceMaster = action.payload;
        },
        setCorporateActions: (state, action: PayloadAction<CorporateActionDashboardItem[]>) => {
            state.CorporateActions = {
                IsLoading: false,
                List: action.payload
            };
        },
        refreshCorporateActions: (state, action: PayloadAction<SetActivePortfolioPayload>) => {
            state.CorporateActions = {
                IsLoading: true
            };
        },
        updateIndicesWatchlist: (state, action: PayloadAction<{ WatchlistType: WatchlistTypeEnum, Indices: { [MProfitCode: number]: boolean } }>) => {

        },
        setIndicesWatchlist: (state, action: PayloadAction<Watchlist>) => {
            state.IndicesWatchlist = {
                IsLoading: false,
                Watchlist: action.payload
            };

            if (action.payload && action.payload.Items && action.payload.Items.length > 0) {
                state.IndicesWatchlist.WatchlistPriceURLs = getWatchlistPriceURLs(action.payload.Items);
            }
        },
        refreshIndices: (state) => {
            state.IndicesWatchlist = {
                IsLoading: true
            };
        },
        setIndicesPriceValues: (state, action: PayloadAction<PriceValuesByAMID>) => {
            state.IndicesWatchlist.WatchlistPriceValues = action.payload;
        },
        refreshIndicesPrices: () => { },
        setPerformanceIndicesWatchlist: (state, action: PayloadAction<Watchlist>) => {
            state.PerformanceIndicesWatchlist = {
                IsLoading: false,
                Watchlist: action.payload
            };
        },
        refreshPerformanceIndices: (state, action: PayloadAction<{ IsUserUpdate?: boolean }>) => {
            state.PerformanceIndicesWatchlist = {
                IsLoading: true
            };
        },
        setPerformanceSingleIndex: (state, action: PayloadAction<{ IndexItem: WatchlistItem, AdvancedPerformanceChartMode?: AdvancedPerformanceChartMode }>) => {
            state.PerformanceSingleIndex = action.payload.IndexItem;
        },
        setXIRRBenchmarkSingleIndex: (state, action: PayloadAction<{ IndexItem: WatchlistItem, NoRefresh?: boolean }>) => {
            state.XIRRBenchmarkSingleIndex = action.payload.IndexItem;
        },
        refreshWatchList: (state) => {
            state.WatchList.IsLoading = true;
        },
        setWatchList: (state, action: PayloadAction<Watchlist>) => {
            state.WatchList.IsLoading = false;
            state.WatchList.Watchlist = action.payload;

            if (action.payload && action.payload.Items && action.payload.Items.length > 0) {
                state.WatchList.WatchlistPriceURLs = getWatchlistPriceURLs(action.payload.Items);
            }
        },
        setWatchlistPriceValues: (state, action: PayloadAction<PriceValuesByAMID>) => {
            state.WatchList.WatchlistPriceValues = action.payload;
        },
        refreshSearchWatchlistListAPI: (state, action: PayloadAction<string>) => {
            state.WatchListSearch = {
                IsLoading: true,
                Term: action.payload
            }
        },
        setSearchWatchList: (state, action: PayloadAction<APIAssetSearchItem[]>) => {
            state.WatchListSearch.IsLoading = false;
            state.WatchListSearch.Items = action.payload;
        },
        addItemToWatchList: (state, action: PayloadAction<WatchlistEditItem>) => {
        },
        removeItemFromWatchList: (state, action: PayloadAction<WatchlistEditItem>) => {
        },
        refreshWatchlistPrices: (state) => {
        },
        updateWatchList: (state, action: PayloadAction<WatchlistEditItem[]>) => {

        },
        onCloseSearchWatchList: (state) => {
            state.WatchListSearch = initialState.WatchListSearch;
        },
        clearWatchListWithPrices: (state) => {
            state.WatchList = initialState.WatchList;
        },
        fetchUserProfile: (state) => {

        },
        setUserProfileFromAPI: (state, action: PayloadAction<UserProfileItemPayload[]>) => {
            state.IsUserProfileFetchedFromAPI = true;
            if (action.payload?.length > 0) {
                action.payload.forEach((item) => {
                    (state.UserProfile[item.Key] as any) = item.Value;
                })
            }
        },
        addUpdateUserProfileItem: (state, action: PayloadAction<{ Item: UserProfileItemPayload[], NoUpdateOnServer?: boolean }>) => {
            if (!action.payload.Item?.length) return;
            
            action.payload.Item.forEach(item => {
                (state.UserProfile[item.Key] as any) = item.Value;
            });
        },
        setTableConfig: (state, action: PayloadAction<{ tableType: TableType, sortConfig: SortConfig, tableMode?: AdvancedPerformanceTableMode, expTableConfig?: ExpTableConfig, scrollPosition?: number, searchTerm?: string }>) => {
            const { tableType } = action.payload;
            state.TableConfigs[tableType] = {
                sortConfig: action.payload.sortConfig,
                tableMode: action.payload.tableMode,
                expTableConfig:action.payload.expTableConfig,
                scrollPosition:action.payload.scrollPosition,
                searchTerm:action.payload.searchTerm,
            };
        },
        initializeTableConfig: (state, action: PayloadAction<{ tableType: TableType, sortConfig: SortConfig, tableMode?: AdvancedPerformanceTableMode,  expTableConfig?: ExpTableConfig, scrollPosition?: number, searchTerm?: string }>) => {
            if (!state.TableConfigs[action.payload.tableType]) {
                state.TableConfigs[action.payload.tableType] = {
                    sortConfig: action.payload.sortConfig,
                    tableMode: action.payload.tableMode,
                    expTableConfig:action.payload.expTableConfig,
                    scrollPosition:action.payload.scrollPosition,
                    searchTerm:action.payload.searchTerm,
                };
            }
        },
        setTableConfigMode: (state, action: PayloadAction<{ tableType: TableType, tableMode: AdvancedPerformanceTableMode }>) => {
            if (state.TableConfigs[action.payload.tableType]) {
                state.TableConfigs[action.payload.tableType]!.tableMode = action.payload.tableMode;
            }
        },
        resetTableConfig: (state, action: PayloadAction<{ tableType: TableType }>) => {
            const { tableType } = action.payload;
            if (state.TableConfigs[tableType]) {
                delete state.TableConfigs[tableType];
            }
        },
        refreshCustomAssetAllocationCategories: (state, action: PayloadAction<number>) => {},
        setCustomAssetAllocationCategories: (state, action: PayloadAction<CustomAssetAllocationCategory[]>) => {
            state.customAssetAllocationCategories = action.payload;
        },
        refreshCustomAssetAllocationMappings: (state, action: PayloadAction<number>) => {},
        setCustomAssetAllocationMappings: (state, action: PayloadAction<CustomAssetAllocationMapping[]>) => {
            state.customAssetAllocationMappings = action.payload;
        },
        refreshCustomAssetAllocationDefaults: (state, action: PayloadAction<number>) => {},
        setCustomAssetAllocationDefaults: (state, action: PayloadAction<CustomAssetAllocationDefault[]>) => {
            state.customAssetAllocationDefaults = action.payload;
        },
        updateCustomAssetAllocationCategories: (state, action: PayloadAction<{ categories: CustomAssetAllocationCategoryUpdate }>) => {},
        updateCustomAssetAllocationMappings: (state, action: PayloadAction<{
            mappings: CustomAssetAllocationMappingUpdate;
        }>) => {},
        updateCustomAssetAllocationCategoryName: (state, action: PayloadAction<{
            categoryUpdate: {
                CustomCategName: string;
                CustomAssetAllocationCategType: number;
                CustomCategID: number;
                CustomParentCategID: number;
            }
        }>) => {},
        resetCustomAssetAllocationToDefault: (state, action: PayloadAction<{
            includeAssets?: boolean;
            includeAssetAllocationCategs?: boolean;
            onAPIResponse?: OnFormSubmissionResponse | undefined;
        }>) => {},
        deleteCustomAssetAllocationCategory: (state, action: PayloadAction<{
            categoryDelete: {
                customAssetAllocationCategType: number;
                customCategID: number;
                customParentCategID: number;
            },
            onAPIResponse?: OnFormSubmissionResponse | undefined;
        }>) => {},
        insertCustomAssetAllocationCategory: (state, action: PayloadAction<InsertCustomAssetAllocationCategoryPayload>) => {},
        insertCustomAssetAllocationSubCategory: (state, action: PayloadAction<InsertCustomAssetAllocationSubCategoryPayload>) => {},
        fetchCustomAssetAllocationAssets: (state, action: PayloadAction<{ assetTypeId: number }>) => {},
        setCustomAssetAllocationAssets: (state, action: PayloadAction<{assetTypeId: number, assets: Array<{AMID: number, AssetName: string, AssetAllocationCategID: number}>}>) => {
            state.customAssetAllocationAssets[action.payload.assetTypeId] = action.payload.assets;
        },
        refreshBlendedBenchmarks: (state, action: PayloadAction<{ CCID: number, FamilyId: number }>) => { },
        setBlendedBenchmarks: (state, action: PayloadAction<BlendedBenchmark[]>) => {
            state.BlendedBenchmarks = action.payload;
        },
        saveBlendedBenchmark: (state, action: PayloadAction<SaveBlendPayload>) => { },
        setXIRRBlendedBenchmarkID: (state, action: PayloadAction<{ BlendedBenchmarkID?: string, NoRefresh?: boolean }>) => {
            state.XIRRBlendedBenchmarkID = action.payload.BlendedBenchmarkID;
        },
        deleteBlendedBenchmark: (state, action: PayloadAction<{ ObjectID: string, CCID: number, FamilyId: number }>) => {},
        setXIRRBlendedBenchmarkStatus: (state, action: PayloadAction<{ IsSuccess: boolean, Message: string }>) => {
            state.XIRRBlendedBenchmarkStatus = {
                IsSuccess: action.payload.IsSuccess,
                Message: action.payload.Message
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(LoginActions.logout, () => initialState)
            .addCase(PerformanceActions.clearCachedXIRR, (state) => {
                state.XIRRBenchmarkSingleIndex = undefined;
            })
            .addCase(LoginActions.resetData, () => initialState)
    }
});


//#region Actions

//#endregion

//#region Payloads

export interface WatchlistPayload { WatchlistType: WatchlistTypeEnum; WatchlistID?: string, CCID: number }
export interface UserProfileItemPayload { Key: UserProfileKeys; Value: any }

//#endregion

export default DashboardSlice.reducer;
export const Actions = { ...DashboardSlice.actions };

//#region Selectors

const dashboardSelector = (state: ClientRootState) => state.shared.dashboard;
const selectCorporateActionsRaw = (state: ClientRootState) => dashboardSelector(state).CorporateActions;
const selectCorporateActionsIsLoading = (state: ClientRootState) => selectCorporateActionsRaw(state).IsLoading;
const selectWatchListSearch = (state: ClientRootState) => dashboardSelector(state).WatchListSearch;
const selectSearchWatchListIsLoading = (state: ClientRootState) => selectWatchListSearch(state).IsLoading;
const selectSearchWatchListItems = (state: ClientRootState) => selectWatchListSearch(state).Items;
// const selectWatchListWithPricesRaw = (state: ClientRootState) => dashboardSelector(state).WatchListWithPrice;

const selectWatchlistRaw = (state: ClientRootState) => dashboardSelector(state).WatchList;
const selectWatchlistIsLoading = (state: ClientRootState) => selectWatchlistRaw(state).IsLoading;
const selectWatchlist = (state: ClientRootState) => selectWatchlistRaw(state).Watchlist;
const selectWatchlistPriceURLs = (state: ClientRootState) => selectWatchlistRaw(state).WatchlistPriceURLs;
const selectWatchlistPriceValues = (state: ClientRootState) => selectWatchlistRaw(state).WatchlistPriceValues;

// const selectWatchListWithPricesIsLoading = (state: ClientRootState) => selectWatchListWithPricesRaw(state).IsLoading;

const selectWatchListWithPrices = createSelector([selectWatchlist, selectWatchlistPriceValues],
    (watchlist, priceValues): WatchlistCardItem[] | undefined => {
        if (!watchlist || !watchlist.Items) return undefined;

        var watchlistValues: WatchlistCardItem[] = [];

        watchlist.Items.forEach((item, index) => {

            var prices = priceValues ? priceValues[item.MProfitCode] : undefined;

            if (prices) {
                watchlistValues.push({
                    MProfitCode: item.MProfitCode,
                    AssetName: item.AssetName,
                    Price: Big(prices.CP),
                    TGain: Big(prices.CP).minus(prices.PP),
                    TGainPct: Big(prices.PP).gt(0) ? Big(prices.CP).div(prices.PP).minus(1) : undefined,
                    TUp: Big(prices.CP).gt(prices.PP)
                })
            } else {
                watchlistValues.push({
                    MProfitCode: item.MProfitCode,
                    AssetName: item.AssetName
                })
            }
        })

        return watchlistValues;
    })

const selectCorporateActionsList = createSelector([selectCorporateActionsRaw, (state, AMID: number) => AMID], (x, AMID) => {
    if (x == null || x.IsLoading || x.List == null) {
        return [];
    } else {
        return (AMID > 0 ? x.List.filter(x => x.AMID === AMID) : x.List).map<CorporateActionDashboardItem>(c => ({
            ...c,
            CorpActionTypeStr: getCorpActionTypeStr(c.CorpActionType),
            CorpActionDateDisplay: dateFormat(createDateObjectIgnoreTimeZone(c.CorpActionDateStr), 'd mmm, yyyy')
        }));
    }
});

const selectIndicesWatchlistRaw = (state: ClientRootState) => dashboardSelector(state).IndicesWatchlist;
const selectIndicesWatchlistIsLoading = (state: ClientRootState) => selectIndicesWatchlistRaw(state).IsLoading;
const selectIndicesWatchlist = (state: ClientRootState) => selectIndicesWatchlistRaw(state).Watchlist;
const selectIndicesWatchlistPriceURLs = (state: ClientRootState) => selectIndicesWatchlistRaw(state).WatchlistPriceURLs;
const selectIndicesWatchlistPriceValues = (state: ClientRootState) => selectIndicesWatchlistRaw(state).WatchlistPriceValues;

const selectTodayPerformanceIndicesChartValues = createSelector([selectIndicesWatchlist, selectIndicesWatchlistPriceValues], (watchlist, priceValues) => {
    if (!priceValues || !watchlist || !watchlist.Items) return undefined;

    var chartValues: BarChartData[] = [];

    watchlist.Items.forEach((item, index) => {
        var prices = priceValues[item.MProfitCode];
        if (prices) {
            var valueBig = Big(prices.CP).div(prices.PP).minus(1);
            chartValues.push({
                Name: item.AssetName || `Index ${index}`,
                Value: valueBig.toNumber(),
                ValueStr: formatPercent(valueBig, 2, 'IN')
            })
        }
    })

    return chartValues;
});

const selectLeftNavIndexCardData = createSelector([selectIndicesWatchlist, selectIndicesWatchlistPriceValues],
    (watchlist, priceValues) => {
        if (!priceValues || !watchlist || !watchlist.Items) return undefined;

        var indexValues: IndexCardItem[] = [];

        watchlist.Items.forEach((item, index) => {

            var prices = priceValues[item.MProfitCode];

            if (prices) {
                indexValues.push({
                    IndexName: item.AssetName || `Index ${index}`,
                    IndexValue: Big(prices.CP),
                    TGain: Big(prices.CP).minus(prices.PP),
                    TGainPct: Big(prices.PP).gt(0) ? Big(prices.CP).div(prices.PP).minus(1) : Big(0),
                    TUp: Big(prices.CP).gt(prices.PP)
                })
            }
        })

        return indexValues;
    })

const selectIndicesMaster = (state: ClientRootState) => dashboardSelector(state).IndicesMaster;
const selectIndicesForPerformanceMaster = (state: ClientRootState) => dashboardSelector(state).IndicesForPerformanceMaster || [];
const selectIndicesForPerformanceMasterMapped = createSelector(
    [selectIndicesForPerformanceMaster],
    (indices): { [key: number]: IndexMaster } => {
        return indices.reduce((acc, index) => {
            acc[index.MProfitCode] = index;
            return acc;
        }, {} as { [key: number]: IndexMaster });
    }
);

const selectIndicesForBlendedBenchmarksMaster = createSelector(
    [selectIndicesForPerformanceMaster],
    (indices): IndexMaster[] => {
        return indices.filter(x => !x.BSECode || x.BSECode <= 0);
    }
);


const selectBlendedBenchmarks = (state: ClientRootState) => dashboardSelector(state).BlendedBenchmarks;
const selectBlendedBenchmarksMapped = createSelector(
    [selectBlendedBenchmarks],
    (benchmarks): { [key: string]: BlendedBenchmark } => {
        return benchmarks.reduce((acc, benchmark) => {
            acc[benchmark.ID] = benchmark;
            return acc;
        }, {} as { [key: string]: BlendedBenchmark });
    }
);
const selectXIRRBlendedBenchmarkID = (state: ClientRootState) => dashboardSelector(state).XIRRBlendedBenchmarkID;
const selectXIRRBlendedBenchmarkStatus = (state: ClientRootState) => dashboardSelector(state).XIRRBlendedBenchmarkStatus;

const selectPerformanceIndicesWatchlistRaw = (state: ClientRootState) => dashboardSelector(state).PerformanceIndicesWatchlist;
const selectPerformanceIndicesWatchlistIsLoading = (state: ClientRootState) => selectPerformanceIndicesWatchlistRaw(state).IsLoading;
const selectPerformanceIndicesWatchlist = (state: ClientRootState) => selectPerformanceIndicesWatchlistRaw(state).Watchlist;

const selectPerformanceSingleIndex = (state: ClientRootState) => dashboardSelector(state).PerformanceSingleIndex;
const selectXIRRBenchmarkSingleIndex = (state: ClientRootState) => dashboardSelector(state).XIRRBenchmarkSingleIndex;

const selectUserProfile = (state: ClientRootState) => dashboardSelector(state).UserProfile;
const selectIsUserProfileFetchedFromAPI = (state: ClientRootState) => dashboardSelector(state).IsUserProfileFetchedFromAPI;
const selectShowInLakhsMillions = (state: ClientRootState) => selectUserProfile(state).ShowInLakhsMillions;
const selectShowDecimals = (state: ClientRootState) => selectUserProfile(state).ShowDecimals;
const selectShowTutorial_Main = (state: ClientRootState) => selectIsUserProfileFetchedFromAPI(state) && selectUserProfile(state).IsWebTutorialViewed_Main !== true;
const selectShowTutorial_Today = (state: ClientRootState) => selectIsUserProfileFetchedFromAPI(state) && selectUserProfile(state).IsWebTutorialViewed_Today !== true;
const selectShowTutorial_Holding = (state: ClientRootState) => selectIsUserProfileFetchedFromAPI(state) && selectUserProfile(state).IsWebTutorialViewed_Holding !== true;
const selectShowTutorial_Performance = (state: ClientRootState) => selectIsUserProfileFetchedFromAPI(state) && selectUserProfile(state).IsWebTutorialViewed_Performance !== true;
const selectTableConfig: (tableType: TableType) => (state: ClientRootState) => { sortConfig: SortConfig, tableMode?: AdvancedPerformanceTableMode, expTableConfig?: ExpTableConfig, scrollPosition?: number, searchTerm?: string } | undefined = (tableType: TableType) => (state: ClientRootState) => state.shared.dashboard.TableConfigs[tableType];

const selectCustomAssetAllocationDefaults = (state: ClientRootState) => dashboardSelector(state).customAssetAllocationDefaults;
const selectCustomAssetAllocationCategories = (state: ClientRootState) => dashboardSelector(state).customAssetAllocationCategories;
const selectCustomAssetAllocationMappings = (state: ClientRootState) => dashboardSelector(state).customAssetAllocationMappings;
const selectCustomAssetAllocationMappingsDict = createSelector(
    [selectCustomAssetAllocationMappings],
    (mappings): CustomAssetAllocationMappingsDict => {
        return mappings.reduce((acc, mapping) => {
            const key = `${mapping.AssetTypeID}|${mapping.AssetAllocationCategID}|${mapping.AMID}`;
            return {
                ...acc,
                [key]: mapping
            };
        }, {});
    }
);

const selectCustomAssetAllocationCategoriesById = createSelector(
    [selectCustomAssetAllocationCategories],
    (categories) => categories.reduce((acc, category) => ({
        ...acc,
        [category.CategID]: {
            CategName: category.CategName,
            IsDefault: category.IsDefault,
            SubCategs: category.SubCategs?.reduce((subAcc, subCategory) => ({
                ...subAcc,
                [subCategory.SubCategID]: {
                    SubCategID: subCategory.SubCategID,
                    SubCategName: subCategory.SubCategName,
                    IsDefault: subCategory.IsDefault
                }
            }), {}) || {}
        }
    }), {} as { 
        [key: number]: { 
            CategName: string,
            IsDefault: boolean,
            SubCategs: { [key: number]: { SubCategName: string, IsDefault: boolean, SubCategID: number } } 
        } 
    })
);

const selectCustomAssetAllocationMappingsById = createSelector(
    [selectCustomAssetAllocationMappings, selectCustomAssetAllocationCategoriesById],
    (mappings, categoriesMap) => {
        return mappings.reduce((acc, mapping) => {
            const category = categoriesMap[mapping.CustomAssetAllocationCategID];
            const subCategory = category?.SubCategs[mapping.CustomAssetAllocationSubCategID];
            
            const key = `${mapping.AssetTypeID}_${mapping.AssetAllocationCategID}`;
            
            return {
                ...acc,
                [key]: {
                    CustomAssetAllocationCategID: mapping.CustomAssetAllocationCategID,
                    CustomAssetAllocationSubCategID: mapping.CustomAssetAllocationSubCategID,
                    CategName: category?.CategName || '',
                    SubCategName: subCategory?.SubCategName || '',
                    IsDefault: subCategory?.IsDefault || false
                }
            };
        }, {} as { [key: string]: {
            CustomAssetAllocationCategID: number;
            CustomAssetAllocationSubCategID: number;
            CategName: string;
            SubCategName: string;
            IsDefault: boolean;
        }});
    }
);

const selectAssetCategorizationData = createSelector(
  [selectCustomAssetAllocationDefaults, selectCustomAssetAllocationMappingsById, selectCustomAssetAllocationCategoriesById],
  (defaults, mappingsById, categoriesById): AssetCategorizationData[] => {
    var categories: AssetCategorizationData[] = defaults.map(assetGroup => ({
      AssetTypeID: assetGroup.AssetTypeID,
      AssetTypeName: AssetTypeNameMapping[assetGroup.AssetTypeID as keyof typeof AssetTypeNameMapping],
      SubCategories: assetGroup.SubCategories.map(subCategory => {
        const mappingKey = `${assetGroup.AssetTypeID}_${subCategory.AssetAllocationCategID}`;
        const mapping = mappingsById[mappingKey];
        const category = mapping ? categoriesById[mapping.CustomAssetAllocationCategID] : null;

        const isCustomMapping = mapping && 
          (subCategory.DefaultCategID !== mapping.CustomAssetAllocationCategID || 
           subCategory.DefaultSubCategID !== mapping.CustomAssetAllocationSubCategID);
        
        return {
          AssetTypeID: assetGroup.AssetTypeID,
          AssetAllocationCategID: subCategory.AssetAllocationCategID,
          DefaultSubCategName: subCategory.DefaultSubCategName,
          DefaultCategID: subCategory.DefaultCategID,
          DefaultSubCategID: subCategory.DefaultSubCategID,
          mapping: mapping ? {
            CustomAssetAllocationCategID: mapping.CustomAssetAllocationCategID,
            CustomAssetAllocationSubCategID: mapping.CustomAssetAllocationSubCategID,
            CategName: mapping.CategName,
            SubCategName: mapping.SubCategName,
            isDefault: category?.IsDefault ?? false,
            mappingType: (isCustomMapping ? 'custom' : 'default') as 'custom' | 'default'
          } : undefined
        };
      })
    })).sort((a, b) => AssetTypeSeqNoMapping[a.AssetTypeID as keyof typeof AssetTypeSeqNoMapping] - AssetTypeSeqNoMapping[b.AssetTypeID as keyof typeof AssetTypeSeqNoMapping]);

    return categories;
  }
);

const selectCategoryOptions = createSelector(
  [selectCustomAssetAllocationCategoriesById],
  (categoriesById) => Object.entries(categoriesById).map(([id, category]) => ({
    id: Number(id),
    name: category.CategName,
    isDefault: category.IsDefault
  })).sort((a, b) => a.name.localeCompare(b.name))
);

const selectAssetCategoriesTableData = createSelector(
  [selectCustomAssetAllocationCategories],
  (categories): {
    categoryId: number;    
    subCategoryId: number; 
    name: string;         
    RowType: 'Primary' | 'Secondary';
    parentId?: number;
    isDefault: boolean;
  }[] => {
    var categoryList: any[] = [];
    if (!categories) return categoryList;

    [...categories]
    .sort((a, b) => a.CategName.localeCompare(b.CategName))
    .forEach((category) => {
      // Add primary row (category)
      categoryList.push({
        categoryId: category.CategID,
        subCategoryId: 0,
        name: category.CategName,
        RowType: 'Primary',
        isDefault: category.IsDefault
      });

      // Add secondary rows (subcategories)
      if (category.SubCategs && category.SubCategs.length > 0) {
        [...category.SubCategs]
        .sort((a, b) => a.SubCategName.localeCompare(b.SubCategName))
        .forEach((subCategory) => {
          categoryList.push({
            categoryId: category.CategID,
            subCategoryId: subCategory.SubCategID,
            name: subCategory.SubCategName,
            RowType: 'Secondary',
            parentId: category.CategID,
            isDefault: subCategory.IsDefault
          });
        });
      }
    });

    return categoryList;
  }
);

const selectCustomAssetAllocationAssets = (state: ClientRootState) => dashboardSelector(state).customAssetAllocationAssets;

const selectAssetCategorizationViewData = createSelector(
    [
        selectCustomAssetAllocationDefaults,
        selectCustomAssetAllocationMappingsDict,
        selectCustomAssetAllocationCategoriesById,
        selectCustomAssetAllocationAssets,
        selectAssetCategorizationData   
    ],
    (defaults, mappingsDict, categoriesById, customAssets, assetCategorizationData) => {
        return Object.entries(customAssets).map(([assetTypeIdStr, assets]) => {
            const assetTypeId = Number(assetTypeIdStr);

            return {
                AssetTypeID: assetTypeId,
                AssetTypeName: AssetTypeNameMapping[assetTypeId as keyof typeof AssetTypeNameMapping],
                Assets: assets.map(asset => {
                    const {effectiveCategID, effectiveSubCategID} = getEffectiveCategIDAndSubCategID(
                        assetTypeId,
                        asset.AMID,
                        mappingsDict,
                        null,
                        asset.AssetAllocationCategID
                    );
                    
                    const category = categoriesById[effectiveCategID];
                    const subCategory = category?.SubCategs?.[effectiveSubCategID];

                    const defaultSubCategory = getDefaultSubCategoryForAsset(
                        assetTypeId,
                        asset.AMID,
                        null,
                        assetCategorizationData,
                        asset.AssetAllocationCategID
                    );

                    const isCustomMapping = defaultSubCategory 
                        ? (defaultSubCategory.DefaultCategID !== effectiveCategID || 
                            defaultSubCategory.DefaultSubCategID !== effectiveSubCategID)
                        : false;
                    
                    return {
                        ...asset,
                        CustomAssetAllocationCategID: effectiveCategID,
                        CustomAssetAllocationSubCategID: effectiveSubCategID,
                        CategName: category?.CategName || '',
                        SubCategName: subCategory?.SubCategName || '',
                        mappingType: isCustomMapping ? 'custom' : 'default',
                        DefaultCategID: defaultSubCategory?.DefaultCategID || -1,
                        DefaultSubCategID: defaultSubCategory?.DefaultSubCategID || -1
                    };
                })
            };
        });
    }
);

export const selectIsUseCustomCategorisationPreference = (state: ClientRootState) => selectUserProfile(state).IsUseCustomCategorisation;

export const Selectors = {
    selectIndicesMaster,
    selectIndicesForPerformanceMaster,
    selectCorporateActionsList,
    selectCorporateActionsIsLoading,
    selectIndicesWatchlistIsLoading,
    selectIndicesWatchlist,
    selectIndicesWatchlistPriceURLs,
    selectTodayPerformanceIndicesChartValues,
    selectLeftNavIndexCardData,
    selectWatchListSearch,
    selectSearchWatchListItems,
    selectSearchWatchListIsLoading,
    selectWatchlistIsLoading,
    selectWatchlistPriceURLs,
    selectWatchlistRaw,
    selectWatchlistPriceValues,
    selectWatchListWithPrices,
    selectPerformanceIndicesWatchlist,
    selectPerformanceIndicesWatchlistIsLoading,
    selectPerformanceSingleIndex,
    selectXIRRBenchmarkSingleIndex,
    selectUserProfile,
    selectShowInLakhsMillions,
    selectShowDecimals,
    selectShowTutorial_Main,
    selectShowTutorial_Today,
    selectShowTutorial_Holding,
    selectShowTutorial_Performance,
    selectTableConfig,
    selectCustomAssetAllocationDefaults,
    selectCustomAssetAllocationCategories,
    selectCustomAssetAllocationMappings,
    selectCustomAssetAllocationCategoriesById,
    selectCustomAssetAllocationMappingsById,
    selectAssetCategorizationData,
    selectCategoryOptions,
    selectAssetCategoriesTableData,
    selectIsUseCustomCategorisationPreference,
    selectCustomAssetAllocationMappingsDict,
    selectCustomAssetAllocationAssets,
    selectAssetCategorizationViewData,
    selectBlendedBenchmarks,
    selectBlendedBenchmarksMapped,
    selectIndicesForPerformanceMasterMapped,
    selectIndicesForBlendedBenchmarksMaster,
    selectXIRRBlendedBenchmarkID,
    selectXIRRBlendedBenchmarkStatus
};

//#endregion
