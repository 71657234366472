import { Box, Grid, IconButton, Stack } from "@mui/material";
import { Dispatch, SetStateAction, memo, useEffect, useState } from "react";
import CustomModal from "../../shared/custom-modal";
import { CustomTypography } from "../../shared";
import { CloseIcon } from "packages/web/src/assets/icons/CloseIcon";
import ButtonText from "../../shared/button-text";
import ButtonPrimaryCTA from "../../shared/button-primary-cta";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities } from "@mprofit/shared";
import { useNavigate } from "react-router-dom";
import { NavigateToPortfolio } from "packages/web/src/routes/navigationMethods";
import { DatabaseDisplay } from "../databaseDisplay";

const SwitchAccountModal = memo(({ isModalOpen, setIsModalOpen, onSwitchAccount }: {isModalOpen: boolean, setIsModalOpen: Dispatch<SetStateAction<boolean>>, onSwitchAccount: () => void}) => {
  const [selectedDatabase, setSelectedDatabase] = useState<SharedConstants.DatabaseItem | undefined>(undefined);
  const databaseList = useAppSelector(SharedRedux.Portfolios.Selectors.selectDatabases);
  const activeDatabase = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveDatabase)

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDatabaseSelect = (database: SharedConstants.DatabaseItem) => {
    setSelectedDatabase(database);
  };

  const onNavigateToPortfolio = (activePortfolio: SharedRedux.Portfolios.SetActivePortfolioPayload) => {
    NavigateToPortfolio({ navigate, activePortfolio, pathname: '/portfolio/today' });
  }

  const handleSwitchAccount = () => {
    navigate('/portfolio/today');
    dispatch(SharedRedux.Login.Actions.resetData());
    dispatch(SharedRedux.Login.Actions.loadDashboard({
      CCID: Number(selectedDatabase?.CustomerId),
      OnNavigateToPortfolio: onNavigateToPortfolio
    }));
    setIsModalOpen(false);
    onSwitchAccount();
  };

  useEffect(() => {
    setSelectedDatabase(activeDatabase);
  }, [activeDatabase]);

  return (
    <CustomModal
        open={isModalOpen}
        handleClose={()=> setIsModalOpen(false)}
      >
        <Box sx={{
          width: { sm: '60%', md: '55%' },
          background: "white",
          borderRadius: "10px",
        }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ margin: "10px 10px 0px 30px" }}>
            <CustomTypography variant="Medium24">
              Switch Account
            </CustomTypography>
            <IconButton onClick={()=> setIsModalOpen(false)} disableRipple>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box sx={{ borderTop: "1px solid #EBECF2" }} />
          <CustomTypography variant="Regular16" sx={{ color: "#12131A", margin: "20px 0px 8px 30px" }}>CURRENT ACCOUNT</CustomTypography>
          <Box sx={{ padding: "0px 16px 0px 16px" }}>
            <DatabaseDisplay database={activeDatabase} />
          </Box>
          <CustomTypography variant="Regular16" sx={{ color: "#12131A", margin: "40px 0px 4px 30px" }}>SWITCH TO ANOTHER ACCOUNT</CustomTypography>
          <Box sx={{ padding: "16px 30px 0px 30px" }}>
            <Grid container spacing={2}>
              {databaseList.map((database, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <DatabaseDisplay
                    hoverBackgroundColor="#EAF4DF"
                    borderColor="#D8DAE5"
                    showRadioButton
                    database={database}
                    selectedDatabase={selectedDatabase}
                    onSelect={onDatabaseSelect}
                    index={index}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ display: "flex", padding: "20px", justifyContent: "space-between", borderTop: "1px solid #EBECF2", marginTop: "64px" }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
              <Box>
                <ButtonText buttonText="Cancel" handleClick={() => setIsModalOpen(false)} buttonSize="large" />
              </Box>
              <Box>
                <ButtonPrimaryCTA
                  buttonText="Switch Account"
                  handleClick={() => {
                    handleSwitchAccount();
                  }}
                  disabled={!selectedDatabase || activeDatabase?.CustomerId === selectedDatabase?.CustomerId}
                />
              </Box>
            </Box>
          </Box>
        </Box>
    </CustomModal>
  )
});

export default SwitchAccountModal;
