export function EditIcon(props: any) {
  return (
    <svg
      id="Group_151562"
      data-name="Group 151562"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 40}
      height={props.height || 40}
      viewBox="0 0 40 40"
      {...props}
    >
      <rect
        id="Rectangle_24197"
        data-name="Rectangle 24197"
        width={props.width || 40}
        height={props.height || 40}
        fill="#fff"
        opacity={0}
      />
      <path
        id="Path_198669"
        data-name="Path 198669"
        d="M17.236,4.544l1.73-1.731a1.923,1.923,0,0,1,2.72,2.72L6.949,20.27A4.615,4.615,0,0,1,5,21.429L2.25,22.25,3.071,19.5A4.615,4.615,0,0,1,4.229,17.55L17.238,4.544Zm0,0L19.942,7.25"
        transform="translate(8.25 8.25)"
        fill="none"
        stroke={props.color || "#3a3d4d"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  );
}