import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import TodayLayout from "../layout";
import { PieChartWithBottomLegend } from "../../../shared";
import { useAppSelector, useAppDispatch } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities } from "@mprofit/shared";
import SharedVirtualizedTable, { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import { TodayCardL2AssetTypeIcons } from "packages/web/src/assets/icons/assetTypes";
import TodayValueCard from "../todayValueCard";
import TodayGainLoss from "../todayGainLoss";
import TodayPerformanceColumnChart from "../todayPerformanceColumnChart";
import { NavigateToAsset, NavigateToL3 } from "packages/web/src/routes/navigationMethods";
import { useNavigate, useLocation } from 'react-router-dom';
import DropDown, { DropdownItem } from "../../../shared/drop-down";
import ScreenerIcon from "packages/web/src/assets/icons/screenerIcon";
import AssetAllocationIcon from "packages/web/src/assets/icons/assetAllocationIcon";
import { useState } from "react";
import EditCategorizationModal from "../level_4/L4StocksInfoCard/EditCategorizationModal";

interface L2_Today_Props {
  MPrUrlParams: MPrUrlParams;
}

type SelectedMapping = {
  categoryId?: number;
  subCategoryId?: number;
};

export default function L2_Today(props: L2_Today_Props) {
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);

  const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);
  const assetAllocationCategID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategID);

  return <TodayLayout
    {...props}
    Screen_TodayValueCard={L2TodayValueCard}
    Screen_TodayGainLossCard={L2TodayGainLossCard}
    Screen_PieChart={L2PieChart}
    Screen_TodayTable={L2TodayTable}
    Screen_TodayPerformanceColumnChart={L2TodayPerformanceColumnChart}
    Screen_TodayTableDropdown={L2TodayTableDropdown}
    IsLoading={isLoading}
    DefaultTableDropdownValue={SharedConstants.HoldingsMode.Assets}
    ShowStocksCards={assetTypeID == SharedConstants.AssetTypeEnum.EQ && !(assetAllocationCategID && assetAllocationCategID > 0)}
    TableTitle={'Current Investments'}
  />;
}

const L2TodayValueCard = () => {
  const L2Total = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL2PortfolioSummaryTotal);

  const assetTypeIDName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetTypeIDName);
  const assetTypeID: SharedConstants.AssetTypeEnum | undefined = assetTypeIDName.AssetTypeID;

  const assetAllocationCategName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategName);

  return <TodayValueCard
    ScreenType={assetAllocationCategName || 'Asset Class'}
    Title={assetTypeIDName.AssetTypeName}
    Screen={SharedConstants.ScreenEnum.L2}
    CurrentValue={L2Total?.CurrValue}
    Icon={assetTypeID ? IconMapping[assetTypeID] : undefined}
  />
}

const L2TodayGainLossCard = () => {
  const L2Total = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL2PortfolioSummaryTotal);

  return <TodayGainLoss TotalValues={L2Total} />
}

const L2TodayTable = ({dropdownValue, searchTerm}: {dropdownValue: SharedConstants.HoldingsMode, searchTerm: string}) => {
  const [editCategModalState, setEditCategModalState] = useState<{
    isOpen: boolean;
    selectedMapping: {
      categoryId?: number;
      subCategoryId?: number;
    };
    row?: RowDataProps;
  }>({
    isOpen: false,
    selectedMapping: {}
  });

  const tableData = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL2TodaysTable(dropdownValue)) || [];
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);
  const showCustomCategEditButton = useAppSelector(SharedRedux.Licensing.Selectors.selectShowCustomCategEditButton);

  if (!assetTypeID) return <></>;

  const handleSave = (event?: React.MouseEvent) => {
    event?.stopPropagation();
    const { selectedMapping, row } = editCategModalState;
    if (selectedMapping.categoryId && selectedMapping.subCategoryId && activeScreenIDs?.DatabaseID && row) {
      dispatch(SharedRedux.Dashboard.Actions.updateCustomAssetAllocationMappings({
        mappings: {
          List: [{
            AssetTypeID: row.AssetTypeID || -1,
            AssetAllocationCategID: -1,
            AMID: row.AMID || -1,
            CustomAssetAllocationCategID: selectedMapping.categoryId,
            CustomAssetAllocationSubCategID: selectedMapping.subCategoryId
          }]
        }
      }));
      setEditCategModalState({
        isOpen: false,
        selectedMapping: {}
      });
    }
  };

  const menuItems = dropdownValue === SharedConstants.HoldingsMode.Assets ? [
    {
      icon: <ScreenerIcon />,
      text: "Open in Screener",
      onClick: (row: RowDataProps) => {
        var screenerInURL = 'https://screener.in/company/';
        if (row.BSECode && row.BSECode > 0) {
          screenerInURL += `bse:${encodeURIComponent(row.BSECode)}/`;
        } else {
          screenerInURL += `nse:${encodeURIComponent(row.NSESymbol)}/`;
        }
        window.open(screenerInURL, '_blank');
      },
      show: (row: RowDataProps) => row.ParentMProfitCode <= 0 && ((row.NSESymbol && row.NSESymbol.trim() != '') || (row.BSECode && row.BSECode > 0))
    },
    {
      icon: <AssetAllocationIcon />,
      text: "Edit Categorisation",
      onClick: (row: RowDataProps) => {
        setEditCategModalState({
          isOpen: true,
          selectedMapping: {},
          row
        });
      },
      show: (row: RowDataProps) => showCustomCategEditButton && row.AssetTypeID !== SharedConstants.AssetTypeEnum.LN
    }
  ] : [];

  const onRowClick = (row: RowDataProps) => {
    if (row && assetTypeID && assetTypeID > 0) {
      if (dropdownValue == SharedConstants.HoldingsMode.Assets && row.AMID > 0) {
        NavigateToAsset({navigate, activeScreenIDs, assetTypeID: assetTypeID, AMID: row.AMID, SID: row.SID, pathname: location.pathname });
      } else if (dropdownValue == SharedConstants.HoldingsMode.Sectors && row.CategID != undefined) {
        NavigateToL3({ navigate, activeScreenIDs, assetTypeID: assetTypeID, SectorID: row.CategID, L3Mode: SharedConstants.HoldingsMode.Sectors, pathname: location.pathname })
      } else if (dropdownValue == SharedConstants.HoldingsMode.MarketCap && row.CategID != undefined) {
        NavigateToL3({ navigate, activeScreenIDs, assetTypeID: assetTypeID, MarketCapID: row.CategID, L3Mode: SharedConstants.HoldingsMode.MarketCap, pathname: location.pathname })
      } else if (dropdownValue == SharedConstants.HoldingsMode.MFSEBICategory && row.CategID != undefined) {
        NavigateToL3({ navigate, activeScreenIDs, assetTypeID: assetTypeID, SEBICategoryID: row.CategID, L3Mode: SharedConstants.HoldingsMode.MFSEBICategory, pathname: location.pathname })
      } else if (dropdownValue == SharedConstants.HoldingsMode.MFSEBISubCategory && row.CategID != undefined && row.SEBICategoryID != undefined) {
        NavigateToL3({ navigate, activeScreenIDs, assetTypeID: assetTypeID, SEBICategoryID: row.SEBICategoryID, SEBISubCategoryID: row.CategID, L3Mode: SharedConstants.HoldingsMode.MFSEBISubCategory, pathname: location.pathname })
      }
    }
  }

  return (
    <>
      <SharedVirtualizedTable 
        columns={getColumns(dropdownValue, assetTypeID)} 
        rows={tableData} 
        headerColor="#EAF4DF" 
        sorting={true} 
        onRowClick={onRowClick} 
        searchTerm={searchTerm} 
        fieldNamesToSearch={[dropdownValue == SharedConstants.HoldingsMode.Assets ? 'Name' : 'CategName']}
        defaultSortConfig={SharedConstants.DefaultSortingForTodayTable} 
        tableType={SharedConstants.TableType.Today}
        menuItems={assetTypeID === SharedConstants.AssetTypeEnum.LN ? undefined : menuItems}
      />

      {showCustomCategEditButton && editCategModalState.isOpen && editCategModalState.row && (
        <EditCategorizationModal
          isOpen={editCategModalState.isOpen}
          onClose={() => setEditCategModalState({isOpen: false, selectedMapping: {}})}
          selectedMapping={editCategModalState.selectedMapping}
          setSelectedMapping={(mapping: SelectedMapping | ((prev: SelectedMapping) => SelectedMapping)) => 
            setEditCategModalState(prev => ({...prev, selectedMapping: typeof mapping === 'function' ? mapping(prev.selectedMapping) : mapping}))}
          handleSave={handleSave}
          assetTypeID={editCategModalState.row.AssetTypeID}
          assetAllocationCategID={editCategModalState.row.AssetAllocationCategID}
          assetAllocationCategName={editCategModalState.row.AssetAllocationCategName}
          companyName={editCategModalState.row.Name}
          assetAllocationSubCategName={editCategModalState.row.AssetAllocationSubCategName}
          amid={editCategModalState.row.AMID}
        />
      )}
    </>
  );
}

const L2TodayTableDropdown = (props: {  dropdownValue: string | number; handleChange?: (selected: DropdownItem) => void}) => {
  const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);
  const assetAllocationCategID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategID);

  if (assetAllocationCategID && assetAllocationCategID > 0) return <></>;
  
  var dropdownList: DropdownItem[] = [];
  
  switch (assetTypeID) {
    case SharedConstants.AssetTypeEnum.EQ:
      dropdownList = [
        { id: SharedConstants.HoldingsMode.Assets, name: 'Assets' },
        { id: SharedConstants.HoldingsMode.Sectors, name: 'Sectors' },
        { id: SharedConstants.HoldingsMode.MarketCap, name: 'Market Cap' }
      ];
      break;
    case SharedConstants.AssetTypeEnum.MFEQ:
    case SharedConstants.AssetTypeEnum.MFD:
      dropdownList = [
        { id: SharedConstants.HoldingsMode.Assets, name: 'Assets' },
        { id: SharedConstants.HoldingsMode.MFSEBICategory, name: 'Category' },
      ];
      break;
    default:
      return <></>;
  }
  
  return <DropDown
    inputValue={props.dropdownValue}
    inputList={dropdownList}
    handleChange={props.handleChange}
  />
}

const L2PieChart = () => {
  const { PieChartData, TotalValue } = useAppSelector((state) => SharedRedux.PortfolioSummary.Selectors.selectL2PieChartData_ByAsset(state, 'CurrValue'));

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);

  const onItemClick = (item: SharedConstants.PieChartData) => {
    console.log('onitemClick', item);

    if (item && assetTypeID && assetTypeID > 0 && item.IDs && item.IDs.AMID > 0) {
      NavigateToAsset({navigate, activeScreenIDs, assetTypeID: assetTypeID, AMID: item.IDs.AMID, SID: item.IDs.SID, pathname: location.pathname });
    }
  }

  return <PieChartWithBottomLegend data={PieChartData} totalValue={TotalValue} onItemClick={onItemClick}/>
}

const L2TodayPerformanceColumnChart = () => {
  const assetType = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);
  const indexChartData = useAppSelector(SharedRedux.Dashboard.Selectors.selectTodayPerformanceIndicesChartValues);
  
  if (assetType == SharedConstants.AssetTypeEnum.EQ) {
    return <L2TodayPerformanceColumnChartStocks indexChartData={indexChartData}/>
  } else {
    return <TodayPerformanceColumnChart data={indexChartData} />
  }
}

const L2TodayPerformanceColumnChartStocks = ({indexChartData}: {indexChartData: SharedConstants.BarChartData[] | undefined}) => {
  const myStocksTGainPct = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectMyStocksTGainPct);
  return <TodayPerformanceColumnChart data={indexChartData && myStocksTGainPct ? [{ Name: 'My Stocks & ETFs', Value: myStocksTGainPct, IsBlack: true }, ...indexChartData].slice(0, 6) : indexChartData} myDataName="your Stocks & ETFs"/>
}

const commonTableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'TGain',
    HeaderName: "TODAY'S GAIN",
    ColumnWidthPercent: '13%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'TGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'TUp'
      }
    ]
  },
  {
    FieldKey: 'OGain',
    HeaderName: 'UNREALISED GAIN',
    ColumnWidthPercent: '13%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'OGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'OUp'
      }
    ]
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '13%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  }
];

export const getColumns = (holdingsMode: SharedConstants.HoldingsMode, assetTypeID: SharedConstants.AssetTypeEnum, headerName: string | undefined = undefined, headerFieldKey: string | undefined = undefined): ColumnDataProps[] => {
  return [
    {
      FieldKey: headerFieldKey || (holdingsMode == SharedConstants.HoldingsMode.Assets ? 'Name' : 'CategName'),
      HeaderName: headerName || SharedUtilities.getHoldingsCategName(holdingsMode),
      ColumnWidthPercent: '22%',
      FieldFormatter: TableDefaultFieldFormatter,
      Align: 'left',
      IsRowName: true
    },
    ...(
      holdingsMode == SharedConstants.HoldingsMode.Assets && assetTypeID < SharedConstants.AssetTypeEnum.ASSET_WITH_QUANT
      ?
      [
        {
          FieldKey: 'Quant',
          HeaderName: "QTY",
          ColumnWidthPercent: '13%',
          FieldFormatter: TableDecimalFieldFormatter,
          Align: 'right'
        },
        {
          FieldKey: 'PxPur',
          HeaderName: "AVG PUR PRICE",
          ColumnWidthPercent: '13%',
          FieldFormatter: TableDecimalFieldFormatter,
          Align: 'right'
        },
        {
          FieldKey: 'PxCurr',
          HeaderName: "CURRENT PRICE",
          ColumnWidthPercent: '13%',
          FieldFormatter: TableDecimalFieldFormatter,
          Align: 'right'
        }
      ]
      :
      ([] as any[])
    ),
    ...commonTableColumns
  ]
}

const IconMapping = {
  [SharedConstants.AssetTypeEnum.EQ]: <TodayCardL2AssetTypeIcons.EQ />,
  [SharedConstants.AssetTypeEnum.ULIP]: <TodayCardL2AssetTypeIcons.ULIP />,
  [SharedConstants.AssetTypeEnum.MFEQ]: <TodayCardL2AssetTypeIcons.MFEQ />,
  [SharedConstants.AssetTypeEnum.INS]: <TodayCardL2AssetTypeIcons.INS />,
  [SharedConstants.AssetTypeEnum.MFD]: <TodayCardL2AssetTypeIcons.MFD />,
  [SharedConstants.AssetTypeEnum.PE]: <TodayCardL2AssetTypeIcons.PE />,
  [SharedConstants.AssetTypeEnum.FD]: <TodayCardL2AssetTypeIcons.FD />,
  [SharedConstants.AssetTypeEnum.BND]: <TodayCardL2AssetTypeIcons.BND />,
  [SharedConstants.AssetTypeEnum.NCD]: <TodayCardL2AssetTypeIcons.NCD />,
  [SharedConstants.AssetTypeEnum.CD]: <TodayCardL2AssetTypeIcons.CD />,
  [SharedConstants.AssetTypeEnum.PPF]: <TodayCardL2AssetTypeIcons.PPF />,
  [SharedConstants.AssetTypeEnum.PO]: <TodayCardL2AssetTypeIcons.PO />,
  [SharedConstants.AssetTypeEnum.GLD]: <TodayCardL2AssetTypeIcons.GLD />,
  [SharedConstants.AssetTypeEnum.SLV]: <TodayCardL2AssetTypeIcons.SLV />,
  [SharedConstants.AssetTypeEnum.JWL]: <TodayCardL2AssetTypeIcons.JWL />,
  [SharedConstants.AssetTypeEnum.PR]: <TodayCardL2AssetTypeIcons.PR />,
  [SharedConstants.AssetTypeEnum.ART]: <TodayCardL2AssetTypeIcons.ART />,
  [SharedConstants.AssetTypeEnum.MIS]: <TodayCardL2AssetTypeIcons.MIS />,
  [SharedConstants.AssetTypeEnum.LN]: <TodayCardL2AssetTypeIcons.LN />,
  [SharedConstants.AssetTypeEnum.BANKS]: <TodayCardL2AssetTypeIcons.BANKS />,
  [SharedConstants.AssetTypeEnum.SFNO]: <></>,
  [SharedConstants.AssetTypeEnum.OT]: <></>,
  [SharedConstants.AssetTypeEnum.SYS_ASSETYPE_LIMIT]: <></>,
  [SharedConstants.AssetTypeEnum.ASSET_WITH_QUANT]: <></>,
}