import { AxiosResponse } from 'axios';
import dateFormat from "dateformat";
import { AccessRightProperties, AssetAllocationCategID, AssetTypeEnum, DatabaseItem, FYListItem, GlobalAccessRights, HoldingsMode, MarketCap_Type, MonthItem, ScreenEnum, TrailingPeriodItem, YearListItem } from '../constants';
import { formatDateAsMonth, formatDateAsMonthYear } from './dateFormat';

export const getIsMprMinDate = (date?: Date) => {
    return date == null ? false : date <= new Date(1970, 3, 1);
}

export const getFileNameFromHttpResponse = (axiosResponse: AxiosResponse) => {
    var contentDispositionHeader = axiosResponse.headers['content-disposition'];
    if (!contentDispositionHeader) return null;
    var result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
}

export const createDateObjectIgnoreTimeZone = (dateString: string) => {
    var dateStringWithoutTimeZone = dateString.substring(dateString.length - 6).startsWith('+') ? dateString.substring(0, dateString.length - 6) : dateString;
    return new Date(dateStringWithoutTimeZone);
}


// create positive negative function here
export function isZeroOrPositive(val: number | undefined) {
    if (!val || val >= 0) {
        return true
    } else {
        return false
    }
}

export const getBarChartDomainValue = (boundaryValue: number, isMin: boolean, isPercent: boolean = true) => {
    if (isMin && boundaryValue >= 0) return 0;
    if (!isMin && boundaryValue <= 0) return 0;

    var isNegative = false;
    if (boundaryValue < 0) {
        isNegative = true;
        boundaryValue = boundaryValue * -1;
    }

    boundaryValue = Math.max(10, (Math.round(boundaryValue * (isPercent ? 100 : 1)) / 10) * 10);

    if (boundaryValue == 0) {

    } else if (boundaryValue < 100) {
        boundaryValue += 10;
    } else if (boundaryValue < 500) {
        boundaryValue += 50;
    } else if (boundaryValue < 1000) {
        boundaryValue += 100;
    } else if (boundaryValue < 3000) {
        boundaryValue += 500;
    } else {
        boundaryValue += 1000;
    }

    if (boundaryValue == 0) {

    } else if (boundaryValue < 100) {
        boundaryValue = Math.ceil(boundaryValue / 10) * 10;
    } else if (boundaryValue < 500) {
        boundaryValue = Math.ceil(boundaryValue / 50) * 50;
    } else if (boundaryValue < 1000) {
        boundaryValue = Math.ceil(boundaryValue / 100) * 100;
    } else if (boundaryValue < 5000) {
        boundaryValue = Math.ceil(boundaryValue / 500) * 500;
    } else {
        boundaryValue = Math.ceil(boundaryValue / 1000) * 1000;
    }

    return boundaryValue / (isPercent ? 100 : 1) * (isNegative ? -1 : 1);
}

export function getFY(date: Date, shiftYear: number = 0): [Date, Date] {
    var curMonth = date.getMonth() + 1;
    var curYear = date.getFullYear() + shiftYear;

    var year1: number;

    if (curMonth > 3) {
        year1 = curYear;
    } else {
        year1 = curYear - 1;
    }

    return [new Date(year1, 3, 1), new Date(year1 + 1, 2, 31)]
}

export const convertISTDateToSameDateUTC = (istDate: Date): Date => {
    var date = new Date(istDate);

    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    return date;
}

export const convertUTCDateToAssetMastersFormat = (date?: Date): string => {
    if (!date) return '';

    return dateFormat(date, 'UTC:dd-mm-yyyy');
}

export const checkSameDay = (date1: Date, date2: Date) => {
    return (date1.getFullYear() == date2.getFullYear()) && (date1.getMonth() == date2.getMonth()) && (date1.getDate() == date2.getDate())
}

export const getDefaultHoldingsModeForScreen = (screen: ScreenEnum, assetType?: AssetTypeEnum) => {
    switch (screen) {
        case ScreenEnum.L1:
            return HoldingsMode.AssetAllocation;
        case ScreenEnum.L2:
            switch (assetType) {
                case AssetTypeEnum.EQ:
                    return HoldingsMode.Sectors;
                case AssetTypeEnum.MFEQ:
                case AssetTypeEnum.MFD:
                    return HoldingsMode.MFSEBICategory;
                default:
                    return HoldingsMode.Assets;
            }
        case ScreenEnum.L3_Category:
            switch (assetType) {
                case AssetTypeEnum.MFEQ:
                case AssetTypeEnum.MFD:
                    return HoldingsMode.MFSEBISubCategory;
                default:
                    return HoldingsMode.Undefined;
            }
        default:
            return HoldingsMode.Undefined;
    }
}

export const getHoldingsModesListForScreen = (screen: ScreenEnum, assetType?: AssetTypeEnum) => {
    switch (screen) {
        case ScreenEnum.L1:
            return [
                {
                    id: HoldingsMode.AssetAllocation,
                    name: "Category"
                },
                {
                    id: HoldingsMode.MProfitClassification,
                    name: "Asset Class"
                }
            ]
        case ScreenEnum.L2:
            switch (assetType) {
                case AssetTypeEnum.EQ:
                    return [
                        {
                            id: HoldingsMode.Sectors,
                            name: "Sector"
                        },
                        {
                            id: HoldingsMode.MarketCap,
                            name: "Market Cap"
                        },
                        {
                            id: HoldingsMode.Assets,
                            name: "Asset"
                        }
                    ]
                case AssetTypeEnum.MFEQ:
                case AssetTypeEnum.MFD:
                    return [
                        {
                            id: HoldingsMode.MFSEBICategory,
                            name: "SEBI Category"
                        },
                        {
                            id: HoldingsMode.Assets,
                            name: "Asset"
                        }
                    ];
                default:
                    return [];
            }
        case ScreenEnum.L3_Category:
            switch (assetType) {
                case AssetTypeEnum.MFEQ:
                case AssetTypeEnum.MFD:
                    return [
                        {
                            id: HoldingsMode.MFSEBISubCategory,
                            name: "SEBI Sub-Category"
                        },
                        {
                            id: HoldingsMode.Assets,
                            name: "Asset"
                        }
                    ];
                default:
                    return [];
            }
        default:
            return [];
    }
}

export const getHoldingsModesForScreen = (screen: ScreenEnum, assetType?: AssetTypeEnum) => {
    return getHoldingsModesListForScreen(screen, assetType).map(x => x.id);
}

export const getHoldingsCategName = (holdingsMode: HoldingsMode) => {
    switch (holdingsMode) {
        case HoldingsMode.AssetAllocation:
            return 'Category';
        case HoldingsMode.MProfitClassification:
            return 'Asset Class';
        case HoldingsMode.Sectors:
            return 'Sector';
        case HoldingsMode.MarketCap:
            return 'Market Cap';
        case HoldingsMode.Assets:
            return 'Asset';
        case HoldingsMode.MFSEBICategory:
            return 'SEBI Category';
        case HoldingsMode.MFSEBISubCategory:
            return 'SEBI Sub-Category';
        case HoldingsMode.SubCategory:
            return 'Sub-Category';
    }

    return 'Category';
}

export const getMarketCapNameFromID = (MarketCapClassification: MarketCap_Type) => {
    switch (MarketCapClassification) {
        case MarketCap_Type.SmallCap:
            return 'Small Cap';
        case MarketCap_Type.MidCap:
            return 'Mid Cap';
        case MarketCap_Type.LargeCap:
            return 'Large Cap';
    }

    return undefined;
}

export const getMarketCapColorFromID = (MarketCapClassification: MarketCap_Type) => {
    switch (MarketCapClassification) {
        case MarketCap_Type.SmallCap:
            return '#CFE3C9';
        case MarketCap_Type.MidCap:
            return '#D2D4FF';
        case MarketCap_Type.LargeCap:
            return '#D6CDA4';
    }

    return undefined;
}

export const getCategIDStrForMultipleIDs = (ID1: string | number | undefined, ID2: string | number | undefined) => {
    return [ID1 || 0, ID2 || 0].join(':');
}

export const getMultipleIDsArrayFromCategIDStr = (CategID: string, IDFieldNames: string[]) => {
    var categIDSplit = (CategID || '').split(':');
    var IDs: { [key: string]: string } = {};

    categIDSplit.forEach((value, index) => {
        if (IDFieldNames[index]) {
            IDs[IDFieldNames[index]] = value;
        }
    })

    return IDs;
}

export const getCategIDForMFSEBISubCategoryID = (SEBICategoryID?: number, SEBISubCategoryID?: number) => {
    return getCategIDStrForMultipleIDs(SEBICategoryID, SEBISubCategoryID);
}

export const getMFSEBICategoryIDsFromCategIDStr = (CategID: string) => {
    var stringIDs = getMultipleIDsArrayFromCategIDStr(CategID, ['SEBICategoryID', 'SEBISubCategoryID']);
    var IDs: { [key: string]: number } = {};
    for (var key in stringIDs) {
        IDs[key] = +stringIDs[key];
    }

    return IDs;
}

export const filterItems = <T>(input: string | undefined, options: T[], fieldNames: string[] | undefined, returnBlankIfNoSearch = false) => {
    console.log(input, options, fieldNames, returnBlankIfNoSearch, 'filterIterms')
    if (input && input != '' && fieldNames && fieldNames.length > 0) {
        input = input.toLowerCase();
        var input_list = new RegExp('^(?=.*' + escapeRegExp(input).split(/\s+/).join(')(?=.*') + ')', 'i');

        options = options.filter((x: any) => {
            for (var fieldName of fieldNames) {
                if (input_list.test(x[fieldName])) return true;
            }

            return false;
        });
    } else if (returnBlankIfNoSearch) {
        return [];
    }

    return options;
}

export function escapeRegExp(text: string) {
    return text.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
}

export const getFinancialYearListForPeriodSelection = (isHistoricalDate: boolean = false) => {
    var today = new Date();
    var startYear = today.getFullYear() - (today.getMonth() < 3 ? 1 : 0);

    console.log('getFinancialYearListForPeriodSelection');

    var lastStartYear = startYear - 10;

    var fyList: FYListItem[] = [];

    var isCurrentFY = true;
    var isLastFY = false;

    var index = 0;

    while (startYear > lastStartYear) {
        var endYear = startYear + 1;
        var startDate = new Date(startYear, 3, 1);
        var endDate = new Date(startYear + 1, 2, 31);

        var fyStr = `FY ${(startYear + '').substring(2, 4)} - ${(endYear + '').substring(2, 4)}`;

        var currentLastFYStr = (isCurrentFY ? 'Current FY • ' : (isLastFY ? 'Last FY • ' : ''));

        var fyListItem: FYListItem = {
            index,
            startDate,
            endDate,
            fyName: fyStr,
            title: currentLastFYStr + fyStr,
            subtitle: `${dateFormat(startDate, 'd mmm yyyy')} to ${dateFormat(endDate, 'd mmm yyyy')}`
        }

        fyList.push(fyListItem);

        startYear--;
        index++;

        if (isCurrentFY) {
            isCurrentFY = false;
            isLastFY = true;
        } else if (isLastFY) {
            isLastFY = false;
        }
    }

    if (isHistoricalDate) {
        fyList.shift();
    }

    return fyList;
}

export const getTrailingPeriodListForPeriodSelection = (isHistoricalDate: boolean = false) => {
    var today = new Date();
    var yearsBeforeToday = [1, 2, 3, 4, 5];

    var list: TrailingPeriodItem[] = [];

    yearsBeforeToday.forEach((yearBeforeToday, index) => {
        list.push({
            title: `Trailing ${yearBeforeToday} year${yearBeforeToday > 1 ? 's' : ''}`,
            index,
            startDate: getNYearsBeforeDate(today, yearBeforeToday, 1),
            endDate: today
        })
    })

    return list;
}

function getNYearsBeforeDate(date: Date, N: number, addDays: number = 0): Date {
    const year = date.getFullYear() - N;
    const month = date.getMonth();
    const day = date.getDate() + addDays;

    // Create a new Date object with N years before the given date
    const newDate = new Date(year, month, day);

    return newDate;
}

export const monthsListForPeriodSelection: MonthItem[] = [
    { monthNumber: 1, shortName: 'Jan', fullName: 'January' },
    { monthNumber: 2, shortName: 'Feb', fullName: 'February' },
    { monthNumber: 3, shortName: 'Mar', fullName: 'March' },
    { monthNumber: 4, shortName: 'Apr', fullName: 'April' },
    { monthNumber: 5, shortName: 'May', fullName: 'May' },
    { monthNumber: 6, shortName: 'Jun', fullName: 'June' },
    { monthNumber: 7, shortName: 'Jul', fullName: 'July' },
    { monthNumber: 8, shortName: 'Aug', fullName: 'August' },
    { monthNumber: 9, shortName: 'Sep', fullName: 'September' },
    { monthNumber: 10, shortName: 'Oct', fullName: 'October' },
    { monthNumber: 11, shortName: 'Nov', fullName: 'November' },
    { monthNumber: 12, shortName: 'Dec', fullName: 'December' }
];

export const getMonthSelectedItem = (monthNumber: number, yearNumber: number) => {
    var selectedMonth = monthsListForPeriodSelection.find(x => x.monthNumber == monthNumber);

    if (selectedMonth) {
        var startDate = new Date(yearNumber, selectedMonth.monthNumber - 1, 1)
        return ({
            startDate,
            endDate: new Date(yearNumber, selectedMonth.monthNumber, 0),
            title: formatDateAsMonthYear(startDate) || '',
            monthNumber: selectedMonth.monthNumber,
            year: yearNumber,
            monthName: formatDateAsMonth(startDate) || '',
        });
    }

    return null;
}

export const getYearListForPeriodSelection = () => {
    var today = new Date();
    var currentYear = today.getFullYear();
    var yearList: YearListItem[] = [];
    var index = 0;
    while (currentYear > 2000) {
        yearList.push({
            index,
            year: currentYear,
            title: '' + currentYear,
            startDate: new Date(currentYear, 0, 1),
            endDate: new Date(currentYear + 1, 0, 0)
        });

        index++;
        currentYear--;
    }

    return yearList;
}

export const getYearSelectedItem = (yearsList: YearListItem[], yearNumber: number) => {
    var selectedYear = yearsList.find(x => x.year == yearNumber);

    if (selectedYear) {
        return {
            title: selectedYear.year + '',
            yearNumber: selectedYear.year,
            startDate: selectedYear.startDate,
            endDate: selectedYear.endDate
        };
    }

    return null;
}

export const getToday = () => {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

export const getMPrMinDate = () => {
    return new Date(1970, 4, 1);
}

export const getLastRefreshedTimeFromStr = (lastRefreshedTimeStr: string | undefined) => {
    if (lastRefreshedTimeStr) {
        var lastRefreshedTime = new Date(lastRefreshedTimeStr);
        if (lastRefreshedTime.getFullYear() < 2023) {
            return undefined;
        } else {
            return lastRefreshedTime;
        }
    } else {
        return undefined;
    }
}

export const convertSelectedIDDictToIDList = (selectedIDs: { [ID: number]: boolean }): number[] => {
    var list: number[] = [];
    for (const [key, value] of Object.entries(selectedIDs)) {
        if (value === true) {
            list.push(+key);
        }
    }

    return list;
}

export const getInitialsFromName = (name: string) => {
    if (!name) return '';
    var nameSplit = name.trim().split(' ');
    var firstName = nameSplit[0];
    var lastName = nameSplit[1];

    return (firstName && firstName.length > 0 ? firstName[0].toUpperCase() : '') + (lastName && lastName.length > 0 ? lastName[0].toUpperCase() : '');
}

export const truncateTextWithEllipsis = (text: string, maxCharacters: number) => {
    if (text && text.length > maxCharacters) {
        return text.substring(0, maxCharacters) + '...';
    }

    return text;
}

export const EmailValidationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isXIRRNotSupportedForAssetType = (assetType: number) => {
    return [AssetTypeEnum.LN, AssetTypeEnum.BANKS].includes(assetType);
}

export const getRefNumField = (assetType: number) => {
    switch (assetType) {
        case AssetTypeEnum.MFEQ:
        case AssetTypeEnum.MFD:
            return 'Folio #';
        case AssetTypeEnum.BANKS:
            return 'Account #';
            break;
        case AssetTypeEnum.INS:
        case AssetTypeEnum.ULIP:
            return 'Policy #';
    }

    return 'Ref #';
}

export const camelCaseToWords = (str: string) => {
    return str?.replace(/([A-Z])/g, ' $1').trim();
}

export const getPMSFeesNameFromFieldName = (fieldName: string) => {
    switch (fieldName) {
        case 'CustodyFees':
            return 'Custody Fees';
        case 'LegacyFees':
            return 'Consolidated Fees';
        case 'ManagementFees':
            return 'Management Fees';
        case 'OtherCharges':
            return 'Other Fees';
        case 'PerformanceFees':
            return 'Performance Fees';
        case 'STT':
            return 'STT';
        case 'TDSOnDividend':
            return 'TDS on Dividend';
        default:
            return camelCaseToWords(fieldName);
    }
}

interface AccessItem {
    AccessType: number,
    Add: boolean,
    Edit: boolean,
    Delete: boolean,
    View: boolean
}

interface AccessDictionary {
    [accessType: number]: GlobalAccessRights;
}

export const getAccessRightsDict = (array: AccessItem[]): AccessDictionary => {
    const dictionary: AccessDictionary = {};

    array.forEach(item => {
        dictionary[item.AccessType] = {
            Add: item.Add,
            Edit: item.Edit,
            Delete: item.Delete,
            View: item.View
        };
    });

    return dictionary;
};

export const hasAccessRights = (dictionary: AccessDictionary, accessType: number, property: AccessRightProperties): boolean => {
    return dictionary?.[accessType]?.[property] === true;
};

export const getAdvancedChartTitleForAssetAllocationCategID = (assetAllocationCategID: number | undefined) => {
    if (!assetAllocationCategID) return undefined;

    switch (assetAllocationCategID) {
        case AssetAllocationCategID.Equity:
            return 'Stocks, Equity ETFs & Equity Funds';
        case AssetAllocationCategID.Debt:
            return 'Debt ETFs & Debt Funds';
        case AssetAllocationCategID.Gold_Plus:
            return 'Gold ETFs & Gold Funds';
        case AssetAllocationCategID.Retirement:
            return 'Solution Oriented Funds';
        case AssetAllocationCategID.International:
            return 'International ETFs & International Funds';
        case AssetAllocationCategID.RealEstate:
            return 'REITs';
        case AssetAllocationCategID.Other:
            return 'Other Stocks & Funds';
    }
}

export const getDatabaseDisplayName = (database: DatabaseItem | undefined) => {
    return database ? (database.IsShared ? database.SharedByUserName : 'My Data') : '';
}

export const getTabsForEquityExposureBreakUpScreen = () => [
    { id: HoldingsMode.Assets, name: 'Stock' },
    { id: HoldingsMode.Sectors, name: 'Sector' },
    { id: HoldingsMode.MarketCap, name: 'Market Cap' }
];

export const getTabsForEquityExposureDetailedBreakUpScreen = (isGroup: boolean) => {
    const baseTabs = [
        {
            id: HoldingsMode.AssetAllocation,
            name: "Asset Type"
        },
        {
            id: HoldingsMode.Assets,
            name: "Asset"
        }
    ];

    if (isGroup) {
        baseTabs.push({
            id: HoldingsMode.Portfolio,
            name: "Portfolio"
        });
    }

    return baseTabs;
}

export const DIRECT_HOLDINGS_CATEGORY_NAME = 'Direct & PMS Holding';
export const DIRECT_STOCKS_CATEGORY_NAME = 'Direct Stocks';
export const PMS_STOCKS_CATEGORY_NAME = 'PMS Stocks';