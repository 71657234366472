export function InfoIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_150840" data-name="Group 150840" transform="translate(-180.146)">
        <rect id="Rectangle_24219" data-name="Rectangle 24219" width="24" height="24" transform="translate(180.146)" fill="rgba(255,255,255,0)" />
        <path id="Path_198461" data-name="Path 198461" d="M2.25,12A9.75,9.75,0,1,1,12,21.75,9.75,9.75,0,0,1,2.25,12Zm8.706-1.442a1.5,1.5,0,0,1,2.126,1.706L12.373,15.1l.042-.02a.75.75,0,0,1,.67,1.34l-.04.022a1.5,1.5,0,0,1-2.127-1.706l.71-2.836-.042.02a.75.75,0,1,1-.671-1.34l.041-.022ZM12,9a.75.75,0,1,0-.75-.75A.75.75,0,0,0,12,9Z" transform="translate(178.75 -0.25)" fill= {props.color || "#5f6f57"}  fillRule="evenodd" />
      </g>
    </svg>

  );
}
