import './index.module.css'
import { Button, SxProps } from '@mui/material'
import React from 'react';
import { ButtonProps as MuiButtonProps } from "@mui/material";


interface buttonPrimaryCTAProps {
    id?: string,
    buttonText: string,
    startIcon?: JSX.Element,
    endIcon?: JSX.Element,
    disabled?: boolean,
    handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    MuiButtonProps?: MuiButtonProps,
    maxHeight?: string;
    sxProps?: SxProps
}

function ButtonPrimaryCTA({ MuiButtonProps, id, buttonText, startIcon, endIcon, disabled, handleClick, maxHeight, sxProps }: buttonPrimaryCTAProps) {
    return (
        <Button
            {...MuiButtonProps}
            id={id}
            variant="contained"
            disableRipple
            startIcon={startIcon}
            endIcon={endIcon}
            disabled={disabled}
            onClick={handleClick}
            sx={{
                minWidth: 'auto',
                maxHeight: maxHeight || '48px',
                height: { xs: '40px', lg: '48px' },
                backgroundColor: '#010006',
                padding: "0px 24px",
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'none',
                fontSize: '16px',
                whiteSpace: 'nowrap',
                lineHeight: '24px',
                fontWeight: '500',
                '&:hover': { backgroundColor: '#252833' },
                '&:focus': { backgroundColor: '#12131A' },
                '&:active': { backgroundColor: '#12131A' },
                ...sxProps
            }}
        >
            {buttonText}
        </Button>
    )
}

export default ButtonPrimaryCTA