import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities, formatDateForReading } from "@mprofit/shared";
import HoldingLayout from "../layout";
import { HoldingCurrentValue } from "../holdingCurrentValue";
import { HoldingAmountInvested } from "../holdingAmountInvested";
import { HoldingUnrealisedGain } from "../holdingUnrealisedGain";
import SharedVirtualizedTable, { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import { useHoldingsMode } from "packages/web/src/classes/HoldingsModeHook";
import { HoldingAssetAllocationChart } from "../holdingAssetAllocationChart";
import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigateToAssetAllocationCateg, NavigateToAssetType, NavigateToCustomAssetAllocationCateg, NavigateToTab } from "packages/web/src/routes/navigationMethods";
import { HOLDING_L1_TOUR_STEPS } from "../../../shared/app-tour/appTourSteps";
import AppTour from "../../../shared/app-tour";
import { WebRedux } from "@mprofit/web";

interface L1_Holding_Props {
  MPrUrlParams: MPrUrlParams;
}

export default function L1_Holding(props: L1_Holding_Props) {
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);
  // const holdingsMode = useHoldingsMode_L1();

  return <HoldingLayout
    {...props}
    Screen_CurrentValueCard={CurrentValueCard}
    Screen_AmountInvestedCard={AmountInvestedCard}
    Screen_UnrealisedGainCard={UnrealisedGainCard}
    Screen_AssetAllocationChart={AssetAllocationChart}
    Screen_Table={HoldingsTable}
    isLoading={isLoading}
    tableHeading={'Current Holdings'}
    Screen_AppTour={HoldingsAppTour}
  />;
}

function useHoldingsMode_L1() {
  return useHoldingsMode(SharedUtilities.getDefaultHoldingsModeForScreen(SharedConstants.ScreenEnum.L1), SharedUtilities.getHoldingsModesForScreen(SharedConstants.ScreenEnum.L1));
}

const CurrentValueCard = () => {
  const currentValue = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryTotalCurrentValue);

  return <HoldingCurrentValue
    CurrentValue={currentValue}
  />
}

const AmountInvestedCard = () => {
  const amountInvested = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryTotalAmountInvested);

  return <HoldingAmountInvested
    AmountInvested={amountInvested}
  />
}

const UnrealisedGainCard = () => {
  const totalValues = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryTotal);

  return <HoldingUnrealisedGain
    OGain={totalValues?.OGain}
    OGainPct={totalValues?.OGainPct}
    OUp={totalValues?.OUp}
  />
}

const AssetAllocationChart = () => {
  const [totalField, setTotalField] = useState<SharedConstants.HoldingAssetAllocationDropdownValue>('CurrValue');

  const holdingsMode = useHoldingsMode_L1();
  const { PieChartData, TotalValue } = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1HoldingsPieChartData(holdingsMode, totalField));

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  const isUsingDefaultCategorization = useAppSelector(SharedRedux.Licensing.Selectors.selectIsUsingDefaultCategorization);

  const onItemClick = (item: SharedConstants.PieChartData) => {
    if (holdingsMode == SharedConstants.HoldingsMode.MProfitClassification && item && item.IDs.AssetTypeID > 0) {
      NavigateToAssetType({ navigate, activeScreenIDs, assetTypeID: item.IDs.AssetTypeID, pathname: location.pathname });
    } else if (holdingsMode == SharedConstants.HoldingsMode.AssetAllocation && item && item.IDs.AssetAllocationCategID && item.IDs.AssetAllocationCategID > 0) {
      const navigationParams = { navigate, activeScreenIDs, pathname: location.pathname }
      if (isUsingDefaultCategorization) {
        NavigateToAssetAllocationCateg({ ...navigationParams, assetAllocationCategID: item.IDs.AssetAllocationCategID });
      } else {
        NavigateToCustomAssetAllocationCateg({ ...navigationParams, customAssetAllocationCategID: item.IDs.AssetAllocationCategID  });
      }
    }
  }

  const holdingTotals = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryTotal);
  const tableData: RowDataProps[] = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1HoldingsTable(holdingsMode)) as RowDataProps[];

  return <HoldingAssetAllocationChart
    data={PieChartData}
    totalValue={TotalValue}
    dropdownValue={totalField}
    holdingsMode={holdingsMode}
    heading="Portfolio Asset Allocation"
    subHeading={`as on ${formatDateForReading(new Date())}`}
    handleDropdownChange={(selected) => { setTotalField(selected.id as SharedConstants.HoldingAssetAllocationDropdownValue) }}
    onItemClick={onItemClick}
    showDownloadPdfIcon={true}
    pdfData={{
      Totals: holdingTotals,
      Table:{
        columnData: (holdingsMode == SharedConstants.HoldingsMode.MProfitClassification ? tableColumns_byAssetType : tableColumns_byAssetAllocation),
        rowData: tableData,
        chartHeading: 'Current Holdings'
      }
    }}
  />
}

const HoldingsTable = ({ searchTerm }: { searchTerm: string }) => {
  const holdingsMode = useHoldingsMode_L1();

  const tableData = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1HoldingsTable(holdingsMode));
  console.log('tableDataH', tableData);

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  const isUsingDefaultCategorization = useAppSelector(SharedRedux.Licensing.Selectors.selectIsUsingDefaultCategorization);

  const onRowClick = (row: RowDataProps) => {
    if (row && row.AssetTypeID > 0) {
      NavigateToAssetType({ navigate, activeScreenIDs, assetTypeID: row.AssetTypeID, pathname: location.pathname });
    } else if (row && row.AssetAllocationCategID && row.AssetAllocationCategID > 0) {
      const navigationParams = { navigate, activeScreenIDs, pathname: location.pathname }
      if (isUsingDefaultCategorization) {
        NavigateToAssetAllocationCateg({ ...navigationParams, assetAllocationCategID: row.AssetAllocationCategID });
      } else {
        NavigateToCustomAssetAllocationCateg({ ...navigationParams, customAssetAllocationCategID: row.AssetAllocationCategID  });
      }
    }
  }

  var fieldNamesToSearch = [holdingsMode == SharedConstants.HoldingsMode.MProfitClassification ? 'AssetTypeName' : 'AssetAllocationCategName'];

  return <SharedVirtualizedTable columns={holdingsMode == SharedConstants.HoldingsMode.MProfitClassification ? tableColumns_byAssetType : tableColumns_byAssetAllocation} rows={tableData} headerColor="#EAF4DF" sorting={true} onRowClick={onRowClick} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch} defaultSortConfig={SharedConstants.DefaultSortingForHoldingTable} tableType={SharedConstants.TableType.Holding} />

}

const commonTableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'InvAmt',
    HeaderName: 'AMOUNT INVESTED',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'OGain',
    HeaderName: 'UNREALISED GAIN',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'OGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'OUp'
      }
    ]
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'HoldingPct',
    HeaderName: 'HOLDING',
    ColumnWidthPercent: '25%',
    FieldFormatter: TablePercentFieldFormatter,
    Align: 'right',
  }
];

const tableColumns_byAssetAllocation: ColumnDataProps[] = [
  {
    FieldKey: 'AssetAllocationCategName',
    HeaderName: 'CATEGORY',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDefaultFieldFormatter,
    Align: 'left',
    IsRowName: true
  },
  ...commonTableColumns
]

const tableColumns_byAssetType: ColumnDataProps[] = [
  {
    FieldKey: 'AssetTypeName',
    HeaderName: 'ASSET CLASS',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDefaultFieldFormatter,
    Align: 'left',
    IsRowName: true
  },
  ...commonTableColumns
]

const HoldingsAppTour = ({ children }: { children: ReactNode }) => {
  const startHoldingTour = useAppSelector(WebRedux.Tutorial.Selectors.selectStartHoldingTour);
  const [runAppTour, setRunAppTour] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  const location = useLocation();

  useEffect(() => {
    if (startHoldingTour) {
      setRunAppTour(true);
      dispatch(WebRedux.Tutorial.Actions.toggleHoldingTour(false));
    }
  }, [startHoldingTour]);

  const assetTypeList = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveAssetTypesList);

  const onTourEnd = () => {
    var goToPerformance = true;
    if (assetTypeList) {
      if (assetTypeList.find(x => x.AssetTypeID === SharedConstants.AssetTypeEnum.EQ)) {
        NavigateToAssetType({ navigate, activeScreenIDs, assetTypeID: SharedConstants.AssetTypeEnum.EQ, dashboardTab: SharedConstants.DashboardTabs.HOLDING });
        dispatch(WebRedux.Tutorial.Actions.toggleHoldingTour(true));
        goToPerformance = false;
      } else if (assetTypeList.find(x => x.AssetTypeID === SharedConstants.AssetTypeEnum.MFEQ)) {
        NavigateToAssetType({ navigate, activeScreenIDs, assetTypeID: SharedConstants.AssetTypeEnum.MFEQ, dashboardTab: SharedConstants.DashboardTabs.HOLDING });
        goToPerformance = false;
        dispatch(WebRedux.Tutorial.Actions.toggleHoldingTour(true));
      }
    }

    if (goToPerformance) {
      NavigateToTab({ navigate, tab: SharedConstants.DashboardTabs.PERFORMANCE, location })
      dispatch(WebRedux.Tutorial.Actions.togglePerformanceTour(true));
    }
  }

  return <AppTour
    runAppTour={runAppTour}
    setRunAppTour={setRunAppTour}
    appTourSteps={HOLDING_L1_TOUR_STEPS}
    onTourEnd={onTourEnd}
  >
    {children}
  </AppTour>
}