const routeNames = {
    LOGIN: '/',
    REDIRECT: '/redirect',
    REGISTER: '/register',
    PORTFOLIO: '/portfolio',
    TODAY: '/portfolio/today',
    HOLDING: '/portfolio/holding',
    PERFORMANCE: '/portfolio/performance',
    ERROR: '/error',
    TEMP: '/portfolio/temp',
    REPORTS: '/portfolio/reports/explore',
    SAVEDREPORTS: '/portfolio/reports/saved',
    REPORTLOG: '/portfolio/reports/log',
    CASHFLOWS: '/portfolio/cashflows',
    ASSET_CATEGORIZATION: '/portfolio/custom-categories',
    SUMMARY: '/portfolio/summary',
    TRANSACTION: '/portfolio/summary/transaction',
    TRANSACTIONDETAILS: '/portfolio/summary/transaction/trade',
    CONTRACTNOTE: '/portfolio/summary/transaction/contractnote',
    MANAGE_CATEGORIES: '/portfolio/custom-categories/manage'
}
export default routeNames;