import { Select, MenuItem, SelectChangeEvent, Menu } from "@mui/material";
import { Box } from "@mui/system";
import { PlusIcon } from "packages/web/src/assets/icons/plusIcon";
import ButtonText from "../../shared/button-text";
import { CustomTypography } from "../../shared";
import { ArrowDown } from "packages/web/src/assets/icons/ArrowDown";
import { CustomAssetAllocationDefaultSubCategory } from "packages/shared/src/constants/assetMasters";

interface DropDownProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    selectedValue: string;
    handleSelectChange: (event: SelectChangeEvent) => void;
    handleNewSubCategory: () => void;
    dropdownList: Array<{
        id: string | number;
        name: string;
        isDefault?: boolean;
    }>;
    disabled?: boolean;
    headerText?: string;
    minWidth?: string | { xs?: string, md?: string, lg?: string, xl?: string };
    selectedCategory?: CustomAssetAllocationDefaultSubCategory | null;
    type?: string;
    selectedCategId?: number;
    isRequired?: boolean;
}

const DropDown: React.FC<DropDownProps> = ({
    open,
    setOpen,
    selectedValue,
    handleSelectChange,
    handleNewSubCategory,
    dropdownList,
    headerText = "",
    minWidth,
    selectedCategory,
    type = "",
    selectedCategId,
    isRequired
}) => {

    const renderHeader = () => (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            m="0px 2px 12px 19px"
        >
            <CustomTypography variant="Regular16" color="#64677A" sx={{ mr: "43.7px" }}>
                {headerText || ''}
            </CustomTypography>
            <ButtonText
                handleClick={() => {
                    setOpen(false);
                    handleNewSubCategory();
                }}
                buttonText={`New ${headerText}`}
                startIcon={<PlusIcon color="#5F6F57" />}
                fontVariant="Regular14"
            />
        </Box>
    );

    const borderColor = isRequired && !selectedValue ? "#d26a60" : "#D8DAE5";

    return (
        <Select
            sx={{
                minWidth: minWidth || '360px',
                border: "none",
                borderRadius: "4px",
                backgroundColor: "#FFFFFF",
                color: "#64677A",
                backgroundImage: `repeating-linear-gradient(0deg, ${borderColor}, ${borderColor} 7px, transparent 7px, transparent 13px, ${borderColor} 14px), repeating-linear-gradient(90deg, ${borderColor}, ${borderColor} 7px, transparent 7px, transparent 13px, ${borderColor} 14px), repeating-linear-gradient(180deg, ${borderColor}, ${borderColor} 7px, transparent 7px, transparent 13px, ${borderColor} 14px), repeating-linear-gradient(270deg, ${borderColor}, ${borderColor} 7px, transparent 7px, transparent 13px, ${borderColor} 14px)`,
                backgroundSize: "1.5px 100%, 100% 1.5px, 1.5px 100% , 100% 1.5px",
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
                height: { xs: "40px", lg: "48px" },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                },
                "&:hover": {
                    color: "#64677A",
                    backgroundColor: "#F9F9F9",
                    border: "1px solid #D8DAE5",
                    backgroundImage: "none",
                    outline: "none",
                },
                "&:active": {
                    backgroundColor: "#EBECF2",
                },
                "& .Mui-disabled": {
                    background: "#EBECF2",
                },
                '& .MuiPaper-root': {
                    boxShadow: 'none !important',
                },
            }}
            MenuProps={{
                sx: {
                    "& .MuiPaper-root": {
                        border: '1px solid #D8DAE5',
                        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
                    },
                }
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={selectedValue}
            onChange={handleSelectChange}
            displayEmpty
            IconComponent={() => (
                <Box
                    onClick={() => setOpen(!open)}
                    sx={{
                        marginRight: '16px',
                        transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                        cursor: 'pointer',
                    }}
                >
                    <ArrowDown color="#12131A" />
                </Box>
            )}
            renderValue={(value) => (
                value.length === 0 ? (
                    <CustomTypography variant="Regular18" ml="8px" color="#9A9EB2">
                        Sub-Category
                    </CustomTypography>
                ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CustomTypography variant="Regular18" ml="8px">
                            {value}
                        </CustomTypography>
                    </Box>
                )
            )}
        >
            {renderHeader()}
            {dropdownList.map((item, index) => (
                <MenuItem
                    sx={{
                        "&.Mui-selected": {
                            backgroundColor: "#CFE3C9",
                            "&:hover": {
                                backgroundColor: "#CFE3C9",
                            },
                        },
                        padding: "10px 12px 10px 16px",
                        "&:focus": {
                            backgroundColor: "#CFE3C9",
                        },
                        "&:hover": {
                            color: "#64677A",
                            backgroundColor: "#F9F9F9",
                        },
                        "&:active": {
                            backgroundColor: "#CFE3C9",
                        },
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    key={item.id}
                    value={item.id}
                >
                    <CustomTypography variant="Regular18">
                        {item.name}
                    </CustomTypography>
                    {(type === "category" && selectedCategory?.DefaultCategID === item.id) || (type === "subcategory" && selectedCategId === selectedCategory?.DefaultCategID && selectedCategory?.DefaultSubCategID === item.id) ? (
                        <CustomTypography variant="Regular16" color="#828699">
                            MProfit Default
                        </CustomTypography>
                    ) : null}
                </MenuItem>
            ))}
        </Select>
    );
};

export default DropDown;