export function ResetIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clip-path="url(#clip0_587_7722)">
        <path d="M4.04806 11.0946C3.88326 12.5375 4.11434 13.998 4.71652 15.3196C5.31871 16.6411 6.26928 17.7738 7.46629 18.5962C8.6633 19.4186 10.0616 19.8996 11.5112 19.9877C12.9608 20.0758 14.4071 19.7676 15.6948 19.0962C16.9826 18.4248 18.0633 17.4155 18.8211 16.1766C19.5788 14.9376 19.985 13.5158 19.9962 12.0636C20.0073 10.6113 19.6228 9.18346 18.8841 7.93309C16.8342 4.46897 12.5612 3.03425 8.86299 4.62525" stroke="#64677A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.9996 0.683216L8.46409 4.21875L11.9996 7.75428" stroke="#64677A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_587_7722">
          <rect width="24" height="24" fill="white" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 24 24)" />
        </clipPath>
      </defs>
    </svg>
  );
}
