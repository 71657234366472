import { MPrUrlParams } from 'packages/web/src/classes/MPrUrlParams';
import { useSearchParams } from 'react-router-dom';
import { SharedConstants } from '@mprofit/shared';
import L1_Holding from './level_1';
import L2_Holding from './level_2';
import L4_Holding from './level_4';
import L3_Holding from './level_3';
import L1_AA_Holding from './level_1_aa';
import L3_Category_Holding from './level_3_category';
import L2_AllStocks_Holding from './level_2_allstocks';
import L1_Custom_AA_Holding from './level_1_custom_aa';
import L1_Custom_AA_Subcategory_Holding from './level_1_custom_aa_subcategory';
import L2_AllStocks_AA_Holding from './level_2_allstocks_aa';

const Holding = (props: { MPrUrlParams: MPrUrlParams }) => {
  const mprUrlParams = props.MPrUrlParams || new MPrUrlParams();

  console.log('Holding mprUrlParams', mprUrlParams)
  // return <L4_Holding />

  switch (mprUrlParams.Screen) {
    default:
    case SharedConstants.ScreenEnum.L1:
      return <L1_Holding MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L1_AA:
      return <L1_AA_Holding MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L1_Custom_AA:
      return <L1_Custom_AA_Holding MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L1_Custom_AA_Subcategory:
      return <L1_Custom_AA_Subcategory_Holding MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L2:
      return <L2_Holding MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L2_AllStocks:
      return <L2_AllStocks_Holding MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L2_AllStocks_AA:
      return <L2_AllStocks_AA_Holding MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L3_Category:
      return <L3_Category_Holding MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L3:
      return <L3_Holding MPrUrlParams={mprUrlParams} />;
    case SharedConstants.ScreenEnum.L4:
    case SharedConstants.ScreenEnum.L4_RefNo:
      return <L4_Holding />
  }

}

export default Holding;