import { call, put, takeLatest } from "redux-saga/effects";
import { MProfitAPI, ResponseGenerator } from "../../api";
import { HttpStatusCode } from "axios";
import { Actions, UserProfileItemPayload } from "../modules/dashboardReducer";
import { PayloadAction } from "@reduxjs/toolkit/dist/createAction";

const UserProfilePath = '/api/Dashboard/UserProfile';

const userProfileListAPI = () => {
    return MProfitAPI.get(UserProfilePath+'/List');
}

function* onFetchUserProfileList() {
    const response: ResponseGenerator = yield call(userProfileListAPI);    
    
    if (response.status == HttpStatusCode.Ok) {
        yield put(Actions.setUserProfileFromAPI(response.data));
    }
}

const addUpdateUserProfileItemAPI = (item: UserProfileItemPayload[]) => {
    return Promise.all(item.map(item => 
        MProfitAPI.post(UserProfilePath+'/AddItem', item)
    ));
}

function* onAddUpdateUserProfileItem(action: PayloadAction<{Item: UserProfileItemPayload[], NoUpdateOnServer?: boolean}>) {
    if (action.payload.NoUpdateOnServer === true) return;

    try {
        const responses: ResponseGenerator[] = yield call(addUpdateUserProfileItemAPI, action.payload.Item);

        const allSuccessful = responses.every((response: ResponseGenerator) => response.status === HttpStatusCode.Ok);
        
        if (!allSuccessful) {
            console.log('Some preference updates failed');
        }
    } catch (error) {
        console.log('Error updating preferences:', error);
    }
}

function* userProfileSaga() {
    yield takeLatest(Actions.fetchUserProfile.type, onFetchUserProfileList);
    yield takeLatest(Actions.addUpdateUserProfileItem.type, onAddUpdateUserProfileItem);
}

export default userProfileSaga;