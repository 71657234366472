import { TranTypeMap, AcctTypes, CurrentField } from "./transactions";

export enum AssetTypeEnum {
    ALL = 0,
    SFNO = 30,
    BND = 40,
    EQ = 50,
    MFEQ = 60,
    MFD = 61,
    SYS_ASSETYPE_LIMIT = 65,
    ULIP = 141,
    INS = 140,
    PE = 66,
    OT = 81,
    ASSET_WITH_QUANT = 90,
    FD = 95,
    NCD = 70,
    CD = 115,
    PPF = 120,
    PO = 135,
    GLD = 75,
    SLV = 77,
    JWL = 155,
    PR = 150,
    ART = 145,
    MIS = 160,
    LN = 195,
    BANKS = 451
}

export const AssetTypesList_Inv = [
    {id: AssetTypeEnum.ALL, text: "All"},
    {id: AssetTypeEnum.EQ, text: "Stocks & ETFs"},
    {id: AssetTypeEnum.MFEQ, text: "Mutual Funds (Equity)"},
    {id: AssetTypeEnum.MFD, text: "Mutual Funds (Debt)"},
    {id: AssetTypeEnum.ULIP, text: "NPS / ULIP"},
    {id: AssetTypeEnum.INS, text: "Insurance"},
    {id: AssetTypeEnum.PE, text: "Private Equity"},
    {id: AssetTypeEnum.FD, text: "FDs"},
    {id: AssetTypeEnum.BND, text: "Traded Bonds"},
    {id: AssetTypeEnum.NCD, text: "NCD/Debentures"},
    {id: AssetTypeEnum.CD, text: "Deposits/Loans"},
    {id: AssetTypeEnum.PPF, text: "PPF/EPF"},
    {id: AssetTypeEnum.PO, text: "Post Office"},
    {id: AssetTypeEnum.GLD, text: "Gold"},
    {id: AssetTypeEnum.SLV, text: "Silver"},
    {id: AssetTypeEnum.JWL, text: "Jewellery"},
    {id: AssetTypeEnum.PR, text: "Properties"},
    {id: AssetTypeEnum.ART, text: "Art"},
    {id: AssetTypeEnum.MIS, text: "AIF"},
    {id: AssetTypeEnum.LN, text: "Loans"},
    {id: AssetTypeEnum.BANKS, text: "Banks"}
]

export const AssetTypeDetails = [
    {
        "ATyp": 30,
        "ASubTyp": 0,
        "SeqNo": 130,
        "Name": "Stock F&O",
        "Short": "S",
        "TransactionFields": {
            "Add": "Buy / Sell",
            "Quantity": "Quantity",
            "Price": "Price",
            "Brokerage": "Brokerage (Rs.)",
            "BalQuantity": "Bal. Quantity",
            "CurrentField": CurrentField.Price,
            "Editable": false,
            "AssetNotEditable": true
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {},
            searchSystemAssets: true,
            showRefNum: false,
            showAssetSubTypeID: false,
            showAgent: false,
            showIssuer: false,
            acctTypes: { }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.MtoMBUY,
                text: 'Add Mark to Market Records'
            },
            {
                id: TranTypeMap.SETTLE_FNO,
                text: 'Settle Position'
            }
        ]
    },
    {
        "ATyp": 50,
        "ASubTyp": 0,
        "SeqNo": 5,
        "Name": "Stocks",
        "Short": "EQ",
        "TransactionFields": {
            "Add": "Buy / Sell",
            "Quantity": "Quantity",
            "Price": "Price",
            "Brokerage": "Brokerage (Rs.)",
            "BalQuantity": "Bal. Quantity",
            "CurrentField": CurrentField.Price,
            "Editable": false,
            "AssetNotEditable": true
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {},
            searchSystemAssets: true,
            showRefNum: false,
            showAssetSubTypeID: false,
            showAgent: false,
            showIssuer: false,
            acctTypes: { }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.BONUS_RECV,
                text: 'Add Bonus Received'
            },
            {
                id: TranTypeMap.SPLIT,
                text: 'Add Split'
            },
            {
                id: TranTypeMap.DMRGR,
                text: 'Add Demerger'
            },
            {
                id: TranTypeMap.MERGE_CLOSE,
                text: 'Add Merger'
            },
            {
                id: TranTypeMap.IPON,
                text: 'Add Off-Market Transaction (IPO, Installment Payment, Op. Bal, Buyback)'
            },
            {
                id: TranTypeMap.DIVIDEND_PAYOUT,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 60,
        "ASubTyp": 0,
        "SeqNo": 10,
        "Name": "Mutual Funds",
        "Short": "MF",
        "TransactionFields": {
            "Add": "Buy / Sell",
            "Quantity": "Quantity",
            "Price": "NAV",
            "BalQuantity": "Bal. Quantity",
            "CurrentField": CurrentField.Price,
            "Editable": false,
            "Description": "Folio No."
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {
                RefNum: 'Folio No',
                AgentCode: 'Agent/Broker',
            },
            searchSystemAssets: true,
            showRefNum: true,
            showAssetSubTypeID: true,
            showAgent: true,
            showIssuer: false,
            acctTypes: {
                AgentCode: AcctTypes.AGENTS
            },
            showLockInPeriod: true
        },
        TransactionTypes: [
            {
                id: TranTypeMap.BONUS_RECV,
                text: 'Add Bonus Received'
            },
            {
                id: TranTypeMap.SPLIT,
                text: 'Add Fund Split'
            },
            {
                id: TranTypeMap.DMRGR,
                text: 'Add Fund Demerger'
            },
            {
                id: TranTypeMap.MERGE_CLOSE,
                text: 'Add Fund Merger'
            },
            {
                id: TranTypeMap.DIVIDEND_PAYOUT,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 61,
        "ASubTyp": 0,
        "SeqNo": 11,
        "Name": "Mutual Funds (Debt)",
        "Short": "MF",
        "TransactionFields": {
            "Add": "Buy / Sell",
            "Quantity": "Quantity",
            "Price": "NAV",
            "BalQuantity": "Bal. Quantity",
            "CurrentField": CurrentField.Price,
            "Editable": false,
            "Description": "Folio No."
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {
                RefNum: 'Folio No',
                AgentCode: 'Agent/Broker',
            },
            searchSystemAssets: true,
            showRefNum: true,
            showAssetSubTypeID: true,
            showAgent: true,
            showIssuer: false,
            acctTypes: {
                AgentCode: AcctTypes.AGENTS
            },
            showLockInPeriod: true
        },
        TransactionTypes: [
            {
                id: TranTypeMap.BONUS_RECV,
                text: 'Add Bonus Received'
            },
            {
                id: TranTypeMap.SPLIT,
                text: 'Add Fund Split'
            },
            {
                id: TranTypeMap.DMRGR,
                text: 'Add Fund Demerger'
            },
            {
                id: TranTypeMap.MERGE_CLOSE,
                text: 'Add Fund Merger'
            },
            {
                id: TranTypeMap.DIVIDEND_PAYOUT,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 81,
        "ASubTyp": 0,
        "SeqNo": 135,
        "Name": "Other F&O",
        "Short": "OT",
        "TransactionFields": {
            "Add": "Buy / Sell",
            "Quantity": "Quantity",
            "Price": "Price",
            "Brokerage": "Brokerage (Rs.)",
            "BalQuantity": "Bal. Quantity",
            "CurrentField": CurrentField.Price,
            "Editable": true
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {},
            searchSystemAssets: false,
            showRefNum: false,
            showAssetSubTypeID: false,
            showAgent: false,
            showIssuer: false,
            acctTypes: { }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.MtoMBUY,
                text: 'Add Mark to Market Records'
            },
            {
                id: TranTypeMap.SETTLE_FNO,
                text: 'Settle Position'
            }
        ]
    },
    {
        "ATyp": 141,
        "ASubTyp": 0,
        "SeqNo": 25,
        "Name": "NPS / ULIP",
        "Short": "ULP",
        "TransactionFields": {
            "Add": "Premium / Withdrawal",
            "AssetTypeAdd": "Add Transaction",
            "CurrentField": CurrentField.Value,
            "Editable": true,
            "Description": "Policy No."
        },
        "BuyTransType": TranTypeMap.FIRST_PREM,
        "AssetBuyTransType": TranTypeMap.PREM_PAID,
        AssetMasterFields: {
            labels: {
                RefNum: 'Policy No',
                AgentCode: 'Agent/Broker',
                ISR: 'Insurance Companies'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.INSU_COS,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.BONUS_ACCURED,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 140,
        "ASubTyp": 0,
        "SeqNo": 24,
        "Name": "Insurance",
        "Short": "INS",
        "TransactionFields": {
            "Add": "Premium / Withdrawal",
            "AssetTypeAdd": "Add Transaction",
            "CurrentField": CurrentField.Value,
            "Editable": true,
            "Description": "Policy No."
        },
        "BuyTransType": TranTypeMap.FIRST_PREM,
        "AssetBuyTransType": TranTypeMap.PREM_PAID,
        AssetMasterFields: {
            labels: {
                RefNum: 'Policy No',
                AgentCode: 'Agent/Broker',
                ISR: 'Insurance Companies'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.INSU_COS,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.BONUS_ACCURED,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 66,
        "ASubTyp": 0,
        "SeqNo": 18,
        "Name": "Private Equity",
        "Short": "PE",
        "TransactionFields": {
            "Add": "Buy / Sell",
            "Quantity": "Quantity",
            "Price": "Price",
            "BalQuantity": "Bal. Quantity",
            "CurrentField": CurrentField.Price,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Company'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.COMPANY,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.DIVIDEND_PAYOUT,
                text: 'Add Income for Asset'
            },
            {
                id: TranTypeMap.MERGE_CLOSE,
                text: 'Merge into a listed stock'
            }
        ]
    },
    {
        "ATyp": 95,
        "ASubTyp": 0,
        "SeqNo": 19,
        "Name": "FDs",
        "Short": "FD",
        "TransactionFields": {
            "Add": "Add Interest / Withdrawal",
            "AssetTypeAdd": "Add Transaction",
            "CurrentField": CurrentField.Value,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.INVEST,
        "AssetBuyTransType": TranTypeMap.INT_CUM,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Bank'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.BANK,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.MISC_INCOME,
                text: 'Add Other Income for Asset'
            }
        ]
    },
    {
        "ATyp": 40,
        "ASubTyp": 0,
        "SeqNo": 15,
        "Name": "Traded Bonds",
        "Short": "BND",
        "TransactionFields": {
            "Add": "Buy / Sell",
            "Quantity": "Quantity",
            "Price": "Price",
            "BalQuantity": "Bal. Quantity",
            "CurrentField": CurrentField.Price,
            "Editable": false,
            "AssetNotEditable": true
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {},
            searchSystemAssets: true,
            showRefNum: false,
            showAssetSubTypeID: false,
            showAgent: false,
            showIssuer: false,
            acctTypes: { }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.OP_BAL,
                text: 'Add Off-Market Transaction'
            },
            {
                id: TranTypeMap.INT_PAYOUT,
                text: 'Add Income for Asset'
            }
        ]
    },
        {
        "ATyp": 70,
        "ASubTyp": 0,
        "SeqNo": 16,
        "Name": "NCD/Debentures",
        "Short": "NCD",
        "TransactionFields": {
            "Add": "Add Transaction",
            "Quantity": "Quantity",
            "Price": "Rate",
            "CurrentField": CurrentField.Price,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Bank'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.BANK,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.INT_CUM,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 115,
        "ASubTyp": 0,
        "SeqNo": 27,
        "Name": "Deposits/Loans",
        "Short": "CD",
        "TransactionFields": {
            "Add": "Add Transaction",
            "CurrentField": CurrentField.Value,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.INVEST,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Company'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.COMPANY,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.INT_CUM,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 120,
        "ASubTyp": 0,
        "SeqNo": 26,
        "Name": "PPF/EPF",
        "Short": "PPF",
        "TransactionFields": {
            "Add": "Add Transaction",
            "CurrentField": CurrentField.Value,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.INVEST,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Bank'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.BANK,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.INT_CUM,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 135,
        "ASubTyp": 0,
        "SeqNo": 55,
        "Name": "Post Office",
        "Short": "PO",
        "TransactionFields": {
            "Add": "Add Transaction",
            "CurrentField": CurrentField.Value,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.INVEST,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Seller'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.SELLER,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.INT_CUM,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 75,
        "ASubTyp": 0,
        "SeqNo": 22,
        "Name": "Gold",
        "Short": "GLD",
        "TransactionFields": {
            "Add": "Buy / Sell",
            "Quantity": "Weight (gm)",
            "Price": "Rate",
            "BalQuantity": "Bal. Quantity",
            "CurrentField": CurrentField.Price,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Seller'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.SELLER,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.MISC_INCOME,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 77,
        "ASubTyp": 0,
        "SeqNo": 23,
        "Name": "Silver",
        "Short": "SLV",
        "TransactionFields": {
            "Add": "Buy / Sell",
            "Quantity": "Weight (kg)",
            "Price": "Rate",
            "BalQuantity": "Bal. Quantity",
            "CurrentField": CurrentField.Price,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Seller'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.SELLER,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.MISC_INCOME,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 155,
        "ASubTyp": 0,
        "SeqNo": 70,
        "Name": "Jewellery",
        "Short": "JWL",
        "TransactionFields": {
            "Add": "Buy / Sell",
            "CurrentField": CurrentField.Value,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Seller'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.SELLER,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.MISC_INCOME,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 150,
        "ASubTyp": 0,
        "SeqNo": 21,
        "Name": "Property",
        "Short": "PR",
        "TransactionFields": {
            "Add": "Sell",
            "Quantity": "Area",
            "Price": "Rate/unit",
            "CurrentField": CurrentField.Value,
            "Editable": true,
            "Description": "Address"
        },
        "BuyTransType": TranTypeMap.BUY,
        "AssetBuyTransType": TranTypeMap.SELL,
        AssetMasterFields: {
            labels: {
                RefNum: 'Address',
                AgentCode: 'Agent/Broker',
                ISR: 'Seller'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.SELLER,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.RENT_RECVD,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 145,
        "ASubTyp": 0,
        "SeqNo": 80,
        "Name": "Art",
        "Short": "ART",
        "TransactionFields": {
            "Add": "Sell",
            "CurrentField": CurrentField.Value,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.BUY,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Seller'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.SELLER,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.MISC_INCOME,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 160,
        "ASubTyp": 0,
        "SeqNo": 17,
        "Name": "AIF",
        "Short": "AIF",
        "TransactionFields": {
            "Add": "Add Transaction",
            "CurrentField": CurrentField.Value,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.INVEST,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Seller'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.SELLER,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.INT_CUM,
                text: 'Add Income for Asset'
            }
        ]
    },
    {
        "ATyp": 195,
        "ASubTyp": 0,
        "SeqNo": 95,
        "Name": "Loans",
        "Short": "LN",
        "TransactionFields": {
            "Add": "Borrow/Interest/Payback",
            "CurrentField": CurrentField.Value,
            "Editable": true,
            "Description": "Ref No."
        },
        "BuyTransType": TranTypeMap.BORROW,
        AssetMasterFields: {
            labels: {
                RefNum: 'Ref No',
                AgentCode: 'Agent/Broker',
                ISR: 'Lender'
            },
            searchSystemAssets: false,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: true,
            showIssuer: true,
            acctTypes: {
                ISR: AcctTypes.COMPANY,
                AgentCode: AcctTypes.AGENTS
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.INT_CUM,
                text: 'Add Other Transactions'
            }
        ]
    },
    {
        "ATyp": AssetTypeEnum.BANKS,
        "ASubTyp": 0,
        "SeqNo": 20,
        "Name": "Banks",
        "Short": "BNK",
        "TransactionFields": {
            "Add": "Deposit/Withdrawal",
            "BalAmount": "BalAmount",
            "NARR": "NARR",
            "CurrentField": CurrentField.Value,
            "Editable": false,
            "Description": "Account No.",
            "TransferDisabled": true
        },
        "BuyTransType": TranTypeMap.BNK_DEPOSIT,
        AssetMasterFields: {
            labels: {
                RefNum: 'Account No',
                Asset: 'Bank'
            },
            searchSystemAssets: true,
            showRefNum: true,
            showAssetSubTypeID: false,
            showAgent: false,
            showIssuer: false,
            acctTypes: {
            }
        },
        TransactionTypes: [
            {
                id: TranTypeMap.BNK_OPBAL,
                text: 'Add Opening Balance'
            }
        ]
    }
]

export const AssetTypeSeqNoMapping = AssetTypeDetails.reduce((acc, assetType) => {
    acc[assetType.ATyp] = assetType.SeqNo;
    return acc;
}, {} as Record<number, number>);
console.log("🚀 ~ AssetTypeSeqNoMapping ~ AssetTypeSeqNoMapping:", AssetTypeSeqNoMapping)

export const AssetTypeNameMapping = {
    [AssetTypeEnum.ALL]: "All",
    [AssetTypeEnum.SYS_ASSETYPE_LIMIT]: "",
    [AssetTypeEnum.ASSET_WITH_QUANT]: "",
    [AssetTypeEnum.SFNO]: "Stock F&O",
    [AssetTypeEnum.EQ]: "Stocks & ETFs",
    [AssetTypeEnum.MFEQ]: "Mutual Funds",
    [AssetTypeEnum.MFD]: "Mutual Funds",
    [AssetTypeEnum.ULIP]: "NPS / ULIP",
    [AssetTypeEnum.INS]: "Insurance",
    [AssetTypeEnum.PE]: "Private Equity",
    [AssetTypeEnum.OT]: "Other F&O",
    [AssetTypeEnum.FD]: "FD",
    [AssetTypeEnum.BND]: "Traded Bonds",
    [AssetTypeEnum.NCD]: "NCD",
    [AssetTypeEnum.CD]: "Deposits",
    [AssetTypeEnum.PPF]: "PPF",
    [AssetTypeEnum.PO]: "Post Office",
    [AssetTypeEnum.GLD]: "Gold",
    [AssetTypeEnum.SLV]: "Silver",
    [AssetTypeEnum.JWL]: "Jewellery",
    [AssetTypeEnum.PR]: "Property",
    [AssetTypeEnum.ART]: "Art",
    [AssetTypeEnum.MIS]: "AIF",
    [AssetTypeEnum.LN]: "Loans",
    [AssetTypeEnum.BANKS]: "Banks"
}

export const AssetTypesList_PeriodwiseXIRR = [
    {id: AssetTypeEnum.EQ, text: "Stocks & ETFs"},
    {id: AssetTypeEnum.MFEQ, text: "Mutual Funds"},
    {id: AssetTypeEnum.ULIP, text: "NPS / ULIP"},
    {id: AssetTypeEnum.MIS, text: "AIF"},
    {id: AssetTypeEnum.INS, text: "Insurance"},
    {id: AssetTypeEnum.PE, text: "Private Equity"},
    {id: AssetTypeEnum.FD, text: "FDs"},
    {id: AssetTypeEnum.NCD, text: "NCD/Debentures"},
    {id: AssetTypeEnum.CD, text: "Deposits/Loans"},
    {id: AssetTypeEnum.PPF, text: "PPF/EPF"},
    {id: AssetTypeEnum.PO, text: "Post Office"},
    {id: AssetTypeEnum.GLD, text: "Gold"},
    {id: AssetTypeEnum.SLV, text: "Silver"},
    {id: AssetTypeEnum.JWL, text: "Jewellery"},
    {id: AssetTypeEnum.PR, text: "Properties"},
    {id: AssetTypeEnum.ART, text: "Art"},
]