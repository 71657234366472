import { StockAddInfo } from "./portfolioSummary";

export interface ReturnsByPeriodRequest {
    MProfitCode: number
    Periods: AM_PeriodRequest[]
}

export interface AM_PeriodRequest {
    StartDate: string;
    EndDate: string;
    IsOlderThan2012Return?: boolean;
}

export interface ReturnsByIndexRequest {
    MProfitCodes: number[]
    StartDate?: string
    EndDate?: string
}

export interface ReturnsByPeriodResponseItem {
    StartDate: string
    EndDate: string
    Return: number
}

export interface ReturnsByIndexResponseItem {
    MProfitCode: number;
    CAGR: number;
    AbsoluteReturnPct: number;
}

export interface IndexMaster {
    MProfitCode: number;
    IndexName: string;
    BSECode?: number;
    NSESymbol?: string;
}

export enum AssetAllocationCategID {
    Exclude = -2,
    PerAsset = -1,
    None = 0,
    Equity = 1,
    Debt = 2,
    International = 3,
    Gold_Plus = 4,
    Retirement = 5,
    RealEstate = 6,
    Cash = 7,
    Other = 100
}

export enum AssetAllocationSubCategID {
    Silver = 1,
    InvIT = 2
}

export enum MarketCap_Type {
    None = 0,
    SmallCap = 1,
    MidCap = 2,
    LargeCap = 3
}

export const AssetAllocationCategNameMapping = {
    [AssetAllocationCategID.Exclude]: "Unknown",
    [AssetAllocationCategID.PerAsset]: "Unknown",
    [AssetAllocationCategID.None]: "Unknown",
    [AssetAllocationCategID.Equity]: "Equity",
    [AssetAllocationCategID.Debt]: "Debt",
    [AssetAllocationCategID.International]: "International",
    [AssetAllocationCategID.Gold_Plus]: "Gold+",
    [AssetAllocationCategID.Retirement]: "Retirement",
    [AssetAllocationCategID.RealEstate]: "Real Estate",
    [AssetAllocationCategID.Cash]: "Cash",
    [AssetAllocationCategID.Other]: "Other",
}

export interface MFPortfolioBreakdownItem {
    MProfitCode: number;
    PercentageOfAUM: number;
}

export interface MFPortfolioBreakdownForAssetType {
    AssetTypeID: number;
    Assets: MFPortfolioBreakdownItem[];
}

export interface MFPortfolioBreakdown {
    MProfitCode: number;
    AssetTypes: MFPortfolioBreakdownForAssetType[];
}

export interface StocksNamesInfo {
    MProfitCode: number;
    CompanyName: string;
}

export interface MFETFPortfolioBreakdown {
    MF: MFPortfolioBreakdown[];
    ETF: MFPortfolioBreakdown[];
    StocksInfo: StockAddInfo[];
}

export enum Stocks_Sub_Category_Type {
    None = 0,
    ETF = 1
}

export interface CustomAssetAllocationSubCategory {
    SubCategID: number;
    SubCategName: string;
    IsDefault: boolean;
}

export interface CustomAssetAllocationCategory {
    CategID: number;
    CategName: string;
    IsDefault: boolean;
    SubCategs: CustomAssetAllocationSubCategory[];
}

export interface CustomAssetAllocationMapping {
    AssetTypeID: number;
    AssetAllocationCategID: number;
    AMID: number;
    CustomAssetAllocationCategID: number;
    CustomAssetAllocationSubCategID: number;
}

export interface CustomAssetAllocationDefault {
    AssetTypeID: number;
    SubCategories: CustomAssetAllocationDefaultSubCategory[];
}

export interface CustomAssetAllocationDefaultSubCategory {
    AssetTypeID: number;
    AssetAllocationCategID: number;
    DefaultSubCategName: string;
    DefaultCategID: number;
    DefaultSubCategID: number;
}

export interface AssetCategorizationData {
    AssetTypeID: number;
    AssetTypeName: string;
    SubCategories: AssetCategorizationSubCategory[];
}

export interface AssetCategorizationSubCategory {
    AssetTypeID: number;
    AssetAllocationCategID: number;
    DefaultSubCategName: string;
    DefaultCategID: number;
    DefaultSubCategID: number;
    mapping?: {
        CustomAssetAllocationCategID: number;
        CustomAssetAllocationSubCategID: number;
        CategName: string;
        SubCategName: string;
        isDefault: boolean;
        mappingType?: 'default' | 'custom';
    };
}

export interface CustomAssetAllocationSubCategoryUpdate {
    CustomAssetAllocationSubCategID: number;
    CustomAssetAllocationSubCategName: string;
}

export interface CustomAssetAllocationCategoryUpdate {
    List: {
        CustomAssetAllocationCategID: number;
        CustomAssetAllocationCategName?: string;
        SubCategs: CustomAssetAllocationSubCategoryUpdate[];
    }[];
}

export interface CustomAssetAllocationMappingUpdate {
    List: {
        AssetTypeID: number;
        AssetAllocationCategID: number;
        AMID: number;
        CustomAssetAllocationCategID: number;
        CustomAssetAllocationSubCategID: number;
    }[];
}

export enum CustomAssetAllocationCategType {
    Categ = 1,
    SubCateg = 2
}

export interface CustomAssetAllocationCategories {
    [key: number]: {
        CategName: string;
        IsDefault: boolean;
        SubCategs: {
            [key: number]: {
                SubCategID: number;
                SubCategName: string;
                IsDefault: boolean;
            }
        }
    }
};

export type CustomAssetAllocationMappingsDict = {
    [key: `${number}|${number}|${number}`]: CustomAssetAllocationMapping;
};
  