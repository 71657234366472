import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { SortingIcon } from '../../../../assets/icons/SortingIcon';
import { TableVirtuoso } from 'react-virtuoso';
import { SharedUtilities, SharedConstants, SharedRedux } from '@mprofit/shared';
import { forwardRef, useEffect, useRef, useState, useCallback, useMemo, Dispatch, SetStateAction } from 'react';
import Big from "big.js";
import { getIconByAssetTypeID } from 'packages/web/src/assets/icons/assetTypes/outlined';
import { ArrowDown } from 'packages/web/src/assets/icons/ArrowDown';
import { ArrowUp } from 'packages/web/src/assets/icons/ArrowUp';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';
import { FullscreenIcon } from 'packages/web/src/assets/icons/FullscreenIcon';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';
import { DownSortIcon } from 'packages/web/src/assets/icons/DownSortIcon';
import { UpSortIcon } from 'packages/web/src/assets/icons/UpSortIcon';
import { SortConfig } from '@mprofit/shared/src/constants';
import { CustomTypography } from '../../../shared';
import SkeletonLoader from '../../../shared/skeleton-loader';
import { ColumnDataProps } from '../../../shared/virtual-table-component';
import { EditIcon } from 'packages/web/src/assets/icons/editIcon';
import { TooltipDelete } from 'packages/web/src/assets/icons/TooltipDelete';
import AddCategoryModal from '../addCategoryModal';
import { CustomAssetAllocationCategType } from 'packages/shared/src/constants';
import ConfirmModal from '../../../shared/confirm-modal';

export const TableDefaultFieldFormatter = (value: any) => value;
export const TableBoldFieldFormatter = (value: any) => <strong>{value}</strong>;

export interface RowDataProps {
    [key: string]: any;
}

interface SkeletonLoaderStyle {
    height?: string;
    width?: string;
    position?: string;
}

export interface SkeletonLoaderConfig {
    [fieldKey: string]: SkeletonLoaderStyle;
}

interface Props {
    columns: ColumnDataProps[];
    rows: RowDataProps[];
    rowHeight?: number;
    headerColor?: string;
    tableHeight?: string;
    sorting?: boolean;
    isScrollable?: boolean;
    onRowClick?: (row: RowDataProps) => void;
    searchTerm?: string;
    fieldNamesToSearch?: string[];
    defaultSortConfig?: SortConfig;
    rowClickDisabled?: boolean;
    PMSBadgeField?: string;
    primaryRowId: string;
    secondaryRowId: string;
    footerContent?: () => React.ReactNode;
    excludeLoadingFields?: string[];
    skeletonLoaderStyles?: SkeletonLoaderConfig;
    isLoading?: boolean;
}

const ManageCategoriesVirtualizedTable: React.FC<Props> = ({ columns, rows, headerColor, tableHeight, sorting, isScrollable = true, onRowClick, searchTerm, fieldNamesToSearch, defaultSortConfig, rowClickDisabled, PMSBadgeField, primaryRowId, secondaryRowId, footerContent, excludeLoadingFields = [], skeletonLoaderStyles = {}, isLoading }) => {
    const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(defaultSortConfig);
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
    const [expandedSecondaryRows, setExpandedSecondaryRows] = useState<Set<string>>(new Set());
    const [expandAll, setExpandAll] = useState<boolean>(false);
    const rootRef = useRef(null);
    const [scroller, setScroller] = useState<HTMLElement | null>(null);
    const [initialize, osInstance] = useOverlayScrollbars({ options: { scrollbars: { autoHide: 'scroll' } }, defer: true });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

    const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState<boolean>(false);
    const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState<boolean>(false);
    const [editData, setEditData] = useState<EditCategoryData | null>(null);
    const [deleteData, setDeleteData] = useState<{
        id: number;
        name: string;
        type: 'category' | 'subcategory';
        parentId?: number;
    } | null>(null);

    const [deleteAPIResponse, setDeleteAPIResponse] = useState<SharedConstants.FormSubmissionResponse | undefined>(undefined);

    const handleEditCategory = (editData: EditCategoryData, newName: string) => {
        dispatch(SharedRedux.Dashboard.Actions.updateCustomAssetAllocationCategoryName({
            categoryUpdate: {
                ...editData,
                CustomCategName: newName
            }
        }));
    };

    const handleSorting = (key: string) => {
        var column = columns.find(x => x.FieldKey === key);

        var isColumnNumeric = column && ((column.IsRowName || column.IsString) !== true);
        let direction: SharedConstants.SortDirection = isColumnNumeric ? SharedConstants.SortDirection.Down : SharedConstants.SortDirection.Up;
        if (
            sortConfig &&
            sortConfig.key === key
        ) {
            direction = (sortConfig?.direction || direction) * -1;
        }

        setSortConfig({ key, direction });
    };
    const [finalRows, setFinalRows] = useState<RowDataProps[]>(rows);

    const filterRows = (rows: RowDataProps[]) => {
        return rows.filter(row =>
            row.RowType === 'Primary' ||
            (row.RowType === 'Secondary' && expandedRows.has(row[primaryRowId]))
        );
    };

    const toggleExpandAll = () => {
        if (expandAll) {
            setExpandedRows(new Set());
            setExpandedSecondaryRows(new Set());
        } else {
            const allPrimaryRowIds = new Set(rows.filter(row => row.RowType !== 'Secondary').map(row => row[primaryRowId]));
            setExpandedRows(allPrimaryRowIds);
        }
        setExpandAll(!expandAll);
    };

    useEffect(() => {
        if (primaryRowId && secondaryRowId) {
            const sortedAndFilteredRows = sortRows(
                SharedUtilities.filterItems(searchTerm, rows, fieldNamesToSearch),
                columns,
                sortConfig?.key,
                sortConfig?.direction,
                expandedRows,
                primaryRowId,
                expandedSecondaryRows,
                secondaryRowId
            )
            setFinalRows(addRowStatusToFinalRows(filterRows(sortedAndFilteredRows)));
        }
    }, [searchTerm, rows, sortConfig, columns, fieldNamesToSearch, expandedRows, primaryRowId, expandedSecondaryRows, secondaryRowId]);

    const handleRowClick = useCallback((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, activeRow: RowDataProps) => {
        if (onRowClick) {
            onRowClick(activeRow);
        } else {
            if (activeRow.RowType === "Secondary") {
                const rowId = activeRow[secondaryRowId];
                const hasTertiaryRows = rows.some(row => row[secondaryRowId] === rowId && row.RowType === 'Tertiary');
                if (hasTertiaryRows) {
                    setExpandedSecondaryRows(prev => {
                        const newExpandedSecondaryRows = new Set(prev);
                        if (newExpandedSecondaryRows.has(rowId)) {
                            newExpandedSecondaryRows.delete(rowId);
                        } else {
                            newExpandedSecondaryRows.add(rowId);
                        }
                        return newExpandedSecondaryRows;
                    });
                } else {
                    dispatch(SharedRedux.Portfolios.Actions.clearActivePeriod());
                    dispatch(SharedRedux.Portfolios.Actions.clearTransactionsAndBalances());
                    // NavigateToAsset({navigate, activeScreenIDs, assetTypeID: activeRow.AssetTypeID, AMID: activeRow.AMID, SID: activeRow.SID, pathname: '/portfolio/summary/transaction', OrigPFID: activeRow.PFID });
                }
            } else if (activeRow.RowType === "Tertiary") {
                dispatch(SharedRedux.Portfolios.Actions.clearActivePeriod());
                dispatch(SharedRedux.Portfolios.Actions.clearTransactionsAndBalances());
                // NavigateToAsset({navigate, activeScreenIDs, assetTypeID: activeRow.AssetTypeID, AMID: activeRow.AMID, SID: activeRow.SID, pathname: '/portfolio/summary/transaction', OrigPFID: activeRow.PFID });
            } else {
                const rowId = activeRow[primaryRowId];
                setExpandedRows(prev => {
                    const newExpandedRows = new Set(prev);
                    if (newExpandedRows.has(rowId)) {
                        newExpandedRows.delete(rowId);
                        setExpandedSecondaryRows(new Set());
                    } else {
                        newExpandedRows.add(rowId);
                    }
                    return newExpandedRows;
                });
            }
        }
    }, [onRowClick, primaryRowId, secondaryRowId, activeScreenIDs, dispatch, navigate, rows]);

    useEffect(() => {
        const { current: root } = rootRef;

        if (scroller && root) {
            initialize({
                target: root,
                elements: {
                    viewport: scroller,
                },
            });
        }

        return () => osInstance()?.destroy();
    }, [scroller, initialize, osInstance]);

    const addRowStatusToFinalRows = (finalRows: RowDataProps[]) => {
        const finalRowsWithStatus = finalRows.map((row, index) => {
            var prevRow = finalRows[index - 1];
            var nextRow = finalRows[index + 1];
            switch (row.RowType) {
                case 'Primary':
                    return { isFirstRow: index === 0, isLastRow: index === finalRows.length - 1, ...row };
                case 'Secondary':
                    return { isFirstRow: !prevRow || prevRow.RowType === 'Primary', isLastRow: !nextRow || nextRow.RowType === 'Primary', isLastRowOfPrimary: !nextRow || nextRow.RowType === 'Primary', ...row };
            }
            return row;
        });
        return finalRowsWithStatus;
    }

    const ExpandableTableRow = (props: any) => {
        const [isHovered, setIsHovered] = useState(false);
        const row = props.item;
        const isFirstRow = row.isFirstRow;
        const isLastRow = row.isLastRow;
        const isLastRowOfPrimary = row.isLastRowOfPrimary;
        const isExpanded = expandedRows.has(row[primaryRowId]);
        const isSecondaryExpanded = expandedSecondaryRows.has(row[secondaryRowId]);

        const hasSecondaryRows = row.RowType === 'Primary' && rows.some(r => r.RowType === 'Secondary' && r.parentId === row.categoryId);

        const { 
            isAddCategoryModalOpen, 
            setIsAddCategoryModalOpen, 
            setEditData, 
            isDeleteCategoryModalOpen, 
            setIsDeleteCategoryModalOpen,
        } = props;

        const handleMouseEnter = () => setIsHovered(true);
        const handleMouseLeave = () => setIsHovered(false);

        if (row.RowType === 'Secondary') {
            const hasTertiaryRows = rows.some(r => r.RowType === 'Tertiary' && r[secondaryRowId] === row[secondaryRowId]);
            return (
                <>
                    <TableRow
                        {...props}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        sx={{
                            ":hover .IsHovered": { background: '#F9F9F9' },
                            ':hover .IsRowName div': {
                                cursor: 'pointer',
                            },
                            "cursor": "pointer",
                            ...(row.empty ? {} : { height: "30px" }),
                        }}
                    >
                        <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
                        <SecondaryTableRow
                            {...{
                                columns,
                                row: row,
                                isFirstRow,
                                isLastRow,
                                onRowClick,
                                PMSBadgeField,
                                isHovered,
                                isExpanded: isSecondaryExpanded,
                                hasTertiaryRows,
                                isAddCategoryModalOpen,
                                isDeleteCategoryModalOpen,
                                setIsAddCategoryModalOpen,
                                setIsDeleteCategoryModalOpen,
                                setEditData,
                                setDeleteData: props.setDeleteData
                            }}
                        />
                        <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
                    </TableRow>
                    {isLastRowOfPrimary &&
                        (<TableRow sx={{ height: "24px", pointerEvents: "none" }}>
                            <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6" }} />
                            <TableCell sx={{ borderStyle: "none", width: "4%" }} />
                            {columns.map((_, index) => (
                                <TableCell key={index} sx={{ borderStyle: "none" }} />
                            ))}
                            <TableCell sx={{ borderStyle: "none", width: "100px" }} />
                            <TableCell sx={{ borderStyle: "none", width: "32px" }} />
                            <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6" }} />
                        </TableRow>)}

                </>
            );
        }

        return (
            <TableRow
                {...props}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                    ":hover": { background: '#F9F9F9' },
                    ':hover .IsRowName div': {
                        cursor: 'pointer',
                    },
                    "cursor": "pointer",
                    ...(row.empty ? { background: "#FFFFFF", pointerEvents: "none" } : { height: "30px" }),
                }}
            >
                <SharedTableRow 
                    {...{ 
                        columns, 
                        row: row, 
                        isFirstRow, 
                        isLastRow, 
                        onRowClick, 
                        PMSBadgeField, 
                        expandedRows, 
                        primaryRowId, 
                        isHovered, 
                        isExpanded,
                        hasSecondaryRows,
                        setIsAddCategoryModalOpen: props.setIsAddCategoryModalOpen,
                        setIsDeleteCategoryModalOpen: props.setIsDeleteCategoryModalOpen,
                        setEditData: props.setEditData,
                        setDeleteData: props.setDeleteData
                    }} 
                />
            </TableRow>
        )
    }

    const onCancelDeleteClick = () => {
        setIsDeleteCategoryModalOpen(false);
        setDeleteData(null);
        setDeleteAPIResponse(undefined);
    };

    return (
        <Box data-overlayscrollbars-initialize="" ref={rootRef} style={{ height: tableHeight, width: "100%" }}>
            <TableVirtuoso
                scrollerRef={(ref) => {
                    if (ref instanceof HTMLElement) {
                        setScroller(ref);
                    }
                }}
                style={{
                    background: '#FFFFFF', width: '100%', minHeight: tableHeight ? tableHeight : '600px', overflow: isScrollable ? "scroll" : "hidden"
                }}
                components={{
                    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate', height: "100%" }} />,
                    TableHead: TableHead,
                    TableFoot: TableFooter,
                    TableRow: (props) => (
                        <ExpandableTableRow 
                            onClick={(event: any) => rowClickDisabled ? undefined : handleRowClick(event, props.item)} 
                            {...props} 
                            isAddCategoryModalOpen={isAddCategoryModalOpen} 
                            setIsAddCategoryModalOpen={setIsAddCategoryModalOpen} 
                            isDeleteCategoryModalOpen={isDeleteCategoryModalOpen} 
                            setIsDeleteCategoryModalOpen={setIsDeleteCategoryModalOpen} 
                            setEditData={setEditData} 
                            setDeleteData={setDeleteData}
                        />
                    ),
                    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
                }}
                data={[...finalRows, { empty: true } as any]}
                fixedHeaderContent={() => (
                    <TableRow sx={{ background: headerColor ? headerColor : "white" }}>
                        <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
                        <TableCell sx={{ borderStyle: "none", width: "4%", borderBottom: "none" }} />
                        {columns.map((column) => (
                            <TableCell key={column.FieldKey} align={column.Align} sx={{ width: column.ColumnWidthPercent, minWidth: "130px", maxWidth: column.ColumnWidthPercent, textTransform: 'uppercase', borderBottom: "none" }}>
                                <Box sx={{ display: "flex", justifyContent: column.Align, alignItems: 'center', marginLeft: { md: "-20px", xl: "-40px" } }}>
                                    <Box onClick={toggleExpandAll} sx={{ cursor: 'pointer', marginTop: "6px", marginRight: "8px", marginLeft: "-8px" }}>
                                        <FullscreenIcon />
                                    </Box>
                                    <Box sx={{ marginRight: '8px' }}>
                                        <CustomTypography variant='Medium14'>{column.HeaderName}</CustomTypography>
                                        {column.SubHeaderName &&
                                            <CustomTypography variant='Regular14' sx={{ color: "#64677A" }}>{column.SubHeaderName}</CustomTypography>}
                                    </Box>
                                    {sorting && (sortConfig?.key === column.FieldKey ? (
                                        <Box sx={{ cursor: 'pointer', width: '10px', height: '20px' }} onClick={() => handleSorting(column.FieldKey)}>
                                            {sortConfig.direction === SharedConstants.SortDirection.Down ? (
                                                <DownSortIcon />
                                            ) : (
                                                <UpSortIcon />
                                            )}
                                        </Box>
                                    ) : (
                                        <Box sx={{ cursor: 'pointer', width: '10px', height: '20px' }} onClick={() => handleSorting(column.FieldKey)}>
                                            <Box sx={{ marginLeft: '-3px', marginTop: '1.5px' }}>
                                                <SortingIcon />
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </TableCell>
                        ))}
                        <TableCell sx={{ borderStyle: "none", width: "100px", borderBottom: "none" }} />
                        <TableCell sx={{ borderStyle: "none", width: "32px", borderBottom: "none" }} />
                        <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
                    </TableRow>
                )}
                fixedFooterContent={footerContent}
            />
            <AddCategoryModal
                headerText={editData?.CustomAssetAllocationCategType === CustomAssetAllocationCategType.Categ ? "Edit Category" : "Edit Sub-Category"}
                subHeaderText={editData?.CustomAssetAllocationCategType === CustomAssetAllocationCategType.Categ ? "Category Name" : "Sub-Category Name"}
                isAddCategoryModalOpen={isAddCategoryModalOpen}
                setIsAddCategoryModalOpen={setIsAddCategoryModalOpen}
                editData={editData}
                handleEditCategory={handleEditCategory}
            />
            <ConfirmModal
                headerText={`Delete ${deleteData?.type === 'category' ? 'Category' : 'Sub-Category'}?`}
                subHeaderText={`Are you sure you wish to permanently delete this ${deleteData?.type === 'category' ? 'Category' : 'Sub-Category'}?`}
                infoText='Please note that this action cannot be undone.'
                isModalOpen={isDeleteCategoryModalOpen}
                setIsModalOpen={setIsDeleteCategoryModalOpen}
                confirmButtonText="Delete"
                confirmButtonTextOnIgnoreValidationError="Delete Anyway"
                apiResponse={deleteAPIResponse}
                isDelete={true}
                onConfirm={(options) => {
                    if (deleteData) {
                        setDeleteAPIResponse(undefined);
                        dispatch(SharedRedux.Dashboard.Actions.deleteCustomAssetAllocationCategory({
                            categoryDelete: {
                                customCategID: deleteData.id,
                                customParentCategID: deleteData.parentId || 0,
                                customAssetAllocationCategType: deleteData.type === 'category' 
                                    ? CustomAssetAllocationCategType.Categ 
                                    : CustomAssetAllocationCategType.SubCateg,
                                ...options,
                            },
                            onAPIResponse: (apiResponse: SharedConstants.FormSubmissionResponse) => {
                                setDeleteAPIResponse(apiResponse);
                            }
                        }));
                    }
                }}
                onCancel={onCancelDeleteClick}
            />
        </Box>
    );
};

const SharedTableRow = ({ columns, row, isFirstRow, isLastRow, onRowClick, PMSBadgeField, expandedRows, primaryRowId, isHovered, isExpanded, hasSecondaryRows, isLoading, excludeLoadingFields = [], skeletonLoaderStyles = {}, setIsAddCategoryModalOpen, setEditData, setIsDeleteCategoryModalOpen, setDeleteData }: { 
    columns: ColumnDataProps[], 
    row: RowDataProps, 
    isFirstRow: boolean, 
    isLastRow: boolean, 
    onRowClick?: (row: RowDataProps) => void, 
    PMSBadgeField?: string, 
    expandedRows: Set<string>, 
    primaryRowId: string, 
    isHovered: boolean, 
    isExpanded: boolean, 
    hasSecondaryRows?: boolean,
    isLoading?: boolean, 
    excludeLoadingFields?: string[], 
    skeletonLoaderStyles?: SkeletonLoaderConfig,
    setIsAddCategoryModalOpen: Dispatch<SetStateAction<boolean>>,
    setEditData: Dispatch<SetStateAction<EditCategoryData | null>>,
    setIsDeleteCategoryModalOpen: Dispatch<SetStateAction<boolean>>,
    setDeleteData: Dispatch<SetStateAction<any>>
}) => {
    const borderBottom = 'none';
    const borderTop = isFirstRow ? 'none' : '1px solid #EBECF2';

    const handleEditClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        const editData: EditCategoryData = {
            CustomCategName: row.name,
            CustomAssetAllocationCategType: CustomAssetAllocationCategType.Categ,
            CustomCategID: row.categoryId,
            CustomParentCategID: 0
        };
        setEditData(editData);
        setIsAddCategoryModalOpen(true);
    };

    return (
        <>
            <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
            <TableCell sx={{ borderTop, borderBottom, width: "4%" }} />
            {
                columns.map((column, index) => {
                    var showRowNameClass = column.IsRowName;
                    return (
                        <TableCell className={`${showRowNameClass ? "IsRowName" : ""} ${isExpanded ? "IsSelected" : ""}`} key={column.FieldKey} align={column.Align} sx={{
                            width: column.ColumnWidthPercent, minWidth: "130px", maxWidth: column.ColumnWidthPercent,
                            borderTop, borderBottom
                        }}>
                            {isLoading && !excludeLoadingFields.includes(column.FieldKey) && !row.empty ? (
                                <SkeletonLoader {...(skeletonLoaderStyles[column.FieldKey] || { height: "12px", width: "86px" })} animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#F8F8F8' }} sxContainer={{ display: "flex", justifyContent: skeletonLoaderStyles[column.FieldKey]?.position || "flex-end" }} />
                            ) : (
                                <>
                                    {!row.empty && <Box display="flex" alignItems="center" justifyContent={column.Align}>
                                        <Box sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', marginLeft: { md: "-20px", xl: "-40px" } }}>
                                            <CustomTypography color="#12131A" variant='Regular24' sx={{ lineHeight: '32px' }}>{column.FieldFormatter(row[column.FieldKey])}</CustomTypography>
                                            {
                                                row.isDefault ?
                                                    <>
                                                        <Box sx={{ borderRadius: "100%", background: "#12131A", width: "4px", height: "4px", margin: "0px 8px" }}></Box>
                                                        <Box sx={{ borderRadius: "4px", border: "1px solid #EBECF2", padding: "2px 4px" }}>
                                                            <CustomTypography color="#828699" variant='Regular16' sx={{ lineHeight: '24px' }}>MProfit Default</CustomTypography>
                                                        </Box>
                                                    </>
                                                    : null
                                            }
                                            {index === 0 && row.RowType === 'Primary' && hasSecondaryRows && (<Box sx={{ marginLeft: "8px" }}>{isExpanded ? <ArrowUp /> : <ArrowDown />}</Box>)}
                                        </Box>
                                    </Box>}
                                </>
                            )}
                        </TableCell>
                    )
                })
            }
            <TableCell sx={{ borderTop, borderBottom, width: "100px" }}>
                {!row.empty && !row.isDefault && (
                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'right', gap: "16px", marginTop: "2px" }}>
                        <Box onClick={handleEditClick} sx={{ cursor: "pointer" }}>
                            <EditIcon height="24px" width="24px" />
                        </Box>
                        <Box onClick={(e) => {
                            e.stopPropagation();
                            setDeleteData({
                                id: row.categoryId,
                                name: row.name,
                                type: row.RowType === 'Primary' ? 'category' : 'subcategory',
                                parentId: row.RowType === 'Secondary' ? row.parentId : undefined
                            });
                            setIsDeleteCategoryModalOpen(true);
                        }} sx={{ cursor: "pointer" }}>
                            <TooltipDelete />
                        </Box>
                    </Box>
                )}
            </TableCell>
            <TableCell sx={{ borderTop, borderBottom, width: "32px" }} />
            <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
        </>
    );
};

const getColStyle = ({ isFirstRow, isLastRow, isFirstCol, isLastCol }: any) => {

    if (isFirstRow && isLastRow) {
        if (isFirstCol) {
            return {
                borderTopLeftRadius: 8,
                border: "solid 1px #EBECF2",
                borderStyle: "solid none solid solid",
                borderBottomLeftRadius: 8,
            }
        }

        if (isLastCol) {
            return {
                borderTopRightRadius: 8,
                border: "solid 1px #EBECF2",
                borderStyle: "solid solid solid none",
                borderBottomRightRadius: 8,
            }
        }

        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none solid none",
        }
    }

    if (isFirstRow) {
        if (isFirstCol) {
            return {
                borderTopLeftRadius: 8,
                border: "solid 1px #EBECF2",
                borderStyle: "solid none none solid",
            }
        }

        if (isLastCol) {
            return {
                borderTopRightRadius: 8,
                border: "solid 1px #EBECF2",
                borderStyle: "solid solid none none",
            }
        }

        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none none none",
        }
    }

    if (isLastRow) {
        if (isFirstCol) {
            return {
                borderBottomLeftRadius: 8,
                border: "solid 1px #EBECF2",
                borderStyle: "solid none solid solid",
            }
        }

        if (isLastCol) {
            return {
                borderBottomRightRadius: 8,
                border: "solid 1px #EBECF2",
                borderStyle: "solid solid solid none",
            }
        }

        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none solid none",
        }
    }

    if (isFirstCol) {
        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none none solid",
        }
    }

    if (isLastCol) {
        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid solid none none",
        }
    }

    return {
        border: "solid 1px #EBECF2",
        borderStyle: "solid none none none",
    }
}

interface EditCategoryData {
    CustomCategName: string;
    CustomAssetAllocationCategType: number;
    CustomCategID: number;
    CustomParentCategID: number;
}

const SecondaryTableRow = ({ 
    columns, 
    row, 
    isFirstRow, 
    isLastRow, 
    isAddCategoryModalOpen, 
    isDeleteCategoryModalOpen, 
    setIsAddCategoryModalOpen, 
    setIsDeleteCategoryModalOpen, 
    onRowClick, 
    PMSBadgeField, 
    isHovered, 
    isExpanded, 
    hasTertiaryRows, 
    setEditData,
    setDeleteData
}: { 
    columns: ColumnDataProps[], 
    row: RowDataProps, 
    isFirstRow: boolean, 
    isLastRow: boolean, 
    isAddCategoryModalOpen: boolean, 
    isDeleteCategoryModalOpen: boolean, 
    setIsAddCategoryModalOpen: Dispatch<SetStateAction<boolean>>, 
    setIsDeleteCategoryModalOpen: Dispatch<SetStateAction<boolean>>, 
    onRowClick?: (row: RowDataProps) => void, 
    PMSBadgeField?: string, 
    isHovered: boolean, 
    isExpanded?: boolean, 
    hasTertiaryRows: boolean, 
    setEditData: Dispatch<SetStateAction<EditCategoryData | null>>,
    setDeleteData: Dispatch<SetStateAction<any>>
}) => {

    const handleEditClick = () => {
        const editData: EditCategoryData = {
            CustomCategName: row.name,
            CustomAssetAllocationCategType: CustomAssetAllocationCategType.SubCateg,
            CustomCategID: row.subCategoryId,
            CustomParentCategID: row.parentId
        };
        setEditData(editData);
        setIsAddCategoryModalOpen(true);
    };

    const handleDeleteClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setDeleteData({
            id: row.subCategoryId,
            name: row.name,
            type: 'subcategory',
            parentId: row.parentId
        });
        setIsDeleteCategoryModalOpen(true);
    }

    return (
        <>
            <TableCell sx={{ borderStyle: "none", width: "4%", borderBottom: "none", pointerEvents: "none" }} />
            {
                columns.map((column, index) => {
                    var showRowNameClass = column.IsRowName;
                    const isFirstCol = index === 0;
                    return (
                        <TableCell className={`${showRowNameClass ? "IsRowName" : ""} ${"IsHovered"} ${isExpanded ? "IsSelected" : ""}`} key={column.FieldKey} align={column.Align} sx={{ width: column.ColumnWidthPercent, minWidth: "130px", maxWidth: column.ColumnWidthPercent, ...getColStyle({ isFirstRow, isLastRow, isFirstCol, isLastCol: false }) }}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <CustomTypography color="#515466" variant='Regular18' sx={{ lineHeight: '27px' }}>{column.FieldFormatter(row[column.FieldKey === "AssetTypeName" ? "Name" : column.FieldKey])}</CustomTypography>
                                {
                                    row.isDefault ?
                                        <>
                                            <Box sx={{ borderRadius: "100%", background: "#12131A", width: "4px", height: "4px", margin: "0px 8px" }}></Box>
                                            <Box sx={{ borderRadius: "4px", border: "1px solid #EBECF2", padding: "2px 4px" }}>
                                                <CustomTypography color="#828699" variant='Regular16' sx={{ lineHeight: '24px' }}>MProfit Default</CustomTypography>
                                            </Box>
                                        </>
                                        : null
                                }
                            </Box>
                        </TableCell>
                    )
                })
            }
            <TableCell className={`${"IsHovered"} ${isExpanded ? "IsSelected" : ""}`} align='right' sx={{ width: "100px", ...getColStyle({ isFirstRow, isLastRow, isFirstCol: false, isLastCol: true }) }}>
                <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'right', gap: "16px", marginTop: "2px" }}>
                    {
                        row.isDefault ? null : <>
                            <Box onClick={handleEditClick} sx={{ cursor: "pointer" }}>
                                <EditIcon height="24px" width="24px" />
                            </Box>
                            <Box onClick={handleDeleteClick} sx={{ cursor: "pointer" }}>
                                <TooltipDelete />
                            </Box>
                        </>
                    }
                </Box>
            </TableCell>
            <TableCell sx={{ borderStyle: "none", width: "32px", borderBottom: "none", pointerEvents: "none" }} />
        </>
    );
};

const sortRows = (rows: RowDataProps[], columns: ColumnDataProps[], sortKey?: string, direction?: SharedConstants.SortDirection, expandedRows?: Set<string>, primaryRowId?: string, expandedSecondaryRows?: Set<string>, secondaryRowId?: string) => {
    if (!rows || !sortKey || !primaryRowId || !secondaryRowId) return rows;

    var column = columns.find(x => x.FieldKey === sortKey);
    if (!column) return rows;

    var isColumnNumeric = (column.IsRowName || column.IsString) !== true;

    try {
        const primaryRows = rows.filter(row => row.RowType !== 'Secondary' && row.RowType !== 'Tertiary');
        const secondaryRows = rows.filter(row => row.RowType === 'Secondary');
        const tertiaryRows = rows.filter(row => row.RowType === 'Tertiary');

        const compareValues = (x: any, y: any) => {
            const comparison = isColumnNumeric
                ? Big(x[sortKey] || 0).cmp(y[sortKey] || 0)
                : (x[sortKey] < y[sortKey] ? -1 : x[sortKey] > y[sortKey] ? 1 : 0);
            return comparison * (direction === SharedConstants.SortDirection.Down ? -1 : 1);
        };

        const sortedPrimaryRows = [...primaryRows].sort(compareValues);

        const result: RowDataProps[] = [];
        sortedPrimaryRows.forEach(primaryRow => {
            result.push(primaryRow);
            const primaryRowKey = primaryRow[primaryRowId];

            if (primaryRowKey && expandedRows?.has(primaryRowKey)) {
                const relatedSecondaryRows = secondaryRows
                    .filter(secondaryRow => secondaryRow[primaryRowId] === primaryRowKey)
                    .sort(compareValues);

                relatedSecondaryRows.forEach(secondaryRow => {
                    result.push(secondaryRow);
                    const secondaryRowKey = secondaryRow[secondaryRowId];

                    if (secondaryRowKey && expandedSecondaryRows?.has(secondaryRowKey)) {
                        const relatedTertiaryRows = tertiaryRows
                            .filter(tertiaryRow => tertiaryRow[secondaryRowId] === secondaryRowKey)
                            .sort(compareValues);

                        result.push(...relatedTertiaryRows);
                    }
                });
            }
        });

        return result;
    } catch {
        return rows;
    }
};


export default ManageCategoriesVirtualizedTable;
