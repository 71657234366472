import { createSelector } from "reselect";
import { ClientRootState } from "../reducers";
import { Selectors as PortfolioSelectors } from "../modules/portfoliosReducer";
import { Selectors as DashboardSelectors } from "../modules/dashboardReducer";
import { EE_Breakdown, AssetTypeEnum, AssetTypeTotal, HoldingAssetAllocationDropdownValue, HoldingsMode, PieChartData, SortDirection, StockAddInfo, CustomAssetAllocationMappingsDict, AssetAllocationCategTotal } from "../../constants";
import Big from "big.js";
import { getAMIDAssetTotalForAMID, getAMIDAssetTotals, getAssetAddInfo, getAssetAllocationCategTotals, getAssetTypeSubCategAMIDAssetTotals, getAssetTypeSubCategTotalForID, getAssetTypeSubCategTotals, getHoldingTableData, getMarketCapIDFromAddInfo, getMarketCapNameFromAddInfo, getMarketCapNameFromID, getPieChartData, getMFSEBICategoryNameFromAddInfo, getMFSEBICategoryIDFromAddInfo, getSectorIDFromAddInfo, getSectorNameFromAddInfo, getCategIDFromAddInfoFunctionBasedOnMode, getHoldingTableDataFromList, getTodayTableData, getCategNameFromAddInfoFunctionBasedOnMode, getPieChartDataFromList, getL1AACategFilterPortfolioSummary, getSIDAssetsForAMID, getMFSEBISubCategoryIDFromAddInfo, getMFSEBISubCategoryNameFromAddInfo, getCategIDForMFSEBISubCategoryID, getL1CustomAACategFilterPortfolioSummary, getAssetTypeNameForAA, getHoldingExpandableTableData, escapeRegExp, getSIDAssetsByAMID, getAssetCustomCategInfo, getDefaultSubCategoryForAsset } from "../../utilities";
import { getStocksAssetAllocationIncludingMFETFs, getDetailedEquityExposureForStockAMID, getMFETFsHavingStockAMID, DetailedEquityExposureForStockAMID, getEquityExposureBreakdownEntryForSection, fillPercentagesInEquityExposureBreakdownEntry, processHoldingAssetsInPortfolioWiseEquityExposure } from "../../utilities/mfPortfolioBreakdown";
import { SharedConstants } from "@mprofit/shared";
import { LicensingSelectors } from "../modules/licensing";

//#region L1

const selectRawPortfolioSummaryAll = (state: ClientRootState) => state.shared.portfolioSummary.RawPortfolioSummary;

const selectPortfolioSummaryRaw = createSelector(
    [
        // Usual first input - extract value from `state`
        selectRawPortfolioSummaryAll,
        // Take the second arg, `category`, and forward to the output selector
        (state, params: { CCID: number, FamilyId: number, PFID: number }) => params
    ],
    // Output selector gets (`items, category)` as args
    (rawPortfolioSummaryAll, params) => ((rawPortfolioSummaryAll[params.CCID] || {})[params.FamilyId] || {})[params.PFID]
)

const selectActivePortfolioSummaryRaw = createSelector(
    [
        selectRawPortfolioSummaryAll,
        PortfolioSelectors.selectActivePortfolioIDs
    ],
    (rawPortfolioSummaryAll, activePortfolioIDs) => {
        if (!activePortfolioIDs) return undefined;

        return ((rawPortfolioSummaryAll[activePortfolioIDs.CCID] || {})[activePortfolioIDs.FamilyId] || {})[activePortfolioIDs.PFID];
    }
)

const portfolioSummarySelector = (state: ClientRootState) => state.shared.portfolioSummary;
const selectPriceURLs = (state: ClientRootState) => portfolioSummarySelector(state).ActivePortfolioPrices.PriceURLs;
const selectPriceValuesIsRefreshedOnce = (state: ClientRootState) => portfolioSummarySelector(state).ActivePortfolioPrices.IsRefreshedOnce;
const selectActiveAssetsAddInfo = (state: ClientRootState) => portfolioSummarySelector(state).ActiveAssetsAddInfo;
const selectActiveMFETFPortfolioBreakdown = (state: ClientRootState) => portfolioSummarySelector(state).ActiveMFETFPortfolioBreakdown;
const selectIsActiveMFETFPortfolioBreakdownLoading = (state: ClientRootState) => selectActiveMFETFPortfolioBreakdown(state)?.IsLoading;
const selectActivePortfolioSummary = (state: ClientRootState) => portfolioSummarySelector(state).ActivePortfolioSummary;
const selectActivePortfolioSummaryTotal = (state: ClientRootState) => selectActivePortfolioSummary(state)?.PortfolioTotal;
const selectActivePortfolioSummaryTotalCurrentValue = (state: ClientRootState) => selectActivePortfolioSummaryTotal(state)?.CurrValue;
const selectActivePortfolioSummaryTotalAmountInvested = (state: ClientRootState) => selectActivePortfolioSummaryTotal(state)?.InvAmt;
const selectActivePortfolioSummaryAssetTypeTotals = (state: ClientRootState) => selectActivePortfolioSummary(state)?.AssetTypeTotals;
const selectActivePortfolioSummaryAssetAllocationCategTotals = createSelector([selectActivePortfolioSummary, selectActiveAssetsAddInfo, LicensingSelectors.selectIsUsingDefaultCategorization, DashboardSelectors.selectCustomAssetAllocationMappingsDict, DashboardSelectors.selectCustomAssetAllocationCategoriesById],
    (activePortfolioSummary, activeAssetsAddInfo, isUsingDefaultCategorization, customAssetAllocationMappingsDict, customAssetAllocationCategoriesById) => getAssetAllocationCategTotals(activePortfolioSummary, activeAssetsAddInfo, isUsingDefaultCategorization, customAssetAllocationMappingsDict, customAssetAllocationCategoriesById)
)

const selectIsActivePortfolioSummaryLoading = (state: ClientRootState) => selectActivePortfolioSummary(state) == undefined;

const selectAssetsForSearch = (state: ClientRootState) => selectActivePortfolioSummary(state)?.AssetsForSearch;

const selectActivePortfolioSummaryAssetTypeListUnsorted = createSelector([selectActivePortfolioSummaryAssetTypeTotals], assetTypeTotals => {
    var assetTypeList: AssetTypeTotal[] = [];
    if (!assetTypeTotals) return assetTypeList;

    assetTypeTotals.AllIDs.forEach((assetTypeID) => {
        assetTypeList.push(assetTypeTotals.ByID[assetTypeID]);
    })

    return assetTypeList;
});

const selectActivePortfolioSummaryAssetTypeListSorted = (SortByField: 'CurrValue' | 'TGain', SortDir: SortDirection) => createSelector([selectActivePortfolioSummaryAssetTypeListUnsorted], assetTypeListUnsorted => {
    return assetTypeListUnsorted.sort((x, y) => (SortDir == SortDirection.Up ? 1 : -1) * (Big(x[SortByField] || 0).lt(y[SortByField] || 0) ? 1 : -1));
});

const selectActivePortfolioSummaryAssetTypeListForChart = selectActivePortfolioSummaryAssetTypeListSorted('CurrValue', SortDirection.Up);

const selectActivePortfolioSummaryAssetAllocationCategListUnsorted = createSelector([selectActivePortfolioSummaryAssetAllocationCategTotals], assetAllocationCategTotals => {
    var assetAllocationCategList: AssetAllocationCategTotal[] = [];
    if (!assetAllocationCategTotals) return assetAllocationCategList;

    assetAllocationCategTotals.AllIDs.forEach((assetAllocationCategID) => {
        assetAllocationCategList.push(assetAllocationCategTotals.ByID[assetAllocationCategID]);
    })

    return assetAllocationCategList;
});

const selectActivePortfolioSummaryAssetAllocationCategListSorted = (SortByField: 'CurrValue' | 'TGain', SortDir: SortDirection) => createSelector([selectActivePortfolioSummaryAssetAllocationCategListUnsorted], assetAllocationCategListUnsorted => {
    return assetAllocationCategListUnsorted.sort((x, y) => (SortDir == SortDirection.Up ? 1 : -1) * (Big(x[SortByField] || 0).lt(y[SortByField] || 0) ? 1 : -1));
});

const selectActivePortfolioSummaryAssetAllocationCategListForChart = selectActivePortfolioSummaryAssetAllocationCategListSorted('CurrValue', SortDirection.Up);

const selectActiveAssetAllocationCategList = createSelector([selectActivePortfolioSummaryAssetAllocationCategListForChart], assetAllocationCategList => {
    return assetAllocationCategList.map(x => ({
        AssetAllocationCategID: x.AssetAllocationCategID,
        AssetAllocationCategName: x.AssetAllocationCategName
    }));
})

const selectL1PieChartData_ByAssetAllocation = createSelector(
    [
        selectActivePortfolioSummaryAssetAllocationCategTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (activePortfolioSummaryAssetAllocationCategTotals, totalField) => {
        return getPieChartData(totalField, (item: any) => item.AssetAllocationCategName, (item: any) => ({ AssetAllocationCategID: item.AssetAllocationCategID }), activePortfolioSummaryAssetAllocationCategTotals)
    });

const selectL1PieChartData_ByAssetType = createSelector(
    [
        selectActivePortfolioSummaryAssetTypeListUnsorted,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (assetTypeTotals, totalField) => {
        return getPieChartDataFromList(totalField, (item: any) => item.AssetTypeName, (item: any) => ({ AssetTypeID: item.AssetTypeID }), assetTypeTotals.filter(x => x.AssetTypeID != AssetTypeEnum.LN), 10);
    });

const selectL1HoldingsPieChartData = (holdingsMode: HoldingsMode, totalField: HoldingAssetAllocationDropdownValue) => {
    switch (holdingsMode) {
        default:
        case HoldingsMode.AssetAllocation:
            return (state: ClientRootState) => selectL1PieChartData_ByAssetAllocation(state, totalField);
        case HoldingsMode.MProfitClassification:
            return (state: ClientRootState) => selectL1PieChartData_ByAssetType(state, totalField);
    }
}

const selectL1Table_ByAssetAllocation = createSelector(
    [selectActivePortfolioSummaryAssetAllocationCategTotals],
    (activePortfolioSummaryAssetAllocationCategTotals) => {
        return getHoldingTableData(activePortfolioSummaryAssetAllocationCategTotals);
    }
);

const selectL1Table_ByAssetType = createSelector(
    [selectActivePortfolioSummaryAssetTypeTotals],
    (assetTypeTotals) => {
        return getHoldingTableData(assetTypeTotals);
    }
);

const selectL1HoldingsTable = (holdingsMode: HoldingsMode) => {
    switch (holdingsMode) {
        default:
        case HoldingsMode.AssetAllocation:
            return selectL1Table_ByAssetAllocation;
        case HoldingsMode.MProfitClassification:
            return selectL1Table_ByAssetType;
    }
}

const selectActiveAssetTypesList = createSelector([selectActivePortfolioSummaryAssetTypeListForChart], assetTypeList => {
    return assetTypeList.map(x => ({
        AssetTypeID: x.AssetTypeID,
        AssetTypeName: x.AssetTypeName
    }));
})

const selectMyStocksTGainPct = createSelector(
    [selectActivePortfolioSummary],
    (activePortfolioSummary) => {
        var TGainPct = activePortfolioSummary?.AssetTypeTotals?.ByID[AssetTypeEnum.EQ]?.TGainPct;
        return TGainPct ? Big(TGainPct).toNumber() : undefined;
    }
)

const selectLeaderboardGainersLosers = createSelector(
    [selectActivePortfolioSummary],
    (activePortfolioSummary) => {
        var stocks = activePortfolioSummary?.AssetsByAssetType[AssetTypeEnum.EQ];
        if (!stocks) return undefined;

        var amidAssetTotals = getAMIDAssetTotals(stocks);

        var stocksSorted = amidAssetTotals.sort((x, y) => Big(x.TGainPct || 0).lt(y.TGainPct || 0) ? -1 : 1);

        var Losers = stocksSorted.filter(x => Big(x.TGainPct || 0).lt(0)).slice(0, 10);
        var Gainers = stocksSorted.filter(x => Big(x.TGainPct || 0).gt(0)).reverse().slice(0, 10);

        return {
            Gainers,
            Losers
        }
    }
)

const selectActivePortfolioSummaryForAllAssetsChart = createSelector([selectActivePortfolioSummary, selectActivePortfolioSummaryAssetTypeTotals, PortfolioSelectors.selectPortfolioById, PortfolioSelectors.selectIsActivePortfolioAGroup],
    (activePortfolioSummary, assetTypeTotals, portfolioMap, isActivePortfolioAGroup) => {
        var assetTypeList: any[] = [];
        if (!assetTypeTotals) return assetTypeList;

        assetTypeTotals.AllIDs.forEach((assetTypeID) => {
            assetTypeList.push({ ...assetTypeTotals.ByID[assetTypeID], RowType: 'Primary' });

            var assetsByAssetTypeList = activePortfolioSummary?.AssetsByAssetType[assetTypeID];
            if (!assetsByAssetTypeList) return;

            var amidAssetTotals = getAMIDAssetTotals(assetsByAssetTypeList, () => true, true);
            if (!amidAssetTotals) return;

            amidAssetTotals.forEach((asset) => {
                assetTypeList.push({ ...asset, SIDTotals: undefined, RowType: 'Secondary', PortfolioName: portfolioMap[asset.PFID]?.PName });

                if (asset.SIDTotals && asset.SIDTotals?.length > (isActivePortfolioAGroup ? 0 : 1)) {
                    asset.SIDTotals.forEach((sidTotal) => {
                        assetTypeList.push({ ...sidTotal, RowType: 'Tertiary', PortfolioName: portfolioMap[sidTotal.PFID]?.PName });
                    });
                }
            });
        });

        return assetTypeList;
    }
);

const selectActivePortfolioSummaryForAllAssetsChartFiltered = createSelector(
    [
        selectActivePortfolioSummary,
        selectActivePortfolioSummaryAssetTypeTotals,
        PortfolioSelectors.selectPortfolioById,
        PortfolioSelectors.selectIsActivePortfolioAGroup,
        (state, searchTerm: string) => searchTerm
    ],
    (activePortfolioSummary, assetTypeTotals, portfolioMap, isActivePortfolioAGroup, searchTerm) => {
        if (!assetTypeTotals) return [];
        if (!searchTerm) return selectActivePortfolioSummaryForAllAssetsChart.resultFunc(activePortfolioSummary, assetTypeTotals, portfolioMap, isActivePortfolioAGroup);

        const searchTerms = searchTerm.toLowerCase().split(/\s+/);
        const searchPattern = new RegExp(searchTerms.map(term =>
            '(?=.*' + escapeRegExp(term) + ')'
        ).join(''), 'i');

        let assetTypeList: any[] = [];

        assetTypeTotals.AllIDs.forEach((assetTypeID) => {
            const assetTypeTotal = assetTypeTotals.ByID[assetTypeID];
            let assetsByAssetTypeList = activePortfolioSummary?.AssetsByAssetType[assetTypeID];
            if (!assetsByAssetTypeList) return;

            let amidAssetTotals = getAMIDAssetTotals(assetsByAssetTypeList, () => true, true);
            if (!amidAssetTotals) return;

            const assetTypeMatches = searchPattern.test(assetTypeTotal.AssetTypeName);

            const filteredAssets = assetTypeMatches
                ? amidAssetTotals
                : amidAssetTotals.filter(asset =>
                    searchPattern.test(asset.Name) ||
                    searchPattern.test(portfolioMap[asset.PFID]?.PName || '')
                );

            if (assetTypeMatches || filteredAssets.length > 0) {
                assetTypeList.push({ ...assetTypeTotal, RowType: 'Primary' });

                filteredAssets.forEach(asset => {
                    assetTypeList.push({
                        ...asset,
                        PortfolioName: portfolioMap[asset.PFID]?.PName,
                        SIDTotals: undefined,
                        RowType: 'Secondary'
                    });

                    if (asset.SIDTotals && asset.SIDTotals.length > (isActivePortfolioAGroup ? 0 : 1)) {
                        assetTypeList.push(...asset.SIDTotals.map(sidTotal => ({
                            ...sidTotal,
                            PortfolioName: portfolioMap[sidTotal.PFID]?.PName,
                            RowType: 'Tertiary'
                        })));
                    }
                });
            }
        });

        return assetTypeList;
    }
);

// Helper functions for each preference type
const applyShowZeroValuesFilter = (assetList: any[], showZeroValues: boolean) => {
    if (!assetList || showZeroValues) return assetList;

    return assetList.filter(assetType => {
        // Always keep primary rows
        if (assetType.RowType === 'Primary') {
            return true;
        }

        // Filter secondary and tertiary rows
        if (assetType.RowType === 'Secondary' || assetType.RowType === 'Tertiary') {
            const invAmt = new Big(assetType.InvAmt || 0);
            const currValue = new Big(assetType.CurrValue || 0);
            const quant = new Big(assetType.Quant || 0);

            if (invAmt.eq(0) && currValue.eq(0) && quant.lte(0)) {
                return false;
            }
        }

        return true;
    });
};

const applyDefaultSortFilter = (assetList: any[], defaultSort: string) => {
    // TODO: Implement default sort logic
    return assetList;
};

const applySortDirectionFilter = (assetList: any[], sortDirection: string) => {
    // TODO: Implement sort direction logic
    return assetList;
};

const applySeparatorFilter = (assetList: any[], separator: string) => {
    // TODO: Implement separator logic
    return assetList;
};

const applyShowDecimalsFilter = (assetList: any[], showDecimals: boolean) => {
    // TODO: Implement show decimals logic
    return assetList;
};

// Create separate selectors for each filter type
const selectFilteredByZeroValues = createSelector(
    [
        (state, params: { showZeroValues: boolean, assetList: any[] }) => params.assetList,
        (state, params: { showZeroValues: boolean, assetList: any[] }) => params.showZeroValues
    ],
    (assetList, showZeroValues) => applyShowZeroValuesFilter(assetList, showZeroValues)
);

const selectFilteredBySort = createSelector(
    [
        (state, params: { defaultSort: string, assetList: any[] }) => params.assetList,
        (state, params: { defaultSort: string, assetList: any[] }) => params.defaultSort
    ],
    (assetList, defaultSort) => applyDefaultSortFilter(assetList, defaultSort)
);

const selectFilteredBySortDirection = createSelector(
    [
        (state, params: { sortDirection: string, assetList: any[] }) => params.assetList,
        (state, params: { sortDirection: string, assetList: any[] }) => params.sortDirection
    ],
    (assetList, sortDirection) => applySortDirectionFilter(assetList, sortDirection)
);

const selectFilteredBySeparator = createSelector(
    [
        (state, params: { separator: string, assetList: any[] }) => params.assetList,
        (state, params: { separator: string, assetList: any[] }) => params.separator
    ],
    (assetList, separator) => applySeparatorFilter(assetList, separator)
);

const selectFilteredByDecimals = createSelector(
    [
        (state, params: { showDecimals: boolean, assetList: any[] }) => params.assetList,
        (state, params: { showDecimals: boolean, assetList: any[] }) => params.showDecimals
    ],
    (assetList, showDecimals) => applyShowDecimalsFilter(assetList, showDecimals)
);

// Main selector that combines all filters
const selectFilteredAssetsWithPreferences = createSelector(
    [
        (state, params: { 
            defaultSort?: string, 
            sortDirection?: string,
            separator?: string,
            showZeroValues?: boolean,
            showDecimals?: boolean,
            searchTerm?: string 
        }) => selectActivePortfolioSummaryForAllAssetsChartFiltered(state, params.searchTerm || ''),
        (state, params) => params
    ],
    (assetList, preferences) => {
        if (!assetList) return assetList;

        let filteredList = assetList;

        // Apply filters only if the preference is defined
        if (preferences.showZeroValues !== undefined) {
            filteredList = selectFilteredByZeroValues(null as any, { 
                showZeroValues: preferences.showZeroValues, 
                assetList: filteredList 
            });
        }

        if (preferences.defaultSort) {
            filteredList = selectFilteredBySort(null as any, { 
                defaultSort: preferences.defaultSort, 
                assetList: filteredList 
            });
        }


        if (preferences.sortDirection) {
            filteredList = selectFilteredBySortDirection(null as any, { 
                sortDirection: preferences.sortDirection, 
                assetList: filteredList 
            });
        }

        if (preferences.separator) {
            filteredList = selectFilteredBySeparator(null as any, { 
                separator: preferences.separator, 
                assetList: filteredList 
            });
        }

        if (preferences.showDecimals !== undefined) {
            filteredList = selectFilteredByDecimals(null as any, { 
                showDecimals: preferences.showDecimals, 
                assetList: filteredList 
            });
        }

        return filteredList;
    }
);

export const L1PortfolioSummarySelectors = {
    selectPortfolioSummaryRaw, portfolioSummarySelector, selectPriceURLs, selectPriceValuesIsRefreshedOnce, selectActivePortfolioSummary, selectActivePortfolioSummaryTotal,
    selectActivePortfolioSummaryTotalCurrentValue, selectActivePortfolioSummaryTotalAmountInvested, selectActivePortfolioSummaryAssetTypeTotals, selectActivePortfolioSummaryAssetTypeListForChart,
    selectActivePortfolioSummaryAssetTypeListUnsorted, selectActivePortfolioSummaryAssetTypeListSorted,
    selectActivePortfolioSummaryAssetAllocationCategTotals, selectL1PieChartData_ByAssetAllocation,
    selectL1HoldingsPieChartData, selectL1HoldingsTable,
    selectActiveAssetTypesList,
    selectIsActivePortfolioSummaryLoading,
    selectActiveAssetsAddInfo,
    selectMyStocksTGainPct, selectLeaderboardGainersLosers,
    selectAssetsForSearch,
    selectActivePortfolioSummaryForAllAssetsChart,
    selectActivePortfolioSummaryForAllAssetsChartFiltered,
    selectFilteredAssetsWithPreferences,
    selectActivePortfolioSummaryAssetAllocationCategListUnsorted,
    selectActivePortfolioSummaryAssetAllocationCategListSorted,
    selectActiveAssetAllocationCategList
};

const selectAssetsByAssetTypeAll = createSelector(
    [L1PortfolioSummarySelectors.selectActivePortfolioSummary],
    (activePortfolioSummary) => {
        return activePortfolioSummary?.AssetsByAssetType;
    }
)

//#endregion

//#region L1 AA

const selectActivePortfolioSummaryForAACategID
    = createSelector([selectActivePortfolioSummary, PortfolioSelectors.selectActiveAssetAllocationCategID, selectActiveAssetsAddInfo],
        (activePortfolioSummary, assetAllocationCategID, assetsAddInfo) => {
            if (activePortfolioSummary && assetsAddInfo && assetAllocationCategID) {
                return getL1AACategFilterPortfolioSummary(activePortfolioSummary, assetAllocationCategID, assetsAddInfo);
            } else {
                return undefined;
            }
        });

const selectActivePortfolioSummaryForAACategIDTotal = (state: ClientRootState) => selectActivePortfolioSummaryForAACategID(state)?.PortfolioTotal;
const selectActivePortfolioSummaryForAACategIDTotalCurrentValue = (state: ClientRootState) => selectActivePortfolioSummaryForAACategIDTotal(state)?.CurrValue;
const selectActivePortfolioSummaryForAACategIDTotalAmountInvested = (state: ClientRootState) => selectActivePortfolioSummaryForAACategIDTotal(state)?.InvAmt;
const selectActivePortfolioSummaryForAACategIDAssetTypeTotals = (state: ClientRootState) => selectActivePortfolioSummaryForAACategID(state)?.AssetTypeTotals;

const selectL1_AA_Table_ByAssetType = createSelector(
    [selectActivePortfolioSummaryForAACategIDAssetTypeTotals],
    (assetTypeTotals) => {
        return getHoldingTableData(assetTypeTotals);
    }
);

const selectL1_AA_PieChartData_ByAssetType = createSelector(
    [
        selectActivePortfolioSummaryForAACategIDAssetTypeTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (assetTypeTotals, totalField) => {
        return getPieChartData(totalField, (item: any) => item.AssetTypeName, (item: any) => ({ AssetTypeID: item.AssetTypeID }), assetTypeTotals, 10);
    });

const selectActivePortfolioSummaryForAACategIDAssetTypeListUnsorted = createSelector([selectActivePortfolioSummaryForAACategIDAssetTypeTotals], assetTypeTotals => {
    var assetTypeList: AssetTypeTotal[] = [];
    if (!assetTypeTotals) return assetTypeList;

    assetTypeTotals.AllIDs.forEach((assetTypeID) => {
        if (assetTypeID != AssetTypeEnum.LN) {
            assetTypeList.push(assetTypeTotals.ByID[assetTypeID]);
        }
    })

    return assetTypeList;
});

const selectActivePortfolioSummaryForAACategIDAssetTypeListSorted = (SortByField: 'CurrValue' | 'TGain', SortDir: SortDirection) => createSelector([selectActivePortfolioSummaryForAACategIDAssetTypeListUnsorted], assetTypeListUnsorted => {
    return assetTypeListUnsorted.sort((x, y) => (SortDir == SortDirection.Up ? 1 : -1) * (Big(x[SortByField] || 0).lt(y[SortByField] || 0) ? 1 : -1));
});

export const L1_AA_PortfolioSummarySelectors = {
    selectActivePortfolioSummaryForAACategID,
    selectActivePortfolioSummaryForAACategIDTotal,
    selectActivePortfolioSummaryForAACategIDTotalCurrentValue,
    selectActivePortfolioSummaryForAACategIDTotalAmountInvested,
    selectL1_AA_Table_ByAssetType,
    selectL1_AA_PieChartData_ByAssetType,
    selectActivePortfolioSummaryForAACategIDAssetTypeListSorted,
}

//#endregion

//#region L1 Custom AA

const selectActivePortfolioSummaryForCustomAACategID
    = createSelector([selectActivePortfolioSummary, PortfolioSelectors.selectActiveCustomAssetAllocationCategID, selectActiveAssetsAddInfo, DashboardSelectors.selectCustomAssetAllocationMappingsDict, DashboardSelectors.selectCustomAssetAllocationCategoriesById],
        (activePortfolioSummary, assetAllocationCategID, assetsAddInfo, customAssetAllocationMappingsDict, customAssetAllocationCategoriesById) => {
            if (activePortfolioSummary && assetsAddInfo && assetAllocationCategID) {
                return getL1CustomAACategFilterPortfolioSummary(activePortfolioSummary, assetAllocationCategID, assetsAddInfo, customAssetAllocationMappingsDict, customAssetAllocationCategoriesById);
            } else {
                return undefined;
            }
        });

const selectActivePortfolioSummaryForCustomAACategIDTotal = (state: ClientRootState) => selectActivePortfolioSummaryForCustomAACategID(state)?.PortfolioTotal;
const selectActivePortfolioSummaryForCustomAACategIDTotalCurrentValue = (state: ClientRootState) => selectActivePortfolioSummaryForCustomAACategIDTotal(state)?.CurrValue;
const selectActivePortfolioSummaryForCustomAACategIDTotalAmountInvested = (state: ClientRootState) => selectActivePortfolioSummaryForCustomAACategIDTotal(state)?.InvAmt;
const selectActivePortfolioSummaryForCustomAACategIDAssetTypeTotals = (state: ClientRootState) => selectActivePortfolioSummaryForCustomAACategID(state)?.AssetTypeTotals;

const selectL1_Custom_AA_Table_ByAssetType = createSelector(
    [selectActivePortfolioSummaryForCustomAACategIDAssetTypeTotals],
    (assetTypeTotals) => {
        return getHoldingTableData(assetTypeTotals);
    }
);

const selectL1_Custom_AA_PieChartData_ByAssetType = createSelector(
    [
        selectActivePortfolioSummaryForCustomAACategIDAssetTypeTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (assetTypeTotals, totalField) => {
        return getPieChartData(totalField, (item: any) => item.SubCategName, (item: any) => ({ SubCategName: item.SubCategName, SubCategID: item.SubCategID }), assetTypeTotals, 10);
    });

const selectActivePortfolioSummaryForCustomAACategIDAssetTypeListUnsorted = createSelector([selectActivePortfolioSummaryForCustomAACategIDAssetTypeTotals], assetTypeTotals => {
    var assetTypeList: AssetTypeTotal[] = [];
    if (!assetTypeTotals) return assetTypeList;

    assetTypeTotals.AllIDs.forEach((assetTypeID) => {
        assetTypeList.push(assetTypeTotals.ByID[assetTypeID]);
    })

    return assetTypeList;
});

const selectActivePortfolioSummaryForCustomAACategIDAssetTypeListSorted = (SortByField: 'CurrValue' | 'TGain', SortDir: SortDirection) => createSelector([selectActivePortfolioSummaryForCustomAACategIDAssetTypeListUnsorted], assetTypeListUnsorted => {
    return assetTypeListUnsorted.sort((x, y) => (SortDir == SortDirection.Up ? 1 : -1) * (Big(x[SortByField] || 0).lt(y[SortByField] || 0) ? 1 : -1));
});

export const L1_Custom_AA_PortfolioSummarySelectors = {
    selectActivePortfolioSummaryForCustomAACategID,
    selectActivePortfolioSummaryForCustomAACategIDTotal,
    selectActivePortfolioSummaryForCustomAACategIDTotalCurrentValue,
    selectActivePortfolioSummaryForCustomAACategIDTotalAmountInvested,
    selectActivePortfolioSummaryForCustomAACategIDAssetTypeTotals,
    selectL1_Custom_AA_PieChartData_ByAssetType,
    selectActivePortfolioSummaryForCustomAACategIDAssetTypeListSorted,
    selectL1_Custom_AA_Table_ByAssetType
}

//#endregion

//#region L1 Custom AA Subcategory

const selectL1_Custom_AA_SubcategoryPortfolioSummaryTotal = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs, L1PortfolioSummarySelectors.selectActivePortfolioSummary, L1_Custom_AA_PortfolioSummarySelectors.selectActivePortfolioSummaryForCustomAACategID],
    (activeScreenIDs, activePortfolioSummary, activePortfolioSummaryForCustomAACategID) => {
        if (!activeScreenIDs || !activeScreenIDs.CustomAssetAllocationSubCategID) return undefined;

        var relevantActivePortfolioSummary = activeScreenIDs.CustomAssetAllocationCategID && activeScreenIDs.CustomAssetAllocationCategID > 0 ? activePortfolioSummaryForCustomAACategID : activePortfolioSummary;

        return relevantActivePortfolioSummary?.AssetTypeTotals?.ByID[activeScreenIDs.CustomAssetAllocationSubCategID] || undefined;
    }
)

const selectL1_Custom_AA_SubcategoryPortfolioSummarySubCategName = (state: ClientRootState) => selectL1_Custom_AA_SubcategoryPortfolioSummaryTotal(state)?.SubCategName;
const selectL1_Custom_AA_SubcategoryPortfolioSummaryTotalCurrentValue = (state: ClientRootState) => selectL1_Custom_AA_SubcategoryPortfolioSummaryTotal(state)?.CurrValue;
const selectL1_Custom_AA_SubcategoryPortfolioSummaryTotalAmountInvested = (state: ClientRootState) => selectL1_Custom_AA_SubcategoryPortfolioSummaryTotal(state)?.InvAmt;

const selectL1_Custom_AA_Subcategory_Assets = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs, L1_Custom_AA_PortfolioSummarySelectors.selectActivePortfolioSummaryForCustomAACategID],
    (activeScreenIDs, activePortfolioSummaryForCustomAACategID) => {
        if (!activeScreenIDs || !activeScreenIDs.CustomAssetAllocationSubCategID) return undefined;

        const subCategID = activeScreenIDs.CustomAssetAllocationSubCategID;

        return activePortfolioSummaryForCustomAACategID?.AssetsByAssetType[subCategID as keyof typeof activePortfolioSummaryForCustomAACategID.AssetsByAssetType];
    }
)

const selectL1_Custom_AA_SubcategoryTodayTable_ByAsset = createSelector(
    [selectL1_Custom_AA_Subcategory_Assets], (activeL1CustomAASubcategoryAssets) => {
        if (!activeL1CustomAASubcategoryAssets) return undefined;

        return getAMIDAssetTotals(activeL1CustomAASubcategoryAssets).sort((x, y) => { return Big(x.CurrValue || 0).gt(y.CurrValue || 0) ? -1 : 1 });
    }
)

const selectL1_Custom_AA_SubcategoryHoldingTable_ByAsset = createSelector(
    [selectL1_Custom_AA_SubcategoryTodayTable_ByAsset], (todayTable) => {
        if (!todayTable) return undefined;

        return getHoldingTableDataFromList(todayTable);
    }
)

const selectL1_Custom_AA_SubcategoryHoldingExpandableTable_ByAssetType = createSelector(
    [selectL1_Custom_AA_Subcategory_Assets],
    (activeL1CustomAASubcategoryAssets) => {
        return getHoldingExpandableTableData(activeL1CustomAASubcategoryAssets);
    }
);

const selectL1_Custom_AA_SubcategoryTodayExpandableTable_ByAsset = createSelector(
    [selectL1_Custom_AA_Subcategory_Assets], (activeL1CustomAASubcategoryAssets) => {
        if (!activeL1CustomAASubcategoryAssets) return undefined;

        const assets = getAMIDAssetTotals(activeL1CustomAASubcategoryAssets)
        .sort((x, y) => Big(x.CurrValue || 0).gt(y.CurrValue || 0) ? -1 : 1);
        
        const assetsByType = assets.reduce((acc: { [key: string]: any[] }, asset) => {
            const assetType = getAssetTypeNameForAA(asset.AssetTypeID) || '-';
            if (!acc[assetType]) {
                acc[assetType] = [];
            }
            acc[assetType].push(asset);
            return acc;
        }, {});

        const result: any[] = [];
        
        Object.entries(assetsByType).forEach(([assetType, assets]) => {
            const assetTypeTotal = assets.reduce((total, asset) => ({
                CurrValue: Big(total.CurrValue || 0).plus(asset.CurrValue || 0).toNumber(),
                InvAmt: Big(total.InvAmt || 0).plus(asset.InvAmt || 0).toNumber(),
                TGain: Big(total.TGain || 0).plus(asset.TGain || 0).toNumber(),
                TGainPct: Big(total.TGain || 0).div(total.InvAmt || 1).times(100).toNumber()
            }), { CurrValue: 0, InvAmt: 0, TGain: 0, TGainPct: 0 });

            result.push({
                ...assetTypeTotal,
                Name: assetType,
                AssetTypeName: assetType,
                RowType: 'Primary',
                AssetTypeID: assets[0].AssetTypeID
            });

            assets.forEach(asset => {
                result.push({
                    ...asset,
                    RowType: 'Secondary',
                    AssetTypeID: asset.AssetTypeID
                });
            });
        });

        return result;
    }
);

const selectL1_Custom_AA_SubcategoryPieChartData_ByAsset = createSelector(
    [
        selectL1_Custom_AA_SubcategoryTodayTable_ByAsset,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
        return getPieChartDataFromList(totalField, (item: any) => item.Name, (item: any) => ({ AMID: item.AMID, SID: item.SID, AssetTypeID: item.AssetTypeID }), totals, 10);
    });

export const L1_Custom_AA_SubcategoryPortfolioSummarySelectors = {
    selectL1_Custom_AA_SubcategoryPortfolioSummaryTotal,
    selectL1_Custom_AA_SubcategoryPortfolioSummarySubCategName,
    selectL1_Custom_AA_SubcategoryPortfolioSummaryTotalCurrentValue,
    selectL1_Custom_AA_SubcategoryPortfolioSummaryTotalAmountInvested,
    selectL1_Custom_AA_SubcategoryTodayTable_ByAsset,
    selectL1_Custom_AA_SubcategoryHoldingTable_ByAsset,
    selectL1_Custom_AA_SubcategoryTodayExpandableTable_ByAsset,
    selectL1_Custom_AA_SubcategoryPieChartData_ByAsset,
    selectL1_Custom_AA_SubcategoryHoldingExpandableTable_ByAssetType
}

// ... existing code ...

//#region L2

const selectActiveL2PortfolioSummaryTotal = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs, L1PortfolioSummarySelectors.selectActivePortfolioSummary, L1_AA_PortfolioSummarySelectors.selectActivePortfolioSummaryForAACategID],
    (activeScreenIDs, activePortfolioSummary, activePortfolioSummaryForAACategID) => {
        if (!activeScreenIDs || !activeScreenIDs.AssetType) return undefined;

        var relevantActivePortfolioSummary = activeScreenIDs.AssetAllocationCategID && activeScreenIDs.AssetAllocationCategID > 0 ? activePortfolioSummaryForAACategID : activePortfolioSummary;

        return relevantActivePortfolioSummary?.AssetTypeTotals?.ByID[activeScreenIDs.AssetType];
    }
)

const selectActiveL2PortfolioSummaryTotalCurrentValue = (state: ClientRootState) => selectActiveL2PortfolioSummaryTotal(state)?.CurrValue;
const selectActiveL2PortfolioSummaryTotalAmountInvested = (state: ClientRootState) => selectActiveL2PortfolioSummaryTotal(state)?.InvAmt;

const selectActiveL2Assets = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs, L1PortfolioSummarySelectors.selectActivePortfolioSummary, L1_AA_PortfolioSummarySelectors.selectActivePortfolioSummaryForAACategID],
    (activeScreenIDs, activePortfolioSummary, activePortfolioSummaryForAACategID) => {
        if (!activeScreenIDs || !activeScreenIDs.AssetType) return undefined;

        var relevantActivePortfolioSummary = activeScreenIDs.AssetAllocationCategID && activeScreenIDs.AssetAllocationCategID > 0 ? activePortfolioSummaryForAACategID : activePortfolioSummary;

        return relevantActivePortfolioSummary?.AssetsByAssetType[activeScreenIDs.AssetType];
    }
)

const selectL2TodaysTable = (holdingsMode: HoldingsMode) => {
    switch (holdingsMode) {
        case HoldingsMode.Sectors:
            return selectL2TodayTable_ByStocksSector;
        case HoldingsMode.MarketCap:
            return selectL2TodayTable_ByStocksMarketCap;
        default:
        case HoldingsMode.Assets:
            return selectL2TodayTable_ByAsset;
        case HoldingsMode.MFSEBICategory:
            return selectL2TodayTable_ByMFSEBICategory;
    }
}

const selectL2TodayTable_ByAsset = createSelector(
    [selectActiveL2Assets, selectActiveAssetsAddInfo, LicensingSelectors.selectIsUsingDefaultCategorization, DashboardSelectors.selectCustomAssetAllocationMappingsDict, DashboardSelectors.selectCustomAssetAllocationCategoriesById], 
    (activeL2Assets, assetsAddInfo, isUsingDefaultCategorization, customAssetAllocationMappingsDict, customAssetAllocationCategoriesById) => {
        if (!activeL2Assets) return undefined;

        var amidAssetTotals = getAMIDAssetTotals(activeL2Assets);
        
        const amidAssetTotalsWithAllocationInfo = amidAssetTotals.map(asset => {
            const assetAddInfo = getAssetAddInfo(asset.AssetTypeID, asset.AMID, assetsAddInfo);
            let assetCustomCategInfo = {};
            if (!isUsingDefaultCategorization) {
                assetCustomCategInfo = getAssetCustomCategInfo(asset.AssetTypeID, asset.AMID, assetsAddInfo, customAssetAllocationMappingsDict, customAssetAllocationCategoriesById);
            } else {
                assetCustomCategInfo = {};
            }       

            return {
                ...asset,
                ...assetAddInfo,
                ...assetCustomCategInfo
            };
        });

        return amidAssetTotalsWithAllocationInfo.sort((x, y) => { return Big(x.CurrValue || 0).gt(y.CurrValue || 0) ? -1 : 1 });
    }
)

const selectActivePortfolioSummaryStocksSectorTotals = createSelector([
    (state: ClientRootState) => selectAssetsByAssetType(state, AssetTypeEnum.EQ),
    selectActiveAssetsAddInfo
],
    (assetsByAssetType, activeAssetsAddInfo) => {
        return getAssetTypeSubCategTotals(
            AssetTypeEnum.EQ, assetsByAssetType, activeAssetsAddInfo,
            getSectorIDFromAddInfo,
            getSectorNameFromAddInfo,
        )
    }
)

const selectActivePortfolioSummaryStocksMarketCapTotals = createSelector([
    (state: ClientRootState) => selectAssetsByAssetType(state, AssetTypeEnum.EQ),
    selectActiveAssetsAddInfo
],
    (assetsByAssetType, activeAssetsAddInfo) => {
        return getAssetTypeSubCategTotals(
            AssetTypeEnum.EQ, assetsByAssetType, activeAssetsAddInfo,
            getMarketCapIDFromAddInfo,
            getMarketCapNameFromAddInfo,
        )
    }
)

const selectActivePortfolioSummaryMFSEBICategoryTotals = createSelector([
    (state: ClientRootState) => selectAssetsByAssetType(state, AssetTypeEnum.MFEQ),
    selectActiveAssetsAddInfo
],
    (assetsByAssetType, activeAssetsAddInfo) => {
        return getAssetTypeSubCategTotals(
            AssetTypeEnum.MFEQ, assetsByAssetType, activeAssetsAddInfo,
            getMFSEBICategoryIDFromAddInfo,
            getMFSEBICategoryNameFromAddInfo,
        )
    }
)

const selectL2TodayTable_ByStocksSector = createSelector(
    [selectActivePortfolioSummaryStocksSectorTotals],
    (totals) => {
        return getTodayTableData(totals);
    }
);

const selectL2TodayTable_ByStocksMarketCap = createSelector(
    [selectActivePortfolioSummaryStocksMarketCapTotals],
    (totals) => {
        return getTodayTableData(totals);
    }
);

const selectL2TodayTable_ByMFSEBICategory = createSelector(
    [selectActivePortfolioSummaryMFSEBICategoryTotals],
    (totals) => {
        return getTodayTableData(totals);
    }
);

const selectL2PieChartData_ByAsset = createSelector(
    [
        selectL2TodayTable_ByAsset,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
        return getPieChartDataFromList(totalField, (item: any) => item.Name, (item: any) => ({ AMID: item.AMID, SID: item.SID }), totals, 10);
    });

const selectL2PieChartData_ByStocksSector = createSelector(
    [
        selectActivePortfolioSummaryStocksSectorTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
        return getPieChartData(totalField, (item: any) => item.CategName, (item: any) => ({ CategID: item.CategID }), totals, 10);
    });

const selectL2PieChartData_ByStocksMarketCap = createSelector(
    [
        selectActivePortfolioSummaryStocksMarketCapTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
        return getPieChartData(totalField, (item: any) => item.CategName, (item: any) => ({ CategID: item.CategID }), totals, 10);
    });

const selectL2PieChartData_ByMFSEBICategory = createSelector(
    [
        selectActivePortfolioSummaryMFSEBICategoryTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
        return getPieChartData(totalField, (item: any) => item.CategName, (item: any) => ({ CategID: item.CategID }), totals, 10);
    });

const selectL2HoldingsPieChartData = (holdingsMode: HoldingsMode, totalField: HoldingAssetAllocationDropdownValue, assetType?: AssetTypeEnum) => {
    switch (holdingsMode) {
        default:
        case HoldingsMode.Sectors:
            return (state: ClientRootState) => selectL2PieChartData_ByStocksSector(state, totalField);
        case HoldingsMode.MarketCap:
            return (state: ClientRootState) => selectL2PieChartData_ByStocksMarketCap(state, totalField);
        case HoldingsMode.Assets:
            return (state: ClientRootState) => selectL2PieChartData_ByAsset(state, totalField);
        case HoldingsMode.MFSEBICategory:
            return (state: ClientRootState) => selectL2PieChartData_ByMFSEBICategory(state, totalField);
    }
}

const selectL2HoldingsTable_ByAsset = createSelector(
    [selectL2TodayTable_ByAsset],
    (totals) => {
        return getHoldingTableDataFromList(totals);
    }
);

const selectL2HoldingsTable_ByStocksSector = createSelector(
    [selectActivePortfolioSummaryStocksSectorTotals],
    (totals) => {
        return getHoldingTableData(totals);
    }
);

const selectL2HoldingsTable_ByStocksMarketCap = createSelector(
    [selectActivePortfolioSummaryStocksMarketCapTotals],
    (totals) => {
        return getHoldingTableData(totals);
    }
);

const selectL2HoldingsTable_ByMFSEBICategory = createSelector(
    [selectActivePortfolioSummaryMFSEBICategoryTotals],
    (totals) => {
        return getHoldingTableData(totals);
    }
);

const selectL2HoldingsTable = (holdingsMode: HoldingsMode) => {
    switch (holdingsMode) {
        default:
        case HoldingsMode.Sectors:
            return selectL2HoldingsTable_ByStocksSector;
        case HoldingsMode.MarketCap:
            return selectL2HoldingsTable_ByStocksMarketCap;
        case HoldingsMode.Assets:
            return selectL2HoldingsTable_ByAsset;
        case HoldingsMode.MFSEBICategory:
            return selectL2HoldingsTable_ByMFSEBICategory;
    }
}

const selectSIDAssetsByAMIDForAssetType = createSelector(
    [selectAssetsByAssetTypeAll, (state: ClientRootState, assetTypeID?: number) => assetTypeID],
    (assetsByAssetType, assetTypeID) => {
        if (!assetsByAssetType || !assetTypeID) return {};
        return getSIDAssetsByAMID(assetsByAssetType[assetTypeID]);
    }
);

//#region Equity Exposure - Summary

const selectL2StocksAllocationIncludingMFETFPortBreakdown = createSelector(
    [
        selectAssetsByAssetTypeAll,
        selectActiveMFETFPortfolioBreakdown,
        selectActiveAssetsAddInfo
    ], (
        assetsByAssetType,
        activeMFETFPortfolioBreakdownObject,
        assetsAddInfo
    ) => {
        if (!assetsByAssetType || !activeMFETFPortfolioBreakdownObject) return undefined;

        var MFETFPortfolioBreakdown = getStocksAssetAllocationIncludingMFETFs(-1, assetsByAssetType, activeMFETFPortfolioBreakdownObject.Data, assetsAddInfo);

        return MFETFPortfolioBreakdown.sort((x, y) => { return Big(x.CurrValue || 0).gt(y.CurrValue || 0) ? -1 : 1 });
    }
)

const selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown = createSelector(
    [
        selectL2StocksAllocationIncludingMFETFPortBreakdown,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
        return getPieChartDataFromList(totalField, (item: any) => item.Name, (item: any) => ({ AMID: item.AMID, SID: item.SID, AssetTypeID: item.AssetTypeID }), totals, 10);
    }
);

const selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown = createSelector(
    [
        selectL2StocksAllocationIncludingMFETFPortBreakdown,
        selectActivePortfolioSummaryTotalCurrentValue
    ],
    (totals, portfolioCurrentValue) => {
        return getHoldingTableDataFromList(totals, true, portfolioCurrentValue);
    }
);

const selectStocksAddInfoForEquityExposure = createSelector(
    [selectActiveMFETFPortfolioBreakdown, selectActiveAssetsAddInfo],
    (mfETFPortfolioBreakdown, activeAssetsAddInfo) => {
        var activeStocksAddInfo: { [AMID: number]: StockAddInfo } = {
            ...(activeAssetsAddInfo?.Stocks || {})
        };

        mfETFPortfolioBreakdown?.Data?.StocksInfo.forEach((stockAddInfo) => {
            activeStocksAddInfo[stockAddInfo.MProfitCode] = stockAddInfo;
        });
        
        return activeStocksAddInfo;
    }
);

const selectActiveStockAddInfoForEquityExposure = createSelector(
    [
        selectStocksAddInfoForEquityExposure,
        PortfolioSelectors.selectActiveScreenIDs
    ],
    (stocksAddInfo, activeScreenIDs) => {
        if (!stocksAddInfo || !activeScreenIDs?.AMID) {
            return undefined;
        }

        return stocksAddInfo[activeScreenIDs.AMID];
    }
);

const selectActiveStockNameForEquityExposure = createSelector(
    [selectActiveStockAddInfoForEquityExposure],
    (activeStockAddInfo) => {
        return activeStockAddInfo?.CompanyName;
    }
);

const selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown_Sector = createSelector(
    [selectL2StocksAllocationIncludingMFETFPortBreakdown, selectStocksAddInfoForEquityExposure],
    (totals, stocksAddInfo): { PieChartData: PieChartData[]; TotalValue: string } | [] => {
        if (!totals || !stocksAddInfo) return [];

        const sectorMap: {
            [key: number]: {
                sectorID: number,
                sectorName: string,
                currValue: number
            }
        } = {};

        totals.forEach(asset => {
            const assetAddInfo = stocksAddInfo[asset.AMID];
            const sectorID = getSectorIDFromAddInfo(assetAddInfo);
            const sectorName = getSectorNameFromAddInfo(assetAddInfo)

            if (sectorID === undefined || sectorName === undefined) return;

            if (!sectorMap[sectorID]) {
                sectorMap[sectorID] = {
                    sectorID,
                    sectorName,
                    currValue: 0
                };
            }

            sectorMap[sectorID].currValue = Big(sectorMap[sectorID].currValue).plus(asset.CurrValue || 0).toNumber();
        });

        const result = getPieChartDataFromList(
            'CurrValue',
            (item: any) => item.Name,
            (item: any) => ({ CategID: item.sectorID }),
            Object.values(sectorMap).map(sector => ({
                Name: sector.sectorName,
                CurrValue: sector.currValue.toString(),
                sectorID: sector.sectorID
            })).sort((a, b) => Big(b.CurrValue).minus(a.CurrValue).toNumber()),
            10
        );

        return result;
    }
);

const selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_Sector = createSelector(
    [selectL2StocksAllocationIncludingMFETFPortBreakdown, selectActivePortfolioSummaryTotalCurrentValue, selectStocksAddInfoForEquityExposure],
    (totals, portfolioCurrentValue, stocksAddInfo) => {
        if (!totals || !stocksAddInfo) return [];

        const totalEquityValue = totals.reduce((sum, asset) => Big(sum).plus(asset.CurrValue || 0).toNumber(), 0);

        const sectorMap: {
            [key: number]: {
                sectorID: number,
                sectorName: string,
                currValue: number,
                invAmt: number,
                tGain: number,
                assets: any[]
            }
        } = {};

        totals.forEach(asset => {
            const assetAddInfo = stocksAddInfo[asset.AMID];
            const sectorID = getSectorIDFromAddInfo(assetAddInfo);
            const sectorName = getSectorNameFromAddInfo(assetAddInfo)

            if (sectorID === undefined || sectorName === undefined) return;

            if (!sectorMap[sectorID]) {
                sectorMap[sectorID] = {
                    sectorID,
                    sectorName,
                    currValue: 0,
                    invAmt: 0,
                    tGain: 0,
                    assets: []
                };
            }

            sectorMap[sectorID].currValue = Big(sectorMap[sectorID].currValue).plus(asset.CurrValue || 0).toNumber();
            sectorMap[sectorID].invAmt = Big(sectorMap[sectorID].invAmt).plus(asset.InvAmt || 0).toNumber();
            sectorMap[sectorID].tGain = Big(sectorMap[sectorID].tGain).plus(asset.TGain || 0).toNumber();
            sectorMap[sectorID].assets.push(asset);
        });

        const sortedSectors = Object.values(sectorMap)
            .sort((a, b) => Big(b.currValue).minus(a.currValue).toNumber());

        const tableRows: any[] = [];

        sortedSectors.forEach((sector, sectorIndex) => {
            const primaryId = `sector_${sectorIndex}`;
            const holdingPctValue = totalEquityValue ? Big(sector.currValue).div(totalEquityValue).toNumber() : 0;
            const pctOfTotalValue = portfolioCurrentValue ? Big(sector.currValue).div(portfolioCurrentValue).toNumber() : 0;

            tableRows.push({
                RowType: 'Primary',
                PrimaryRowID: primaryId,
                Name: sector.sectorName,
                CurrValue: sector.currValue,
                InvAmt: sector.invAmt,
                TGain: sector.tGain,
                TGainPct: sector.invAmt ? Big(sector.tGain).div(sector.invAmt).toNumber() : 0,
                HoldingPctValue: holdingPctValue,
                HoldingPct: holdingPctValue.toString(),
                PctOfTotalValue: pctOfTotalValue,
                PctOfTotal: pctOfTotalValue.toString()
            });

            const sortedAssets = sector.assets
                .sort((a, b) => Big(b.CurrValue || 0).minus(a.CurrValue || 0).toNumber());

            sortedAssets.forEach((asset, assetIndex) => {
                const secondaryId = `${primaryId}_secondary_${assetIndex}`;
                const assetHoldingPctValue = totalEquityValue ? Big(asset.CurrValue || 0).div(totalEquityValue).toNumber() : 0;
                const assetPctOfTotalValue = portfolioCurrentValue ? Big(asset.CurrValue || 0).div(portfolioCurrentValue).toNumber() : 0;

                tableRows.push({
                    RowType: 'Secondary',
                    PrimaryRowID: primaryId,
                    SecondaryRowID: secondaryId,
                    Name: asset.Name,
                    CurrValue: asset.CurrValue,
                    InvAmt: asset.InvAmt,
                    TGain: asset.TGain,
                    TGainPct: asset.TGainPct,
                    HoldingPctValue: assetHoldingPctValue,
                    HoldingPct: assetHoldingPctValue.toString(),
                    PctOfTotalValue: assetPctOfTotalValue,
                    PctOfTotal: assetPctOfTotalValue.toString(),
                    AMID: asset.AMID,
                    SID: asset.SID,
                    AssetTypeID: asset.AssetTypeID
                });
            });

        });

        return tableRows.sort((a, b) => {
            if (a.RowType === 'Primary' && b.RowType === 'Primary') {
                return Big(b.CurrValue || 0).minus(a.CurrValue || 0).toNumber();
            }
            return 0;
        });
    }
);

const selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown_MarketCap = createSelector(
    [selectL2StocksAllocationIncludingMFETFPortBreakdown, selectStocksAddInfoForEquityExposure],
    (totals, stocksAddInfo): { PieChartData: PieChartData[]; TotalValue: string } | [] => {
        if (!totals || !stocksAddInfo) return [];

        const marketCapMap: { [key: number]: {
            marketCapID: number,
            marketCapName: string,
            currValue: number
        }} = {};

        totals.forEach(asset => {
            const assetAddInfo = stocksAddInfo[asset.AMID];
            const marketCapID = getMarketCapIDFromAddInfo(assetAddInfo);
            const marketCapName = getMarketCapNameFromAddInfo(assetAddInfo);

            if (marketCapID === undefined || marketCapName === undefined) return;

            if (!marketCapMap[marketCapID]) {
                marketCapMap[marketCapID] = {
                    marketCapID,
                    marketCapName,
                    currValue: 0
                };
            }

            marketCapMap[marketCapID].currValue = Big(marketCapMap[marketCapID].currValue).plus(asset.CurrValue || 0).toNumber();
        });

        return getPieChartDataFromList(
            'CurrValue', 
            (item: any) => item.Name,
            (item: any) => ({ CategID: item.marketCapID }),
            Object.values(marketCapMap).map(mcap => ({
                Name: mcap.marketCapName,
                CurrValue: mcap.currValue.toString(),
                marketCapID: mcap.marketCapID
            })).sort((a, b) => Big(b.CurrValue).minus(a.CurrValue).toNumber()),
            10
        );
    }
);

const selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_MarketCap = createSelector(
    [selectL2StocksAllocationIncludingMFETFPortBreakdown, selectActivePortfolioSummaryTotalCurrentValue, selectStocksAddInfoForEquityExposure],
    (totals, portfolioCurrentValue, stocksAddInfo) => {
        if (!totals || !stocksAddInfo) return [];

        const totalEquityValue = totals.reduce((sum, asset) => Big(sum).plus(asset.CurrValue || 0).toNumber(), 0);

        const marketCapMap: {
            [key: number]: {
                marketCapID: number,
                marketCapName: string,
                currValue: number,
                invAmt: number,
                tGain: number,
                assets: any[]
            }
        } = {};

        totals.forEach(asset => {
            const assetAddInfo = stocksAddInfo[asset.AMID];
            const marketCapID = getMarketCapIDFromAddInfo(assetAddInfo);
            const marketCapName = getMarketCapNameFromAddInfo(assetAddInfo);

            if (marketCapID === undefined || marketCapName === undefined) return;

            if (!marketCapMap[marketCapID]) {
                marketCapMap[marketCapID] = {
                    marketCapID,
                    marketCapName,
                    currValue: 0,
                    invAmt: 0,
                    tGain: 0,
                    assets: []
                };
            }

            marketCapMap[marketCapID].currValue = Big(marketCapMap[marketCapID].currValue).plus(asset.CurrValue || 0).toNumber();
            marketCapMap[marketCapID].invAmt = Big(marketCapMap[marketCapID].invAmt).plus(asset.InvAmt || 0).toNumber();
            marketCapMap[marketCapID].tGain = Big(marketCapMap[marketCapID].tGain).plus(asset.TGain || 0).toNumber();
            marketCapMap[marketCapID].assets.push(asset);
        });

        const tableRows: any[] = [];

        Object.values(marketCapMap).forEach((marketCap, marketCapIndex) => {
            const primaryId = `marketcap_${marketCapIndex}`;
            const holdingPctValue = totalEquityValue ? Big(marketCap.currValue).div(totalEquityValue).toNumber() : 0;
            const pctOfTotalValue = portfolioCurrentValue ? Big(marketCap.currValue).div(portfolioCurrentValue).toNumber() : 0;

            tableRows.push({
                RowType: 'Primary',
                PrimaryRowID: primaryId,
                Name: marketCap.marketCapName,
                CurrValue: marketCap.currValue,
                InvAmt: marketCap.invAmt,
                TGain: marketCap.tGain,
                TGainPct: marketCap.invAmt ? Big(marketCap.tGain).div(marketCap.invAmt).toNumber() : 0,
                HoldingPctValue: holdingPctValue,
                HoldingPct: holdingPctValue.toString(),
                PctOfTotalValue: pctOfTotalValue,
                PctOfTotal: pctOfTotalValue.toString()
            });

            marketCap.assets.forEach((asset, assetIndex) => {
                const secondaryId = `${primaryId}_secondary_${assetIndex}`;
                const assetHoldingPctValue = totalEquityValue ? Big(asset.CurrValue || 0).div(totalEquityValue).toNumber() : 0;
                const assetPctOfTotalValue = portfolioCurrentValue ? Big(asset.CurrValue || 0).div(portfolioCurrentValue).toNumber() : 0;

                tableRows.push({
                    RowType: 'Secondary',
                    PrimaryRowID: primaryId,
                    SecondaryRowID: secondaryId,
                    Name: asset.Name,
                    CurrValue: asset.CurrValue,
                    InvAmt: asset.InvAmt,
                    TGain: asset.TGain,
                    TGainPct: asset.TGainPct,
                    HoldingPctValue: assetHoldingPctValue,
                    HoldingPct: assetHoldingPctValue.toString(),
                    PctOfTotalValue: assetPctOfTotalValue,
                    PctOfTotal: assetPctOfTotalValue.toString(),
                    AMID: asset.AMID,
                    SID: asset.SID,
                    AssetTypeID: asset.AssetTypeID
                });
            });

        });

        return tableRows.sort((a, b) => {
            if (a.RowType === 'Primary' && b.RowType === 'Primary') {
                return Big(b.CurrValue || 0).minus(a.CurrValue || 0).toNumber();
            }
            return 0;
        });
    }
);

const selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_Sector_Primary = createSelector(
    [selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_Sector],
    (tableData) => {
        if (!tableData) return [];
        
        return tableData.filter(row => row.RowType === 'Primary');
    }
);

const selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_MarketCap_Primary = createSelector(
    [selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_MarketCap],
    (tableData) => {
        if (!tableData) return [];
        
        return tableData.filter(row => row.RowType === 'Primary');
    }
);

//#endregion

export const L2PortfolioSummarySelectors = {
    selectActiveL2PortfolioSummaryTotal,
    selectActiveL2PortfolioSummaryTotalCurrentValue,
    selectActiveL2PortfolioSummaryTotalAmountInvested,
    selectL2PieChartData_ByAsset,
    selectL2TodaysTable,
    selectL2HoldingsPieChartData, selectL2HoldingsTable,
    selectL2StocksAllocationIncludingMFETFPortBreakdown,
    selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown,
    selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown,
    selectIsActiveMFETFPortfolioBreakdownLoading,
    selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_Sector,
    selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_MarketCap,
    selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown_Sector,
    selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown_MarketCap,
    selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_Sector_Primary,
    selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_MarketCap_Primary,
    selectActiveStockAddInfoForEquityExposure,
    selectActiveStockNameForEquityExposure
}

//#endregion

//#region L3

const selectAssetsByAssetType = createSelector(
    [L1PortfolioSummarySelectors.selectActivePortfolioSummary, (state: ClientRootState, assetTypeID?: number) => assetTypeID],
    (activePortfolioSummary, assetTypeID) => {
        if (!assetTypeID) return undefined;

        return activePortfolioSummary?.AssetsByAssetType[assetTypeID];
    }
)

const selectActiveL3IDs = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs],
    (activeScreenIDs) => {
        if (!activeScreenIDs || !activeScreenIDs.AssetType) return undefined;

        switch (activeScreenIDs.AssetType) {
            case AssetTypeEnum.EQ:
                if (activeScreenIDs.L3Mode === HoldingsMode.Sectors) {
                    return { Mode: HoldingsMode.Sectors, AssetTypeID: activeScreenIDs.AssetType, CategID: activeScreenIDs.SectorID };
                } else if (activeScreenIDs.L3Mode === HoldingsMode.MarketCap) {
                    return { Mode: HoldingsMode.MarketCap, AssetTypeID: activeScreenIDs.AssetType, CategID: activeScreenIDs.MarketCapID };
                } else {
                    return undefined;
                }
            case AssetTypeEnum.MFEQ:
            case AssetTypeEnum.MFD:
                if (activeScreenIDs.L3Mode === HoldingsMode.MFSEBICategory) {
                    return { Mode: HoldingsMode.MFSEBICategory, AssetTypeID: activeScreenIDs.AssetType, CategID: activeScreenIDs.SEBICategoryID };
                } else if (activeScreenIDs.L3Mode === HoldingsMode.MFSEBISubCategory) {
                    return { Mode: HoldingsMode.MFSEBISubCategory, AssetTypeID: activeScreenIDs.AssetType, CategID: getCategIDForMFSEBISubCategoryID(activeScreenIDs.SEBICategoryID, activeScreenIDs.SEBISubCategoryID) };
                }
        }

        return undefined;
    }
)

const selectActiveL3TableData = createSelector(
    [
        selectActiveL2Assets,
        selectActiveL3IDs,
        selectActiveAssetsAddInfo
    ], (activeL2Assets, activeL3IDs, assetsAddInfo) => {
        if (!activeL2Assets || !activeL3IDs || activeL3IDs.CategID === undefined) return undefined;

        var getCategIDFromAssetAddInfo = getCategIDFromAddInfoFunctionBasedOnMode(activeL3IDs.Mode);

        if (getCategIDFromAssetAddInfo) {
            return getAssetTypeSubCategAMIDAssetTotals(activeL3IDs.CategID, activeL3IDs.AssetTypeID, activeL2Assets, assetsAddInfo, getCategIDFromAssetAddInfo);
        } else {
            return undefined;
        }
    }
)

const selectL3PieChartData = createSelector(
    [
        selectActiveL3TableData,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
        return getPieChartDataFromList(totalField, (item: any) => item.Name, (item: any) => ({ AMID: item.AMID, SID: item.SID }), totals, 10);
    });

const selectActiveL3PortfolioSummaryTotal = createSelector(
    [
        selectActiveL2Assets,
        selectActiveL3IDs,
        selectActiveAssetsAddInfo
    ], (activeL2Assets, activeL3IDs, assetsAddInfo) => {
        if (!activeL2Assets || !activeL3IDs || activeL3IDs.CategID == undefined) return undefined;

        var getCategIDFromAssetAddInfo = getCategIDFromAddInfoFunctionBasedOnMode(activeL3IDs.Mode);
        var getCategNameFromAddInfo = getCategNameFromAddInfoFunctionBasedOnMode(activeL3IDs.Mode);

        if (getCategIDFromAssetAddInfo) {
            return getAssetTypeSubCategTotalForID(activeL3IDs.CategID, activeL3IDs.AssetTypeID, activeL2Assets, assetsAddInfo, getCategIDFromAssetAddInfo, getCategNameFromAddInfo);
        } else {
            return undefined;
        }
    }
)

const selectActiveL3CategDetails = (state: ClientRootState) => {
    const total = selectActiveL3PortfolioSummaryTotal(state);
    return total ? { CategID: total.CategID, CategName: total.CategName } : undefined;
}

// const selectL3HoldingsPieChartData = (holdingsMode: HoldingsMode, totalField: HoldingAssetAllocationDropdownValue, assetType?: AssetTypeEnum) => {
//     switch (holdingsMode) {
//         default:
//         case HoldingsMode.Sectors:
//             return (state: ClientRootState) => selectL2PieChartData_ByStocksSector(state, totalField);
//         case HoldingsMode.MarketCap:
//             return (state: ClientRootState) => selectL2PieChartData_ByStocksMarketCap(state, totalField);
//         case HoldingsMode.Assets:
//             return (state: ClientRootState) => selectL2PieChartData_ByAsset(state, totalField);
//         case HoldingsMode.MFSEBICategory:
//             return (state: ClientRootState) => selectL2PieChartData_ByMFSEBICategory(state, totalField);
//     }
// }

const selectL3HoldingsPieChartData = createSelector(
    [
        selectActiveL3TableData,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
        return getPieChartDataFromList(totalField, (item: any) => item.Name, (item: any) => ({ AMID: item.AMID, SID: item.SID }), totals, 10);
    });


const selectL3HoldingsTable = createSelector(
    [selectActiveL3TableData],
    (totals) => {
        return getHoldingTableDataFromList(totals);
    }
);

export const L3PortfolioSummarySelectors = {
    selectActiveL3TableData,
    selectActiveL3PortfolioSummaryTotal,
    selectActiveL3IDs,
    selectL3PieChartData,
    selectL3HoldingsTable,
    selectL3HoldingsPieChartData,
    selectActiveL3CategDetails
}

//#endregion

//#region L3 Category

const selectActiveMFSEBICategoryBySubCategoryTotals = createSelector(
    [
        (state: ClientRootState) => selectAssetsByAssetType(state, AssetTypeEnum.MFEQ),
        selectActiveL3IDs,
        selectActiveAssetsAddInfo
    ], (assetsByAssetType, activeL3IDs, assetsAddInfo) => {
        if (!assetsByAssetType || !activeL3IDs || activeL3IDs.CategID === undefined) return undefined;

        return getAssetTypeSubCategTotals(
            AssetTypeEnum.MFEQ, assetsByAssetType, assetsAddInfo,
            getMFSEBISubCategoryIDFromAddInfo,
            getMFSEBISubCategoryNameFromAddInfo,
            {
                getCategIDFromAssetAddInfo: getMFSEBICategoryIDFromAddInfo,
                matchValue: activeL3IDs.CategID
            }
        )
    }
)

const selectL3CategoryHoldingsTable_ByMFSEBISubCategory = createSelector(
    [selectActiveMFSEBICategoryBySubCategoryTotals],
    (totals) => {
        return getHoldingTableData(totals);
    }
);

const selectL3CategoryHoldingsTable = (holdingsMode: HoldingsMode) => {
    switch (holdingsMode) {
        default:
        case HoldingsMode.MFSEBISubCategory:
            return selectL3CategoryHoldingsTable_ByMFSEBISubCategory;
        case HoldingsMode.Assets:
            return selectL3HoldingsTable;
    }
}

const selectL3CategoryPieChartData_ByMFSEBISubCategory = createSelector(
    [
        selectActiveMFSEBICategoryBySubCategoryTotals,
        (state, totalField: HoldingAssetAllocationDropdownValue) => totalField
    ],
    (totals, totalField) => {
        return getPieChartData(totalField, (item: any) => item.CategName, (item: any) => ({ CategID: item.CategID }), totals, 10);
    });


const selectL3CategoryHoldingsPieChartData = (holdingsMode: HoldingsMode, totalField: HoldingAssetAllocationDropdownValue, assetType?: AssetTypeEnum) => {
    switch (holdingsMode) {
        case HoldingsMode.MFSEBISubCategory:
            return (state: ClientRootState) => selectL3CategoryPieChartData_ByMFSEBISubCategory(state, totalField);
        default:
        case HoldingsMode.Assets:
            return (state: ClientRootState) => selectL3PieChartData(state, totalField);
    }
}

const selectL3CategoryTodayTable_ByMFSEBISubCategory = createSelector(
    [selectActiveMFSEBICategoryBySubCategoryTotals],
    (totals) => {
        return getTodayTableData(totals);
    }
);

const selectL3CategoryTodayTable = (holdingsMode: HoldingsMode) => {
    switch (holdingsMode) {
        case HoldingsMode.MFSEBISubCategory:
            return selectL3CategoryTodayTable_ByMFSEBISubCategory;
        default:
        case HoldingsMode.Assets:
            return selectActiveL3TableData;
    }
}

export const L3CategoryPortfolioSummarySelectors = {
    selectL3CategoryHoldingsTable,
    selectL3CategoryHoldingsPieChartData,
    selectL3CategoryTodayTable
}

//#endregion

//#region L4

const selectActiveL4PortfolioSummaryTotal = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs, selectActiveL2Assets],
    (activeScreenIDs, SIDAssetTotals) => {
        if (!activeScreenIDs || !SIDAssetTotals || !activeScreenIDs.AssetType || !activeScreenIDs.AMID) return undefined;

        if (activeScreenIDs.SID && activeScreenIDs.SID > 0) {
            return SIDAssetTotals.ByID[activeScreenIDs.SID];
        } else {
            return getAMIDAssetTotalForAMID(SIDAssetTotals, activeScreenIDs.AMID);
        }
    }
)

const selectActiveL4TGainPct = createSelector(
    [selectActiveL4PortfolioSummaryTotal],
    (activeL4PortfolioSummaryTotal) => {
        var TGainPct = activeL4PortfolioSummaryTotal?.TGainPct;
        return TGainPct ? Big(TGainPct).toNumber() : undefined;
    }
)

const selectActiveL4AssetName = createSelector(
    [selectActiveL4PortfolioSummaryTotal],
    (activeL4PortfolioSummaryTotal) => {
        return activeL4PortfolioSummaryTotal?.Name
    }
)

const selectActiveL4AssetCustomCategInfo = createSelector([PortfolioSelectors.selectActiveScreenIDs, selectActiveAssetsAddInfo, DashboardSelectors.selectCustomAssetAllocationMappingsDict, DashboardSelectors.selectCustomAssetAllocationCategoriesById], 
    (activeScreenIDs, assetsAddInfo, customAssetAllocationMappingsDict, customAssetAllocationCategoriesById) => {
    if (!activeScreenIDs || !activeScreenIDs.AssetType || !activeScreenIDs.AMID) return undefined;

    return getAssetCustomCategInfo(activeScreenIDs.AssetType, activeScreenIDs.AMID, assetsAddInfo, customAssetAllocationMappingsDict, customAssetAllocationCategoriesById);
})

const selectActiveL4AssetAddInfo = createSelector(
    [PortfolioSelectors.selectActiveScreenIDs, selectActiveAssetsAddInfo, LicensingSelectors.selectIsUsingDefaultCategorization, selectActiveL4AssetCustomCategInfo],
    (activeScreenIDs, assetsAddInfo, isUsingDefaultCategorization, assetCustomCategInfo) => {
        if (!activeScreenIDs || !activeScreenIDs.AssetType || !activeScreenIDs.AMID) return undefined;

        const baseInfo = getAssetAddInfo(activeScreenIDs.AssetType, activeScreenIDs.AMID, assetsAddInfo);
        if (!baseInfo) return undefined;

        return {
            ...baseInfo,
            ...(!isUsingDefaultCategorization ? assetCustomCategInfo : {})
        } as (SharedConstants.StockAddInfo | SharedConstants.MFAddInfo | SharedConstants.AssetAllocationInfo);
    }
)

const selectActiveL4AssetStocksSector = createSelector(
    [selectActiveL4AssetAddInfo],
    (assetAddInfo) => {
        var SectorID = (assetAddInfo as StockAddInfo)?.SectorID;
        var SectorName = (assetAddInfo as StockAddInfo)?.SectorName;
        return { SectorID, SectorName };
    }
)

const selectActiveL4AssetStocksMarketCap = createSelector(
    [selectActiveL4AssetAddInfo],
    (assetAddInfo) => {
        var MarketCapID = (assetAddInfo as StockAddInfo)?.MarketCapClassification;
        var MarketCapName = getMarketCapNameFromID(MarketCapID);
        return { MarketCapID, MarketCapName };
    }
)

const selectActiveL4PercentOfTotal_Portfolio = (totalField: HoldingAssetAllocationDropdownValue) => ((state: ClientRootState) => selectActivePortfolioSummaryTotal(state)?.[totalField]);

const selectActiveL4PercentOfTotal_AssetType = (totalField: HoldingAssetAllocationDropdownValue) => ((state: ClientRootState) => selectActiveL2PortfolioSummaryTotal(state)?.[totalField]);

const selectStocksSectorTotal = createSelector(
    [
        (state: ClientRootState) => selectAssetsByAssetType(state, AssetTypeEnum.EQ),
        selectActiveAssetsAddInfo,
        (state, sectorID: number) => (sectorID)
    ],
    (assetsByAssetType, activeAssetsAddInfo, sectorID) => {
        return getAssetTypeSubCategTotalForID(
            sectorID, AssetTypeEnum.EQ, assetsByAssetType, activeAssetsAddInfo,
            getSectorIDFromAddInfo,
            getSectorNameFromAddInfo
        );
    });

const selectActiveL4PercentOfTotal_Sector = (totalField: HoldingAssetAllocationDropdownValue, sectorID: number) => ((state: ClientRootState) => selectStocksSectorTotal(state, sectorID)?.[totalField]);


const selectL4TodayTable_BySID = createSelector(
    [selectActiveL2Assets, PortfolioSelectors.selectActiveAMIDSID], (activeL2Assets, { AMID, SID }) => {
        if (!activeL2Assets || !AMID) return [];

        return getSIDAssetsForAMID(activeL2Assets, AMID);
    }
)

const selectL4HoldingsTable_BySID = createSelector(
    [selectL4TodayTable_BySID],
    (totals) => {
        return getHoldingTableDataFromList(totals);
    }
);

const selectActiveL4Refno = createSelector(
    [selectActiveL4PortfolioSummaryTotal],
    (activeL4PortfolioSummaryTotal) => {
        return activeL4PortfolioSummaryTotal?.Refno
    }
)

const selectActiveL4AssetAllocationCategInfo = createSelector(
    [selectActiveL4AssetAddInfo],
    (assetAddInfo) => {
        return {
            AssetAllocationCategID: assetAddInfo?.AssetAllocationCategID,
            AssetAllocationCategName: assetAddInfo?.AssetAllocationCategName,
            AssetAllocationSubCategID: assetAddInfo?.AssetAllocationSubCategID,
            AssetAllocationSubCategName: assetAddInfo?.AssetAllocationSubCategName
        }
    }
)

const selectActiveL4AssetDefaultAssetAllocationCategInfo = createSelector(
    [
        DashboardSelectors.selectAssetCategorizationData,
        selectActiveAssetsAddInfo,
        (state: ClientRootState, props: { assetTypeID: number, customAssetAllocationCategID: number, amid: number }) => props
    ],
    (assetCategorizationData, assetsAddInfo, { assetTypeID, customAssetAllocationCategID, amid }) => {
        return getDefaultSubCategoryForAsset(assetTypeID, amid, assetsAddInfo, assetCategorizationData);
    }
);

export const L4PortfolioSummarySelectors = {
    selectActiveL4PortfolioSummaryTotal,
    selectActiveL4TGainPct,
    selectActiveL4AssetAddInfo,
    selectActiveL4AssetName,
    selectActiveL4AssetStocksSector,
    selectActiveL4AssetStocksMarketCap,
    selectActiveL4PercentOfTotal_Portfolio, selectActiveL4PercentOfTotal_AssetType, selectActiveL4PercentOfTotal_Sector,
    selectL4TodayTable_BySID, selectL4HoldingsTable_BySID,
    selectActiveL4Refno,
    selectActiveL4AssetAllocationCategInfo,
    selectActiveL4AssetDefaultAssetAllocationCategInfo
}

//#endregion

//#region Equity Exposure - Detailed

const selectETFSIDAssetsByAMID = (state: ClientRootState) =>
    selectSIDAssetsByAMIDForAssetType(state, AssetTypeEnum.EQ);

const selectMFSIDAssetsByAMID = (state: ClientRootState) =>
    selectSIDAssetsByAMIDForAssetType(state, AssetTypeEnum.MFEQ);

const selectDetailedEquityExposureForStockAMID = createSelector([
    selectAssetsByAssetTypeAll,
    selectETFSIDAssetsByAMID,
    selectMFSIDAssetsByAMID,
    selectActiveMFETFPortfolioBreakdown,
    PortfolioSelectors.selectPortfolioById,
    (state: ClientRootState, AMID?: number) => AMID
], (
    assetsByAssetType,
    ETFsByAMID,
    MFsByAMID,
    activeMFETFPortfolioBreakdownObject,
    portfolioMap,
    AMID
) => {
        if (!assetsByAssetType || !ETFsByAMID || !MFsByAMID || !activeMFETFPortfolioBreakdownObject || !AMID) return undefined;

        var mfETFsHavingStockAMID = getMFETFsHavingStockAMID(
            AMID,
            activeMFETFPortfolioBreakdownObject.Data
        );

        var detailedEquityExposureAssetBreakdown = getDetailedEquityExposureForStockAMID(
            AMID,
            ETFsByAMID,
            MFsByAMID,
            mfETFsHavingStockAMID,
            assetsByAssetType,
            portfolioMap
        );

        return detailedEquityExposureAssetBreakdown;
    }
)

const selectAssetClassWiseDetailedEquityExposureBreakdown = createSelector(
    [(state: ClientRootState, AMID: number) => selectDetailedEquityExposureForStockAMID(state, AMID)],
    (breakdown: DetailedEquityExposureForStockAMID | undefined) => {
        if (!breakdown) return undefined;

        const result: EE_Breakdown = {
            totalValue: 0
        };

        if (breakdown.Direct?.length) {
            result.Direct = getEquityExposureBreakdownEntryForSection(breakdown.Direct, 'Stocks', true);
            result.totalValue += result.Direct.currValue;
        }

        if (breakdown.ETF?.length) {
            result.ETF = getEquityExposureBreakdownEntryForSection(breakdown.ETF, 'ETFs');
            result.totalValue += result.ETF.currValue;
        }

        if (breakdown.MF?.length) {
            result.MF = getEquityExposureBreakdownEntryForSection(breakdown.MF, 'Mutual Funds');
            result.totalValue += result.MF.currValue;
        }

        if (result.totalValue > 0) {
            result.Direct = fillPercentagesInEquityExposureBreakdownEntry(result.Direct, result.totalValue);
            result.ETF = fillPercentagesInEquityExposureBreakdownEntry(result.ETF, result.totalValue);
            result.MF = fillPercentagesInEquityExposureBreakdownEntry(result.MF, result.totalValue);
        }

        return result;
    }
);

const selectPortfolioWiseDetailedEquityExposureBreakdown = createSelector(
    [(state: ClientRootState, AMID: number) => selectDetailedEquityExposureForStockAMID(state, AMID)],
    (breakdown: DetailedEquityExposureForStockAMID | undefined) => {
        if (!breakdown) return undefined;

        const portfolioMap: {
            [key: number]: {
                name: string,
                currValue: number,
                percentage: number,
                IsCashMgmtPortfolio: boolean,
                subEntries: {
                    name: string,
                    currValue: number,
                    percentage: number,
                    assetClass: 'Direct' | 'ETF' | 'MF'
                }[]
            }
        } = {};
        let totalValue = 0;

        breakdown.Direct?.forEach((holding) => {
            totalValue = processHoldingAssetsInPortfolioWiseEquityExposure(holding, portfolioMap, 'Direct', totalValue);
        });

        breakdown.ETF?.forEach((holding) => {
            totalValue = processHoldingAssetsInPortfolioWiseEquityExposure(holding, portfolioMap, 'ETF', totalValue);
        });

        breakdown.MF?.forEach((holding) => {
            totalValue = processHoldingAssetsInPortfolioWiseEquityExposure(holding, portfolioMap, 'MF', totalValue);
        });

        if (totalValue > 0) {
            Object.values(portfolioMap).forEach(portfolio => {
                portfolio.percentage = Big(portfolio.currValue).div(totalValue).toNumber();
                portfolio.subEntries.forEach(entry => {
                    entry.percentage = Big(entry.currValue).div(totalValue).toNumber();
                });
            });
        }

        const sortedPortfolios = Object.values(portfolioMap).sort((a, b) => 
            Big(b.currValue).minus(a.currValue).toNumber()
    );

        return {
            portfolios: sortedPortfolios,
            totalValue
        };
    }
);

const selectAssetClassWiseDetailedEquityExposureTableData = createSelector(
    [selectAssetClassWiseDetailedEquityExposureBreakdown, PortfolioSelectors.selectIsActivePortfolioAGroup],
    (data, IsGroup) => {
        if (!data || !data.totalValue) return [];

        const rows: any[] = [];

        Object.entries(data).forEach(([assetClass, value]: [string, any]) => {
            if (assetClass === 'totalValue' || value === undefined) return;

            rows.push({
                RowType: 'Primary',
                PrimaryRowID: assetClass,
                Name: value.name,
                Value: value.currValue,
                Percentage: value.percentage,
            });

            if (value.subEntries?.length > 0) {
                value.subEntries.forEach((subEntry: any, subIndex: number) => {
                    const secondaryId = `${assetClass}_secondary_${subIndex}`;
                    rows.push({
                        RowType: 'Secondary',
                        PrimaryRowID: assetClass,
                        SecondaryRowID: secondaryId,
                        Name: subEntry.name,
                        Value: subEntry.currValue,
                        Percentage: subEntry.percentage,
                    });

                    if (subEntry.subEntries?.length > 0 && IsGroup) {
                        subEntry.subEntries.forEach((tertiaryEntry: any, tertiaryIndex: number) => {
                            const tertiaryId = `${secondaryId}_tertiary_${tertiaryIndex}`;
                            rows.push({
                                RowType: 'Tertiary',
                                PrimaryRowID: assetClass,
                                SecondaryRowID: secondaryId,
                                TertiaryRowID: tertiaryId,
                                Name: tertiaryEntry.name,
                                Value: tertiaryEntry.currValue,
                                Percentage: tertiaryEntry.percentage,
                                SID: tertiaryEntry.SID,
                                PFID: tertiaryEntry.PFID,
                                IsCashMgmtPortfolio: tertiaryEntry.IsCashMgmtPortfolio
                            });
                        });
                    }
                });
            }
        });

        return rows;
    }
);

const selectPortfolioWiseDetailedEquityExposureTableData = createSelector(
    [selectPortfolioWiseDetailedEquityExposureBreakdown],
    (data) => {
        if (!data || !data.portfolios) return [];

        const rows: any[] = [];

        data.portfolios.forEach((portfolio: any, portfolioIndex: number) => {
            const primaryId = `portfolio_${portfolioIndex}`;
            rows.push({
                RowType: 'Primary',
                PrimaryRowID: primaryId,
                Name: portfolio.name,
                Value: portfolio.currValue,
                Percentage: portfolio.percentage,
                IsCashMgmtPortfolio: portfolio.IsCashMgmtPortfolio
            });

            if (portfolio.subEntries?.length > 0) {
                portfolio.subEntries.forEach((subEntry: any, subIndex: number) => {
                    const secondaryId = `${primaryId}_secondary_${subIndex}`;
                    rows.push({
                        RowType: 'Secondary',
                        PrimaryRowID: primaryId,
                        SecondaryRowID: secondaryId,
                        Name: subEntry.name,
                        Value: subEntry.currValue,
                        Percentage: subEntry.percentage,
                        AssetClass: subEntry.assetClass
                    });
                });
            }
        });

        return rows;
    }
);

const selectAssetClassWiseDetailedEquityExposurePieChartData = createSelector(
    [selectAssetClassWiseDetailedEquityExposureBreakdown],
    (breakdown) => {
        if (!breakdown || !breakdown.totalValue) return { PieChartData: [], TotalValue: "0" };

        const assetClassList = [];

        if (breakdown.Direct) {
            assetClassList.push({
                Name: breakdown.Direct.name,
                CurrValue: breakdown.Direct.currValue.toString(),
                AssetClass: 'Direct'
            });
        }

        if (breakdown.ETF) {
            assetClassList.push({
                Name: breakdown.ETF.name,
                CurrValue: breakdown.ETF.currValue.toString(),
                AssetClass: 'ETF'
            });
        }

        if (breakdown.MF) {
            assetClassList.push({
                Name: breakdown.MF.name,
                CurrValue: breakdown.MF.currValue.toString(),
                AssetClass: 'MF'
            });
        }

        return getPieChartDataFromList(
            'CurrValue',
            (item: any) => item.Name,
            (item: any) => ({ AssetClass: item.AssetClass }),
            assetClassList
        );
    }
);

const selectAssetClassWiseDetailedEquityExposureSubEntriesPieChartData = createSelector(
    [selectAssetClassWiseDetailedEquityExposureBreakdown],
    (breakdown) => {
        if (!breakdown || !breakdown.totalValue) return {
            PieChartData: [],
            TotalValue: "0"
        };

        const assetList: any = [];

        breakdown.Direct?.subEntries?.forEach(entry => {
            assetList.push({
                Name: entry.name,
                CurrValue: entry.currValue.toString(),
                AssetClass: 'Direct'
            });
        });

        breakdown.ETF?.subEntries?.forEach(entry => {
            assetList.push({
                Name: entry.name,
                CurrValue: entry.currValue.toString(),
                AssetClass: 'ETF'
            });
        });

        breakdown.MF?.subEntries?.forEach(entry => {
            assetList.push({
                Name: entry.name,
                CurrValue: entry.currValue.toString(),
                AssetClass: 'MF'
            });
        });

        return getPieChartDataFromList(
            'CurrValue',
            (item: any) => item.Name,
            (item: any) => ({ AssetClass: item.AssetClass }),
            assetList
        );
    }
);

const selectPortfolioWiseDetailedEquityExposurePieChartData = createSelector(
    [selectPortfolioWiseDetailedEquityExposureBreakdown],
    (breakdown) => {
        if (!breakdown || !breakdown.portfolios) return {
            PieChartData: [],
            TotalValue: "0"
        };

        const portfolioList = breakdown.portfolios.map(portfolio => ({
            Name: portfolio.name,
            CurrValue: portfolio.currValue.toString(),
            PortfolioName: portfolio.name
        }));

        return getPieChartDataFromList(
            'CurrValue',
            (item: any) => item.Name,
            (item: any) => ({ PortfolioName: item.PortfolioName }),
            portfolioList
        );
    }
);

const selectAssetClassWiseDetailedEquityExposureSubEntriesTableData = createSelector(
    [selectAssetClassWiseDetailedEquityExposureBreakdown],
    (breakdown) => {
        if (!breakdown || !breakdown.totalValue) return [];

        const tableData: any[] = [];

        breakdown.Direct?.subEntries?.forEach((entry, index) => {
            tableData.push({
                RowType: 'Primary',
                PrimaryRowID: `Direct_${index}`,
                Name: entry.name,
                Value: entry.currValue,
                Percentage: entry.percentage
            });
        });

        breakdown.ETF?.subEntries?.forEach((entry, index) => {
            tableData.push({
                RowType: 'Primary',
                PrimaryRowID: `ETF_${index}`,
                Name: entry.name,
                Value: entry.currValue,
                Percentage: entry.percentage
            });
        });

        breakdown.MF?.subEntries?.forEach((entry, index) => {
            tableData.push({
                RowType: 'Primary',
                PrimaryRowID: `MF_${index}`,
                Name: entry.name,
                Value: entry.currValue,
                Percentage: entry.percentage
            });
        });

        return tableData.sort((a, b) => b.Value - a.Value);
    }
);

export const DetailedEquityExposureSelectors = {
    selectAssetClassWiseDetailedEquityExposureBreakdown,
    selectPortfolioWiseDetailedEquityExposureBreakdown,
    selectAssetClassWiseDetailedEquityExposureTableData,
    selectPortfolioWiseDetailedEquityExposureTableData,
    selectAssetClassWiseDetailedEquityExposureSubEntriesTableData,
    selectAssetClassWiseDetailedEquityExposurePieChartData,
    selectAssetClassWiseDetailedEquityExposureSubEntriesPieChartData,
    selectPortfolioWiseDetailedEquityExposurePieChartData,
};

//#endregion