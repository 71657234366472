import { Box, } from "@mui/material";
import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { CloseIcon } from "packages/web/src/assets/icons/CloseIcon";
import CustomTypography from "../../shared/customTypography";
import ButtonText from "../../shared/button-text";
import CustomModal from "../../shared/custom-modal";
import ButtonPrimaryCTA from "../../shared/button-primary-cta";
import InputField from "../../shared/InputField";
import { ErrorIcon } from "../../../assets/icons/errorIcon";

interface EditCategoryData {
  CustomCategName: string;
  CustomAssetAllocationCategType: number;
  CustomCategID: number;
  CustomParentCategID: number;
}

interface AddCategoryModalProps {
  isAddCategoryModalOpen: boolean;
  setIsAddCategoryModalOpen: Dispatch<SetStateAction<boolean>>;
  handleAddCategory?: (name: string) => void;
  handleEditCategory?: (editData: EditCategoryData, newName: string) => void;
  headerText?: string;
  subHeaderText?: string;
  listData?: any[];
  currentValue?: string;
  editData?: EditCategoryData | null;
}

export default function AddCategoryModal({
  isAddCategoryModalOpen,
  setIsAddCategoryModalOpen,
  handleAddCategory,
  handleEditCategory,
  headerText,
  subHeaderText,
  listData,
  currentValue,
  editData
}: AddCategoryModalProps) {
  const [isError, setIsError] = useState(false);
  const [value, setValue] = useState(currentValue || "");

  useEffect(() => {
    if (editData) {
      setValue(editData.CustomCategName);
    }
  }, [editData]);

  const handleAddClick = () => {
    if (editData && handleEditCategory) {
      handleEditCategory(editData, value);
    } else {
      if (listData?.find(item => item.name === value)) {
        setIsError(true);
        return;
      }
      if (handleAddCategory) {
        handleAddCategory(value);
      }
    }
    setValue("");
    setIsAddCategoryModalOpen(false);
  };

  return (
    <CustomModal
      open={isAddCategoryModalOpen}
      handleClose={() => setIsAddCategoryModalOpen(false)}
    >
      <Box
        sx={{
          width: "32vw",
          minWidth: "480px",
          minHeight: "300px",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          position: "relative",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {/* header */}
        <Box
          sx={{
            padding: "24px 16px 16px 24px",
            alignItems: "center",
            borderBottom: "1px solid #EBECF2"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomTypography variant="Medium24">
              {headerText}
            </CustomTypography>
            <Box
              sx={{
                cursor: "pointer",
              }}
              onClick={() => setIsAddCategoryModalOpen(false)}
            >
              <CloseIcon color="#12131A" />
            </Box>
          </Box>
        </Box>

        {/* content */}
        <Box sx={{ flex: 1, padding: "32px 24px 24px 24px" }}>
          <CustomTypography variant="Regular18">
            {subHeaderText}
          </CustomTypography>
          <Box mt={"12px"} width={"70%"}>
            <InputField
              autoFocus
              placeholder="Enter sub-category"
              value={value}
              onChange={(e) => {
                setIsError(false);
                setValue(e.target.value);
              }}
            />
            {isError && <Box sx={{ mt: '8px', display: "flex", alignItems: "center" }}>
              <ErrorIcon />
              <CustomTypography variant="Regular16" color="#C94C40" ml={"8px"}>Same name already exist.</CustomTypography>
            </Box>}
          </Box>
        </Box>

        {/* footer */}
        <Box
          p={"20px 24px"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            position: "sticky",
            bottom: 0,
            backgroundColor: "#FFFFFF",
            borderRadius: '8px'
          }}
        >
          <Box sx={{ mr: "16px" }}>
            <ButtonText
              buttonText="Cancel"
              handleClick={() => {
                setValue("");
                setIsAddCategoryModalOpen(false);
              }}
              sxProps={{
                width: "120px"
              }}
            />
          </Box>
          <Box>
            <ButtonPrimaryCTA
              handleClick={handleAddClick}
              buttonText={editData ? "Save" : "Add"}
              disabled={!value}
              maxHeight="40px"
              sxProps={{
                width: "140px"
              }}
            />
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
}
