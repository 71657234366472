import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ActivePortfolioSummary, AssetsAddInfo, AssetsAddInfoRaw, AssetAllocationInfo, AssetAllocationInfoByAMID, DBPortfolioSummaryRaw, FamilyPortfolioSummaryRaw, MFAddInfo, PortfolioSummaryRaw, PriceData, PriceValues, StockAddInfo, StockAddInfoByAMID, PortfoliosInGroupByDB, MFETFPortfolioBreakdown } from "../../constants";
import { getActivePortfolioSummaryFromRaw, getCombinedPortfolioSummaryRawForGroup, getPriceURLs, updatePricesInActivePortfolioSummary } from "../../utilities";
import { L1PortfolioSummarySelectors, L1_AA_PortfolioSummarySelectors, L2PortfolioSummarySelectors, L3PortfolioSummarySelectors, L3CategoryPortfolioSummarySelectors, L4PortfolioSummarySelectors, L1_Custom_AA_PortfolioSummarySelectors, L1_Custom_AA_SubcategoryPortfolioSummarySelectors, DetailedEquityExposureSelectors } from "../selectors/portfolioSummarySelectors";
import { SetActivePortfolioPayload } from "./portfoliosReducer";
import { ClientRootState } from "../reducers";
import { Actions as PortfolioActions } from './portfoliosReducer';
import { Actions as LoginActions } from './loginReducer';

export interface SharedPortfolioSummaryState {
    RawPortfolioSummary: { [CCID: number]: DBPortfolioSummaryRaw };
    ActivePortfolioSummary?: ActivePortfolioSummary;
    ActivePortfolioPrices: PriceData;
    ActiveAssetsAddInfo: AssetsAddInfo;
    PortfoliosInGroup: PortfoliosInGroupByDB;
    ActiveMFETFPortfolioBreakdown: {
        Data?: MFETFPortfolioBreakdown;
        IsLoading: boolean;
    }
}

const initialState: SharedPortfolioSummaryState = {
    RawPortfolioSummary: {},
    ActivePortfolioPrices: {},
    ActiveAssetsAddInfo: {},
    PortfoliosInGroup: {},
    ActiveMFETFPortfolioBreakdown: {
        IsLoading: true
    }
};

const portfolioSummarySlice = createSlice({
    name: 'portfolioSummary',
    initialState,
    reducers: {
        setFamilyPortfolioSummary: (state, action: PayloadAction<SetFamilyPortfolioSummaryPayload>) => {
            var familyPortfolioSummary: FamilyPortfolioSummaryRaw = {};

            action.payload.FamilyPortfolioSummary.forEach((portfolioSummaryRaw) => {
                familyPortfolioSummary[portfolioSummaryRaw.PortfolioId] = portfolioSummaryRaw;
            });

            state.RawPortfolioSummary[action.payload.CCID] = {
                ...(state.RawPortfolioSummary[action.payload.CCID] || {}),
                [action.payload.FamilyId]: familyPortfolioSummary
            };
        },
        setPortfolioSummary: (state, action: PayloadAction<SetPortfolioSummaryPayload>) => {
            state.RawPortfolioSummary = {
                ...state.RawPortfolioSummary,
                [action.payload.CCID]: {
                    ...(state.RawPortfolioSummary[action.payload.CCID] || {}),
                    [action.payload.FamilyId]: {
                        ...((state.RawPortfolioSummary[action.payload.CCID] || {})[action.payload.FamilyId] || {}),
                        [action.payload.PFID]: action.payload.PortfolioSummary
                    }
                }
            };
        },
        setPortfoliosInGroup: (state, action: PayloadAction<SetPortfoliosInGroupPayload>) => {
            state.PortfoliosInGroup = {
                ...state.PortfoliosInGroup,
                [action.payload.CCID]: {
                    ...(state.PortfoliosInGroup[action.payload.CCID] || {}),
                    [action.payload.FamilyId]: {
                        ...((state.PortfoliosInGroup[action.payload.CCID] || {})[action.payload.FamilyId] || {}),
                        [action.payload.PFID]: action.payload.PortfoliosInGroup
                    }
                }
            };
        },
        fetchFamilyPortfolioSummary: (state, action: PayloadAction<FetchFamilyPortfolioSummaryPayload>) => { },
        setActivePortfolioSummary: (state, action: PayloadAction<{ CCID: number, FamilyId: number, PFID: number, IsGroup: boolean }>) => {
            var familyPortfolioSummary = state.RawPortfolioSummary[action.payload.CCID]?.[action.payload.FamilyId];
            if (!familyPortfolioSummary) return;

            var portfolioSummaryRaw: any;
            if (action.payload.IsGroup) {
                var portfoliosInGroup = state.PortfoliosInGroup?.[action.payload.CCID]?.[action.payload.FamilyId]?.[action.payload.PFID];
                if (portfoliosInGroup) {
                    portfolioSummaryRaw = getCombinedPortfolioSummaryRawForGroup({ CCID: action.payload.CCID, FamilyId: action.payload.FamilyId, PFID: action.payload.PFID }, familyPortfolioSummary, portfoliosInGroup);
                } else {
                    return;
                }
            } else {
                portfolioSummaryRaw = familyPortfolioSummary?.[action.payload.PFID];
            }

            if (!portfolioSummaryRaw) {
                state.ActivePortfolioSummary = {
                    CCID: action.payload.CCID,
                    FamilyId: action.payload.FamilyId,
                    PFID: action.payload.PFID,
                    IsGroup: action.payload.IsGroup,
                    AssetsByAssetType: [],
                    AssetTypeTotals: { ByID: {}, AllIDs: [] },
                    PortfolioTotal: {},
                    AssetsForSearch: []
                };
            } else {
                state.ActivePortfolioSummary = getActivePortfolioSummaryFromRaw(action, portfolioSummaryRaw);

                if (state.ActivePortfolioPrices?.PriceValues) {
                    updatePricesInActivePortfolioSummary(state, state.ActivePortfolioSummary, state.ActivePortfolioPrices.PriceValues);
                }

                state.ActivePortfolioPrices.PriceURLs = getPriceURLs(state.ActivePortfolioSummary);
                state.ActivePortfolioPrices.IsRefreshedOnce = false;
            }
        },
        setActiveAssetsAddInfo: (state, action: PayloadAction<AssetsAddInfoRaw>) => {
            var stocksAddInfoList = action.payload?.Stocks || [];
            var activeStocksAddInfo: { [AMID: number]: StockAddInfo } = {};
            stocksAddInfoList.forEach((stockAddInfo) => {
                activeStocksAddInfo[stockAddInfo.MProfitCode] = stockAddInfo;
            });

            var mfAddInfoList = action.payload?.MF || [];
            var activeMFAddInfo: { [AMID: number]: MFAddInfo } = {};
            mfAddInfoList.forEach((mfAddInfo) => {
                activeMFAddInfo[mfAddInfo.MProfitCode] = mfAddInfo;
            });

            var othersAddInfoList = action.payload.Others || [];
            var activeOthersAddInfo: { [ATTY: number]: AssetAllocationInfoByAMID } = {};
            othersAddInfoList.forEach((otherAddInfo) => {
                if (activeOthersAddInfo[otherAddInfo.AssetType] == null) {
                    activeOthersAddInfo[otherAddInfo.AssetType] = {};
                }

                activeOthersAddInfo[otherAddInfo.AssetType][otherAddInfo.MProfitCode] = otherAddInfo;
            })

            state.ActiveAssetsAddInfo = {
                Stocks: activeStocksAddInfo,
                MF: activeMFAddInfo,
                Others: activeOthersAddInfo
            }
        },
        refreshPrices: (state, action: PayloadAction) => { },
        setPriceValues: (state, action: PayloadAction<{ PriceValues: PriceValues, ActivePortfolioSummary: ActivePortfolioSummary }>) => {
            state.ActivePortfolioPrices.PriceValues = action.payload.PriceValues;
            state.ActivePortfolioPrices.IsRefreshedOnce = true;

            updatePricesInActivePortfolioSummary(state, action.payload.ActivePortfolioSummary, action.payload.PriceValues);
        },
        refreshActivePortfolioSummaryOnPortfolioChange: (state, action: PayloadAction<SetActivePortfolioPayload>) => { },
        refreshMFETFPortfolioBreakdown: (state, action: PayloadAction<{ Force: boolean } | undefined>) => {
            if ((action.payload && action.payload.Force) || !state.ActiveMFETFPortfolioBreakdown.Data) {
                state.ActiveMFETFPortfolioBreakdown = {
                    IsLoading: true
                };
            }
        },
        setMFETFPortfolioBreakdown: (state, action: PayloadAction<MFETFPortfolioBreakdown>) => {
            state.ActiveMFETFPortfolioBreakdown.Data = action.payload;
            state.ActiveMFETFPortfolioBreakdown.IsLoading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(PortfolioActions.setActivePortfolio, (state, action) => {
                state.ActivePortfolioSummary = undefined;
                state.ActiveMFETFPortfolioBreakdown = initialState.ActiveMFETFPortfolioBreakdown;
            })
            .addCase(LoginActions.logout, () => initialState)
            .addCase(LoginActions.resetData, () => initialState)
    }
})


//#region Payloads

export interface SetFamilyPortfolioSummaryPayload { CCID: number, FamilyId: number, FamilyPortfolioSummary: PortfolioSummaryRaw[] }
export interface SetPortfolioSummaryPayload { CCID: number, FamilyId: number, PFID: number, PortfolioSummary: PortfolioSummaryRaw }
export interface FetchFamilyPortfolioSummaryPayload { CCID: number, FamilyId: number, Force: boolean }
export interface SetPortfoliosInGroupPayload extends SetActivePortfolioPayload { PortfoliosInGroup: number[] }

//#endregion


//#region Misc Selectors

const portfolioSummarySelector = (state: ClientRootState) => state.shared.portfolioSummary;

const selectPortfoliosInGroupForIDs = (payload: SetActivePortfolioPayload) => (state: ClientRootState) => {
    var portfoliosInGroup = portfolioSummarySelector(state).PortfoliosInGroup;

    return portfoliosInGroup[payload.CCID]?.[payload.FamilyId]?.[payload.PFID];
}

const MiscPortfolioSummarySelectors = {
    selectPortfoliosInGroupForIDs
}

//#endregion

export default portfolioSummarySlice.reducer;
export const Actions = portfolioSummarySlice.actions;
export const Selectors = {
    ...L1PortfolioSummarySelectors,
    ...L1_AA_PortfolioSummarySelectors,
    ...L1_Custom_AA_PortfolioSummarySelectors,
    ...L1_Custom_AA_SubcategoryPortfolioSummarySelectors,
    ...L2PortfolioSummarySelectors,
    ...L3PortfolioSummarySelectors,
    ...L3CategoryPortfolioSummarySelectors,
    ...L4PortfolioSummarySelectors,
    ...MiscPortfolioSummarySelectors,
    ...DetailedEquityExposureSelectors
};