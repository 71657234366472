import { SharedConstants, SharedRedux } from '@mprofit/shared'
import { Box, IconButton, SelectChangeEvent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { CloseIcon } from 'packages/web/src/assets/icons/CloseIcon'
import { ArrowRight } from 'packages/web/src/assets/icons/global'
import { CustomTypography } from 'packages/web/src/components/shared'
import ButtonPrimaryCTA from 'packages/web/src/components/shared/button-primary-cta'
import ButtonText from 'packages/web/src/components/shared/button-text'
import CustomModal from 'packages/web/src/components/shared/custom-modal'
import { InfoIconTooltip } from 'packages/web/src/components/shared/info-icon-tooltip'
import { InfoBox } from 'packages/web/src/components/shared/info-box'
import DropDown from '../../../assetCategorization/dropDown'
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks'
import { useState, useCallback } from 'react'
import AddCategoryModal from '../../../assetCategorization/addCategoryModal'
import { useSelector } from 'react-redux'
import { ClientRootState } from 'packages/shared/src/redux'

interface SelectedMapping {
    categoryId?: number;
    categoryName?: string;
    subCategoryId?: number;
    subCategoryName?: string;
    isDefault?: boolean;
}

interface EditCategorizationModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedMapping: SelectedMapping;
    setSelectedMapping: (mapping: SelectedMapping | ((prev: SelectedMapping) => SelectedMapping)) => void;
    handleSave: (event?: React.MouseEvent) => void;
    assetTypeID?: number;
    assetAllocationCategID?: number;
    assetAllocationCategName?: string;
    companyName?: string;
    assetAllocationSubCategName?: string;
    amid?: number;
}

interface ModalConfig {
    type: 'category' | 'subcategory';
    parentCategoryId?: number;
}

export default function EditCategorizationModal({
    isOpen,
    onClose,
    selectedMapping,
    setSelectedMapping,
    handleSave,
    assetTypeID,
    assetAllocationCategID,
    assetAllocationCategName,
    companyName,
    assetAllocationSubCategName,
    amid = -1
}: EditCategorizationModalProps) {

    const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
    const [openCategoryIndex, setOpenCategoryIndex] = useState<number | null>(null);
    const [openSubCategoryIndex, setOpenSubCategoryIndex] = useState<number | null>(null);
    const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);

    const defaultCategorizationInfo = useSelector((state: ClientRootState) => SharedRedux.PortfolioSummary.Selectors.selectActiveL4AssetDefaultAssetAllocationCategInfo(state, {
        assetTypeID: assetTypeID || -1,
        customAssetAllocationCategID: assetAllocationCategID || -1,
        amid: amid || -1
    }));

    const assetTypeName = assetTypeID && assetTypeID > 0 ? SharedConstants.AssetTypeNameMapping[assetTypeID as SharedConstants.AssetTypeEnum] : undefined;

    const categoryOptions = useAppSelector(SharedRedux.Dashboard.Selectors.selectCategoryOptions);
    const categoriesById = useAppSelector(SharedRedux.Dashboard.Selectors.selectCustomAssetAllocationCategoriesById);
    const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
    const dispatch = useAppDispatch();

    const getSubCategoryOptions = (categoryId: number) => {
        const category = categoriesById[categoryId];
        if (!category) return [];

        return Object.entries(category.SubCategs).map(([id, subCategory]) => ({
            id: Number(id),
            name: subCategory.SubCategName,
            isDefault: subCategory.IsDefault
        })).sort((a, b) => a.name.localeCompare(b.name));
    };

    const handleMappingUpdate = useCallback((updates: Partial<SelectedMapping>) => {
        setSelectedMapping(prev => ({
            ...prev,
            ...updates
        }));
    }, [setSelectedMapping]);

    const handleSelectChange = (event: SelectChangeEvent<string>, isSubCategory: boolean = false) => {
        event.stopPropagation();
        if (isSubCategory) {
            const categoryId = selectedMapping?.categoryId || assetAllocationCategID;

            if (!categoryId) {
                return;
            }

            const category = categoriesById[categoryId];
            if (!category) {
                return;
            }

            setSelectedMapping((prev: SelectedMapping) => ({
                ...prev,
                categoryId: prev.categoryId || assetAllocationCategID,
                categoryName: prev.categoryName || assetAllocationCategName,
                subCategoryId: Number(event.target.value),
                subCategoryName: category.SubCategs[event.target.value as any]?.SubCategName
            }));
        } else {
            const categoryId = Number(event.target.value);
            const category = categoriesById[categoryId];

            setSelectedMapping({
                categoryId: categoryId,
                categoryName: category?.CategName || '',
                subCategoryId: undefined,
                subCategoryName: ''
            });
        }
    };

    const handleAddCategory = (name: string) => {
        if (!name || !modalConfig || !activeScreenIDs?.DatabaseID) return;

        if (modalConfig.type === 'category') {
            dispatch(SharedRedux.Dashboard.Actions.insertCustomAssetAllocationCategory({
                customAssetAllocationCategName: name,
                callback: (newCategoryId: number) => {
                    handleMappingUpdate({
                        categoryId: newCategoryId,
                        categoryName: name,
                        subCategoryId: undefined,
                        subCategoryName: ''
                    });
                }
            }));
        } else {
            dispatch(SharedRedux.Dashboard.Actions.insertCustomAssetAllocationSubCategory({
                customAssetAllocationCategID: modalConfig.parentCategoryId || 0,
                customAssetAllocationSubCategName: name,
                callback: (newSubCategoryId: number) => {
                    handleMappingUpdate({
                        subCategoryId: newSubCategoryId,
                        subCategoryName: name,
                        categoryId: modalConfig.parentCategoryId
                    });
                }
            }));
        }

        setIsAddCategoryModalOpen(false);
        setModalConfig(null);
    };

    const handleNewSubCategory = (categoryId: number | null, type: 'category' | 'subcategory') => {
        setModalConfig({
            type,
            parentCategoryId: categoryId || undefined
        });
        setIsAddCategoryModalOpen(true);
    };

    return (
        <>
            <CustomModal open={isOpen} handleClose={onClose}>
                <Box sx={{ width: { sm: '60%', md: '55%' }, background: "white", borderRadius: "8px" }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ margin: "10px 10px 0px 30px" }}>
                        <CustomTypography color="#12131A" variant="Medium24" sx={{ lineHeight: "32px" }}>Edit Categorisation</CustomTypography>
                        <IconButton onClick={onClose} disableRipple>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Box sx={{ borderTop: "1px solid #EBECF2" }} />
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "24px 0px 24px 30px" }}>
                        <CustomTypography color="#12131A" variant="Regular24" sx={{ lineHeight: "28.44" }}>{companyName || '-'}</CustomTypography>
                        <Box sx={{ borderRadius: "100%", background: "#12131A", width: "8px", height: "8px", margin: "0px 8px" }}></Box>
                        <CustomTypography variant='Regular14' color='#FFFFFF' sx={{ lineHeight: "21px", borderRadius: "4px", padding: "4px 8px", background: "#3A3D4D", width: "fit-content" }}>{assetTypeName}</CustomTypography>
                    </Box>
                    <Box sx={{ margin: "0 32px", background: "#F9F9F9", borderRadius: "8px" }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ borderBottom: "1px solid #D8DAE5" }}>
                                            <Box display="flex" flexDirection="row" alignItems="center" gap="8px" sx={{ padding: "8px 0 8px 22px" }}>
                                                <CustomTypography variant='Medium16' color='#64677A' sx={{ lineHeight: "24px", textTransform: "uppercase" }}>Category</CustomTypography>
                                                <InfoIconTooltip tooltip="Assign a category to this asset, as per your asset allocation preferences" placement='right' color='#BABDCC' />
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #D8DAE5" }}></TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #D8DAE5" }}>
                                            <Box display="flex" flexDirection="row" alignItems="center" gap="8px" sx={{ padding: "8px 0" }}>
                                                <CustomTypography variant='Medium16' color='#64677A' sx={{ lineHeight: "24px", textTransform: "uppercase" }}>Sub-Category</CustomTypography>
                                                <InfoIconTooltip tooltip="Assign a sub-category to this asset, as per your asset allocation preferences" placement='right' color='#BABDCC' />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ borderBottom: "none" }}>
                                            <Box sx={{ padding: "12px 0 12px 22px" }}>
                                                <DropDown
                                                    open={openCategoryIndex === 0}
                                                    setOpen={() => setOpenCategoryIndex(openCategoryIndex === 0 ? null : 0)}
                                                    selectedValue={selectedMapping?.categoryName || assetAllocationCategName || ''}
                                                    handleSelectChange={(e) => handleSelectChange(e, false)}
                                                    handleNewSubCategory={() => handleNewSubCategory(selectedMapping?.categoryId || null, 'category')}
                                                    dropdownList={categoryOptions}
                                                    headerText='Category'
                                                    minWidth={{ md: "150px", lg: "250px", xl: "330px" }}
                                                    type='category'
                                                    selectedCategory={defaultCategorizationInfo}
                                                    selectedCategId={selectedMapping?.categoryId || assetAllocationCategID}
                                                />
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "none" }}>
                                            <ArrowRight />
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "none" }}>
                                            <Box sx={{ padding: "12px 0" }}>
                                                <DropDown
                                                    open={openSubCategoryIndex === 0}
                                                    setOpen={() => setOpenSubCategoryIndex(openSubCategoryIndex === 0 ? null : 0)}
                                                    selectedValue={selectedMapping?.categoryId ? (selectedMapping?.subCategoryName || '') : (assetAllocationSubCategName || '')}
                                                    handleSelectChange={(e) => handleSelectChange(e, true)}
                                                    handleNewSubCategory={() => handleNewSubCategory(selectedMapping?.categoryId || assetAllocationCategID || null, 'subcategory')}
                                                    dropdownList={getSubCategoryOptions(selectedMapping?.categoryId || assetAllocationCategID || 0)}
                                                    disabled={!selectedMapping?.categoryId && !assetAllocationCategID}
                                                    headerText='Sub-Category'
                                                    minWidth={{ md: "150px", lg: "250px", xl: "330px" }}
                                                    type='subcategory'
                                                    selectedCategory={defaultCategorizationInfo}
                                                    selectedCategId={selectedMapping?.categoryId || assetAllocationCategID}
                                                />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box sx={{ margin: "24px 32px 0 32px" }}>
                        <InfoBox message="Set a Category and Sub-Category for this asset. This change will apply only to this asset across all your portfolios." />
                    </Box>
                    <Box sx={{ display: "flex", padding: "20px", justifyContent: "space-between", borderTop: "1px solid #EBECF2", marginTop: "24px" }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
                            <Box>
                                <ButtonText
                                    buttonText="Cancel"
                                    handleClick={() => {
                                        onClose();
                                        setSelectedMapping({});
                                    }}
                                    buttonSize="large"
                                />
                            </Box>
                            <Box>
                                <ButtonPrimaryCTA
                                    buttonText="Save"
                                    handleClick={event => handleSave(event)}
                                    disabled={!selectedMapping.categoryId || !selectedMapping.subCategoryId}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </CustomModal>
            <AddCategoryModal
                isAddCategoryModalOpen={isAddCategoryModalOpen}
                setIsAddCategoryModalOpen={setIsAddCategoryModalOpen}
                handleAddCategory={handleAddCategory}
                headerText={modalConfig?.type === 'category' ? "Add new category" : "Add new sub-category"}
                subHeaderText={modalConfig?.type === 'category' ? 'Category Name' : 'Sub-Category Name'}
                listData={modalConfig?.type === 'category'
                    ? categoryOptions
                    : (modalConfig?.parentCategoryId
                        ? getSubCategoryOptions(modalConfig.parentCategoryId)
                        : [])}
            />
        </>
    );
}
