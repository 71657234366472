import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { SortingIcon } from '../../../assets/icons/SortingIcon';
import { TableVirtuoso } from 'react-virtuoso';
import CustomTypography from '../customTypography';
import { SharedUtilities, formatDecimal, formatPercent, SharedConstants, SharedRedux } from '@mprofit/shared';
import { forwardRef, useEffect, useRef, useState, useCallback } from 'react';
import Big from "big.js";
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';
import { FullscreenIcon } from 'packages/web/src/assets/icons/FullscreenIcon';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';
import { colors } from '../../leftNav/databaseDisplay';
import { DownSortIcon } from 'packages/web/src/assets/icons/DownSortIcon';
import { UpSortIcon } from 'packages/web/src/assets/icons/UpSortIcon';
import { ColumnDataProps } from '../virtual-table-component';
import { SortConfig, TableType } from '@mprofit/shared/src/constants';
import { PrimaryRowCell } from './primaryRowCell';
import { SecondaryRowCell } from './secondaryRowCell';
import { TertiaryRowCell } from './tertiaryRowCell';

export const TableDefaultFieldFormatter = (value: any) => value;
export const TableBoldFieldFormatter = (value: any) => <strong>{value}</strong>;
export const TableDecimalFieldFormatter = (value: any, dec?: number, sep?: string) => formatDecimal(value, dec || 2, sep || 'IN');
export const TablePercentFieldFormatter = (value: any, dec?: number, sep?: string) => formatPercent(value, dec || 2, sep || 'IN');

export interface RowDataProps {
    [key: string]: any;
}

interface SkeletonLoaderStyle {
    height?: string;
    width?: string;
    position?: string;
}

export interface SkeletonLoaderConfig {
    [fieldKey: string]: SkeletonLoaderStyle;
}
export interface RowLevelCellProps {
    HeaderRowCell?: React.ReactNode;
    PrimaryRowCell?: (props: { borderTop: string, borderBottom: string }) => React.ReactNode;
    SecondaryRowCell?: (props: { isExpanded: boolean, isFirstRow: boolean, isLastRow: boolean }) => React.ReactNode;
    LastEmptySecondaryRowCell?: React.ReactNode;
    TertiaryRowCell?: React.ReactNode;
    LastEmptyTertiaryRowCell?: React.ReactNode;
}

type UpdateConfigPayload = Partial<{
    scrollPosition: number;
    sortConfig: SharedConstants.SortConfig;
    expTableConfig: Partial<SharedConstants.ExpTableConfig>;
    tableType: TableType;
    tableMode: SharedConstants.AdvancedPerformanceTableMode;
}>;

export interface RowLevelCellProps {
    HeaderRowCell?: React.ReactNode;
    PrimaryRowCell?: (props: { borderTop: string, borderBottom: string }) => React.ReactNode;
    SecondaryRowCell?: (props: { isExpanded: boolean, isFirstRow: boolean, isLastRow: boolean }) => React.ReactNode;
    LastEmptySecondaryRowCell?: React.ReactNode;
    TertiaryRowCell?: React.ReactNode;
    LastEmptyTertiaryRowCell?: React.ReactNode;
}

interface Props {
    columns: ColumnDataProps[];
    rows: RowDataProps[];
    rowHeight?: number;
    headerColor?: string;
    tableHeight?: string;
    cellRadius?: number;
    sorting?: boolean;
    isScrollable?: boolean;
    onRowClick?: (row: RowDataProps) => void;
    searchTerm?: string;
    fieldNamesToSearch?: string[];
    defaultSortConfig?: SortConfig;
    rowClickDisabled?: boolean;
    PMSBadgeField?: string;
    primaryRowId: string;
    secondaryRowId: string;
    footerContent?: () => React.ReactNode;
    excludeLoadingFields?: string[];
    skeletonLoaderStyles?: SkeletonLoaderConfig;
    isLoading?: boolean;
    onSecondaryRowClick?: (row: RowDataProps) => void;
    onTertiaryRowClick?: (row: RowDataProps) => void;
    emptyRowStyles?: {
        borderTop?: boolean;
        background?: string;
    }
    isMarginCellRequired?: boolean;
    StartingCell?: RowLevelCellProps;
    EndingCell?: RowLevelCellProps;
    isExpandedRowBold?: boolean;
    tableType?: SharedConstants.TableType
    expandAllByDefault?: boolean;
}

export const getColor = (index: number) => colors[index % colors.length];

const ExpandableVirtualizedTable: React.FC<Props> = ({ columns, rows, headerColor, tableHeight, cellRadius, sorting, isScrollable = true, onRowClick, searchTerm, fieldNamesToSearch, defaultSortConfig, rowClickDisabled, PMSBadgeField, primaryRowId, secondaryRowId, footerContent, excludeLoadingFields = [], skeletonLoaderStyles = {}, isLoading, onSecondaryRowClick, onTertiaryRowClick, emptyRowStyles, isMarginCellRequired = true, StartingCell, EndingCell, isExpandedRowBold = true, tableType, expandAllByDefault = false }) => {
    const tableConfig = useAppSelector(SharedRedux.Dashboard.Selectors.selectTableConfig(tableType!));
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set(tableConfig?.expTableConfig?.expandedRowState) || new Set());
    const [expandedSecondaryRows, setExpandedSecondaryRows] = useState<Set<string>>(new Set(tableConfig?.expTableConfig?.expandedSecondaryRowState) || new Set());
    const [expandAll, setExpandAll] = useState<boolean>(tableConfig?.expTableConfig?.expandAllState || false);
    const rootRef = useRef(null);
    const [scroller, setScroller] = useState<HTMLElement | null>(null);
    const [initialize, osInstance] = useOverlayScrollbars({ options: { scrollbars: { autoHide: 'scroll' } }, defer: true });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
    const virtuoso = useRef<any>(null);

    const [preSearchExpandedState, setPreSearchExpandedState] = useState<Set<string> | null>(null);

    useEffect(() => {
        if (tableType && defaultSortConfig && !tableConfig) {
            const initialExpandedRows = expandAllByDefault 
                ? rows
                    .filter(row => row.RowType === 'Primary')
                    .map(row => row[primaryRowId])
                : [];
            
            dispatch(SharedRedux.Dashboard.Actions.initializeTableConfig({
                tableType,
                sortConfig: {
                    key: defaultSortConfig.key,
                    direction: defaultSortConfig.direction
                },
                scrollPosition: 0,
                expTableConfig: {
                    expandAllState: expandAllByDefault,
                    expandedRowState: initialExpandedRows,
                    expandedSecondaryRowState: []
                }
            }));

            setExpandAll(expandAllByDefault);
            setExpandedRows(new Set(initialExpandedRows));
        }
    }, [tableType, defaultSortConfig, tableConfig, expandAllByDefault, rows, primaryRowId, dispatch]);

    // Handle expandAllByDefault in the case previous useEffect condition is not met
    useEffect(() => {
        if (!(tableType && defaultSortConfig && !tableConfig)) {
            if (expandAllByDefault) {
                const initialExpandedRows = expandAllByDefault 
                    ? rows
                        .filter(row => row.RowType === 'Primary')
                        .map(row => row[primaryRowId])
                    : [];
    
                setExpandAll(expandAllByDefault);
                setExpandedRows(new Set(initialExpandedRows));
            }
        }
    }, [rows]);

    const updateConfigState = (state: UpdateConfigPayload) => {
        const updatedState = {
            ...tableConfig,
            searchTerm: searchTerm,
            tableType: tableType!,
            sortConfig: {
                ...tableConfig?.sortConfig!,
                ...state.sortConfig
            },
            expTableConfig: {
                ...tableConfig?.expTableConfig!,
                ...state.expTableConfig,
            },
            scrollPosition: state.scrollPosition!,
        };
        dispatch(SharedRedux.Dashboard.Actions.setTableConfig(updatedState));
    };

    const handleSorting = (key: string) => {
        var column = columns.find(x => x.FieldKey === key);

        var isColumnNumeric = column && ((column.IsRowName || column.IsString) !== true);
        let direction: SharedConstants.SortDirection = isColumnNumeric ? SharedConstants.SortDirection.Down : SharedConstants.SortDirection.Up;
        if (
            tableConfig?.sortConfig && tableConfig?.sortConfig?.key === key
        ) {
            direction = (tableConfig?.sortConfig?.direction || direction) * -1;
        }
        updateConfigState({ sortConfig: { key, direction } })
    };

    const [finalRows, setFinalRows] = useState<RowDataProps[]>(rows);

    const filterRows = (rows: RowDataProps[]) => {
        return rows.filter(row =>
            row.RowType === 'Primary' ||
            (row.RowType === 'Secondary' && expandedRows.has(row[primaryRowId])) ||
            (row.RowType === 'Tertiary' && expandedSecondaryRows.has(row[secondaryRowId]))
        );
    };

    const toggleExpandAll = () => {
        if (expandAll) {
            setExpandedRows(new Set());
            setExpandedSecondaryRows(new Set());
            updateConfigState({ expTableConfig: { expandedRowState: Array.from(new Set()), expandedSecondaryRowState: Array.from(new Set()), expandAllState: !expandAll } })
        } else {
            const allPrimaryRowIds = new Set(rows.filter(row => row.RowType !== 'Secondary').map(row => row[primaryRowId]));
            setExpandedRows(allPrimaryRowIds);
            updateConfigState({ expTableConfig: { expandedRowState: Array.from(allPrimaryRowIds), expandAllState: !expandAll } })
        }
        setExpandAll(!expandAll);
    };

    useEffect(() => {
        if (searchTerm) {
            if (!preSearchExpandedState) {
                setPreSearchExpandedState(new Set(expandedRows));
            }

            const newExpandedPrimary = new Set<string>(
                rows
                    .filter(row => row.RowType === 'Primary')
                    .map(row => row[primaryRowId])
            );

            setExpandedRows(newExpandedPrimary);
            updateConfigState({ expTableConfig: { expandedRowState: Array.from(newExpandedPrimary) } })
        } else if (preSearchExpandedState) {
            setExpandedRows(preSearchExpandedState);
            updateConfigState({ expTableConfig: { expandedRowState: Array.from(preSearchExpandedState) } })
            setPreSearchExpandedState(null);
        }
    }, [searchTerm, rows, primaryRowId]);

    useEffect(() => {
        if (primaryRowId && secondaryRowId) {
            const sortedAndFilteredRows = sortRows(
                rows,
                columns,
                tableConfig?.sortConfig?.key,
                tableConfig?.sortConfig?.direction,
                expandedRows,
                primaryRowId,
                expandedSecondaryRows,
                secondaryRowId
            )
            setFinalRows(addRowStatusToFinalRows(filterRows(sortedAndFilteredRows)));
        }
    }, [rows, tableConfig, columns, primaryRowId, expandedRows, expandedSecondaryRows, secondaryRowId]);

    const handleRowClick = useCallback((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, activeRow: RowDataProps, itemIndex: number) => {
        if (onRowClick) {
            onRowClick(activeRow);
        } else {
            updateConfigState({ scrollPosition: itemIndex + 2 }) // +2 is to position the row at the bottom of the table
            if (activeRow.RowType === "Secondary") {
                const rowId = activeRow[secondaryRowId];
                const hasTertiaryRows = rows.some(row => row[secondaryRowId] === rowId && row.RowType === 'Tertiary');
                if (hasTertiaryRows) {
                    setExpandedSecondaryRows(prev => {
                        const newExpandedSecondaryRows = new Set(prev);
                        if (newExpandedSecondaryRows.has(rowId)) {
                            newExpandedSecondaryRows.delete(rowId);
                        } else {
                            newExpandedSecondaryRows.add(rowId);
                        }
                        updateConfigState({ expTableConfig: { expandedSecondaryRowState: Array.from(newExpandedSecondaryRows)} })
                        return newExpandedSecondaryRows;
                    });
                }  else if (onSecondaryRowClick) {
                    onSecondaryRowClick(activeRow);
                }
            } else if (activeRow.RowType === "Tertiary" && onTertiaryRowClick) {
                onTertiaryRowClick(activeRow);
            } else {
                const rowId = activeRow[primaryRowId];
                console.log("🚀 ~ handleRowClick ~ rowId:", activeRow, rowId, expandedRows)
                setExpandedRows(prev => {
                    const newExpandedRows = new Set(prev);
                    if (newExpandedRows.has(rowId)) {
                        newExpandedRows.delete(rowId);
                        setExpandedSecondaryRows(new Set());
                        updateConfigState({ expTableConfig: { expandedSecondaryRowState: Array.from(new Set()) } })
                    } else {
                        newExpandedRows.add(rowId);
                    }
                    updateConfigState({ expTableConfig: { expandedRowState: Array.from(newExpandedRows) } })
                    return newExpandedRows;
                });

            }
        }
    }, [onRowClick, primaryRowId, secondaryRowId, activeScreenIDs, dispatch, navigate, rows, tableConfig]);

    useEffect(() => {
        const { current: root } = rootRef;

        if (scroller && root) {
            initialize({
                target: root,
                elements: {
                    viewport: scroller,
                },
            });
        }

        return () => osInstance()?.destroy();
    }, [scroller, initialize, osInstance]);

    const addRowStatusToFinalRows = (finalRows: RowDataProps[]) => {
        const finalRowsWithStatus = finalRows.map((row, index) => {
            var prevRow = finalRows[index - 1];
            var nextRow = finalRows[index + 1];
            switch (row.RowType) {
                case 'Primary':
                    return { isFirstRow: index === 0, isLastRow: index === finalRows.length - 1, ...row };
                case 'Secondary':
                    return { isFirstRow: !prevRow || prevRow.RowType === 'Primary', isLastRow: !nextRow || nextRow.RowType === 'Primary', isLastRowOfPrimary: !nextRow || nextRow.RowType === 'Primary', ...row };
                case 'Tertiary':
                    return { isFirstRow: !prevRow || prevRow.RowType !== 'Tertiary', isLastRow: !nextRow || nextRow.RowType !== 'Tertiary', isLastRowOfPrimary: !nextRow || nextRow.RowType === 'Primary', ...row };
            }
            return row;
        });
        return finalRowsWithStatus;
    }

    const ExpandableTableRow = (props: any) => {
        const [isHovered, setIsHovered] = useState(false);
        const { item: row, isMarginCellRequired } = props;
        const isFirstRow = row.isFirstRow;
        const isLastRow = row.isLastRow;
        const isLastRowOfPrimary = row.isLastRowOfPrimary;
        // const { isFirstRow: isPrimaryFirstRow, isLastRow: isPrimaryLastRow } = getPrimaryRowStatus(finalRows, row.AssetTypeID, primaryRowId);
        // const { isFirstRow: isSecondaryFirstRow, isLastRow: isSecondaryLastRow } = getSecondaryRowStatus(finalRows, row[primaryRowId], row[secondaryRowId], primaryRowId, secondaryRowId);
        // const { isFirstRow: isTertiaryFirstRow, isLastRow: isTertiaryLastRow } = getTertiaryRowStatus(finalRows, row[secondaryRowId], row.TertiaryId, secondaryRowId);
        const isExpanded = expandedRows.has(row[primaryRowId]);
        const isSecondaryExpanded = expandedSecondaryRows.has(row[secondaryRowId]);

        const handleMouseEnter = () => setIsHovered(true);
        const handleMouseLeave = () => setIsHovered(false);

        if (row.RowType === 'Tertiary') {
            return (
                <>
                    <TableRow
                        {...props}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        sx={{
                            ":hover .IsHovered": { background: '#F9F9F9' },
                            '.IsRowName div': {
                                // display: 'inline-block'
                            },
                            ':hover .IsRowName div': {
                                color: isExpandedRowBold ?'#5F854C' : "",
                                cursor: 'pointer',
                            },
                            "cursor": "pointer",
                            ...(row.empty ? {} : { height: "30px" }),
                        }}
                    >
                        { isMarginCellRequired ? 
                        <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
                        : null
                        }
                        {StartingCell ? 
                        StartingCell.TertiaryRowCell : 
                        <TableCell sx={{ borderStyle: "none", pointerEvents: "none" }} />
                        }
                        <TertiaryRowCell {...{ columns, row: row, isFirstRow, isLastRow, isLastRowOfPrimary, onRowClick, PMSBadgeField, isHovered, EndingCell, cellRadius }} />
                        { isMarginCellRequired ? 
                        <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
                        : null
                        }
                    </TableRow>
                    {isLastRow &&
                        (<TableRow sx={{ height: "30px", pointerEvents: "none" }}>
                            { isMarginCellRequired ? 
                            <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6" }} />
                            : null
                            }
                            {StartingCell ? 
                            StartingCell.LastEmptyTertiaryRowCell : 
                            <TableCell sx={{ borderStyle: "none" }} />
                            }
                            {columns.map((_, index) => (
                                <TableCell key={index} sx={{ borderStyle: "none" }} />
                            ))}
                            {EndingCell ? 
                            EndingCell.LastEmptyTertiaryRowCell : 
                            <>
                            <TableCell sx={{ borderStyle: "none", width: "50px" }} />
                            <Box sx={{ borderStyle: "none", width: "10px" }} />
                            </>
                            }
                            { isMarginCellRequired ? 
                            <TableCell sx={{ borderStyle: "none", background: "#F6F6F6" }} />
                            : null
                            }
                        </TableRow>
                    )}
                </>
            );
        }

        if (row.RowType === 'Secondary') {
            const hasTertiaryRows = rows.some(r => r.RowType === 'Tertiary' && r[secondaryRowId] === row[secondaryRowId]);
            return (
                <>
                    <TableRow
                        {...props}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        sx={{
                            ":hover .IsHovered": { background: '#F9F9F9' },
                            '.IsRowName div': {
                                // display: 'inline-block'
                            },
                            ':hover .IsRowName div': {
                                color: isExpandedRowBold ? '#5F854C' : "",
                                cursor: 'pointer',
                            },
                            ":hover .icon-by-asset-type-fill": { fill: isExpandedRowBold ? '#5F854C' : "" },
                            ".icon-by-asset-type-fill": { fill: '#12131A' },
                            "cursor": "pointer",
                            ...(row.empty ? {} : { height: "30px" }),
                        }}
                    >
                        { isMarginCellRequired ? 
                        <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
                        : null
                        }
                        {StartingCell && StartingCell.SecondaryRowCell ? 
                        StartingCell.SecondaryRowCell({ isExpanded: false, isFirstRow: false, isLastRow: false }) : 
                        <TableCell sx={{ borderStyle: "none", pointerEvents: "none" }} />
                        }
                        <SecondaryRowCell {...{ columns, row: row, isFirstRow, isLastRow, onRowClick, PMSBadgeField, isHovered, isExpanded: isSecondaryExpanded, hasTertiaryRows, StartingCell, EndingCell, cellRadius }} />
                        { isMarginCellRequired ? 
                        <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
                        : null
                        }
                    </TableRow>
                    {isLastRowOfPrimary &&
                        (<TableRow sx={{ height: "30px", pointerEvents: "none" }}>
                            { isMarginCellRequired ? 
                            <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6" }} />
                            : null
                            }
                            {StartingCell ? 
                            StartingCell.LastEmptySecondaryRowCell : 
                            <TableCell sx={{ borderStyle: "none" }} />
                            }
                            {columns.map((_, index) => (
                                <TableCell key={index} sx={{ borderStyle: "none" }} />
                            ))}
                            {EndingCell ? 
                            EndingCell.LastEmptySecondaryRowCell : 
                            <>
                            <TableCell sx={{ borderStyle: "none", width: "50px" }} />
                            <Box sx={{ borderStyle: "none", width: "10px" }} />
                            </>
                            }
                            { isMarginCellRequired ? 
                            <TableCell sx={{ borderStyle: "none", background: "#F6F6F6" }} />
                            : null
                            }
                        </TableRow>
                    )}
                </>
            );
        }

        return (
            <TableRow
                {...props}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                    ":hover": { background: '#F9F9F9' },
                    ".icon-by-asset-type-fill": { fill: '#12131A' },
                    ".icon-by-asset-type-stroke": { stroke: '#12131A' },
                    ":hover .icon-by-asset-type-fill": { fill: isExpandedRowBold ? '#5F854C' : "" },
                    ":hover .icon-by-asset-type-stroke": { stroke: isExpandedRowBold ? '#5F854C' : "" },
                    '.IsRowName div': {
                        // display: 'inline-block'
                    },
                    '.IsSelected div': {
                        color: '#12131A',
                        fontWeight: isExpandedRowBold ? 500 : "normal",
                    },
                    ':hover .IsRowName div': {
                        color: isExpandedRowBold ? '#5F854C' : "",
                        cursor: 'pointer',
                    },
                    "cursor": "pointer",
                    ...(row.empty ? { background: emptyRowStyles?.background || "#F6F6F6", pointerEvents: "none" } : { height: "30px" }),
                }}
            >
                <PrimaryRowCell {...{ columns, row: row, isFirstRow, isLastRow, onRowClick, PMSBadgeField, expandedRows, primaryRowId, isHovered, isExpanded, emptyRowStyles, isMarginCellRequired, StartingCell, EndingCell }} />
            </TableRow>
        )
    }

    useEffect(() => {
        virtuoso.current &&  virtuoso?.current?.scrollToIndex({index: tableConfig?.scrollPosition, align: 'end', behavior:'auto'});   
    }, [])

    return (
        <Box data-overlayscrollbars-initialize="" ref={rootRef} style={{ height: tableHeight, width: "100%" }}>
            <TableVirtuoso
                scrollerRef={(ref) => {
                    if (ref instanceof HTMLElement) {
                        setScroller(ref);
                    }
                }}
                ref={virtuoso}
                style={{
                    background: '#FFFFFF', width: '100%', minHeight: tableHeight ? tableHeight : '400px', overflow: isScrollable ? "scroll" : "hidden"
                }}
                components={{
                    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate', height: "100%" }} />,
                    TableHead: TableHead,
                    TableFoot: TableFooter,
                    TableRow: (props) => <ExpandableTableRow onClick={(event: any) => rowClickDisabled ? undefined : handleRowClick(event, props.item, props['data-index'])} {...props} isMarginCellRequired={isMarginCellRequired} />,
                    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
                }}
                data={[...finalRows, { empty: true } as any]}
                fixedHeaderContent={() => (
                    <TableRow sx={{ background: headerColor ? headerColor : "white" }}>
                        { isMarginCellRequired ? 
                        <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
                        : null
                        }
                        { StartingCell?.HeaderRowCell ? 
                            StartingCell?.HeaderRowCell : 
                            (
                            <TableCell sx={{ borderBottom: "none" }} align="left">
                                <Box onClick={toggleExpandAll} sx={{ cursor: 'pointer',  display:'flex', alignItems:'center', justifyContent:'center' }}>
                                    <FullscreenIcon />
                                </Box>
                            </TableCell>
                            )
                        }
                        {columns.map((column) => (
                            <TableCell key={column.FieldKey} align={column.Align} sx={{ width: column.ColumnWidthPercent, minWidth: "130px", maxWidth: column.ColumnWidthPercent, textTransform: 'uppercase', borderBottom: "none" }}>
                                <Box sx={{display: "flex", justifyContent: column.Align, alignItems: 'center'}}>
                                    {
                                    column.HeaderRowCell ? 
                                    column.HeaderRowCell({column, toggleExpandAll}) : 
                                    (
                                    <Box sx={{ marginRight: '8px' }}>
                                        <CustomTypography variant='Medium14' sx={{ whiteSpace: "pre-line" }}>{column.HeaderName}</CustomTypography>
                                        {column.SubHeaderName &&
                                        <CustomTypography variant='Regular14' sx={{color: "#64677A"}}>{column.SubHeaderName}</CustomTypography>}
                                    </Box>
                                    )
                                    }
                                    {sorting && (tableConfig?.sortConfig?.key === column.FieldKey ? (
                                        <Box sx={{ cursor: 'pointer', width: '10px', height: '20px' }} onClick={() => handleSorting(column.FieldKey)}>
                                            {tableConfig?.sortConfig?.direction === SharedConstants.SortDirection.Down ? (
                                                <DownSortIcon />
                                            ) : (
                                                <UpSortIcon />
                                            )}
                                        </Box>
                                    ) : (
                                        <Box sx={{ cursor: 'pointer', width: '10px', height: '20px' }} onClick={() => handleSorting(column.FieldKey)}>
                                            <Box sx={{ marginLeft: '-3px', marginTop: '1.5px' }}>
                                                <SortingIcon />
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </TableCell>
                        ))}
                        {EndingCell ? 
                        EndingCell.HeaderRowCell : 
                        <>
                        <TableCell sx={{ borderStyle: "none", width: "50px", borderBottom: "none" }} />
                        <Box sx={{ width: "12px" }} />
                        </>
                        }
                        { isMarginCellRequired ? 
                        <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
                        : null
                        }
                    </TableRow>
                )}
                fixedFooterContent={footerContent}
            />
        </Box>
    );
};

const sortRows = (rows: RowDataProps[], columns: ColumnDataProps[], sortKey?: string, direction?: SharedConstants.SortDirection, expandedRows?: Set<string>, primaryRowId?: string, expandedSecondaryRows?: Set<string>, secondaryRowId?: string) => {
    if (!rows || !sortKey || !primaryRowId || !secondaryRowId) return rows;

    var column = columns.find(x => x.FieldKey === sortKey);
    if (!column) return rows;

    var isColumnNumeric = (column.IsRowName || column.IsString) !== true;

    try {
        const primaryRows = rows.filter(row => row.RowType !== 'Secondary' && row.RowType !== 'Tertiary');
        const secondaryRows = rows.filter(row => row.RowType === 'Secondary');
        const tertiaryRows = rows.filter(row => row.RowType === 'Tertiary');

        const compareValues = (x: any, y: any) => {
            const comparison = isColumnNumeric
                ? Big(x[sortKey] || 0).cmp(y[sortKey] || 0)
                : (x[sortKey] < y[sortKey] ? -1 : x[sortKey] > y[sortKey] ? 1 : 0);
            return comparison * (direction === SharedConstants.SortDirection.Down ? -1 : 1);
        };

        const sortedPrimaryRows = [...primaryRows].sort(compareValues);

        const result: RowDataProps[] = [];
        sortedPrimaryRows.forEach(primaryRow => {
            result.push(primaryRow);
            const primaryRowKey = primaryRow[primaryRowId];

            if (primaryRowKey && expandedRows?.has(primaryRowKey)) {
                const relatedSecondaryRows = secondaryRows
                    .filter(secondaryRow => secondaryRow[primaryRowId] === primaryRowKey)
                    .sort(compareValues);

                relatedSecondaryRows.forEach(secondaryRow => {
                    result.push(secondaryRow);
                    const secondaryRowKey = secondaryRow[secondaryRowId];

                    if (secondaryRowKey && expandedSecondaryRows?.has(secondaryRowKey)) {
                        const relatedTertiaryRows = tertiaryRows
                            .filter(tertiaryRow => tertiaryRow[secondaryRowId] === secondaryRowKey)
                            .sort(compareValues);

                        result.push(...relatedTertiaryRows);
                    }
                });
            }
        });

        return result;
    } catch {
        return rows;
    }
};

export default ExpandableVirtualizedTable;
