export interface FYListItem {
    index: number;
    title: string;
    subtitle: string;
    startDate: Date;
    endDate: Date;
    fyName: string;
}

export interface MonthItem {
    monthNumber: number;
    shortName: string;
    fullName: string;
}

export interface YearListItem {
    index: number;
    year: number;
    title: string;
    startDate: Date;
    endDate: Date;
}

export interface TrailingPeriodItem {
    index: number;
    title: string;
    startDate: Date;
    endDate: Date;
}

export enum TableType {
    Today = 1,
    Holding = 2,
    PerformanceByPortfolio = 3,
    PerformanceByAsset = 4,
    EquityExposure = 5,
    Transaction = 6,
    SummaryScreen = 7,
    EquityExposureSectorWise = 8,
    EquityExposureMarketCapWise = 9,
    EquityExposureAssetClassWise = 10,
    EquityExposurePortfolioWise = 11,
    EquityExposureHoldingsWise = 12,
    CustomAASubCategoryToday = 13,
    CustomAASubCategoryHoldings = 14
}

export interface ExpTableConfig {
    expandAllState?:boolean,
    expandedRowState:string[],
    expandedSecondaryRowState?: string[]
}