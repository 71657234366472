import React from 'react'

export default function ScreenerIcon(props: any) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.60156 13.5078C9.60156 12.6794 8.92999 12.0078 8.10156 12.0078C7.27314 12.0078 6.60156 12.6794 6.60156 13.5078V17.7078C6.60156 18.5362 7.27314 19.2078 8.10156 19.2078C8.92999 19.2078 9.60156 18.5362 9.60156 17.7078V13.5078Z" fill="#64B80B"/>
            <path d="M13.8008 11.1094C13.8008 10.2809 13.1292 9.60938 12.3008 9.60938C11.4724 9.60938 10.8008 10.2809 10.8008 11.1094V17.7094C10.8008 18.5378 11.4724 19.2094 12.3008 19.2094C13.1292 19.2094 13.8008 18.5378 13.8008 17.7094V11.1094Z" fill="#71C910"/>
            <path d="M18.002 6.90625C18.002 6.07782 17.3304 5.40625 16.502 5.40625C15.6735 5.40625 15.002 6.07782 15.002 6.90625V17.7063C15.002 18.5347 15.6735 19.2063 16.502 19.2063C17.3304 19.2063 18.002 18.5347 18.002 17.7063V6.90625Z" fill="#77D611"/>
        </svg>        
    )
}
