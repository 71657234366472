import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import TodayLayout from "../layout";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import TodayValueCard from "../todayValueCard";
import TodayGainLoss from "../todayGainLoss";
import TodayPerformanceColumnChart from "../todayPerformanceColumnChart";
import { useNavigate, useLocation } from 'react-router-dom';
import ExpandableVirtualizedTable from "../../../shared/expandable-table";
import { NavigateToAsset } from "packages/web/src/routes/navigationMethods";

interface L1_AA_Subcategory_Today_Props {
  MPrUrlParams: MPrUrlParams;
}

export default function L1_Custom_AA_Subcategory_Today(props: L1_AA_Subcategory_Today_Props) {
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);
  const subCategName = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryPortfolioSummarySubCategName);

  return <TodayLayout
    {...props}
    Screen_TodayValueCard={L1TodayValueCard}
    Screen_TodayGainLossCard={L1TodayGainLossCard}
    Screen_PieChart={undefined}
    Screen_TodayTable={L1TodayTable}
    Screen_TodayPerformanceColumnChart={L1TodayPerformanceColumnChart}
    IsLoading={isLoading}
    ShowStocksCards={false}
    TableTitle={`Current ${subCategName} Investments`}
  />;
}

const L1TodayValueCard = () => {
  const currentValue = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryPortfolioSummaryTotalCurrentValue);
  const subCategName = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryPortfolioSummarySubCategName);
  const categName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveCustomAssetAllocationCategName);

  return <TodayValueCard
    ScreenType={categName || 'Sub Category'}
    Title={subCategName}
    Screen={SharedConstants.ScreenEnum.L1_Custom_AA_Subcategory}
    CurrentValue={currentValue}
  />
}

const L1TodayGainLossCard = () => {
  const totalValues = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryPortfolioSummaryTotal);

  return <TodayGainLoss TotalValues={totalValues} />
}

const L1TodayTable = ({ searchTerm }: { searchTerm: string }) => {
  const tableData = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryTodayExpandableTable_ByAsset);  
  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const onSecondaryRowClick = (row: RowDataProps) => {
    if (row && row.AssetTypeID > 0 && row.AMID > 0) {
      NavigateToAsset({navigate, activeScreenIDs, assetTypeID: row.AssetTypeID, AMID: row.AMID, pathname: location.pathname });
    }
  }

  var fieldNamesToSearch = ['Name'];

  return <ExpandableVirtualizedTable columns={tableColumns} rows={tableData || []} headerColor="#EAF4DF" sorting={true} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch} defaultSortConfig={SharedConstants.DefaultSortingForTodayTable} primaryRowId="AssetTypeID" secondaryRowId="SID" isMarginCellRequired={false} tableHeight="400px" onSecondaryRowClick={onSecondaryRowClick} expandAllByDefault={true} tableType={SharedConstants.TableType.CustomAASubCategoryToday}/>

}

const L1TodayPerformanceColumnChart = () => {
  const indexChartData = useAppSelector(SharedRedux.Dashboard.Selectors.selectTodayPerformanceIndicesChartValues);
  return <TodayPerformanceColumnChart data={indexChartData} />
}

const tableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'Name',
    HeaderName: 'ASSET',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDefaultFieldFormatter,
    Align: 'left',
    IsRowName: true,
  },
  {
    FieldKey: 'TGain',
    HeaderName: "TODAY'S GAIN",
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'TGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'TUp'
      }
    ]
  },
  {
    FieldKey: 'OGain',
    HeaderName: 'UNREALISED GAIN',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'OGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'OUp'
      }
    ]
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  }
];