import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Container, FormControl, InputLabel, TextField, Typography, styled } from '@mui/material'
import LongBackArrow from 'packages/web/src/assets/icons/login/LongBackArrow';
import CustomTypography from '../../../shared/customTypography';
import IllustrationsBackground from '../../../../assets/background/illustrations.jpg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, SubmitHandler } from "react-hook-form";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useAppDispatch } from 'packages/web/src/redux/hooks';
import { WebRedux } from '@mprofit/web';
import { AuthPage } from 'packages/web/src/constants/login';
import { SharedAPIs, SharedUtilities } from '@mprofit/shared';
import { HttpStatusCode } from 'axios';
import LoginBtnLoader from '../login-btn-loader';
interface IFormInputs {
  email: string
  resetCode: string
}

export default function ForgotPasswordWithResetCode() {
  const dispatch = useAppDispatch();
  const { register, formState: { errors }, handleSubmit } = useForm<IFormInputs>();
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [loading, setLoader] = useState(false);

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    setIsWrongOtp(false)
    setLoader(true);
    SharedAPIs.Login.SubmitResetPasswordAPI(data.email, data.resetCode).then(response => {
      setLoader(false);
      if (response.status === HttpStatusCode.Ok && response.data.IsSuccess) {
        dispatch(WebRedux.Auth.Actions.setLoginPayload({ email: data.email, password: '', mfaOTP: data.resetCode }));
        dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: AuthPage.SetNewPassword }))
      } else {
        setIsWrongOtp(true)
      }
    })

  };

  return (
    <Container
      sx={{
        boxSizing: 'border-box',
        width: '53%',
        minWidth: '550px',
        maxWidth: '606px',
        marginLeft: 'auto',
        background: '#FFFFFF',
        maxHeight: '73%',
        minHeight: '600px',
        borderRadius: '8px',
        padding: '24px',
        margin: 'auto',
        height: '65%',
      }}
    >
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <BackContainer onClick={() => dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: 1 }))}>
          <LongBackArrow />
          <CustomTypography variant='Medium16' sx={{ marginLeft: '8px', color: { xs: 'brandBase.300' } }}>Back to login</CustomTypography>
        </BackContainer>
        <HeaderCont sx={{ marginBottom: { lg: '8px' } }}>
          <Heading>Forgot password?</Heading>
          <CustomTypography variant='Regular18' sx={{ paddingTop: '16px' }}>No problem. Just enter your email address and your password reset code.</CustomTypography>
        </HeaderCont>

        <FormContainer>
          <CodeInputCont>
            <Box component="form" noValidate autoComplete="off" sx={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
              <InputLabel htmlFor="emailId" sx={{ padding: '10px 0px', color: 'black', fontSize: '18px' }} >Email Id</InputLabel>
              <FormControl fullWidth={true}>
                <TextField
                  InputProps={{ style: { height: '48px', fontSize: '18px' } }}
                  size='medium'
                  id="emailId"
                  aria-describedby="email-id"
                  placeholder='Enter your email id'
                  hiddenLabel
                  error={errors.email && true}
                  sx={{
                    ":hover": {
                      background: '#f9f9f9',
                    },
                    ":hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #D8DAE5",
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        border: "1px solid #D8DAE5",
                      },
                    },
                  }}
                  {...register("email", { required: true, pattern: SharedUtilities.EmailValidationRegex, setValueAs: (value) => value.trim() })}
                />
                <ErrorText sx={{ visibility: errors.email ? "visible" : 'hidden', color: { xs: '#C94C40' }, marginTop: '5px' }} variant='Regular16'><WarningRoundedIcon fontSize="small" sx={{ marginRight: '5px' }} />Please enter a valid email address</ErrorText>
              </FormControl>

              <InputLabel htmlFor="resetCode" sx={{ padding: '10px 0px', color: 'black', fontSize: '18px' }}>Password reset code</InputLabel>
              <FormControl fullWidth={true}>
                <TextField
                  InputProps={{ style: { height: '48px', fontSize: '18px' } }}
                  size='medium'
                  id="resetCode"
                  aria-describedby="resetCode"
                  placeholder='Enter code'
                  hiddenLabel
                  error={errors.email && true}
                  sx={{
                    ":hover": {
                      background: '#f9f9f9',
                    },
                    ":hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #D8DAE5",
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        border: "1px solid #D8DAE5",
                      },
                    },
                  }}
                  {...register("resetCode", { required: true })}
                />
                {isWrongOtp && <ErrorText sx={{ color: { xs: '#C94C40' }, marginTop: '5px' }} variant='Regular16'><WarningRoundedIcon fontSize="small" sx={{ marginRight: '5px' }} />Error in resetting password. Please try again.</ErrorText>}
                <ErrorText sx={{ visibility: errors.email ? "visible" : 'hidden', color: { xs: '#C94C40' }, marginTop: '5px' }} variant='Regular16'><WarningRoundedIcon fontSize="small" sx={{ marginRight: '5px' }} />Please enter a valid code</ErrorText>
              </FormControl>
              <Box
                sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px', marginTop: '-16px' }}
              >
                <CustomTypography
                  variant='Medium16'
                  sx={{
                    color: { xs: "#5F6F57" },
                    textAlign: 'end',
                    padding: '10px 16px 10px 16px',
                    width: 'fit-content',
                    alignSelf: 'flex-end',
                    cursor: 'pointer',
                    ':hover': { background: '#EAF4DF', color: '#5F6F57', borderRadius: '4px' }
                  }}
                  onClick={() => dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: AuthPage.ForgotPassword }))}
                >
                  Send new code
                </CustomTypography>
              </Box>
              <VerifyButton size='small' variant='contained' type='submit'>
                {
                  loading ?
                    <LoginBtnLoader />
                    :
                    'Continue to reset password'
                }
              </VerifyButton>
            </Box>
          </CodeInputCont>
        </FormContainer>
      </Box>
    </Container >
  )
}


const Wrapper = styled(Container)({
  boxSizing: 'border-box',
  maxHeight: '73%',
  minHeight: '550px',
  height: '65%',
  minWidth: '550px',
  width: '53%',
  marginLeft: 'auto',
  background: '#FFFFFF',
  borderRadius: '8px',
  margin: 'auto',
  padding: '24px'
});

const BackContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
});

const HeaderCont = styled(Box)({
  marginLeft: '32px',
  marginRight: '32px',
  marginTop: '6%',
});

const Heading = styled(Typography)({
  fontSize: '36px',
  lineHeight: '48px',
  fontWeight: 500
});

const FormContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '32px',
  paddingRight: '32px'
});

const VerifyButton = styled(Button)({
  padding: '15px 32px 14px 32px',
  background: '#010006',
  textTransform: 'none',
  color: 'base.white',
  width: '100%',
  height: '48px',
  ':hover': {
    background: '#252833'
  },
  ':disabled': {
    background: '#EBECF2',
    color: '#BABDCC'
  }
});

const CodeInputCont = styled(Box)({
  display: 'flex'
});

const SpanText = styled(CustomTypography)({
  marginLeft: '6px',
  cursor: 'pointer'
});
const ErrorText = styled(CustomTypography)({
  minHeight: '24px',
  display: 'flex',
  alignItems: 'center',
});