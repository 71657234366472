import { call, put, select, takeLatest, takeEvery } from "redux-saga/effects";
import { MProfitAPI, ResponseGenerator } from "../../api";
import { FetchPortfoliosPayload, Actions, SetActivePortfolioPayload, Selectors, FetchTransactionsPayload, FetchBalancesPayload, ActiveScreenPayload } from "../modules/portfoliosReducer";
import type { PayloadAction } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { FamiliesByID, Family, PeriodInfo, PFolioTypes, PortfoliosByPTypeRaw, SetPeriodPayload } from "../../constants";
import { Actions as PortfolioSummaryActions } from "../modules/portfolioSummaryReducer";
import { Actions as PerformanceActions } from "../modules/performanceReducer";
import { Selectors as PortfolioSummarySelectors } from "../modules/portfolioSummaryReducer";

const portfoliosAPI = (ccid: number) => {
    return MProfitAPI.get('/api/portfolios', {
        params: {
            ccid,
            familyId: -1
        }
    });
}

const familyAPI = (ccid: number) => {
    return MProfitAPI.get('/api/Family/all', {
        params: {
            ccid
        }
    });
}

const recentPortfoliosAPI = (ccid: number) => {
    return MProfitAPI.get('/api/Portfolio/Recent', {
        params: {
            ccid
        }
    });
}

const transactionsAPI = (familyId: number, pfid: number, sid: number, ccid: number) => {
    return MProfitAPI.get('/api/Trans', {
        params: {
            familyId,
            pfid,
            sid,
            ccid
        }
    });
}

const balancesAPI = (familyId: number, pfid: number, sid: number, amid: number, atyp: number, dateTime: string, isClosing: boolean, ccid: number) => {
    return MProfitAPI.get('/api/GetBalVals', {
        params: {
            familyId,
            pfid,
            sid,
            amid,
            atyp,
            dateTime,
            isClosing,
            ccid
        }
    });
}

function* onFetchPortfolios(action: PayloadAction<FetchPortfoliosPayload>) {
    const familiesResponse: ResponseGenerator = yield call(familyAPI, action.payload.CCID);

    if (familiesResponse.status == HttpStatusCode.Ok) {
        var familiesByID: FamiliesByID = {
            ByID: {},
            AllIDs: []
        };

        (familiesResponse.data || []).forEach((family: Family) => {
            familiesByID.ByID[family.FamilyId] = family;
            familiesByID.AllIDs.push(family.FamilyId);
        });

        yield put(Actions.setFamilies({ FamiliesByID: familiesByID }));
    }

    const portfoliosResponse: ResponseGenerator = yield call(portfoliosAPI, action.payload.CCID);

    if (portfoliosResponse.status == HttpStatusCode.Ok) {
        var portfoliosByPTypeRaw: PortfoliosByPTypeRaw[] = portfoliosResponse.data;
        yield put(Actions.setPortfolios({ CCID: action.payload.CCID, PortfoliosData: portfoliosByPTypeRaw }));

        if (action.payload.IsLaunch) {
            var activePortfolio: SetActivePortfolioPayload = { CCID: action.payload.CCID, FamilyId: -1, PFID: -1 };

            if (action.payload.ActivePortfolio && action.payload.ActivePortfolio.PFID > 0) {
                var tempActivePortfolio = action.payload.ActivePortfolio;
                for (var portfoliosByPType of portfoliosByPTypeRaw) {
                    for (var portfolio of portfoliosByPType.PList) {
                        if (portfolio.FamilyId == tempActivePortfolio.FamilyId && portfolio.Id == tempActivePortfolio.PFID) {
                            activePortfolio = tempActivePortfolio;
                        }
                        if (activePortfolio && activePortfolio.PFID > 0) break;
                    }
                    if (activePortfolio && activePortfolio.PFID > 0) break;
                }
            }

            if (!activePortfolio || activePortfolio.PFID <= 0) {
                const recentPortfoliosResponse: ResponseGenerator = yield call(recentPortfoliosAPI, action.payload.CCID);
                if (recentPortfoliosResponse.status == HttpStatusCode.Ok) {
                    for (var recentPortfolio of recentPortfoliosResponse.data) {
                        for (var portfoliosByPType of portfoliosByPTypeRaw) {
                            for (var portfolio of portfoliosByPType.PList) {
                                if (portfoliosByPType.PType === PFolioTypes.PMS && portfolio.FamilyId == recentPortfolio.FamilyId && portfolio.Id == recentPortfolio.Id) {
                                    activePortfolio = {
                                        CCID: action.payload.CCID,
                                        FamilyId: recentPortfolio.FamilyId,
                                        PFID: recentPortfolio.Id
                                    }
                                    console.log('recentPortfolio', activePortfolio, portfolio, recentPortfolio);
                                }
                                if (activePortfolio && activePortfolio.PFID > 0) break;
                            }
                            if (activePortfolio && activePortfolio.PFID > 0) break;
                        }
                        if (activePortfolio && activePortfolio.PFID > 0) break;
                    };
                }

                if (!activePortfolio || activePortfolio.PFID <= 0) {
                    for (var portfoliosByPType of portfoliosByPTypeRaw) {
                        for (var portfolio of portfoliosByPType.PList) {
                            activePortfolio = {
                                CCID: action.payload.CCID,
                                FamilyId: portfolio.FamilyId,
                                PFID: portfolio.Id
                            }
                            if (activePortfolio && activePortfolio.PFID > 0) break;
                        }
                        if (activePortfolio && activePortfolio.PFID > 0) break;
                    }
                }

                if (action.payload.OnNavigateToPortfolio) {
                    action.payload.OnNavigateToPortfolio(activePortfolio);
                }
            }

            if (activePortfolio && activePortfolio.PFID > 0) {
                yield put(PortfolioSummaryActions.refreshActivePortfolioSummaryOnPortfolioChange(activePortfolio));
            }
        }
    }
}

function* fetchTransactions(action: PayloadAction<FetchTransactionsPayload>) {
    try {
        const { FamilyId, PFID, SID, CCID } = action.payload;
        const response: ResponseGenerator = yield call(transactionsAPI, FamilyId, PFID, SID, CCID);
        
        if (response.status === HttpStatusCode.Ok) {
            yield put(Actions.setTransactions({ transactions: response.data }));
        } else {
            console.log('Failed to fetch transactions:', response);
        }
    } catch (error) {
        console.log('Error fetching transactions:', error);
    }
}

function* fetchBalances(action: PayloadAction<FetchBalancesPayload>) {
    try {
        const activePeriod: PeriodInfo = yield select(Selectors.selectActivePeriod);
        const dateTime = action.payload.IsClosing ? activePeriod.PeriodTo : activePeriod.PeriodFrom;

        const response: ResponseGenerator = yield call(balancesAPI, 
            action.payload.FamilyId as number, 
            action.payload.PFID as number, 
            action.payload.SID as number, 
            action.payload.Amid as number, 
            action.payload.Atyp as number, 
            dateTime as string, 
            action.payload.IsClosing as boolean, 
            action.payload.CCID as number
        );
        
        if (response.status === HttpStatusCode.Ok) {
            if (action.payload.IsClosing) {
                yield put(Actions.setClosingBalances({ closingBalances: response.data }));
            } else {
                yield put(Actions.setOpeningBalances({ openingBalances: response.data }));
            }
        } else {
            console.log('Failed to fetch opening balances:', response);
        }
    } catch (error) {
        console.log('Error fetching opening balances:', error);
    }
}

function* onChangePortfolio() {
    var previousPortfolioIDs: SetActivePortfolioPayload | undefined = yield select(Selectors.selectPreviousPortfolioIDs);
    var activePortfolioIDs: SetActivePortfolioPayload | undefined = yield select(Selectors.selectActivePortfolioIDs);
    if (activePortfolioIDs && previousPortfolioIDs?.FamilyId != activePortfolioIDs?.FamilyId) {
        yield put(Actions.setActiveFamily({ CCID: activePortfolioIDs.CCID, FamilyId: activePortfolioIDs.FamilyId }));
        yield put(PortfolioSummaryActions.fetchFamilyPortfolioSummary({ CCID: activePortfolioIDs.CCID, FamilyId: activePortfolioIDs.FamilyId, Force: false }));
    }
    yield put(PerformanceActions.setCashMgmtDetails(undefined));
}

function* onActivePeriodChange(action: PayloadAction<SetPeriodPayload>) {
    var activeScreenIDs: ActiveScreenPayload = yield select(Selectors.selectActiveScreenIDs);

    if (activeScreenIDs && activeScreenIDs.DatabaseID && activeScreenIDs.FamilyID && activeScreenIDs.PortfolioID && activeScreenIDs.SID && activeScreenIDs.AssetType && activeScreenIDs.AMID) {
        yield put(Actions.fetchBalances({
            FamilyId: activeScreenIDs.FamilyID,
            PFID:  activeScreenIDs.OrigPFID as number,
            SID: activeScreenIDs.SID,
            Amid: activeScreenIDs.AMID,
            Atyp: activeScreenIDs.AssetType,
            IsClosing: false,
            CCID: activeScreenIDs.DatabaseID,
        }));
        yield put(Actions.fetchBalances({
            FamilyId: activeScreenIDs.FamilyID,
            PFID:  activeScreenIDs.OrigPFID as number,
            SID: activeScreenIDs.SID,
            Amid: activeScreenIDs.AMID,
            Atyp: activeScreenIDs.AssetType,
            IsClosing: true,
            CCID: activeScreenIDs.DatabaseID,
        }));
    }
}

function* portfoliosSaga() {
    yield takeLatest(Actions.fetchPortfolios.type, onFetchPortfolios);
    yield takeLatest(Actions.setActivePortfolio.type, onChangePortfolio);
    yield takeLatest(Actions.fetchTransactions.type, fetchTransactions);
    yield takeEvery(Actions.fetchBalances.type, fetchBalances);
    yield takeLatest(Actions.setActivePeriod.type, onActivePeriodChange)
}

export default portfoliosSaga;



