import React from 'react'
import { Box, SxProps, Tab, Theme } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import styled from '@emotion/styled';
import { theme } from '../theme';

interface ITabsButtonProps {
  tabs: Array<{id: number, label: string}>,
  activeTabID: number,
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  sxProps?: SxProps<Theme> | undefined,
  containerSx?:  SxProps<Theme> | undefined,
  tabSx?:  SxProps<Theme> | undefined,
  TabIndicatorProps?: React.CSSProperties | undefined
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabsButton({ tabs, activeTabID, handleChange, sxProps , containerSx, tabSx, TabIndicatorProps }: ITabsButtonProps) {
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', ...containerSx }}>
      <Tabs
        sx={{
          "& .MuiTab-root": {
            color: "black",
          },
          "& .MuiTab-root.Mui-selected": {
            color: "black", 
          },
        
          ...sxProps
        }}
        value={activeTabID}
        onChange={handleChange}
        aria-label="basic tab buttons"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#F9F9F9",
            ...TabIndicatorProps
          }
        }}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} value={tab.id} {...a11yProps(index)} sx={tabSx}/>
        ))}
      </Tabs>
    </Box>
  )
}
