import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import TodayLayout from "../layout";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import SharedVirtualizedTable, { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import TodayValueCard from "../todayValueCard";
import TodayGainLoss from "../todayGainLoss";
import TodayPerformanceColumnChart from "../todayPerformanceColumnChart";
import { NavigateToCustomAASubcategory } from "packages/web/src/routes/navigationMethods";
import { useNavigate, useLocation } from 'react-router-dom';

interface L1_AA_Today_Props {
  MPrUrlParams: MPrUrlParams;
}

export default function L1_Custom_AA_Today(props: L1_AA_Today_Props) {
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);
  const categName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveCustomAssetAllocationCategName);

  return <TodayLayout
    {...props}
    Screen_TodayValueCard={L1TodayValueCard}
    Screen_TodayGainLossCard={L1TodayGainLossCard}
    Screen_PieChart={undefined}
    Screen_TodayTable={L1TodayTable}
    Screen_TodayPerformanceColumnChart={L1TodayPerformanceColumnChart}
    IsLoading={isLoading}
    ShowStocksCards={false}
    TableTitle={`Current ${categName} Investments`}
  />;
}

const L1TodayValueCard = () => {
  const currentValue = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryForCustomAACategIDTotalCurrentValue);
  const categName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveCustomAssetAllocationCategName);

  return <TodayValueCard
    ScreenType='Category'
    Title={categName}
    Screen={SharedConstants.ScreenEnum.L1_AA}
    CurrentValue={currentValue}
  />
}

const L1TodayGainLossCard = () => {
  const totalValues = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryForCustomAACategIDTotal);

  return <TodayGainLoss TotalValues={totalValues} />
}

const L1TodayTable = ({ searchTerm }: { searchTerm: string }) => {
  const assetTypeTotals = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryForCustomAACategIDAssetTypeListSorted('CurrValue', SharedConstants.SortDirection.Up));
  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const onRowClick = (row: RowDataProps) => {
    if (row && row.SubCategID > 0) {
      NavigateToCustomAASubcategory({ navigate, activeScreenIDs, pathname: location.pathname, customAssetAllocationSubCategID: row.SubCategID });
    }
  }

  var fieldNamesToSearch = ['SubCategName'];

  return <SharedVirtualizedTable columns={tableColumns} rows={assetTypeTotals} headerColor="#EAF4DF" sorting={true} onRowClick={onRowClick} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch} defaultSortConfig={SharedConstants.DefaultSortingForTodayTable} tableType={SharedConstants.TableType.Today} />
}

const L1TodayPerformanceColumnChart = () => {
  const indexChartData = useAppSelector(SharedRedux.Dashboard.Selectors.selectTodayPerformanceIndicesChartValues);
  return <TodayPerformanceColumnChart data={indexChartData} />
}

const tableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'SubCategName',
    HeaderName: 'CATEGORY NAME',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDefaultFieldFormatter,
    Align: 'left',
    IsRowName: true,
  },
  {
    FieldKey: 'TGain',
    HeaderName: "TODAY'S GAIN",
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'TGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'TUp'
      }
    ]
  },
  {
    FieldKey: 'OGain',
    HeaderName: 'UNREALISED GAIN',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'OGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'OUp'
      }
    ]
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  }
];