import React from 'react'

export default function AssetAllocationIcon(props: any) {
    return (
            <svg {...props} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="pie" clip-path="url(#clip0_1002_5042)">
                <path id="Vector" d="M9 0.5C4.03763 0.5 0 4.53763 0 9.5C0 14.4624 4.03763 18.5 9 18.5C13.9624 18.5 18 14.4624 18 9.5C18 4.53763 13.9624 0.5 9 0.5ZM6.1875 9.5C6.1875 7.94919 7.44919 6.6875 9 6.6875C10.5508 6.6875 11.8125 7.94919 11.8125 9.5C11.8125 11.0508 10.5508 12.3125 9 12.3125C7.44919 12.3125 6.1875 11.0508 6.1875 9.5ZM1.125 9.5C1.125 5.34762 4.35769 1.94394 8.4375 1.65369V5.6075C6.53231 5.882 5.0625 7.52056 5.0625 9.5C5.0625 10.3831 5.35838 11.1959 5.85113 11.8535L3.0555 14.6491C1.85625 13.2671 1.125 11.4693 1.125 9.5Z" fill="black"/>
                </g>
                <defs>
                <clipPath id="clip0_1002_5042">
                <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
                </defs>
            </svg>
    )
}
