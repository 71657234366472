import { Box } from "@mui/material";
import { Dispatch, useState } from "react";
import ComponentTabs from "../../../shared/advanced-chart-tabs";
import AssetWisePerformanceTable from "../../../shared/asset-wise-performanc-table";
import CustomTypography from "../../../shared/customTypography";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { TableSearchBar } from "../../../shared/table-search-bar";
import { RowDataProps } from "../../../shared/virtual-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigateToAsset, NavigateToAssetType, NavigateToCustomAASubcategory, NavigateToCustomAssetAllocationCateg } from "packages/web/src/routes/navigationMethods";
import { XIRRTablePDFDownloadButton } from "../../../shared/asset-wise-performanc-table/pdf";
import { ChartsSelectors } from "packages/web/src/redux/selectors/chartsSelectors";


export default function PerformanceTable(props: { MPrUrlParams: MPrUrlParams, OnRefreshXIRRTable: (urlParams: MPrUrlParams, dispatch: Dispatch<any>, force?: boolean) => void, rowFieldName: string, rowFieldHeading?: string, disabled?: boolean, isGroupByPortfolio?: boolean, isPerformancePeriodAllToDate: boolean, showLockedMessage?: boolean, tableType?: SharedConstants.TableType, isGroupByCustomCategory?: boolean }) {
  const tableConfig = useAppSelector(SharedRedux.Dashboard.Selectors.selectTableConfig(props.tableType!));
  const [tableMode, setTableMode] = useState<SharedConstants.AdvancedPerformanceTableMode>(tableConfig?.tableMode || SharedConstants.AdvancedPerformanceTableMode.Details);
  
  const {tableData, tableRowData, isLoading, columnData, chartDisclaimer, heading } = useAppSelector(ChartsSelectors.selectXIRRTableData(props.isGroupByPortfolio, props.rowFieldHeading, props.rowFieldName, tableMode, props.isGroupByCustomCategory));

  const [searchTerm, setSearchTerm] = useState('');

  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const onRowClick = (row: RowDataProps) => {
    if (row && row.ATTY > 0) {
      if (props.MPrUrlParams.Screen === SharedConstants.ScreenEnum.L1 || props.MPrUrlParams.Screen === SharedConstants.ScreenEnum.L1_AA) {
        NavigateToAssetType({ navigate, activeScreenIDs, assetTypeID: row.ATTY, pathname: location.pathname });
      } else if ((props.MPrUrlParams.Screen === SharedConstants.ScreenEnum.L1_Custom_AA_Subcategory || props.MPrUrlParams.Screen === SharedConstants.ScreenEnum.L2 || props.MPrUrlParams.Screen === SharedConstants.ScreenEnum.L4) && row.AMID && row.AMID > 0) {
        NavigateToAsset({navigate, activeScreenIDs, assetTypeID: row.ATTY, AMID: row.AMID, SID: row.SID, pathname: location.pathname });
      }
    } else if (props.MPrUrlParams.Screen === SharedConstants.ScreenEnum.L1 && row.CustomAssetAllocationCategID && row.CustomAssetAllocationCategID > 0) {
      NavigateToCustomAssetAllocationCateg({ navigate, activeScreenIDs, pathname: location.pathname, customAssetAllocationCategID: row.CustomAssetAllocationCategID });
    } else if (props.MPrUrlParams.Screen === SharedConstants.ScreenEnum.L1_Custom_AA && row.CustomAssetAllocationSubCategID && row.CustomAssetAllocationSubCategID > 0) {
      NavigateToCustomAASubcategory({ navigate, activeScreenIDs, pathname: location.pathname, customAssetAllocationSubCategID: row.CustomAssetAllocationSubCategID });
    } 
  }

  return (
    <Box sx={{ margin: "5px 40px 32px 40px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "28px",
          alignItems: 'center'
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
          <CustomTypography variant="Medium28" sx={{ textAlign: "left" }}>
          {heading}
          </CustomTypography>
          {
            tableData && isLoading !== true &&
            <XIRRTablePDFDownloadButton
              heading={heading}
              rowFieldHeading={props.rowFieldHeading}
              rowFieldName={props.rowFieldName}
              tableMode={tableMode}
              isGroupByPortfolio={props.isGroupByPortfolio === true}
              tableType={props.tableType!}
              isGroupByCustomCategory={props.isGroupByCustomCategory === true}
            />
          }
        </Box>
        
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TableSearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
          <Box sx={{ marginLeft: "12px" }}>
            <ComponentTabs
              options={[{ id: SharedConstants.AdvancedPerformanceTableMode.Summary, text: 'Summary' }, { id: SharedConstants.AdvancedPerformanceTableMode.Details, text: 'Details' }]}
              handleChange={setTableMode}
              value={tableMode}
              bgColor="#ffffff"
            />
          </Box>
        </Box>
      </Box>
      <AssetWisePerformanceTable
        TableData={tableData}
        TableRowData={tableRowData}
        isLoading={isLoading}
        onRefresh={() => props.OnRefreshXIRRTable(props.MPrUrlParams, dispatch, true)}
        rowFieldName={props.rowFieldName}
        rowFieldHeading={props.rowFieldHeading}
        searchTerm={searchTerm}
        fieldNamesToSearch={['RowName']}
        onRowClick={onRowClick}
        disabled={props.disabled}
        rowClickDisabled={props.isGroupByPortfolio}
        PMSBadgeField={props.isGroupByPortfolio ? 'IsCashMgmtPortfolio' : undefined}
        showLockedMessage={props.showLockedMessage || false}
        columnData={columnData}
        tableMode={tableMode}
        tableType={props.tableType!}
      />
      {
        chartDisclaimer &&
        <Box sx={{
          display: "flex",
          alignItems: 'center',
          gap: "20px",
          // padding: "24px",
          background: 'white',
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
          height: 72,
          paddingLeft: '16px'
        }}>
          <CustomTypography variant="Regular16">
            {chartDisclaimer}
          </CustomTypography>
        </Box>
      }
    </Box>
  );
}


