import { Box, Skeleton, SkeletonProps, styled, SxProps, Theme } from '@mui/material'

interface CustomSkeletonProps extends SkeletonProps {
    position?: string;
    style?: React.CSSProperties;
    sxContainer?: SxProps<Theme>;
}

export default function SkeletonLoader(props: CustomSkeletonProps) {
    const { sxContainer, ...skeletonProps } = props;
    return (
        <Box sx={{ ...sxContainer }}>
            <Skeletons {...skeletonProps} animation={props.animation || false} sx={props.style} />
        </Box>
    )
}

const Skeletons = styled(Skeleton)({
    background: '#252833',
    borderRadius: '14px'
})