import { SharedConstants, SharedRedux, SharedUtilities } from '@mprofit/shared'
import { Box, Divider, Grid } from '@mui/material'
import { ScreenerInIcon } from 'packages/web/src/assets/icons/ScreenerInIcon'
import AssetAllocationIcon from 'packages/web/src/assets/icons/assetAllocationIcon'
import { EditIcon } from 'packages/web/src/assets/icons/editIcon'
import { PopOutIcon } from 'packages/web/src/assets/icons/popOutIcon'
import AssetIcon from 'packages/web/src/assets/icons/todayL4/assetIcon'
import LargeCap from 'packages/web/src/assets/icons/todayL4/largeCap'
import MidCap from 'packages/web/src/assets/icons/todayL4/midCap'
import SectorIcon from 'packages/web/src/assets/icons/todayL4/sectorIcon'
import SmallCap from 'packages/web/src/assets/icons/todayL4/smallCap'
import { CustomTypography } from 'packages/web/src/components/shared'
import TooltipDark from 'packages/web/src/components/shared/tooltip-dark'
import { useState } from 'react'
import { useAppSelector, useAppDispatch } from 'packages/web/src/redux/hooks'
import EditCategorizationModal from './EditCategorizationModal'

const marketCapIcon = {
  [SharedConstants.MarketCap_Type.None]: <></>,
  [SharedConstants.MarketCap_Type.SmallCap]: <SmallCap />,
  [SharedConstants.MarketCap_Type.MidCap]: <MidCap />,
  [SharedConstants.MarketCap_Type.LargeCap]: <LargeCap />
}

interface CategoryTabProps {
  label: string;
  value: string;
}

export const CategoryTab: React.FC<CategoryTabProps> = ({ label, value }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '12px', gap: '6px' }}>
      <CustomTypography color="#64677A" variant='Regular16' sx={{ lineHeight: '24px' }}>{label}</CustomTypography>
      <CustomTypography color="#12131A" variant='Regular20' sx={{ lineHeight: '30px' }}>{value}</CustomTypography>
    </Box>
  );
};

export default function L4StocksInfoCard({ stocksInfo }: { stocksInfo?: SharedConstants.StockAddInfo }) {
  const showOpenInScreenerInButton = stocksInfo && stocksInfo.ParentMProfitCode <= 0 && ((stocksInfo.NSESymbol && stocksInfo.NSESymbol.trim() != '') || (stocksInfo.BSECode && stocksInfo.BSECode > 0))

  const [isEditCategorizationModalOpen, setIsEditCategorizationModalOpen] = useState<boolean>(false);
  const [selectedMapping, setSelectedMapping] = useState<{
    categoryId?: number;
    categoryName?: string;
    subCategoryId?: number;
    subCategoryName?: string;
    isDefault?: boolean;
  }>({});


  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  const activeL4PortfolioSummaryTotal = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4PortfolioSummaryTotal);
  const assetTypeID = activeL4PortfolioSummaryTotal?.AssetTypeID;
  const isUsingDefaultCategorization = useAppSelector(SharedRedux.Licensing.Selectors.selectIsUsingDefaultCategorization);
  const showCustomCategEditButton = useAppSelector(SharedRedux.Licensing.Selectors.selectShowCustomCategEditButton);

  const dispatch = useAppDispatch();

  const handleSave = (event?: React.MouseEvent) => {
    event?.stopPropagation();
    if (selectedMapping.categoryId && selectedMapping.subCategoryId && activeScreenIDs?.DatabaseID) {
      dispatch(SharedRedux.Dashboard.Actions.updateCustomAssetAllocationMappings({
        mappings: {
          List: [{
            AssetTypeID: assetTypeID || -1,
            AssetAllocationCategID: -1,
            AMID: activeL4PortfolioSummaryTotal?.AMID || -1,
            CustomAssetAllocationCategID: selectedMapping.categoryId,
            CustomAssetAllocationSubCategID: selectedMapping.subCategoryId
          }]
        }
      }));
      setIsEditCategorizationModalOpen(false);
      setSelectedMapping({});
    }
  };

  return (
    <>
      <Grid container padding="16px 24px" spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <AssetIcon />
            <CustomTypography sx={{ paddingLeft: '16px' }} variant="Regular24">About</CustomTypography>
          </Box>
          {
            showOpenInScreenerInButton &&
            <Box
              sx={{
                paddingLeft: '16px', paddingRight: '16px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '50px', border: '1px solid #EBECF2', backgroundColor: '#F9F9F9',
                flexDirection: 'row', justifyContent: 'space-between', display: 'flex', alignItems: 'center', gap: '8px',
                ":hover": {
                  backgroundColor: '#EBECF2',
                  cursor: 'pointer'
                }
              }}
              onClick={
                () => {
                  var screenerInURL = 'https://screener.in/company/';
                  if (stocksInfo.BSECode && stocksInfo.BSECode > 0) {
                    screenerInURL += `bse:${encodeURIComponent(stocksInfo.BSECode)}/`;
                  } else {
                    screenerInURL += `nse:${encodeURIComponent(stocksInfo.NSESymbol)}/`;
                  }
                  window.open(screenerInURL, '_blank');
                }
              }
            >
              <PopOutIcon />
              <ScreenerInIcon />
            </Box>
          }
        </Grid>
        <Grid item xs={12}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', background: '#f9f9fb', borderRadius: '16px', padding: '25px 16px 24px 24px', alignItems: 'center' }}>
            <Box>
              <CustomTypography variant="Regular16">{stocksInfo?.NSESymbol || stocksInfo?.BSESymbol ? 'Symbol' : 'Code'}</CustomTypography>
              <CustomTypography variant="Regular28">{stocksInfo?.NSESymbol || stocksInfo?.BSESymbol || stocksInfo?.BSECode}</CustomTypography>
            </Box>
            {
              stocksInfo &&
              <Box sx={{ display: 'flex', alignItems: 'center', background: SharedUtilities.getMarketCapColorFromID(stocksInfo.MarketCapClassification), borderRadius: '32px', padding: '2px 12px' }}>
                {marketCapIcon[stocksInfo.MarketCapClassification as SharedConstants.MarketCap_Type]}
                <CustomTypography variant='Regular14' sx={{ marginLeft: '8px' }}>{SharedUtilities.getMarketCapNameFromID(stocksInfo.MarketCapClassification)}</CustomTypography>
              </Box>
            }
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', }}>
            <SectorIcon />
            <CustomTypography sx={{ color: { xs: "#64677A" }, paddingLeft: '8px' }} variant='Regular18'>Sector</CustomTypography>
          </Box>
          <CustomTypography variant='Regular20'>{stocksInfo?.SectorName || 'Other'}</CustomTypography>
        </Grid>
        {
          stocksInfo?.ISIN &&
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <CustomTypography sx={{ color: { xs: "#64677A" } }} variant='Regular18'>ISIN</CustomTypography>
              <CustomTypography variant='Regular20'>{stocksInfo.ISIN}</CustomTypography>
            </Grid>
          </>
        }
        {
          !isUsingDefaultCategorization ? 
          <>
             <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AssetAllocationIcon />
              <CustomTypography sx={{ color: { xs: "#64677A" }, paddingLeft: '8px' }} variant='Regular18'>Asset Allocation</CustomTypography>
            </Box>
            {
              showCustomCategEditButton &&
              <TooltipDark title="Edit categorisation" placement="left">
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', ":hover": { backgroundColor: "#D8DAE5", borderRadius: "5px" } }} onClick={() => setIsEditCategorizationModalOpen(true)}>
                  <EditIcon width={26} height={26} />
                </Box>
              </TooltipDark>
            }
          </Box>
          <Box sx={{ padding: '12px', background: '#FFFFFF', border: "1px solid #EBECF2", borderRadius: '12px', margin: "15px 0 0 0" }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
              <Box sx={{ flex: 1 }}>
                <CategoryTab label="Category" value={stocksInfo?.AssetAllocationCategName || '-'} />
              </Box>
              <Box sx={{ flex: 1, background: "#F8F9FA", borderRadius: "8px" }}>
                <CategoryTab label="Sub-Category" value={stocksInfo?.AssetAllocationSubCategName || '-'} />
              </Box>
            </Box>
          </Box>
        </Grid>
          </> : null
        }
      </Grid>
      <EditCategorizationModal
        isOpen={isEditCategorizationModalOpen}
        onClose={() => setIsEditCategorizationModalOpen(false)}
        assetTypeID={assetTypeID}
        assetAllocationCategID={stocksInfo?.AssetAllocationCategID}
        assetAllocationCategName={stocksInfo?.AssetAllocationCategName}
        companyName={stocksInfo?.CompanyName}
        assetAllocationSubCategName={stocksInfo?.AssetAllocationSubCategName}
        selectedMapping={selectedMapping}
        setSelectedMapping={setSelectedMapping}
        handleSave={handleSave}
        amid={activeL4PortfolioSummaryTotal?.AMID || -1}
      />
    </>
  )
}
