import { call, put, takeLatest } from "redux-saga/effects";
import { API_URL, MProfitAPI, ResponseGenerator, SetAPIToken } from "../../api";
import { LoginPayload, Login_Status, User } from "../../constants";
import { Actions } from "../modules/loginReducer";
import type { PayloadAction } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { getAccessRightsDict } from "../../utilities";
const qs = require('qs');

const loginAPI = (loginPayload: LoginPayload) => {
  // return MProfitAPI.post('/authn', loginPayload);

  return MProfitAPI.post('/authn',
    qs.stringify({
      username: loginPayload.email.trim(),
      password: loginPayload.password,
      grant_type: 'password',
      forceLogin: '1',
      isOTP: loginPayload.isOTP ? '1' : undefined,
      mfaOTP: loginPayload.mfaOTP || undefined,
      mfaToken: loginPayload.mfaOTP === undefined && loginPayload.mfaToken ? loginPayload.mfaToken : undefined,
      rememberMFADevice: loginPayload.mfaOTP !== undefined && loginPayload.rememberMFADevice ? '1' : undefined
    }), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

const viewAccessRightsAPI = () => {
  return MProfitAPI.get('/api/AccessManagement/user/viewAccessRights');
}

function* onTryLogin(action: PayloadAction<LoginPayload>) {
  const loginResponse: ResponseGenerator = yield call(loginAPI, action.payload);

  if (loginResponse.status === HttpStatusCode.Ok) {
    yield put(Actions.loginSuccess({ ...loginResponse.data, email: action.payload.email }));
  } else {
    if (loginResponse.status === HttpStatusCode.BadRequest) {
      console.log('loginResponse', loginResponse);
      if (loginResponse.data?.error) {
        switch (loginResponse.data?.error) {
          case 'passwordreset':
            yield put(Actions.setLoginState({
              LoginStatus: Login_Status.ENTER_RESET_PASSWORD_CODE,
              IsError: false,
              Message: 'MProfit requests you to periodically change your password. We have sent a password reset email to your registered MProfit email address. Please check your email.'
            }));
            break;
          case 'alreadyLoggedIn':
            yield put(Actions.setLoginState({
              LoginStatus: Login_Status.ALREADY_LOGGED_IN,
              IsError: false,
              Message: 'It appears that your last MProfit session is still active or you may have closed the browser recently without logging out. Logging in will log out your other session. To continue, click "Okay" below.'
            }));
            break;
          case 'resetcoderesent':
            yield put(Actions.setLoginState({
              LoginStatus: Login_Status.ENTER_RESET_PASSWORD_CODE,
              IsError: false,
              Message: 'Your temporary password has expired so we have sent you a new one on your email. Please check your email and try again.'
            }));
            break;
          case 'mfaotpsent':
            yield put(Actions.setLoginState({
              LoginStatus: Login_Status.ENTER_MFA_OTP,
              IsError: false,
              Message: 'Your 2-factor authentication OTP has been sent to your email.'
            }));
            break;
          case 'mfaotpexpired':
            yield put(Actions.setLoginState({
              LoginStatus: Login_Status.MFA_OTP_EXPIRED_USED,
              IsError: true,
              Message: 'Your 2FA OTP has expired. Please request a new one.'
            }));
            break;
          case 'mfaotpused':
            yield put(Actions.setLoginState({
              LoginStatus: Login_Status.MFA_OTP_EXPIRED_USED,
              IsError: true,
              Message: 'Your 2FA OTP has already been used. Please request a new one.'
            }));
            break;
          case 'mfaotpinvalid':
            yield put(Actions.setLoginState({
              LoginStatus: Login_Status.MFA_OTP_INVALID,
              IsError: true,
              Message: 'Incorrect 2FA OTP'
            }));
            break;
          default:
            yield put(Actions.setLoginState({
              LoginStatus: Login_Status.INCORRECT_PASSWORD,
              IsError: true,
              Message: 'Invalid password'
            }));
            break;
        }
      }
    } else {
      yield put(Actions.setLoginState({
        LoginStatus: Login_Status.SERVER_ERROR,
        IsError: true,
        Message: 'Unable to connect to server. Please check network connection'
      }));
    }
  }
}

function* onLoginSuccess(action: PayloadAction<User>) {
  if (action.payload) {
    SetAPIToken(action.payload.access_token);
  }
}

const logoutAPI = () => {
  return MProfitAPI.post('/api/Logout', {});
}

function* onLogout(action: PayloadAction<{ IsUserAction: boolean }>) {
  if (action.payload.IsUserAction === true) {
    yield call(logoutAPI);
  }
}

const licenseInfoAPI = () => {
  return MProfitAPI.get('/api/Admin/getLicenseInformation');
}

function* refreshLicenseInfo() {
  const response: ResponseGenerator = yield call(licenseInfoAPI);

  if (response.status === HttpStatusCode.Ok) {
    yield put(Actions.setLicenseInfo(response.data));
  }
}

function* getAccessRights() {
  const response: ResponseGenerator = yield call(viewAccessRightsAPI);
  if (response.status === HttpStatusCode.Ok) {
    const accessRights = {
      ...response.data,
      GlobalAccessRights: getAccessRightsDict(response.data.GlobalAccessRights)
    };
    console.log("🚀 ~ function*getAccessRights ~ accessRights:", accessRights)
    yield put(Actions.setAccessRights(accessRights));
  }
}

function* loginSaga() {
  yield takeLatest(Actions.loginClicked.type, onTryLogin);
  yield takeLatest(Actions.loginSuccess.type, onLoginSuccess);
  yield takeLatest(Actions.logout.type, onLogout);
  yield takeLatest(Actions.refreshLicenseInfo.type, refreshLicenseInfo);
  yield takeLatest(Actions.getAccessRights.type, getAccessRights);
}
export default loginSaga;



