import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities, formatDateForReading } from "@mprofit/shared";
import HoldingLayout from "../layout";
import { HoldingCurrentValue } from "../holdingCurrentValue";
import { HoldingAmountInvested } from "../holdingAmountInvested";
import { HoldingUnrealisedGain } from "../holdingUnrealisedGain";
import SharedVirtualizedTable, { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import { useHoldingsMode } from "packages/web/src/classes/HoldingsModeHook";
import { HoldingAssetAllocationChart } from "../holdingAssetAllocationChart";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigateToAsset, NavigateToAssetType } from "packages/web/src/routes/navigationMethods";
import ExpandableVirtualizedTable from "../../../shared/expandable-table";

interface L1_AA_Subcategory_Holding_Props {
  MPrUrlParams: MPrUrlParams;
}

export default function L1_Custom_AA_Subcategory_Holding(props: L1_AA_Subcategory_Holding_Props) {
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);

  return <HoldingLayout
    {...props}
    Screen_CurrentValueCard={CurrentValueCard}
    Screen_AmountInvestedCard={AmountInvestedCard}
    Screen_UnrealisedGainCard={UnrealisedGainCard}
    Screen_AssetAllocationChart={AssetAllocationChart}
    Screen_Table={HoldingsTable}
    isLoading={isLoading}
    tableHeading={`Current Holdings`}
  />;
}

const CurrentValueCard = () => {
  const currentValue = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryPortfolioSummaryTotalCurrentValue);

  return <HoldingCurrentValue
      CurrentValue={currentValue}
    />
}

const AmountInvestedCard = () => {
  const amountInvested = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryPortfolioSummaryTotalAmountInvested);

  return <HoldingAmountInvested
      AmountInvested={amountInvested}
    />
}

const UnrealisedGainCard = () => {
  const totalValues = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryPortfolioSummaryTotal);

  return <HoldingUnrealisedGain
    OGain={totalValues?.OGain}
    OGainPct={totalValues?.OGainPct}
    OUp={totalValues?.OUp}
  />
}

const AssetAllocationChart = () => {
  const [totalField, setTotalField] = useState<SharedConstants.HoldingAssetAllocationDropdownValue>('CurrValue');

  const {PieChartData, TotalValue} = useAppSelector((state) => SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryPieChartData_ByAsset(state, totalField));

//   const categName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveCustomAssetAllocationCategName);
  const subCategName = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryPortfolioSummarySubCategName);

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const onItemClick = (item: SharedConstants.PieChartData) => {
    if (item && item.IDs.AssetTypeID > 0 && item.IDs.AMID > 0) {
      NavigateToAsset({navigate, activeScreenIDs, assetTypeID: item.IDs.AssetTypeID, AMID: item.IDs.AMID, pathname: location.pathname });
    }
  }

  const holdingTotals = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryPortfolioSummaryTotal);
  const tableData: RowDataProps[] = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryHoldingTable_ByAsset) as RowDataProps[];

  return <HoldingAssetAllocationChart
    data={PieChartData}
    totalValue={TotalValue}
    dropdownValue={totalField}
    holdingsMode={SharedConstants.HoldingsMode.Assets}
    heading={`${subCategName} Asset Allocation`}
    subHeading={`as on ${formatDateForReading(new Date())}`}
    handleDropdownChange={(selected) => {setTotalField(selected.id as SharedConstants.HoldingAssetAllocationDropdownValue)}}
    onItemClick={onItemClick}
    showDownloadPdfIcon={true}
    pdfData={{
      Totals: holdingTotals,
      Table:{
        columnData: tableColumns_byAssetType,
        rowData: tableData,
        chartHeading: 'Current Holdings'
      }
    }}
  />
}

const HoldingsTable = ({searchTerm}: {searchTerm: string}) => {
  const tableData = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryHoldingExpandableTable_ByAssetType);

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const onSecondaryRowClick = (row: RowDataProps) => {
    if (row && row.AssetTypeID > 0 && row.AMID > 0) {
      NavigateToAsset({navigate, activeScreenIDs, assetTypeID: row.AssetTypeID, AMID: row.AMID, pathname: location.pathname });
    }
  }

  var fieldNamesToSearch = ['Name'];

  return <ExpandableVirtualizedTable columns={tableColumns_byAssetType} rows={tableData || []} headerColor="#EAF4DF" sorting={true} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch} defaultSortConfig={SharedConstants.DefaultSortingForTodayTable} primaryRowId="AssetTypeID" secondaryRowId="SID" isMarginCellRequired={false} tableHeight="400px" onSecondaryRowClick={onSecondaryRowClick} expandAllByDefault={true} tableType={SharedConstants.TableType.CustomAASubCategoryHoldings} />

}

const commonTableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'InvAmt',
    HeaderName: 'AMOUNT INVESTED',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'OGain',
    HeaderName: 'UNREALISED GAIN',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'OGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'OUp'
      }
    ]
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'HoldingPct',
    HeaderName: 'HOLDING',
    ColumnWidthPercent: '25%',
    FieldFormatter: TablePercentFieldFormatter,
    Align: 'right',
  }
];

const tableColumns_byAssetType: ColumnDataProps[] = [
  {
    FieldKey: 'Name',
    HeaderName: 'ASSET',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDefaultFieldFormatter,
    Align: 'left',
    IsRowName: true
  },
  ...commonTableColumns
]
