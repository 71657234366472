import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import CustomTypography from '../../customTypography';
import { ArrowDown } from 'packages/web/src/assets/icons/ArrowDown';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ControlledMonth, SetMonthProps } from '../month';
import { ControlledYear, SetYearProps } from '../year';
import { SharedUtilities } from '@mprofit/shared';

interface ICustomDAteProps {
    setStartDate?: (value: React.SetStateAction<Date | undefined>) => void;
    setEndDate?: (value: React.SetStateAction<Date | undefined>) => void;
    isAllToDate?: boolean;
    startDate: Date | null;
    endDate: Date | null;
}

enum CustomDateMode {
    MonthSelect = 1,
    YearSelect = 2,
    DateSelect = 3
}

export default function CustomDate({ setStartDate, setEndDate, isAllToDate = false, startDate, endDate }: ICustomDAteProps) {
    const handleChange = (val: any) => {
        console.log('handleChangeDate',val)
        let startDate = val[0];
        let EndDate = val[1];

        if (setStartDate) setStartDate(startDate);
        if (setEndDate) setEndDate(EndDate);

          // Update selectedYear and selectedMonth based on the new start date
          if (startDate) {
            const newYear = startDate.getFullYear();
            const newMonth = startDate.getMonth() + 1;
            setSelectedYearNumber(newYear);
            setSelectedMonthNumber(newMonth);
            onMonthChange(newMonth, newYear);
            onYearChange(newYear);
        }
    }

    const now = new Date();

    const currentYearNumber = now.getFullYear();
    const currentMonth = now.getMonth() + 1;

    const [selectedYearNumber, setSelectedYearNumber] = useState<number>(currentYearNumber);
    const [selectedMonthNumber, setSelectedMonthNumber] = useState<number>(currentMonth);

    const [mode, setMode] = useState<CustomDateMode>(CustomDateMode.DateSelect);

    const [selectedMonth, setSelectedMonth] = useState<SetMonthProps | undefined>(undefined);

    const [selectedYear, setSelectedYear] = useState<SetYearProps | undefined>(undefined);

    const [activeStartDate, setActiveStartDate] = useState<Date | null>(null);

    //#region Month Select

    const onSelectMonthNumber = (monthNumber: number) => {
        setSelectedMonthNumber(monthNumber);

        onMonthChange(monthNumber, selectedYearNumber);
        
        setActiveStartDate(new Date(selectedYearNumber, monthNumber - 1, 1));
    }

    // const onSelectYearNumber = (yearNumber: number) => {
    //     setSelectedYearNumber(yearNumber);

    //     onMonthChange(selectedMonthNumber, yearNumber);
    // }

    const onMonthChange = (monthNumber: number, yearNumber: number, isMount: boolean = false) => {
        var newSelectedMonth = SharedUtilities.getMonthSelectedItem(monthNumber, yearNumber);

        if (newSelectedMonth) {
            setSelectedMonth(newSelectedMonth);

            if (!isMount) {
                setMode(CustomDateMode.DateSelect);
            }
        }
    }

    useEffect(() => {
        onMonthChange(selectedMonthNumber, selectedYearNumber, true);
    }, []);

    //#endregion

    //#region Year Select

    const yearsList = useMemo(SharedUtilities.getYearListForPeriodSelection, []);

    const onSelectYearNumber = (yearNumber: number) => {
        setSelectedYearNumber(yearNumber);

        onYearChange(yearNumber);
        
        setActiveStartDate(new Date(yearNumber, selectedMonthNumber - 1, 1));
    }

    const onYearChange = (yearNumber: number, isMount: boolean = false) => {
        var newSelectedYear = SharedUtilities.getYearSelectedItem(yearsList, yearNumber);

        if (newSelectedYear) {
            setSelectedYear(newSelectedYear);

            if (!isMount) {
                setMode(CustomDateMode.DateSelect);
            }
        }
    }

    useEffect(() => {
        onYearChange(selectedYearNumber, true);
    }, []);

    //#endregion

    // const activeStartDate = selectedMonth && selectedYear && new Date(selectedYear.yearNumber, selectedMonth.monthNumber - 1, 1);

    useEffect(() => {
        if (endDate) {
            setActiveStartDate(new Date(endDate.getFullYear(), endDate.getMonth(), 1));
            setSelectedYearNumber(endDate.getFullYear());
            setSelectedMonthNumber(endDate.getMonth() + 1);
            onMonthChange(endDate.getMonth() + 1, endDate.getFullYear());
            onYearChange(endDate.getFullYear());
        } else if (startDate) {
            setActiveStartDate(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
            setSelectedYearNumber(startDate.getFullYear());
            setSelectedMonthNumber(startDate.getMonth() + 1);
            onMonthChange(startDate.getMonth() + 1, startDate.getFullYear());
            onYearChange(startDate.getFullYear());
        }
    }, [startDate, endDate]);

    const onActiveStartDateChange = (newActiveStartDate: Date | null) => {
        if (newActiveStartDate) {
            setActiveStartDate(newActiveStartDate);
            const newMonth = newActiveStartDate.getMonth() + 1;
            const newYear = newActiveStartDate.getFullYear();
            
            setSelectedMonthNumber(newMonth);
            setSelectedYearNumber(newYear);
            onMonthChange(newMonth, newYear);
            onYearChange(newYear);
        }
    };

    console.log('activeStartDate1',activeStartDate);
    console.log('startDateEndDate',startDate,endDate);

    return (
        <>
            {
                mode == CustomDateMode.DateSelect &&
                <Box className="year-month __Container" sx={{ display: 'flex', position: 'absolute', zIndex: 2, }}>
                    <Box className="month__buttons-container" sx={{ display: 'flex', alignItems: 'center', cursor: isAllToDate ? 'default' : 'pointer' }} onClick={() => {if(!isAllToDate) setMode(CustomDateMode.MonthSelect)}}>
                        <CustomTypography variant="Regular20">{selectedMonth?.monthName}</CustomTypography>
                        {
                            !isAllToDate &&
                            <Box sx={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginleft: "8px", ":hover": isAllToDate ? {} : { background: '#F9F9F9' } }}>
                                <ArrowDown />
                            </Box>
                        }
                    </Box>
                    <Box className="year__buttons-container" sx={{ display: 'flex', alignItems: 'center', marginLeft: '16px', cursor: isAllToDate ? 'default' : 'pointer' }} onClick={() => {if(!isAllToDate) setMode(CustomDateMode.YearSelect)}}>
                        <CustomTypography variant="Regular20">{selectedYear?.title}</CustomTypography>
                        {
                            !isAllToDate &&
                            <Box sx={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginleft: "8px", ":hover": isAllToDate ? {} : { background: '#F9F9F9' } }}>
                                <ArrowDown />
                            </Box>
                        }
                    </Box>
                </Box>
            }
            {
                mode == CustomDateMode.MonthSelect &&
                <ControlledMonth
                    selectedMonthNumber={selectedMonthNumber}
                    selectedYearNumber={selectedYearNumber}
                    onSelectMonthNumber={onSelectMonthNumber}
                    // onSelectYearNumber={onSelectYearNumber}
                    onMonthChange={onMonthChange}
                    isOnlyMonthSelection={true}
                />
                // <Month setSelectedMonth={setSelectedMonth} isOnlyMonthSelection={true} startMonthNumber={selectedMonth?.monthNumber} startYearNumber={selectedYear?.yearNumber} setMonthSelectClosed={setDateSelectMode}/>
            }
            {
                mode == CustomDateMode.YearSelect &&
                <ControlledYear
                    onSelectYear={onSelectYearNumber}
                    selectedYearNumber={selectedYearNumber}
                    yearsList={yearsList}
                />
            }
            <Box className="date-range-wrapper" sx={{ position: 'relative', display: mode == CustomDateMode.DateSelect ? undefined : 'none' }}>
                <DateRangePicker
                    onChange={handleChange}
                    isOpen={true}
                    clearIcon={null}
                    activeStartDate={activeStartDate ?? new Date()}
                    onActiveStartDateChange={({ action, activeStartDate, value, view }) => onActiveStartDateChange(activeStartDate)}
                    allowPartialRange={true}
                    goToRangeStartOnSelect={false}                               
                    value={[startDate,endDate]}
                    disabled={isAllToDate}
                    className={isAllToDate ? 'disabled-date-range-picker' : undefined}
                    showNavigation={!isAllToDate}
                    maxDate={new Date()}
                />
            </Box>
        </>
    )
}