import { styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';
import { SharedConstants, SharedRedux } from '@mprofit/shared';
import { NavigateToAsset, NavigateToAssetAllocationCateg, NavigateToAssetType, NavigateToCustomAASubcategory, NavigateToCustomAssetAllocationCateg, NavigateToL3, NavigateToL3Category, NavigateToPortfolio, NavigateToAssetCategorization } from 'packages/web/src/routes/navigationMethods';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'packages/web/src/assets/icons/arrowLeft';
import routeNames from 'packages/web/src/routes/routeNames';

interface BreadCrumbComponentProps {
    rightItems?: React.ReactNode;
}

export default function BreadCrumbComponent({ rightItems }: BreadCrumbComponentProps = {}) {
    const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

    const activePortfolioIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolioIDs);
    const refNo = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4Refno);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    if (!activeScreenIDs || !activeScreenIDs.Screen || activeScreenIDs.Screen <= SharedConstants.ScreenEnum.L1) {
        return <></>;
    }

    const activeScreen = activeScreenIDs?.Screen || SharedConstants.ScreenEnum.UNDEEFINED;
    const isSummaryScreen = location.pathname.includes('/summary')

    const onBack = () => {
        switch (activeScreen) {
            case SharedConstants.ScreenEnum.L1_AA:
                onNavigateToLevel(SharedConstants.ScreenEnum.L1);
                break;
            case SharedConstants.ScreenEnum.ManageAssetCategorization:
                onNavigateToLevel(SharedConstants.ScreenEnum.ManageAssetCategorization);
                break;
            case SharedConstants.ScreenEnum.L2:
                if (activeScreenIDs.AssetAllocationCategID && activeScreenIDs.AssetAllocationCategID > 0) {
                    onNavigateToLevel(SharedConstants.ScreenEnum.L1_AA);
                } else {
                    onNavigateToLevel(SharedConstants.ScreenEnum.L1);
                }
                break;
            case SharedConstants.ScreenEnum.L2_AllStocks:
                onNavigateToLevel(SharedConstants.ScreenEnum.L1);
                break;
            case SharedConstants.ScreenEnum.L2_AllStocks_AA:
                onNavigateToLevel(SharedConstants.ScreenEnum.L2_AllStocks);
                break;
            case SharedConstants.ScreenEnum.L3_Category:
                onNavigateToLevel(SharedConstants.ScreenEnum.L2);
                break;
            case SharedConstants.ScreenEnum.L3:
                if (activeScreenIDs.SEBICategoryID && activeScreenIDs.SEBICategoryID > 0) {
                    onNavigateToLevel(SharedConstants.ScreenEnum.L3_Category);
                } else {
                    onNavigateToLevel(SharedConstants.ScreenEnum.L2);
                }
                break;
            case SharedConstants.ScreenEnum.L4:
                switch (holdingsModeL3forL4) {
                    case SharedConstants.HoldingsMode.Undefined:
                        if (isSummaryScreen) {
                            navigate(-1);
                        } else if (activeScreenIDs.CustomAssetAllocationCategID && activeScreenIDs.CustomAssetAllocationSubCategID) {
                            onNavigateToLevel(SharedConstants.ScreenEnum.L1_Custom_AA_Subcategory);
                        } else {
                            onNavigateToLevel(SharedConstants.ScreenEnum.L2);
                        }
                        break;
                    case SharedConstants.HoldingsMode.MFSEBICategory:
                        onNavigateToLevel(SharedConstants.ScreenEnum.L3_Category);
                        break;
                    default:
                        onNavigateToLevel(SharedConstants.ScreenEnum.L3);
                        break;
                }
                break;
            case SharedConstants.ScreenEnum.L4_RefNo:
                onNavigateToLevel(SharedConstants.ScreenEnum.L4);
                break;
            case SharedConstants.ScreenEnum.L1_Custom_AA:
                onNavigateToLevel(SharedConstants.ScreenEnum.L1);
                break;
            case SharedConstants.ScreenEnum.L1_Custom_AA_Subcategory:
                onNavigateToLevel(SharedConstants.ScreenEnum.L1_Custom_AA);
                break;
        }
    }

    const onNavigateToLevel = (screen: SharedConstants.ScreenEnum) => {
        if (isSummaryScreen) {
            navigate({ pathname: routeNames.SUMMARY, search: location.search })
            return;
        }

        switch (screen) {
            case SharedConstants.ScreenEnum.L1:
                if (activePortfolioIDs) {
                    NavigateToPortfolio({ navigate, activePortfolio: activePortfolioIDs, pathname: location.pathname, screen: SharedConstants.ScreenEnum.L1 });
                }
                break;
            case SharedConstants.ScreenEnum.L1_AA:
                if (activeScreenIDs && activeScreenIDs.AssetAllocationCategID) {
                    NavigateToAssetAllocationCateg({ navigate, activeScreenIDs, assetAllocationCategID: activeScreenIDs.AssetAllocationCategID, pathname: location.pathname });
                }
                break;
            case SharedConstants.ScreenEnum.L1_Custom_AA:
                if (activeScreenIDs && activeScreenIDs.CustomAssetAllocationCategID) {
                    NavigateToCustomAssetAllocationCateg({ navigate, activeScreenIDs, customAssetAllocationCategID: activeScreenIDs.CustomAssetAllocationCategID, pathname: location.pathname });
                }
                break;
            case SharedConstants.ScreenEnum.L1_Custom_AA_Subcategory:
                if (activeScreenIDs && activeScreenIDs.CustomAssetAllocationSubCategID && activeScreenIDs.AssetType) {
                    NavigateToCustomAASubcategory({ navigate, activeScreenIDs, assetTypeID: activeScreenIDs.AssetType, customAssetAllocationSubCategID: activeScreenIDs.CustomAssetAllocationSubCategID, pathname: location.pathname });
                }
                break;
            case SharedConstants.ScreenEnum.ManageAssetCategorization:
                NavigateToAssetCategorization({ navigate, activeScreenIDs, pathname: routeNames.ASSET_CATEGORIZATION, activeScreen: SharedConstants.ScreenEnum.AssetCategorization });
                break;
            case SharedConstants.ScreenEnum.L2:
                if (activeScreenIDs && activeScreenIDs.AssetType) {
                    NavigateToAssetType({ navigate, activeScreenIDs, assetTypeID: activeScreenIDs.AssetType, pathname: location.pathname });
                }
                break;
            case SharedConstants.ScreenEnum.L2_AllStocks:
                if (activePortfolioIDs) {
                    NavigateToPortfolio({
                        navigate,
                        activePortfolio: activePortfolioIDs,
                        pathname: location.pathname,
                        screen: SharedConstants.ScreenEnum.L2_AllStocks
                    });
                }
                break;
            case SharedConstants.ScreenEnum.L3:
                if (activeScreenIDs && activeScreenIDs.AssetType && activeScreenIDs.SectorID) {
                    NavigateToL3({ navigate, activeScreenIDs, assetTypeID: activeScreenIDs.AssetType, SectorID: activeScreenIDs.SectorID, L3Mode: SharedConstants.HoldingsMode.Sectors, pathname: location.pathname })
                } else if (activeScreenIDs && activeScreenIDs.AssetType && activeScreenIDs.MarketCapID) {
                    NavigateToL3({ navigate, activeScreenIDs, assetTypeID: activeScreenIDs.AssetType, MarketCapID: activeScreenIDs.MarketCapID, L3Mode: SharedConstants.HoldingsMode.MarketCap, pathname: location.pathname })
                } else if (activeScreenIDs && activeScreenIDs.AssetType && activeScreenIDs.SEBISubCategoryID) {
                    NavigateToL3({ navigate, activeScreenIDs, assetTypeID: activeScreenIDs.AssetType, SEBICategoryID: activeScreenIDs.SEBICategoryID, SEBISubCategoryID: activeScreenIDs.SEBISubCategoryID, L3Mode: SharedConstants.HoldingsMode.MFSEBISubCategory, pathname: location.pathname })
                }
                break;
            case SharedConstants.ScreenEnum.L3_Category:
                if (activeScreenIDs && activeScreenIDs.AssetType && activeScreenIDs.SEBICategoryID) {
                    NavigateToL3Category({ navigate, activeScreenIDs, assetTypeID: activeScreenIDs.AssetType, SEBICategoryID: activeScreenIDs.SEBICategoryID, L3Mode: SharedConstants.HoldingsMode.MFSEBICategory, pathname: location.pathname })
                }
                break;
            case SharedConstants.ScreenEnum.L4:
                if (activeScreenIDs && activeScreenIDs.AssetType && activeScreenIDs.AMID) {
                    NavigateToAsset({ navigate, activeScreenIDs, assetTypeID: activeScreenIDs.AssetType, AMID: activeScreenIDs.AMID, pathname: location.pathname })
                }
                break;
        }
    }

    var holdingsModeL3forL4: SharedConstants.HoldingsMode = SharedConstants.HoldingsMode.Undefined;
    if (activeScreen == SharedConstants.ScreenEnum.L4) {
        if (activeScreenIDs.SectorID && activeScreenIDs.SectorID > 0) {
            holdingsModeL3forL4 = SharedConstants.HoldingsMode.Sectors;
        } else if (activeScreenIDs.MarketCapID && activeScreenIDs.MarketCapID > 0) {
            holdingsModeL3forL4 = SharedConstants.HoldingsMode.MarketCap;
        } else if (activeScreenIDs.SEBISubCategoryID && activeScreenIDs.SEBISubCategoryID > 0) {
            holdingsModeL3forL4 = SharedConstants.HoldingsMode.MFSEBISubCategory;
        } else if (activeScreenIDs.SEBICategoryID && activeScreenIDs.SEBICategoryID > 0) {
            holdingsModeL3forL4 = SharedConstants.HoldingsMode.MFSEBICategory;
        }
    }
    const getMarginBottom = () => {
        return (activeScreen === SharedConstants.ScreenEnum.L2_AllStocks_AA || activeScreen === SharedConstants.ScreenEnum.L2_AllStocks) ? 0 : '31px';
    };

    return (
        <Box role="presentation"
            sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: getMarginBottom(),
                justifyContent: 'space-between',
                width: '100%'
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowLeft onClick={onBack} style={{ cursor: 'pointer' }} />
                <Breadcrumbs aria-label="breadcrumb">
                    {activeScreenIDs.Screen === SharedConstants.ScreenEnum.ManageAssetCategorization ?
                        <ManageCategBreadcrumb activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.ManageAssetCategorization)} isCurrentScreen={false} />
                        :
                        <L1Breadcrumb activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L1)} isCurrentScreen={false} />

                    }
                    {
                        activeScreen >= SharedConstants.ScreenEnum.L1_AA && activeScreenIDs.AssetAllocationCategID && activeScreenIDs.AssetAllocationCategID > 0 &&
                        <L1AABreadcrumb activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L1_AA)} isCurrentScreen={activeScreen == SharedConstants.ScreenEnum.L1_AA} />
                    }
                    {
                        activeScreen >= SharedConstants.ScreenEnum.L1_Custom_AA && activeScreenIDs.CustomAssetAllocationCategID && activeScreenIDs.CustomAssetAllocationCategID > 0 &&
                        <L1CustomAABreadcrumb activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L1_Custom_AA)} isCurrentScreen={activeScreen == SharedConstants.ScreenEnum.L1_Custom_AA} />
                    }
                    {
                        activeScreen >= SharedConstants.ScreenEnum.L1_Custom_AA_Subcategory && activeScreenIDs.CustomAssetAllocationSubCategID && activeScreenIDs.CustomAssetAllocationSubCategID > 0 &&
                        <L1CustomAASubcategoryBreadcrumb activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L1_Custom_AA_Subcategory)} isCurrentScreen={activeScreen == SharedConstants.ScreenEnum.L1_Custom_AA_Subcategory} />
                    }
                    {
                        activeScreen > SharedConstants.ScreenEnum.L1_AA &&
                        activeScreen !== SharedConstants.ScreenEnum.L1_Custom_AA &&
                        activeScreen !== SharedConstants.ScreenEnum.L1_Custom_AA_Subcategory &&
                        // don't need to show L2Breadcrumb on L4 screen when custom AA data is present
                        !((activeScreen === SharedConstants.ScreenEnum.L4 || activeScreen === SharedConstants.ScreenEnum.L4_RefNo) &&
                            activeScreenIDs.CustomAssetAllocationCategID &&
                            activeScreenIDs.CustomAssetAllocationSubCategID) &&
                        (
                            activeScreen === SharedConstants.ScreenEnum.L2_AllStocks || activeScreen === SharedConstants.ScreenEnum.L2_AllStocks_AA ? (
                                <L2AllStocksBreadcrumb
                                    activeScreenIDs={activeScreenIDs}
                                    isCurrentScreen={activeScreen === SharedConstants.ScreenEnum.L2_AllStocks}
                                    onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L2_AllStocks)}
                                />
                            ) : (
                                <L2Breadcrumb
                                    activeScreenIDs={activeScreenIDs}
                                    onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L2)}
                                    isCurrentScreen={activeScreen === SharedConstants.ScreenEnum.L2}
                                />
                            )
                        )
                    }
                    {
                        activeScreen === SharedConstants.ScreenEnum.L2_AllStocks_AA ?
                            <L2AllStocksAABreadcrumb activeScreenIDs={activeScreenIDs} onClick={() => { }} isCurrentScreen={activeScreen === SharedConstants.ScreenEnum.L2_AllStocks_AA} /> : null
                    }
                    {
                        activeScreen === SharedConstants.ScreenEnum.L3 &&
                        <L3BreadcrumbForL3 activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L3)} isCurrentScreen={true} />
                    }
                    {
                        activeScreen === SharedConstants.ScreenEnum.L3_Category &&
                        <L3CategoryBreadcrumbForL3Category activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L3_Category)} isCurrentScreen={true} />
                    }
                    {
                        activeScreen >= SharedConstants.ScreenEnum.L4 && holdingsModeL3forL4 == SharedConstants.HoldingsMode.Sectors &&
                        <L3BreadcrumbForL4StocksSector activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L3)} isCurrentScreen={false} />
                    }
                    {
                        activeScreen >= SharedConstants.ScreenEnum.L4 && holdingsModeL3forL4 == SharedConstants.HoldingsMode.MarketCap &&
                        <L3BreadcrumbForL4StocksMarketCap activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L3)} isCurrentScreen={false} />
                    }
                    {
                        activeScreen >= SharedConstants.ScreenEnum.L4 &&
                        <L4Breadcrumb activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L4)} isCurrentScreen={activeScreen == SharedConstants.ScreenEnum.L4 || (activeScreen == SharedConstants.ScreenEnum.L4_RefNo && isSummaryScreen)} />
                    }
                    {
                        activeScreen >= SharedConstants.ScreenEnum.L4_RefNo && !isSummaryScreen && refNo &&
                        <L4RefnoBreadcrumb activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.L4_RefNo)} isCurrentScreen={activeScreen == SharedConstants.ScreenEnum.L4_RefNo} />
                    }
                    {
                        activeScreen === SharedConstants.ScreenEnum.ManageAssetCategorization &&
                        <ManageCategBreadcrumb activeScreenIDs={activeScreenIDs} onClick={() => onNavigateToLevel(SharedConstants.ScreenEnum.AssetCategorization)} isCurrentScreen={true} />
                    }
                </Breadcrumbs>
            </Box>

            {rightItems || <></>}
        </Box>
    )
}

const L1Breadcrumb = ({ onClick, isCurrentScreen }: LXBreadcrumbProps) => {
    const portfolioName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolioShortName);

    if (portfolioName) {
        return <StyledChip
            label={portfolioName}
            onClick={isCurrentScreen ? undefined : onClick}
            isCurrentScreen={isCurrentScreen}
        />
    } else {
        return <></>
    }
}

const ManageCategBreadcrumb = ({ onClick, isCurrentScreen }: LXBreadcrumbProps) => {
    return <StyledChip
        label={isCurrentScreen ? 'Category Master' : 'Custom Categories'}
        onClick={isCurrentScreen ? undefined : onClick}
        isCurrentScreen={isCurrentScreen}
        chipStyle={{ maxWidth: undefined }}
/>
}


const L1AABreadcrumb = ({ onClick, isCurrentScreen }: LXBreadcrumbProps) => {
    const categName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategName);

    if (categName) {
        return <StyledChip
            label={categName}
            onClick={isCurrentScreen ? undefined : onClick}
            isCurrentScreen={isCurrentScreen}
        />
    } else {
        return <></>
    }
}

const L1CustomAABreadcrumb = ({ onClick, isCurrentScreen }: LXBreadcrumbProps) => {
    const categName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveCustomAssetAllocationCategName);

    if (categName) {
        return <StyledChip
            label={categName}
            onClick={isCurrentScreen ? undefined : onClick}
            isCurrentScreen={isCurrentScreen}
        />
    } else {
        return <></>
    }
}

const L1CustomAASubcategoryBreadcrumb = ({ onClick, isCurrentScreen }: LXBreadcrumbProps) => {
    const subCategName = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL1_Custom_AA_SubcategoryPortfolioSummarySubCategName);

    if (subCategName) {
        return <StyledChip
            label={subCategName}
            onClick={isCurrentScreen ? undefined : onClick}
            isCurrentScreen={isCurrentScreen}
        />
    } else {
        return <></>
    }
}

const L2Breadcrumb = ({ onClick, isCurrentScreen }: LXBreadcrumbProps) => {
    const assetTypeIDName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetTypeIDName);

    if (assetTypeIDName) {
        return <StyledChip
            label={assetTypeIDName.AssetTypeName}
            onClick={isCurrentScreen ? undefined : onClick}
            isCurrentScreen={isCurrentScreen}
        />
    } else {
        return <></>
    }
}

const L2AllStocksBreadcrumb = ({ isCurrentScreen, onClick }: LXBreadcrumbProps) => {
    return <StyledChip
        label={'Equity Exposure'}
        isCurrentScreen={isCurrentScreen}
        onClick={isCurrentScreen ? undefined : onClick}
    />
}

const L2AllStocksAABreadcrumb = ({ isCurrentScreen, onClick, activeScreenIDs }: LXBreadcrumbProps) => {
    const assetName = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveStockNameForEquityExposure);

    return <StyledChip
        label={assetName}
        isCurrentScreen={isCurrentScreen}
        onClick={isCurrentScreen ? undefined : onClick}
    />
}

const L3BreadcrumbForL3 = ({ isCurrentScreen }: LXBreadcrumbProps) => {
    const categDetails = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3CategDetails);

    if (categDetails) {
        return <StyledChip
            label={categDetails.CategName}
            isCurrentScreen={isCurrentScreen}
        />
    } else {
        return <></>
    }
}

const L3CategoryBreadcrumbForL3Category = ({ isCurrentScreen }: LXBreadcrumbProps) => {
    const categDetails = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3CategDetails);

    if (categDetails) {
        return <StyledChip
            label={categDetails.CategName}
            isCurrentScreen={isCurrentScreen}
        />
    } else {
        return <></>
    }
}

const L3BreadcrumbForL4StocksSector = ({ isCurrentScreen, onClick }: LXBreadcrumbProps) => {
    const categDetails = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4AssetStocksSector);

    if (categDetails) {
        return <StyledChip
            label={categDetails.SectorName}
            onClick={isCurrentScreen ? undefined : onClick}
            isCurrentScreen={isCurrentScreen}
        />
    } else {
        return <></>
    }
}

const L3BreadcrumbForL4StocksMarketCap = ({ isCurrentScreen, onClick }: LXBreadcrumbProps) => {
    const categDetails = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4AssetStocksMarketCap);

    if (categDetails) {
        return <StyledChip
            label={categDetails.MarketCapName}
            onClick={isCurrentScreen ? undefined : onClick}
            isCurrentScreen={isCurrentScreen}
        />
    } else {
        return <></>
    }
}

const L4Breadcrumb = ({ isCurrentScreen, onClick }: LXBreadcrumbProps) => {
    const assetName = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4AssetName);
    if (assetName) {
        return <StyledChip
            label={assetName}
            onClick={isCurrentScreen ? undefined : onClick}
            isCurrentScreen={isCurrentScreen}
        />
    } else {
        return <></>
    }
}

const L4RefnoBreadcrumb = ({ isCurrentScreen }: LXBreadcrumbProps) => {
    const refNo = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4Refno);
    if (refNo) {
        return <StyledChip
            label={'#' + refNo}
            isCurrentScreen={isCurrentScreen}
        />
    } else {
        return <></>
    }
}

interface ChipProps {
    isCurrentScreen: boolean;
    chipStyle?: React.CSSProperties;
}

const StyledChip = styled(Chip)<ChipProps>(({ theme, isCurrentScreen, chipStyle }) => {
    const backgroundColor =
        isCurrentScreen ? '#EAF4DF' : (theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800]);
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: isCurrentScreen ? '#5F6F57' : '#64677A',
        fontWeight: theme.typography.fontWeightRegular,
        maxWidth: '190px',
        borderRadius: '4px',
        '&:hover, &:focus': {
            cursor: isCurrentScreen ? 'default' : 'pointer',
            backgroundColor: isCurrentScreen ? backgroundColor : '#EBECF2',
        },
        ...chipStyle
    };
});

interface LXBreadcrumbProps {
    activeScreenIDs: SharedRedux.Portfolios.ActiveScreenPayload,
    onClick: () => void,
    isCurrentScreen: boolean
}