import { EQ } from './EQ';
import { ULIP } from './ULIP';
import { MFEQ } from './MFEQ';
import { INS } from './INS';
import { MFD } from './MFD';
import { PE } from './PE';
import { FD } from './FD';
import { BND } from './BND';
import { NCD } from './NCD';
import { CD } from './CD';
import { PPF } from './PPF';
import { PO } from './PO';
import { GLD } from './GLD';
import { SLV } from './SLV';
import { JWL } from './JWL';
import { PR } from './PR';
import { ART } from './ART';
import { MIS } from './MIS';
import { LN } from './LN';
import { BANKS } from './BANKS';
import { AssetTypeEnum } from 'packages/shared/src/constants';
import { RealEstate } from './RS';
import { Equity } from './EQT';
import { Debt } from './DT';
import { Gold_Plus } from './GLDPLS';
import { Retirement } from './RET';
import { Other } from './OTH';
import { International } from './INT';
import { Cash } from './CSH';

export { EQ, ULIP, MFEQ, INS, MFD, PE, FD, BND, NCD, CD, PPF, PO, GLD, SLV, JWL, PR, ART, MIS, LN, BANKS, RealEstate, Equity, Debt, Gold_Plus, Retirement, Other, International, Cash };

export const getAssetTypeIcon: { [key in AssetTypeEnum]?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
    [AssetTypeEnum.EQ]: EQ,
    [AssetTypeEnum.MFEQ]: MFEQ,
    [AssetTypeEnum.MFD]: MFD,
    [AssetTypeEnum.ULIP]: ULIP,
    [AssetTypeEnum.INS]: INS,
    [AssetTypeEnum.PE]: PE,
    [AssetTypeEnum.FD]: FD,
    [AssetTypeEnum.BND]: BND,
    [AssetTypeEnum.NCD]: NCD,
    [AssetTypeEnum.CD]: CD,
    [AssetTypeEnum.PPF]: PPF,
    [AssetTypeEnum.PO]: PO,
    [AssetTypeEnum.GLD]: GLD,
    [AssetTypeEnum.SLV]: SLV,
    [AssetTypeEnum.JWL]: JWL,
    [AssetTypeEnum.PR]: PR,
    [AssetTypeEnum.ART]: ART,
    [AssetTypeEnum.MIS]: MIS,
    [AssetTypeEnum.LN]: LN,
    [AssetTypeEnum.BANKS]: BANKS
};

export const getIconByAssetTypeID = (assetTypeID: AssetTypeEnum): React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null => {
    return getAssetTypeIcon[assetTypeID] || null;
};
