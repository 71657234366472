import { createSelector } from "@reduxjs/toolkit";
import { ClientRootState } from "../reducers";
import { Selectors as LoginSelectors } from "./loginReducer";
import { Selectors as DashboardSelectors } from "./dashboardReducer";
import { B2B2C_Tier, B2C_Tier, B2X, LicenseInfo, ProductType } from "../../constants";
import { HasSupportedLicenseTier } from "../../utilities/licensing";
import { Selectors as PortfolioSelectors, selectIsActivePortfolioCashMgmtPortfolio } from "./portfoliosReducer";

const loginSelector = (state: ClientRootState) => state.shared.login;
const selectLicenseInfo = (state: ClientRootState) => loginSelector(state).licenseInfo;

const selectIsLicenseViolation = createSelector(
    [
        selectLicenseInfo,
        PortfolioSelectors.selectActiveDatabase
    ],
    (licenseInfo, activeDatabase) => {
        if (activeDatabase?.IsShared === true) {
            return false;
        } else {
            return licenseInfo?.IsLicenseViolation
        }
    }
);

const selectIsLicenseLegacyProduct = createSelector(
    [
        selectLicenseInfo,
        PortfolioSelectors.selectActiveDatabase
    ],
    (licenseInfo, activeDatabase) => {
        if (activeDatabase?.IsShared === true) {
            return false;
        } else {
            return licenseInfo?.IsLegacyProduct
        }
    }
);

//#region Performance license checks

const selectIsPerformanceViewEnabledBasedOnLicense = createSelector(
    [
        selectLicenseInfo,
        PortfolioSelectors.selectActiveDatabase
    ],
    (licenseInfo, activeDatabase) => {
        if (activeDatabase?.IsShared === true) {
            return activeDatabase?.CanAccessPerformanceViewForSharedData === true;
        } else {
            if (!licenseInfo) return undefined;
        
            if (licenseInfo && !licenseInfo.IsLicenseViolation && !licenseInfo.IsFree && !(licenseInfo.IsLegacyProduct && licenseInfo.ProductType == ProductType.INVESTOR)) {
                return true;
            }
        
            return false;
        }
    }
);

const selectIsPerformanceViewEnabled = createSelector([selectIsPerformanceViewEnabledBasedOnLicense, LoginSelectors.selectHasCurrentValueAccess],
    (isPerformanceViewEnabledBasedOnLicense, hasCurrentValueAccess) => {
        return (isPerformanceViewEnabledBasedOnLicense && hasCurrentValueAccess);
    }
);

const selectIsBenchmarkXIRREnabled = createSelector(
    [
        selectLicenseInfo,
        PortfolioSelectors.selectActiveDatabase
    ],
    (licenseInfo, activeDatabase) => {
        if (activeDatabase?.IsShared === true) {
            return activeDatabase?.CanAccessPerformanceViewForSharedData === true;
        } else {
            if (!licenseInfo) return undefined;

            if (licenseInfo && !licenseInfo.IsLicenseViolation && !licenseInfo.IsFree && !licenseInfo.IsLegacyProduct) {
                if (licenseInfo.B2X == B2X.B2C && [B2C_Tier.HNI, B2C_Tier.FAMILYOFFICE, B2C_Tier.DESKTOP_HNI, B2C_Tier.DESKTOP_MULTIFAMILY].includes(licenseInfo.B2C_Tier)) {
                    return true;
                } else if (licenseInfo.B2X == B2X.B2B2C && [B2B2C_Tier.PLATINUM].includes(licenseInfo.B2B2C_Tier)) {
                    return true;
                }
            }
        
            return false;
        }
    }
);

const selectIsBlendedBenchmarkXIRREnabled = createSelector(
    [
        selectLicenseInfo,
        PortfolioSelectors.selectActiveDatabase
    ],
    (licenseInfo, activeDatabase) => {
        if (activeDatabase?.IsShared === true) {
            return activeDatabase?.CanAccessPerformanceViewForSharedData === true;
        } else {
            if (!licenseInfo) return undefined;

            if (licenseInfo && !licenseInfo.IsLicenseViolation && !licenseInfo.IsFree && !licenseInfo.IsLegacyProduct) {
                if (licenseInfo.B2X == B2X.B2C && [B2C_Tier.FAMILYOFFICE, B2C_Tier.DESKTOP_MULTIFAMILY].includes(licenseInfo.B2C_Tier)) {
                    return true;
                } else if (licenseInfo.B2X == B2X.B2B2C && [B2B2C_Tier.PLATINUM].includes(licenseInfo.B2B2C_Tier)) {
                    return true;
                }
            }
        
            return false;
        }
    }
);

const selectPerformanceViewLockedMessage = createSelector(
    [
        selectLicenseInfo,
        PortfolioSelectors.selectActiveDatabase
    ],
    (licenseInfo, activeDatabase) => {
        if (activeDatabase?.IsShared === true) {
            return {
                title: 'Feature unavailable',
                body: 'Please contact MProfit support',
                button: 'Contact MProfit Support',
                isContactUsButton: true
            };
        } else {
            if (!licenseInfo) return undefined;

            var title: string = '';
            var body: string = `The Performance View provides you period-wise XIRR, portfolio performance vs indices and other advanced performance insights.`;
            var button: string | undefined = 'Upgrade now';
            var isContactUsButton = false;

            if (licenseInfo.IsExpired) {
                title = licenseInfo.IsTrial ? 'Your trial has expired' : 'Your plan has expired';
                button = licenseInfo.IsTrial ? 'Buy now' : 'Renew your plan';
                isContactUsButton = false;
            }
            else if (licenseInfo.IsLicenseViolation) {
                title = 'Plan limit exceeded.';
                body = 'Your plan limit has been exceeded. Please contact us at support@mprofit.in and we will help you upgrade to the right plan'
                button = 'Contact MProfit Support';
                isContactUsButton = true;
            }
            else if (licenseInfo.IsLegacyProduct) {
                title = 'This feature is locked in your current plan.'
                button = 'Contact MProfit Support';
                isContactUsButton = true;
            }
            else {
                title = 'This feature is locked in your current plan';
                button = 'Upgrade to a Paid Plan';
                isContactUsButton = false;
            }

            return {
                title,
                body,
                button,
                isContactUsButton
            };
        }
    }
);

const selectIsOnlyHNIFOWealthPlatinum = createSelector(
    [
        selectLicenseInfo,
        selectIsPerformanceViewEnabledBasedOnLicense,
        PortfolioSelectors.selectActiveDatabase
    ],
    (licenseInfo, isPerformanceViewEnabledBasedOnLicense, activeDatabase) => {
        if (activeDatabase?.IsShared === true) {
            return activeDatabase?.CanAccessPerformanceViewForSharedData === true;
        } else {
            if (isPerformanceViewEnabledBasedOnLicense !== true) return isPerformanceViewEnabledBasedOnLicense;

            var b2cTiersSupported = [B2C_Tier.HNI, B2C_Tier.FAMILYOFFICE, B2C_Tier.DESKTOP_HNI, B2C_Tier.DESKTOP_MULTIFAMILY];
        
            if (licenseInfo && !licenseInfo.IsLegacyProduct && (licenseInfo.B2X === B2X.B2B2C ? licenseInfo.B2B2C_Tier === B2B2C_Tier.PLATINUM : b2cTiersSupported.includes(licenseInfo.B2C_Tier))) {
                return true;
            }
        
            return false;
        }
    }
);

const selectIsAssetAllocationPerformanceViewEnabled = selectIsOnlyHNIFOWealthPlatinum;
const selectIsAdvancedEquityExposureEnabled = selectIsOnlyHNIFOWealthPlatinum;

//#endregion

//#region PMS Cash Management

const selectIsPMSCashManagementEnabled = createSelector(
    [
        selectLicenseInfo,
        PortfolioSelectors.selectActiveDatabase
    ],
    (licenseInfo, activeDatabase) => {
        if (activeDatabase?.IsShared === true) {
            return true;
        } else {
            if (!licenseInfo) return undefined;

            if (licenseInfo && (licenseInfo.HasPmsPremiumPack || HasSupportedLicenseTier(licenseInfo, [B2B2C_Tier.PREMIUM, B2B2C_Tier.PLATINUM], false, [B2C_Tier.HNI, B2C_Tier.FAMILYOFFICE], false))) {
                return true;
            } else {
                return false;
            }
        }
    }
);

const selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck = createSelector([selectIsActivePortfolioCashMgmtPortfolio, selectIsPMSCashManagementEnabled], (isActivePortfolioCashMgmtPortfolio, isPMSFeaturesEnabled) => {
    return (isActivePortfolioCashMgmtPortfolio && isPMSFeaturesEnabled) === true;
});

const selectIsBlendedBenchmarkHidden = createSelector(
    [PortfolioSelectors.selectActiveDatabase],
    (activeDatabase) => {
        return activeDatabase?.IsShared ? true : false;
    }
)

//#endregion

//#region Custom Categorisation

const selectIsCustomCategorisationEnabled = createSelector(
    [
        selectLicenseInfo,
        PortfolioSelectors.selectActiveDatabase
    ],
    (licenseInfo, activeDatabase) => {
        if (activeDatabase?.IsShared === true) {
            return activeDatabase?.CanAccessAnalyticsPrimeForSharedData === true;
        } else {
            if (!licenseInfo) return undefined;

            return licenseInfo.IsPrimePlanForFOWealth;
        }
    }
);

export const selectIsUsingDefaultCategorization = createSelector(
    [
        DashboardSelectors.selectIsUseCustomCategorisationPreference,
        selectIsCustomCategorisationEnabled
    ],
    (
        isUseCustomCategorisationPreference,
        isCustomCategorisationEnabled
    ) => {
        return isCustomCategorisationEnabled !== true;
        // return isCustomCategorisationEnabled ? isUseCustomCategorisationPreference !== true : true;
    }
)

export const selectActiveDefaultOrCustomAssetAllocationCategID = createSelector(
    [
        selectIsUsingDefaultCategorization,
        PortfolioSelectors.selectActiveAssetAllocationCategID,
        PortfolioSelectors.selectActiveCustomAssetAllocationCategID
    ], (
        isUsingDefaultCategorization,
        assetAllocationCategID,
        customAssetAllocationCategID
    ) => {
        return isUsingDefaultCategorization ? assetAllocationCategID : customAssetAllocationCategID;
    }
);

export const selectActiveDefaultOrCustomAssetAllocationCategName = createSelector(
    [
        selectIsUsingDefaultCategorization,
        PortfolioSelectors.selectActiveAssetAllocationCategName,
        PortfolioSelectors.selectActiveCustomAssetAllocationCategName
    ], (
        isUsingDefaultCategorization,
        assetAllocationCategName,
        customAssetAllocationCategName
    ) => {
        return isUsingDefaultCategorization ? assetAllocationCategName : customAssetAllocationCategName;
    }
);

export const selectShowCustomCategEditButton = createSelector(
    [
        LoginSelectors.selectHasCustomCategAccess,
        selectIsUsingDefaultCategorization,
        PortfolioSelectors.selectIsActiveDatabaseShared
    ],
    (
        hasCustomCategAccess,
        isUsingDefaultCategorization,
        isActiveDatabaseShared
    ) => {
        return hasCustomCategAccess && !isUsingDefaultCategorization && !isActiveDatabaseShared;
    }
)

//#endregion

export const Selectors = {
    selectIsPMSCashManagementEnabled,
    selectPerformanceViewLockedMessage,
    selectIsLicenseViolation,
    selectIsPerformanceViewEnabled,
    selectIsBenchmarkXIRREnabled,
    selectIsBlendedBenchmarkXIRREnabled,
    selectIsLicenseLegacyProduct,
    selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck,
    selectIsAssetAllocationPerformanceViewEnabled,
    selectIsBlendedBenchmarkHidden,
    selectIsAdvancedEquityExposureEnabled,
    selectIsUsingDefaultCategorization,
    selectActiveDefaultOrCustomAssetAllocationCategID,
    selectActiveDefaultOrCustomAssetAllocationCategName,
    selectShowCustomCategEditButton
}

export const LicensingSelectors = Selectors;