import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Container, FormControl, InputLabel, TextField, Typography, styled, Divider } from '@mui/material'
import LongBackArrow from 'packages/web/src/assets/icons/login/LongBackArrow';
import CustomTypography from '../../../shared/customTypography';
import IllustrationsBackground from '../../../../assets/background/illustrations.jpg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useForm, SubmitHandler } from "react-hook-form";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useAppDispatch } from 'packages/web/src/redux/hooks';
import { WebRedux } from '@mprofit/web';
import { AuthPage } from 'packages/web/src/constants/login';
import { SharedAPIs, SharedUtilities } from '@mprofit/shared';
import { HttpStatusCode } from "axios";
import LoginBtnLoader from '../login-btn-loader';
interface IFormInputs {
    email: string
    password: string
}

export default function ForgotPassword() {
    const dispatch = useAppDispatch();
    const { register, formState: { errors }, handleSubmit } = useForm<IFormInputs>();
    const [apiError, setApiError] = useState(false)
    const [loading, setLoader] = useState(false);

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {
        setApiError(false);
        setLoader(true);
        SharedAPIs.Login.ResetPasswordAPI(data.email).then(response => {
            if (response.status === HttpStatusCode.NoContent) {
                setLoader(false);
                dispatch(WebRedux.Auth.Actions.setLoginPayload(data));
                dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: AuthPage.PasswordResetCodeVerification }))

            } else {
                setLoader(false);
                setApiError(true);
            }
        })
    };
    return (
        <Wrapper>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <BackContainer onClick={() => dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: 1 }))}>
                    <LongBackArrow />
                    <CustomTypography variant='Medium16' sx={{ marginLeft: '8px', color: { xs: 'brandBase.300' } }}>Back To Login</CustomTypography>
                </BackContainer>
                <HeaderCont>
                    <Heading>Forgot password?</Heading>
                    <CustomTypography variant='Regular18' sx={{ paddingTop: '16px' }}>No problem. Just enter your email address and we'll send you a password reset code.</CustomTypography>
                </HeaderCont>
                <FormContainer>
                    <CodeInputCont>
                        <Box component="form" noValidate autoComplete="off" sx={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
                            <InputLabel htmlFor="emailId" sx={{ padding: '10px 0px', color: 'black', fontSize: '18px' }} >Email Id</InputLabel>
                            <FormControl fullWidth={true}>
                                <TextField
                                    size='medium'
                                    id="emailId"
                                    aria-describedby="email-id"
                                    placeholder='Enter your email id'
                                    hiddenLabel
                                    error={errors.email && true}
                                    {...register("email", { required: true, pattern: SharedUtilities.EmailValidationRegex, setValueAs: (value) => value.trim() })}
                                />
                                <ErrorText sx={{ visibility: errors.email ? "visible" : 'hidden', color: { xs: '#C94C40' }, marginBottom: '6px', margin: '5px 0px' }} variant='Regular16'><WarningRoundedIcon fontSize="small" sx={{ marginRight: '5px' }} />Please enter a valid email address</ErrorText>
                                {apiError && <ErrorText sx={{ color: { xs: '#C94C40' }, marginBottom: '6px' }} variant='Regular16'><WarningRoundedIcon fontSize="small" sx={{ marginRight: '5px' }}/>Error resetting password. Please try again.</ErrorText>}
                            </FormControl>
                            <VerifyButton size='small' variant='contained' type='submit'>
                                {
                                    loading ?
                                        <LoginBtnLoader />
                                        :
                                        'Send reset code'
                                }
                            </VerifyButton>
                        </Box>
                    </CodeInputCont>
                </FormContainer>
                {
                    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'end' }}>
                        <Divider variant='middle' sx={{ width: '90%' }} />
                        <CustomTypography variant="Regular16"
                            sx={{ display: 'flex', paddingLeft: '32px', padding: { xs: '15px', lg: '30px' } }}
                        >
                            Already have a password reset code?
                            <SpanText variant='Medium16'
                                sx={{ color: { xs: 'brandBase.300' } }}
                                onClick={() => dispatch(WebRedux.Auth.Actions.activePage({ activeAuth: AuthPage.ForgotPasswordWithResetCode }))}>
                                Enter Code
                            </SpanText>
                        </CustomTypography>
                    </Box>
                }
            </Box>
        </Wrapper >
    )
}


const Wrapper = styled(Container)({
    boxSizing: 'border-box',
    maxHeight: '73%',
    minHeight: '550px',
    height: '65%',
    minWidth: '550px',
    width: '53%',
    marginLeft: 'auto',
    background: '#FFFFFF',
    borderRadius: '8px',
    margin: 'auto',
    padding: '24px'
});

const BackContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ':hover': {
        background: '#cfe3c9'
    },
    width: '30%',
    minWidth: '100px'
});

const HeaderCont = styled(Box)({
    marginLeft: '32px',
    marginRight: '32px',
    marginTop: '6%'
});

const Heading = styled(Typography)({
    fontSize: '36px',
    lineHeight: '48px',
    fontWeight: 500
});

const FormContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '32px',
    paddingRight: '32px'
});

const VerifyButton = styled(Button)({
    padding: '15px 32px 14px 32px',
    background: '#010006',
    textTransform: 'none',
    color: 'base.white',
    width: '100%',
    height: '48px',
    ':hover': {
        background: '#252833'
    },
    ':disabled': {
        background: '#EBECF2',
        color: '#BABDCC'
    }
});

const CodeInputCont = styled(Box)({
    display: 'flex'
});

const SpanText = styled(CustomTypography)({
    marginLeft: '6px',
    cursor: 'pointer'
});
const ErrorText = styled(CustomTypography)({
    minHeight: '24px',
    display: 'flex',
    alignItems: 'center',
});