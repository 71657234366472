import { Box } from "@mui/material";
import { InfoIcon } from "packages/web/src/assets/icons/InfoIcon";
import CustomTypography from "../customTypography";

interface InfoBoxProps {
  message: string;
  backgroundColor?: string;
  iconColor?: string;
}

export const InfoBox = ({ 
  message, 
  backgroundColor = "#E6F2F8", 
  iconColor = "#4094F7" 
}: InfoBoxProps) => {
  return (
    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: "8px", background: backgroundColor, padding: "16px", borderRadius: "8px"}}>
        <InfoIcon color={iconColor} />
        <CustomTypography variant='Regular16' color='#12131A' sx={{ lineHeight: "24px" }}>{message}</CustomTypography>
    </Box>
  )
}
