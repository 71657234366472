import { CompleteTablePage } from "@mprofit-soft/pdf";
import { DownloadPDF, ExtraHeaderProps, ExtraOverviewProps } from "../../download-pdf";
import { ChartsSelectors } from "packages/web/src/redux/selectors/chartsSelectors";
import { usePDFSelector } from "../../download-pdf/redux";
import { SharedConstants, SharedRedux, SharedUtilities } from "@mprofit/shared";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { RowDataProps, sortRows } from "../virtual-table-component";

const _XIRRTablePDFPage = ({ headerProps, overviewProps, isGroupByPortfolio, rowFieldHeading, rowFieldName, tableMode, tableType, isGroupByCustomCategory}: {
        headerProps: ExtraHeaderProps;
        overviewProps: ExtraOverviewProps;
        isGroupByPortfolio: boolean;
        rowFieldHeading?: string;
        rowFieldName: string;
        tableMode: SharedConstants.AdvancedPerformanceTableMode;
        tableType?: SharedConstants.TableType;
        isGroupByCustomCategory?: boolean;
    }) => {
    const {tableData, tableRowData, isLoading, columnData, chartDisclaimer, heading } = usePDFSelector(ChartsSelectors.selectXIRRTableData(isGroupByPortfolio, rowFieldHeading, rowFieldName, tableMode, isGroupByCustomCategory));
    const tableConfig = usePDFSelector(SharedRedux.Dashboard.Selectors.selectTableConfig(tableType!));

    const [finalRows, setFinalRows] = useState<RowDataProps[]>(tableRowData);

    useEffect(() => {
        setFinalRows(sortRows(tableRowData, columnData, tableConfig?.sortConfig.key, tableConfig?.sortConfig.direction));
    }, [tableConfig])

    if (!tableData || isLoading) {
        return <></>;
    }

    return <CompleteTablePage
        headerProps={{
            ...headerProps,
            reportType: "Performance",
        }}
        overviewProps={{
            ...overviewProps,
            heading
        }}
        data={{
            columnData,
            rowData: finalRows,
            PMSBadgeField: isGroupByPortfolio ? 'IsCashMgmtPortfolio' : undefined
        }}
        chartDisclaimer={chartDisclaimer}
    />
}

export const XIRRTablePDFPage = (
    isGroupByPortfolio: boolean, rowFieldHeading: string | undefined, rowFieldName: string, tableMode: SharedConstants.AdvancedPerformanceTableMode, tableType?: SharedConstants.TableType, isGroupByCustomCategory?: boolean
) => ({ headerProps, overviewProps }: {
    headerProps: ExtraHeaderProps;
    overviewProps: ExtraOverviewProps;
}) => {
    return <_XIRRTablePDFPage {...{headerProps, overviewProps, isGroupByPortfolio, rowFieldHeading, rowFieldName, tableMode, tableType, isGroupByCustomCategory}}/>;
}

interface XIRRTablePDFDownloadButtonProps {
    isGroupByPortfolio: boolean;
    rowFieldHeading?: string;
    rowFieldName: string;
    tableMode: SharedConstants.AdvancedPerformanceTableMode;
    heading: string;
    tableType?: SharedConstants.TableType;
    isGroupByCustomCategory?: boolean;
}

export const XIRRTablePDFDownloadButton = ({heading, isGroupByPortfolio, rowFieldHeading, rowFieldName, tableMode, tableType, isGroupByCustomCategory}: XIRRTablePDFDownloadButtonProps) => {
    return <Box>
        <DownloadPDF
            PDFPage={
                XIRRTablePDFPage(isGroupByPortfolio, rowFieldHeading, rowFieldName, tableMode, tableType!, isGroupByCustomCategory)
            }
            ReportNameForFileName={heading}
        />
    </Box>
}
