import { Box, IconButton, Stack } from "@mui/material"
import CustomModal from "../shared/custom-modal"
import { CustomTypography } from "../shared"
import { CloseIcon } from "../../assets/icons/CloseIcon"
import DropDown from "../shared/drop-down"
import PrimaryIconButton from "../shared/primary-icon-button"
import { AscendingOrderIcon } from "../../assets/icons/ascendingorderIcon"
import { CustomRadio } from "."
import SwitchButton from "../shared/switch-button"
import ButtonText from "../shared/button-text"
import ButtonPrimaryCTA from "../shared/button-primary-cta"

interface PreferenceModalProps {
    isOpen: boolean;
    onClose: () => void;
    preferences: {
      defaultSort: string;
      sortDirection: string;
      separator: string;
      showZeroValues: boolean;
      showDecimals: boolean;
      isUseCustomCategorisation: boolean;
    };
    onPreferenceChange: (key: string, value: any) => void;
    onSubmit: () => void;
}

export const PreferenceModal = ({ 
    isOpen, 
    onClose, 
    preferences, 
    onPreferenceChange,
    onSubmit 
  }: PreferenceModalProps) => {
    return (
        <CustomModal
            open={isOpen}
            handleClose={onClose}
            >
            <Box sx={{
                width: '564px',
                background: "#FFFFFF",
                borderRadius: "8px",
            }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ margin: "24px 24px 0px 24px" }}>
                <CustomTypography variant="Medium24">
                    Preferences
                </CustomTypography>
                <IconButton onClick={onClose} disableRipple>
                    <CloseIcon />
                </IconButton>
                </Stack>

                <Box sx={{ padding: "26px 24px 0px 24px" }}>
                    {/* <CustomTypography variant="Regular16" color="#64677A">
                        Default Sort
                    </CustomTypography>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "12px 0px 32px 0px" }}>
                        <Box>
                        <DropDown
                            inputValue={preferences.defaultSort}
                            inputList={[
                            { id: 'Amount Invested', name: 'Amount Invested' },
                            { id: 'Name', name: 'Name' },
                            { id: 'Current Value', name: 'Current Value' },
                            { id: "Today's Gain", name: "Today's Gain" },
                            { id: "Today's Gain %", name: "Today's Gain %" },
                            { id: "Unrealised Gain", name: "Unrealised Gain" },
                            { id: "Unrealised Gain %", name: "Unrealised Gain %" }
                            ]}
                            handleChange={(value) => onPreferenceChange('defaultSort', value.name)}
                            containerSx={{width: "416px", height: "40px"}}
                        />
                        </Box>
                        <Box onClick={() => onPreferenceChange('sortDirection', 'Ascending')}>
                        <PrimaryIconButton
                            icon={<AscendingOrderIcon style={{ transform: 'rotate(180deg)' }} />}
                            tooltipText="Ascending"
                            buttonSize='large'
                            iconBorderRadius='4px'
                            backgroundColor={preferences.sortDirection === 'Ascending' ? '#CFE3C9' : '#ffffff'}
                            />
                        </Box>
                        <Box onClick={() => onPreferenceChange('sortDirection', 'Descending')}>
                        <PrimaryIconButton
                            icon={<AscendingOrderIcon />}
                            tooltipText="Descending"
                            buttonSize='large'
                            iconBorderRadius='4px'
                            backgroundColor={preferences.sortDirection === 'Descending' ? '#CFE3C9' : '#ffffff'}
                        />
                        </Box>
                    </Box>
                    <CustomTypography variant="Regular16" color="#64677A">
                        Separator
                    </CustomTypography>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "18px 0 0 0", gap: "50px" }}>
                        <CustomRadio 
                        label="Lakhs"
                        checked={preferences.separator === 'IN'}
                        onChange={() => onPreferenceChange('separator', 'IN')}
                        />
                        <CustomRadio 
                        label="Millions"
                        checked={preferences.separator === 'US'}
                        onChange={() => onPreferenceChange('separator', 'US')}
                        />
                    </Box>

                    <Box sx={{ width: "100%", height: "1px", background: "#D8DAE5", margin: "32px 0" }} /> */}

                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <CustomTypography variant="Regular18" color="#12131A">Zero Values</CustomTypography>
                        <Box sx={{ marginRight: "-25px" }}>
                        <SwitchButton checked={preferences.showZeroValues} onChange={() => onPreferenceChange('showZeroValues', !preferences.showZeroValues)} />
                        </Box>
                    </Box>
                    {/* <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <CustomTypography variant="Regular18" color="#12131A">Custom Categorisation</CustomTypography>
                        <Box sx={{ marginRight: "-25px" }}>
                        <SwitchButton checked={preferences.isUseCustomCategorisation} onChange={() => onPreferenceChange('isUseCustomCategorisation', !preferences.isUseCustomCategorisation)} />
                        </Box>
                    </Box> */}
                    {/* <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "8px" }}>
                        <CustomTypography variant="Regular18" color="#12131A">Show Decimals</CustomTypography>
                        <Box sx={{ marginRight: "-25px" }}>
                        <SwitchButton checked={preferences.showDecimals} onChange={() => onPreferenceChange('showDecimals', !preferences.showDecimals)} />
                        </Box>
                    </Box> */}
                </Box>

                <Box sx={{ display: "flex", padding: "20px 24px", justifyContent: "space-between", borderTop: "1px solid #EBECF2", marginTop: "32px" }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}></Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
                    <Box>
                    <ButtonText buttonText="Cancel" handleClick={onClose} buttonSize="large" />
                    </Box>
                    <Box>
                    <ButtonPrimaryCTA
                        buttonText="Done"
                        disabled={false}
                        handleClick={onSubmit}
                    />
                    </Box>
                </Box>
                </Box>
            </Box>
        </CustomModal>
    )
}