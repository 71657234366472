export function PreferenceSettingIcon(props: any) {
    return (
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 151966">
            <path id="Ellipse 1977" d="M10.7959 5.23742C12.0235 5.24963 13.0286 4.26431 13.0409 3.03664C13.0531 1.80897 12.0677 0.803852 10.8401 0.791641C9.61241 0.77943 8.60729 1.76475 8.59508 2.99242C8.58287 4.22009 9.56819 5.22521 10.7959 5.23742Z" stroke="#9A9EB2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Line 535" d="M1.47656 3.36719L8.59121 3.43795" stroke="#9A9EB2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Line 536" d="M13.0312 3.48047L16.5881 3.51585" stroke="#9A9EB2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <g id="Group 150747">
            <path id="Ellipse 1978" d="M5.3818 13.1905C6.60946 13.2028 7.61458 12.2174 7.6268 10.9898C7.63901 9.7621 6.65368 8.75698 5.42602 8.74477C4.19835 8.73256 3.19323 9.71788 3.18102 10.9455C3.1688 12.1732 4.15413 13.1783 5.3818 13.1905Z" stroke="#9A9EB2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Line 537" d="M1.40625 10.4805L3.18516 10.4982" stroke="#9A9EB2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Line 538" d="M7.63281 10.543L16.5264 10.6314" stroke="#9A9EB2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            </g>
        </svg>    
    );
}
