import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import TodayLayout from "../layout";
import { PieChartWithBottomLegend } from "../../../shared";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import SharedVirtualizedTable, { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import TodayValueCard from "../todayValueCard";
import TodayGainLoss from "../todayGainLoss";
import TodayPerformanceColumnChart from "../todayPerformanceColumnChart";
import { NavigateToAssetAllocationCateg, NavigateToAssetType, NavigateToCustomAssetAllocationCateg } from "packages/web/src/routes/navigationMethods";
import { useNavigate, useLocation } from 'react-router-dom';

interface L1_Today_Props {
  MPrUrlParams: MPrUrlParams;
}

export default function L1_Today(props: L1_Today_Props) {
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);
  const IsGroup: boolean = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsActivePortfolioAGroup);

  return <TodayLayout
    {...props}
    Screen_TodayValueCard={L1TodayValueCard}
    Screen_TodayGainLossCard={L1TodayGainLossCard}
    Screen_PieChart={L1PieChart}
    Screen_TodayTable={L1TodayTable}
    Screen_TodayPerformanceColumnChart={L1TodayPerformanceColumnChart}
    IsLoading={isLoading}
    ShowStocksCards={true}
    HideCorporateActions={IsGroup === true}
    IsAllowTour={true}
    TableTitle={'My Current Portfolio'}
  />;
}

const L1TodayValueCard = () => {
  const currentValue = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryTotalCurrentValue);
  const activePortfolio = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolio_INV);
  const selectActivePortfolioTypeName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolioTypeName);
  console.log(activePortfolio, 'activePortfolio1')

  return <TodayValueCard
    ScreenType={selectActivePortfolioTypeName}
    Title={activePortfolio ? activePortfolio.PFullName || activePortfolio.PName : ''}
    Screen={SharedConstants.ScreenEnum.L1}
    CurrentValue={currentValue}
  />
}

const L1TodayGainLossCard = () => {
  const totalValues = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryTotal);

  return <TodayGainLoss TotalValues={totalValues} />
}

const L1TodayTable = ({ searchTerm }: { searchTerm: string }) => {
  const assetTypeTotals = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryAssetTypeListSorted('CurrValue', SharedConstants.SortDirection.Up));
  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const onRowClick = (row: RowDataProps) => {
    console.log('onRowClick', row);
    if (row && row.AssetTypeID > 0) {
      NavigateToAssetType({ navigate, activeScreenIDs, assetTypeID: row.AssetTypeID, pathname: location.pathname });
    }
  }

  var fieldNamesToSearch = ['AssetTypeName'];

  return <SharedVirtualizedTable columns={tableColumns} rows={assetTypeTotals} headerColor="#EAF4DF" sorting={true} onRowClick={onRowClick} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch} defaultSortConfig={SharedConstants.DefaultSortingForTodayTable} tableType={SharedConstants.TableType.Today} />
}

const L1PieChart = () => {
  const { PieChartData, TotalValue } = useAppSelector((state) => SharedRedux.PortfolioSummary.Selectors.selectL1PieChartData_ByAssetAllocation(state, 'CurrValue'));

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  const isUsingDefaultCategorization = useAppSelector(SharedRedux.Licensing.Selectors.selectIsUsingDefaultCategorization);

  const onItemClick = (item: SharedConstants.PieChartData) => {
    if (item && item.IDs && item.IDs.AssetAllocationCategID > 0) {
      const navigationParams = { navigate, activeScreenIDs, pathname: location.pathname }
      if (isUsingDefaultCategorization) {
        NavigateToAssetAllocationCateg({ ...navigationParams, assetAllocationCategID: item.IDs.AssetAllocationCategID });
      } else {
        NavigateToCustomAssetAllocationCateg({ ...navigationParams, customAssetAllocationCategID: item.IDs.AssetAllocationCategID  });
      }
    }
  }

  return <PieChartWithBottomLegend data={PieChartData} totalValue={TotalValue} onItemClick={onItemClick} />
}

const L1TodayPerformanceColumnChart = () => {
  const indexChartData = useAppSelector(SharedRedux.Dashboard.Selectors.selectTodayPerformanceIndicesChartValues);
  const myStocksTGainPct = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectMyStocksTGainPct);
  return <TodayPerformanceColumnChart data={indexChartData && myStocksTGainPct ? [{ Name: 'My Stocks & ETFs', Value: myStocksTGainPct, IsBlack: true }, ...indexChartData].slice(0, 6) : indexChartData} myDataName="your Stocks & ETFs"/>
}

const tableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'AssetTypeName',
    HeaderName: 'ASSET CLASS',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDefaultFieldFormatter,
    Align: 'left',
    IsRowName: true,
  },
  {
    FieldKey: 'TGain',
    HeaderName: "TODAY'S GAIN",
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'TGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'TUp'
      }
    ]
  },
  {
    FieldKey: 'OGain',
    HeaderName: 'UNREALISED GAIN',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'OGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'OUp'
      }
    ]
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  }
];