import { Box, Grid, styled } from "@mui/material";
import { CustomTypography } from "../../shared";
import { ColumnDataProps } from "../../shared/virtual-table-component";
import { AmountWithTooltip } from "../../shared/numberWithTooltip";
import { useAppDispatch } from "packages/web/src/redux/hooks";
import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedRedux } from "@mprofit/shared";
import Big from "big.js";
import ManageCategoriesVirtualizedTable, { TableDefaultFieldFormatter } from "./manage-categories-table";
import BreadCrumbComponent from "../../shared/breadcrumbs";

const tableColumns: ColumnDataProps[] = [ 
  {
    FieldKey: 'name',
    HeaderName: "CATEGORY / SUB-CATEGORY",
    FieldFormatter: TableDefaultFieldFormatter,
    ColumnWidthPercent: "100%",
    Align: 'left',
    IsRowName: true,
  }
];

export function ManageCategories(props: { MPrUrlParams: MPrUrlParams }) {
  const dispatch = useAppDispatch();
  const rows = useAppSelector(SharedRedux.Dashboard.Selectors.selectAssetCategoriesTableData);
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  React.useEffect(() => {
    if (activeScreenIDs) {
      dispatch(SharedRedux.Dashboard.Actions.refreshCustomAssetAllocationCategories(activeScreenIDs.DatabaseID || -1))
    }
  }, [dispatch, activeScreenIDs]);

  return (
    <>
      <Grid sx={{ margin:"0 35px 24px 28px" }}>
      <BreadCrumbComponent />
      <Box display="flex" justifyContent="space-between" alignItems="center" >
        <CustomTypography variant="Medium28" sx={{ color: "#12131A" }}>Category Master</CustomTypography>
      </Box>
      </Grid>
      <ManageCategoriesVirtualizedTable 
        rows={rows} 
        columns={tableColumns} 
        primaryRowId="categoryId"
        secondaryRowId="subCategoryId"
        headerColor="#EAF4DF" 
        sorting={false}
        tableHeight="calc(100vh - (88px + 34px + 42px + 50px))"
        isLoading={false}
      />
    </>
  )
}
