import { Box } from "@mui/material"
import TooltipDark from "../tooltip-dark"
import { InfoIcon } from "../../../assets/icons/InfoIcon"

export const InfoIconTooltip = ({ tooltip, tooltipLines, placement, color }: { tooltip?: string, tooltipLines?: string[]; placement?: 'top' | 'bottom' | 'left' | 'right', color?: string }) => {
    return (
        <TooltipDark title={tooltip} tooltipLines={tooltipLines} placement={placement}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <InfoIcon color={color} />
            </Box>
        </TooltipDark>

    )
}