import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { B2B2C_Tier, B2C_Tier, B2X, GlobalAccessRightTypes, LicenseInfo, LoginPayload, Login_Status, ProductType, User, UserAccessRights } from "../../constants";
import { ClientRootState } from "../reducers";
import { LoadDashboardPayload } from "../sagas/loadingSaga";
import { hasAccessRights } from "../../utilities";

export interface SharedLoginState {
    user?: User;
    loginState: {
        LoginStatus: Login_Status;
        Message?: string;
        IsError: boolean;
    },
    licenseInfo?: LicenseInfo;
    accessRights: UserAccessRights;
}

const initialState: SharedLoginState = {
    user: undefined,
    loginState: {
        LoginStatus: Login_Status.LOGGED_OUT,
        IsError: false
    },
    accessRights: {
        GlobalAccessRights: {},
        IsAdmin: false,
        IsOwner: false,
        IsMultipleUsers: false
    }
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<User>) => {
            var user = action.payload;
            state.loginState = { LoginStatus: Login_Status.LOGIN_SUCCESS, IsError: false };
            state.user = {
                ...user,
                isActive: user.isActive !== 'False' && user.isActive !== false,
                isMFAEnabled: (user.isMFAEnabled === true) || (user.isMFAEnabled == 'true')
            };
        },
        reset: (state) => {
            state.loginState = { LoginStatus: Login_Status.LOGGED_OUT, IsError: false };
            state.user = undefined;
        },
        logout: (state, action: PayloadAction<{ IsUserAction: boolean }>) => {
            state.loginState = { LoginStatus: Login_Status.LOGGED_OUT, IsError: false };
            state.user = undefined;
        },
        setLoginState: (state, action: PayloadAction<{ LoginStatus: Login_Status, Message: string, IsError: boolean }>) => {
            state.loginState = action.payload;
        },
        loginClicked: (state, action: PayloadAction<LoginPayload>) => {
            state.loginState = { LoginStatus: Login_Status.LOGGING_IN, IsError: false };
        },
        loadDashboard: (state, action: PayloadAction<LoadDashboardPayload>) => { },
        refreshLicenseInfo: (state) => { },
        setLicenseInfo: (state, action: PayloadAction<LicenseInfo>) => {
            state.licenseInfo = action.payload;
        },
        getAccessRights: (state) => { },
        setAccessRights: (state, action: PayloadAction<UserAccessRights>) => {
            state.accessRights = action.payload;
        },
        resetData: (state) => { }
    }
})

//#region Selectors

const loginSelector = (state: ClientRootState) => state.shared.login;
const selectLoginState = (state: ClientRootState) => loginSelector(state).loginState;
const selectLoginStatus = (state: ClientRootState) => selectLoginState(state).LoginStatus;
const selectLoginStateMessage = (state: ClientRootState) => selectLoginState(state).Message;
const selectUser = (state: ClientRootState) => loginSelector(state).user;

const selectUserCCID = createSelector([selectUser], (user) => +(user?.customerId || "-1"));
const selectUserEmail = (state: ClientRootState) => selectUser(state)?.email;
const selectUserName = (state: ClientRootState) => selectUser(state)?.userName;
const selectUserAlphabet = createSelector([selectUser], (user) => {
    if (!user) return undefined;
    var relevantTerm = ((user.userName || user.email) || '').trim();
    return relevantTerm && relevantTerm.length > 0 ? relevantTerm[0] : undefined;
});


//#region Access Rights

const selectAccessRights = (state: ClientRootState) => loginSelector(state).accessRights;
const selectGlobalAccessRights = (state: ClientRootState) => selectAccessRights(state).GlobalAccessRights;
const selectIsAdmin = (state: ClientRootState) => selectAccessRights(state).IsAdmin;
const selectIsMultipleUsers = (state: ClientRootState) => selectAccessRights(state).IsMultipleUsers;
const selectIsOwner = (state: ClientRootState) => selectAccessRights(state).IsOwner;

const selectHasCurrentValueAccess = createSelector([selectGlobalAccessRights], (globalAccessRights) => {
    return hasAccessRights(globalAccessRights, GlobalAccessRightTypes.ViewCurrentValue, 'View')
});

const selectHasCustomCategAccess = createSelector([selectGlobalAccessRights], (globalAccessRights) => {
    return hasAccessRights(globalAccessRights, GlobalAccessRightTypes.IsAdmin, 'Add');
});

//#endregion

//#endregion

export default loginSlice.reducer;
export const Actions = loginSlice.actions;
export const Selectors = {
    loginSelector,
    selectLoginState,
    selectLoginStatus,
    selectLoginStateMessage,
    selectUser,
    selectUserCCID,
    selectUserEmail,
    selectUserName,
    selectUserAlphabet,
    selectGlobalAccessRights,
    selectIsAdmin,
    selectIsMultipleUsers,
    selectIsOwner,
    selectHasCurrentValueAccess,
    selectHasCustomCategAccess
};