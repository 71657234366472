import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { DownloadPDF } from "../../../download-pdf"
import { XIRRTablePDFPage } from "../../../shared/asset-wise-performanc-table/pdf"
import { XIRRBarChartPDFPage } from "../../../shared/xirr-bar-chart/pdf"
import { PMSXIRRCardPage, PMSXIRRCardPDFDownloadButton } from "../performance-pms/pdf";
import { XIRRCardPage, XIRRCardPDFDownloadButton } from "../../../shared/performance-xirr-chart-card/pdf";
import { XIRRBenchmarkChartPDFPage } from "../xirr-benchmark-chart/pdf";
import { AdvancedPerformanceChartPDFPage } from "../advanced-performance-chart/pdf";
import { CashflowChartPDFPage } from "../../../shared/cashflow-chart/pdf";
import { Alert, Box, Divider, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { CustomTypography } from "../../../shared";
import { ArrowDown } from "packages/web/src/assets/icons/ArrowDown";
import CustomModal from "../../../shared/custom-modal";
import ButtonText from "../../../shared/button-text";
import ButtonPrimaryCTA from "../../../shared/button-primary-cta";
import { CloseIcon } from "packages/web/src/assets/icons/CloseIcon";
import { useState } from "react";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { DownloadIconInButton } from "packages/web/src/assets/icons/downloadIconInButton";

export interface PerformanceCombinedPDFProps {
    performanceXIRRData: SharedConstants.XIRRTotalItem | undefined;
    showPerformanceByPortfolio?: boolean;
    showPerformanceByCategory?: boolean;
    isPerformanceLoading: boolean;
    showPMSView: boolean;
    rowFieldHeading?: string;
    rowFieldName: string;
    specificTitleText: string;
    advancedChartTitleText: string;
}

export const PerformanceCombinedPDF = ({performanceXIRRData, showPerformanceByPortfolio, showPerformanceByCategory, isPerformanceLoading, showPMSView, specificTitleText, rowFieldHeading, rowFieldName, advancedChartTitleText}: PerformanceCombinedPDFProps) => {
    const isLoading = (performanceXIRRData && !isPerformanceLoading && [SharedConstants.XIRRStatus.Completed, SharedConstants.XIRRStatus.NoInvestments].includes(performanceXIRRData.Status)) !== true;
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isLegacyProduct = useAppSelector(SharedRedux.Licensing.Selectors.selectIsLicenseLegacyProduct);

    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [startDownloadCombined, setStartDownloadCombined] = useState(false);

    const handleDownloadCombined = () => {
        setStartDownloadCombined(true);
        closeModal();
    }

    const [startDownloadSnapshot, setStartDownloadSnapshot] = useState(false);

    const handleDownloadSnapshot = () => {
        setStartDownloadSnapshot(true);
        handleClose();
    }

    if (isLoading || isLegacyProduct) {
        return <></>;
    }

    return (
        <>
            <Box
                onClick={handleClick}
                sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '9px 12px',
                    color: "#64677A",
                    border: "1px solid #D8DAE5",
                    borderRadius: '4px',
                    background: '#FFFFFF',
                    '&:hover': {
                        background: '#f6f6f6'
                    },
                    textTransform: 'unset',
                }}>
                <DownloadIconInButton />
                <CustomTypography variant="Regular16" sx={{ color: { xs: '#64677A', marginLeft: '8px' } }}>Download</CustomTypography>
                <ArrowDown style={{ padding: '0px 12px 0px 10px' }} />
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableAutoFocusItem={true}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem 
                    sx={{
                        '&:hover': {
                        backgroundColor: '#CFE3C9',
                        },
                        }}
                    onClick={handleDownloadSnapshot}>Snapshot</MenuItem>
                <MenuItem 
                    sx={{
                        '&:hover': {
                        backgroundColor: '#CFE3C9',
                        },
                        }}
                    onClick={()=> { openModal(); handleClose(); }}>Performance Report</MenuItem>
            </Menu>
            <CustomModal
                open={open}
                handleClose={closeModal}
            >
                <Box sx={{
                    width: { sm: '60%', md: '40%' },
                    background: "white",
                    borderRadius: "10px",
                }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{margin:"10px 10px 0px 30px"}}>
                        <CustomTypography variant="Medium24">
                            Performance Report PDF download
                        </CustomTypography>
                        <IconButton onClick={closeModal} disableRipple>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Stack px={4} marginBottom="32px">
                        <Alert severity="info" style={{borderRadius: "8px", fontSize: "16px", color: "#12131A"}}>
                            Please note that you will first need to generate the charts/tables that you wish to include in your Performance Report.<br/><br/>
                            Only those charts/tables that you have generated in the current Performance tab will be included in the downloaded report.
                        </Alert>
                    </Stack>
                    <Divider />
                    <Box sx={{ display: "flex", padding: "20px", justifyContent: "space-between", borderTop: "1px solid #EBECF2"}}>
                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center'}}>
                            <Box>
                                <ButtonText buttonText="Cancel" handleClick={() => closeModal()} buttonSize="large" />
                            </Box>
                            <Box>
                            <ButtonPrimaryCTA 
                                buttonText="Download" 
                                handleClick={handleDownloadCombined} 
                            />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </CustomModal>
            {
                startDownloadCombined &&
                <DownloadPDF
                    PDFPages={[
                        { PDFPage: showPMSView ? PMSXIRRCardPage : XIRRCardPage },
                        { PDFPage: XIRRBarChartPDFPage(specificTitleText) },
                        ...(showPMSView ? [{ PDFPage: CashflowChartPDFPage }] : []),
                        { PDFPage: XIRRBenchmarkChartPDFPage },
                        ...(showPerformanceByPortfolio ? [{ PDFPage: XIRRTablePDFPage(true, undefined, "Portfolio", SharedConstants.AdvancedPerformanceTableMode.Details)}] : []),
                        ...(showPerformanceByCategory ? [{ PDFPage: XIRRTablePDFPage(false, undefined, "Category", SharedConstants.AdvancedPerformanceTableMode.Details, undefined, true)}] : []),
                        { PDFPage: XIRRTablePDFPage(false, rowFieldHeading, rowFieldName, SharedConstants.AdvancedPerformanceTableMode.Details) },
                        { PDFPage: AdvancedPerformanceChartPDFPage(SharedConstants.AdvancedPerformanceChartMode.YearWise, advancedChartTitleText), IsAdvancedPerformanceChart: true },
                        { PDFPage: AdvancedPerformanceChartPDFPage(SharedConstants.AdvancedPerformanceChartMode.FYWise, advancedChartTitleText), IsAdvancedPerformanceChart: true },
                    ]}
                    ReportNameForFileName="Performance Report"
                    IsHideIcon
                    IsDownloadOnMount
                    OnDownloadComplete={() => setStartDownloadCombined(false)}
                />
            }
            {
                startDownloadSnapshot &&
                (
                    showPMSView ?
                    <PMSXIRRCardPDFDownloadButton IsHideIcon IsDownloadOnMount OnDownloadComplete={() => setStartDownloadSnapshot(false)}/>
                    :
                    <XIRRCardPDFDownloadButton IsHideIcon IsDownloadOnMount OnDownloadComplete={() => setStartDownloadSnapshot(false)}/>
                )
            }
        </>
    )
}