import { Box, Stack } from "@mui/material"
import { CustomTypography } from "../../../shared"
import ButtonWithTextAndIconTransparent from "../../../shared/button-with-label-and-icon-transparent"
import { PMSReportsIcon } from "packages/web/src/assets/icons/PMSReportsIcon"
import { useLocation, useNavigate } from "react-router-dom"
import routeNames from "packages/web/src/routes/routeNames"
import { PerformanceCombinedPDF, PerformanceCombinedPDFProps } from "../performance-combined-pdf"

interface PerformanceHeaderProps extends PerformanceCombinedPDFProps {
    isPerformanceViewAllowed: boolean;
}

export const PerformanceHeader = (
    {
        performanceXIRRData, showPerformanceByPortfolio, showPerformanceByCategory, isPerformanceLoading, showPMSView, specificTitleText, rowFieldHeading, rowFieldName, advancedChartTitleText,
        isPerformanceViewAllowed
    }: PerformanceHeaderProps
) => {
    const navigate = useNavigate();
    const location = useLocation();
    
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{padding: "0 40px", paddingTop: "20px"}}>
            <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px'}}>
                <CustomTypography
                    variant="Medium28"
                    sx={{
                        color: '#12131A',
                    }}
                >
                    Performance Snapshot
                </CustomTypography>
                {/* {
                    PerformanceXIRRData && !IsPerformanceLoading && [SharedConstants.XIRRStatus.Completed, SharedConstants.XIRRStatus.NoInvestments].includes(PerformanceXIRRData.Status) &&
                    <PMSXIRRCardPDFDownloadButton />
                } */}
            </Box>
            <Stack direction="row" gap={1}>
                {/* <IconButton disableRipple sx={{ border: '1px solid #D8DAE5', backgroundColor: 'white', borderRadius: '4px', '&:hover': { backgroundColor: '#EFF0F5' }, }}>
                    <PrinterIcon />
                </IconButton> */}
                {
                    showPMSView &&
                    <ButtonWithTextAndIconTransparent
                        buttonlabel="View Cashflows"
                        labelColor="#5F6F57"
                        startIcon={<PMSReportsIcon color="#5F6F57" />}
                        onClick={() => navigate({ pathname: routeNames.CASHFLOWS, search: location.search })}
                    />
                }
                {
                    isPerformanceViewAllowed &&
                    <PerformanceCombinedPDF
                        performanceXIRRData={performanceXIRRData}
                        isPerformanceLoading={isPerformanceLoading}
                        showPerformanceByPortfolio={showPerformanceByPortfolio}
                        showPerformanceByCategory={showPerformanceByCategory}
                        showPMSView={showPMSView}
                        specificTitleText={specificTitleText}
                        rowFieldHeading={rowFieldHeading}
                        rowFieldName={rowFieldName}
                        advancedChartTitleText={advancedChartTitleText}
                    />
                }
            </Stack>
        </Stack>
    )
}