import { Box, Button } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CloseIcon } from "packages/web/src/assets/icons/CloseIcon";
import CustomTypography from "../../shared/customTypography";
import CustomModal from "../../shared/custom-modal";
import ButtonPrimaryCTA from "../../shared/button-primary-cta";
import { ErrorIcon } from "packages/web/src/assets/icons/errorIcon";
import { SharedConstants } from "@mprofit/shared";
import { InfoBox } from "../info-box";

interface ConfirmModalProps {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    headerText?: string;
    subHeaderText?: string;
    infoText?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    confirmButtonTextOnIgnoreValidationError?: string;
    onCancel?: () => void;
    onConfirm?: (options?: Record<string, any>) => void;
    apiResponse?: SharedConstants.FormSubmissionResponse;
    isDelete?: boolean
}

export default function ConfirmModal({
    isModalOpen,
    setIsModalOpen,
    headerText,
    subHeaderText,
    infoText,
    cancelButtonText = "Cancel",
    confirmButtonText = "Delete",
    confirmButtonTextOnIgnoreValidationError = "Delete Anyway",
    onCancel,
    onConfirm,
    apiResponse,
    isDelete
}: ConfirmModalProps) {
    const [isIgnoringValidation, setIsIgnoringValidation] = useState(false);

    useEffect(() => {
        if (apiResponse && apiResponse.IsSuccess) {
            setIsModalOpen(false);
            setIsIgnoringValidation(false);
        }
    }, [apiResponse, setIsModalOpen]);

    const showErrorMessage = apiResponse && !apiResponse.IsSuccess;
    const errorMessage = showErrorMessage
        ? (!apiResponse.IsValid 
            ? apiResponse.ErrorMessage 
            : "There was an error submitting your request. Please check your data and try again. If this error persists, please contact MProfit Support.")
        : null;

    const isConfirmButtonDisabled = showErrorMessage && !apiResponse.IsConfirmation;

    const currentConfirmButtonText = (showErrorMessage && apiResponse.IsConfirmation) 
        ? confirmButtonTextOnIgnoreValidationError
        : confirmButtonText;

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsIgnoringValidation(false);
        onCancel?.();
    };

    const handleConfirmClick = () => {
        if (apiResponse && !apiResponse.IsSuccess && apiResponse.IsConfirmation && !isIgnoringValidation) {
            setIsIgnoringValidation(true);

            const options: Record<string, any> = {};
            if (apiResponse.ValidationType) {
                if (apiResponse.IsIgnoreInQueryParams) {
                    options[`Ignore${apiResponse.ValidationType}`] = true;
                    // options.IsIgnoreInQueryParams = true;
                } else {
                    options[`Ignore${apiResponse.ValidationType}`] = true;
                    // options.ValidationType = apiResponse.ValidationType;
                    // options.IsIgnoreInQueryParams = false;
                }
            }

            onConfirm?.(options);
        } else {
            onConfirm?.();
        }
    };

    return (
        <CustomModal open={isModalOpen} handleClose={handleCloseModal}>
            <Box sx={{display: "flex", flexDirection: "column", flexShrink: "0", width: "35vw", backgroundColor: "#FFFFFF", borderRadius: "8px"}}>
                {/* header */}
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "24px 24px 12px 24px"}}>
                    <CustomTypography variant="Medium24" sx={{lineHeight: "32px"}}>
                        {headerText}
                    </CustomTypography>
                    <Box sx={{cursor: "pointer", width: "40px", height: "40px", flexShrink: "0", display: "flex", alignItems: "center", justifyContent: "center"}} onClick={handleCloseModal}>
                        <CloseIcon color="#12131A" />
                    </Box>
                </Box>
                {/* content */}
                <Box sx={{padding: "0 24px"}}>
                    <CustomTypography variant="Regular20" sx={{lineHeight: "30px"}}>
                        {subHeaderText}
                    </CustomTypography>
                </Box>
                {/* error message */}
                <Box sx={{padding: "0 24px", minHeight: "40px"}}>
                {showErrorMessage && (
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "flex-start", gap: "12px", justifyContent: "flex-start", background: "#FFF5F5", padding: "16px", borderRadius: "8px", border: "1px solid #FFE5E5", margin: "24px 0 40px 0"}}>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "24px", height: "24px"}}>
                            <ErrorIcon color= "#C94C40" size={24}/>
                        </Box>
                        <CustomTypography variant='Regular16' color='#12131A' sx={{ lineHeight: "24px" }}>{errorMessage}.</CustomTypography>
                    </Box>
                )}
                {
                    infoText && !showErrorMessage &&
                    <Box sx={{ margin: "24px 0 24px 0" }}>
                        <InfoBox message={infoText} />
                    </Box>
                }
                </Box>
                {/* footer */}
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "16px", padding: "20px 24px", borderTop: "1px solid #D8DAE5"}}>
                    <Box>
                        <Button onClick={handleCloseModal} sx={{ backgroundColor: "#FFFFFF", color: "#64677A", border: "1px solid #D8DAE5", padding: "14px 32px", fontSize: "16px", fontWeight: "500", textAlign: "center", textTransform: "none", maxHeight:"48px" }}>{cancelButtonText}</Button>
                    </Box>
                    <Box>
                        <ButtonPrimaryCTA
                            handleClick={handleConfirmClick}
                            buttonText={currentConfirmButtonText}
                            disabled={isConfirmButtonDisabled}
                            maxHeight="48px"
                            sxProps={{backgroundColor: isDelete ? "#C94C40" : "black", ":hover": {backgroundColor: isDelete ? "#C94C40" : "black"}, ":focus": {backgroundColor: isDelete ? "#C94C40" : "black"}, ...(isConfirmButtonDisabled && {backgroundColor: "#E8E9EF", color: "#A6A9BC", ":hover": {backgroundColor: "#E8E9EF"}, ":focus": {backgroundColor: "#E8E9EF"}})}}
                        />
                    </Box>
                </Box>
            </Box>
        </CustomModal>
    );
}
