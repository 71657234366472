import { useEffect, useState } from 'react'
import { Box, Button, Grid, Stack } from '@mui/material'
import NavigationTabs from '../../../shared/navigation-tabs'
import styles from './index.module.css'
import DropDown from '../../../shared/drop-down'
import { DocumentIcon } from '../../../../assets/icons/documentIcon'
import { PreferenceIcon } from '../../../../assets/icons/preferenceIcon'
import ButtonPrimaryCTA from '../../../shared/button-primary-cta'
import { PlusIcon } from '../../../../assets/icons/plusIcon'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import CustomTypography from '../../../shared/customTypography'
import { getPathnameFromDashboardTab } from 'packages/web/src/routes/navigationMethods'
import { SharedConstants, SharedRedux, SharedUtilities } from '@mprofit/shared'
import SecondaryIconButton from '../../../shared/secondary-icon-button'
import { ArrowDown } from 'packages/web/src/assets/icons/ArrowDown'
import CustomModal from '../../../shared/custom-modal'
import PeriodSelector from '../../../shared/period-selector'
import NavigationSecondaryTabs from '../../../shared/navigation-secondary-tabs'
import { HoldingTabs } from '../../holding/holdingTabs'
import { MPrUrlParams } from 'packages/web/src/classes/MPrUrlParams'
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks'
import { ScreenEnum } from 'packages/shared/src/constants'
import BreadCrumbComponent from '../../../shared/breadcrumbs'
import { PerformanceAssetTypesSelect } from '../../../shared/performance-assettypes-select'
import { SetPerformancePeriodModal } from '../set-performance-period-modal'

interface DashboardHeaderProps {
  MPrUrlParams?: MPrUrlParams
}

export default function DashboardHeader({ MPrUrlParams }: DashboardHeaderProps) {

  const navigate = useNavigate();
  const location = useLocation();
  let currentPath = location.pathname.split("/")[2];

  const activeScreen = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreen);
  const activeAssetAllocationCategID = useAppSelector(SharedRedux.Licensing.Selectors.selectActiveDefaultOrCustomAssetAllocationCategID);
  const activeAssetType = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);

  const [activeTab, setActiveTab] = useState<SharedConstants.DashboardTabs>(SharedConstants.DashboardTabs.TODAY);

  useEffect(() => {
    if (currentPath === "today") setActiveTab(SharedConstants.DashboardTabs.TODAY)
    else if (currentPath === "holding") setActiveTab(SharedConstants.DashboardTabs.HOLDING)
    else setActiveTab(SharedConstants.DashboardTabs.PERFORMANCE)
  }, [currentPath])

  const handleNavTabs = (event: any, newValue: SharedConstants.DashboardTabs) => {
    if (newValue !== null) {
      var pathname = getPathnameFromDashboardTab(newValue);
      if (newValue !== activeTab) {
        setActiveTab(newValue);
        navigate({ pathname: pathname, search: location.search });
      }
    }
  }

  const isPerformancePeriodAllToDate = useAppSelector(SharedRedux.Performance.Selectors.selectIsPeriodAllToDate);
  const isCashMgmtPortfolio = useAppSelector(SharedRedux.Licensing.Selectors.selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck);

  const hasCurrentValueAccess = useAppSelector(SharedRedux.Login.Selectors.selectHasCurrentValueAccess);
  const activeDatabase = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveDatabase)
  const IsGroup: boolean = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsActivePortfolioAGroup);
  
  const isAssetAllocationPerformanceViewEnabled = useAppSelector(SharedRedux.Licensing.Selectors.selectIsAssetAllocationPerformanceViewEnabled);
  const isAdvancedEquityExposureEnabled = useAppSelector(SharedRedux.Licensing.Selectors.selectIsAdvancedEquityExposureEnabled);

  const hidePerformanceViewAccessForShared = activeDatabase?.IsShared && activeDatabase?.CanAccessPerformanceViewForSharedData !== true;

  const isHidePerformanceTab = hidePerformanceViewAccessForShared || !hasCurrentValueAccess || activeScreen === ScreenEnum.L3 || activeScreen === ScreenEnum.L3_Category
    || (!isAssetAllocationPerformanceViewEnabled && activeScreen && activeScreen < ScreenEnum.L4 && activeAssetAllocationCategID && activeAssetAllocationCategID > 0)
    || (activeAssetType && SharedUtilities.isXIRRNotSupportedForAssetType(activeAssetType));

  const hideAllTabs = activeScreen === ScreenEnum.L2_AllStocks || activeScreen === ScreenEnum.L2_AllStocks_AA;

  const getBreadCrumbRightItems = () => {
    if (!isAdvancedEquityExposureEnabled) return null;
    if (activeScreen === SharedConstants.ScreenEnum.L2_AllStocks) {
      return <SecondaryTabsForEquityExposure tabs={SharedUtilities.getTabsForEquityExposureBreakUpScreen()} defaultTab={SharedConstants.HoldingsMode.Assets}/>;
    } else if (activeScreen === SharedConstants.ScreenEnum.L2_AllStocks_AA) {
      return <SecondaryTabsForEquityExposure tabs={SharedUtilities.getTabsForEquityExposureDetailedBreakUpScreen(IsGroup)} defaultTab={SharedConstants.HoldingsMode.AssetAllocation}/>;
    }
    return null;
};

  return (
    <Grid container>
      <Grid item xs={12}>
        <BreadCrumbComponent rightItems={getBreadCrumbRightItems()} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', maxHeight: '80px' }}>
          {
            !hideAllTabs &&
            <NavigationTabs
              tabIDs={[SharedConstants.DashboardTabs.TODAY, SharedConstants.DashboardTabs.HOLDING, ...(isHidePerformanceTab ? [] : [SharedConstants.DashboardTabs.PERFORMANCE])]}
              tabTexts={{ [SharedConstants.DashboardTabs.TODAY]: 'Today', [SharedConstants.DashboardTabs.HOLDING]: 'Holdings', [SharedConstants.DashboardTabs.PERFORMANCE]: 'Performance' }}
              handleChange={handleNavTabs}
              activeID={activeTab}
              tabExtraClassNames={{ [SharedConstants.DashboardTabs.TODAY]: 'apptour-tabs-today', [SharedConstants.DashboardTabs.HOLDING]: 'apptour-tabs-holdings', [SharedConstants.DashboardTabs.PERFORMANCE]: 'apptour-tabs-performance' }}
            />
          }
          <HeaderRightItems activeTab={activeTab} MPrUrlParams={MPrUrlParams} />
        </Box>
        {
          !isPerformancePeriodAllToDate && ((MPrUrlParams?.Screen === SharedConstants.ScreenEnum.L1 && (isCashMgmtPortfolio === false)) || (MPrUrlParams?.Screen === SharedConstants.ScreenEnum.L1_AA || MPrUrlParams?.Screen === SharedConstants.ScreenEnum.L1_Custom_AA || MPrUrlParams?.Screen === SharedConstants.ScreenEnum.L1_Custom_AA_Subcategory)) && activeTab === SharedConstants.DashboardTabs.PERFORMANCE &&
          <Box sx={{ marginTop: '20px' }}>
            <PerformanceAssetTypesSelect />
          </Box>
        }
      </Grid>
    </Grid>

  )
}

const HeaderRightItems = ({ activeTab, MPrUrlParams }: { activeTab: SharedConstants.DashboardTabs, MPrUrlParams?: MPrUrlParams }) => {
  switch (activeTab) {
    case SharedConstants.DashboardTabs.TODAY:
      return (
        <Box sx={{ display: 'flex', marginLeft: "15px", overflow: 'auto', '::-webkit-scrollbar': { width: '1px', } }}>
          {/* <Box sx={{ marginRight: '15px' }}>
            <DropDown
              inputList={[]}
              inputValue={"Actions"}
              startIcon={<DocumentIcon />}
              handleChange={handleDropDown}
            />
          </Box> */}
          {/* <Box>
            <DropDown
              inputList={[]}
              inputValue={"Prefrences"}
              startIcon={<PreferenceIcon />}
              handleChange={handleDropDown}
            />
          </Box> */}

          {/* <ButtonPrimaryCTA
            buttonText={"Add"}
            startIcon={<PlusIcon />}
          /> */}
        </Box>
      )
    case SharedConstants.DashboardTabs.HOLDING:
      if (!MPrUrlParams) {
        return <></>;
      }
      return (
        <HoldingTabs MPrUrlParams={MPrUrlParams} />
      )
    case SharedConstants.DashboardTabs.PERFORMANCE:
      return (
        <PerformancePeriodDropdown />
      )
    default:
      return <></>
  }
}

const PerformancePeriodDropdown = () => {
  const [showPerformancePeriodModal, setShowPerformancePeriodModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const periodText = useAppSelector(SharedRedux.Performance.Selectors.selectActivePeriodText);

  const handleDropDown = () => {
    window.scrollTo(0, 0);
    setShowPerformancePeriodModal(true)
  }

  const isTradedBonds = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsActiveAssetTypeTradedBonds);
  return (
    <Box className={styles.performanceContainer}>
      <CustomTypography
        variant="Regular18"
        sx={{ color: { xs: "#64677A" }, paddingRight: "17px" }}
      >
        Period
      </CustomTypography>
      <Box sx={{ backgroundColor: "#fff" }}>
        <Button
          disableRipple
          endIcon={isTradedBonds ? undefined : <ArrowDown />}
          sx={{
            textTransform: "none",
            border: "1px solid #D8DAE5",
            padding: "6px 16px 6.5px 16px",
            color: "#64677A",
            fontSize: "18px",
            fontWeight: "400",
            '&.Mui-disabled': {
              color: '#64677A'
            }
          }}
          onClick={() => handleDropDown()}
          disabled={isTradedBonds}
        >
          {periodText}
        </Button>
        {
          showPerformancePeriodModal && 
          <SetPerformancePeriodModal showPerformancePeriodModal={showPerformancePeriodModal} setShowPerformancePeriodModal={setShowPerformancePeriodModal} />
        }
      </Box>
    </Box>
  );
}

const SecondaryTabsForEquityExposure = ({ tabs, defaultTab }: { tabs: { id: number; name: string; }[], defaultTab: SharedConstants.HoldingsMode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabID = parseInt(searchParams.get('tab') || (''+defaultTab));

  const setActiveTab = (newActiveTabID: string | number) => {
      if (newActiveTabID === null || (newActiveTabID === activeTabID)) return;
      let updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set('tab', '' + newActiveTabID);
      setSearchParams(updatedSearchParams.toString());
  }

  return (
      <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
      }}>
          <CustomTypography
              variant="Regular18"
              sx={{
                  color: '#64677A',
                  marginRight: '21px',
                  minWidth: 'max-content'
              }}
          >
              View by
          </CustomTypography>
          <NavigationSecondaryTabs
              tabs={tabs}
              setActiveTab={setActiveTab}
              activeTabID={activeTabID}
              bgColor="#ffffff"
          />
      </Box>
  );
};