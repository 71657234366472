import React from 'react';
import { Box } from '@mui/material';
import { LeftArrow } from 'packages/web/src/assets/icons/LeftArrow';
import CustomTypography from '../customTypography';
import { useLocation, useNavigate } from 'react-router-dom';

interface BackButtonProps {
  title: string;
  navigateTo?: string;
  handleNavigate?: () => void;
}

export const BackButton: React.FC<BackButtonProps> = ({ 
  title, 
  navigateTo,
  handleNavigate
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Box 
      display="flex"
      alignItems="center"
      gap="4px"
      onClick={() => handleNavigate ? handleNavigate() : navigate({
        pathname: navigateTo,
        search: location.search
      })}
      sx={{ cursor: 'pointer' }}
    >
      <LeftArrow />
      <CustomTypography variant='Medium16' color='#5F6F57' sx={{lineHeight: "18.96px"}}>
        {title}
      </CustomTypography>
    </Box>
  );
};
